import { toHaveDisplayValue } from '@testing-library/jest-dom/matchers';
import React, { Component } from 'react';
import Organization from './organization';
import Curriculums from './curriculums';
import Module from './module';
import AdminUser from './adminUser';
import { baseUrlForLongRunningTask, primaryColor } from '../../../store/apiConstants';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import withRouter from '../../lib/withRouter';
const createOrganizationFields = require('./organization/createOrganization.json');
const orgFormFields = createOrganizationFields;

class CreateOrg extends Component {
    constructor(props) {
        super(props);
        this.orgFormFields = getFormFields(createOrganizationFields);
        this.state = {
            currentTab: 0,
            steps: 0,
            organizationDetails: this.props.editOrganization || this.orgFormFields.data,
            orgFormValidations: this.orgFormFields.formValidations,
            curriculums: {
                boardsList: [{ boardId: '', classes: [], boardName: '', grade: [] }]
            },
            verify: {
                emailOperationalOtp: '',
                emailOperational: false,
                emailOperationalOtpSent: false,
                emailOperationalOtpSentCount: 0,
                emailAccountsOtp: '',
                emailAccounts: false,
                emailAccountsOtpSent: false,
                emailAccountsOtpSentCount: 0,
                mobileOperationalOtp: '',
                mobileOperational: false,
                mobileOperationalOtpSent: false,
                mobileOperationalOtpSentCount: 0,
                mobileAccountsOtp: '',
                mobileAccounts: false,
                mobileAccountsOtpSent: false,
                mobileAccountsOtpSentCount: 0
            },
            assignedModules: this.props.editOrganization?.assignedModules || []
        };
        this.service = new Service();
    }
    componentDidMount() {
        console.log(this.props);
        const curriculums = {
            boardsList: []
        };
        if (this.props.editOrganization?.assignedCurriculums.length > 0) {
            this.props.editOrganization.assignedCurriculums.forEach(({ boardId, classId, className, groupId, groupName, assigned }) => {
                let board = curriculums.boardsList.find((b) => b.boardId === boardId);
                if (!board) {
                    board = { boardId, classes: [], boardName: '', grade: [] };
                    curriculums.boardsList.push(board);
                }
                const classEntry = {
                    classId,
                    label: className,
                    groupId,
                    groupName,
                    assigned
                };
                board.grade.push(classEntry);
                board.classes.push(classEntry);
            });
            console.log(curriculums);
            this.setState({
                curriculums: curriculums
            });
        }
    }
    onSubmitClick = async (data) => {
        const { organizationDetails, curriculums, assignedModules } = this.state;
        const assignedCurriculums = [];
        curriculums.boardsList.forEach((curriculum) => {
            curriculum.grade.forEach((grade) => {
                assignedCurriculums.push({ boardId: curriculum.boardId, classId: grade.classId, className: grade.label, groupId: grade.groupId, groupName: grade.groupName, assigned: grade.assigned });
            });
        });
        const payload = {
            ...organizationDetails,
            assignedCurriculums: assignedCurriculums,
            assignedModules: assignedModules,
            superAdmin: data
        };
        console.log(payload);
        if (this.props.editOrganization) {
            let url = `${baseUrlForLongRunningTask}/organization/update`;
            this.setState({
                isLoading: true
            });
            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                            isShowCreateSuccess: true
                        });
                        this.toast.show({ severity: 'success', summary: 'ORG UPDATED', detail: 'Organization successfully updated', life: 3000 });

                        this.props.setCreateOrEdit();
                        //   this.toast.show({ severity: 'success', summary: 'ORG updated', detail: 'Organization successfully updated', life: 3000 });

                        // thisObj.props.onCreateOrEditSuccess();
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({
                            severity: 'error',
                            summary: 'Some error occured',
                            detail: res.errMessage,
                            life: 3000
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Some error occured',
                        detail: e.message,
                        life: 3000
                    });
                    console.log(e);
                });
        } else {
            let url = `${baseUrlForLongRunningTask}/organization/create`;
            this.setState({
                isLoading: true
            });

            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                            isShowCreateSuccess: true
                        });
                        this.toast.show({ severity: 'success', summary: 'ORG CREATED', detail: 'Organization successfully created', life: 3000 });
                        this.props.setCreateOrEdit();
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({
                            severity: 'error',
                            summary: 'Some error occured',
                            detail: res.errMessage,
                            life: 3000
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Some error occured',
                        detail: e.message,
                        life: 3000
                    });
                    console.log(e);
                });
        }
    };
    sendOrganizationDetails = (data) => {
        console.log(data);
        this.setState({
            organizationDetails: data
        });
    };
    sendCurriculumsDetails = (data) => {
        console.log(data);
        this.setState({
            curriculums: data
        });
    };
    sendModules = (data) => {
        this.setState({
            assignedModules: data
        });
    };

    onTabChange = (value) => {
        this.setState({ currentTab: value });
    };
    onNextStep = (value) => {
        this.setState({ steps: value });
    };
    render() {
        return (
            <div>
                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tabheadText"
                                        onClick={() => {
                                            this.onTabChange(0);
                                        }}
                                    >
                                        Organization
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            console.log(this.state.currentTab);
                                            if (this.state.steps >= 1) this.onTabChange(1);
                                        }}
                                    >
                                        Curriculums
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            if (this.state.steps >= 2) this.onTabChange(2);
                                        }}
                                    >
                                        Module
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            if (this.state.steos >= 3) this.onTabChange(3);
                                        }}
                                    >
                                        Admin User
                                    </p>
                                </div>
                                {/* <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                            <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                        </div> */}
                            </div>
                        </div>
                    </div>
                    {this.state.currentTab == 0 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.BOARDS_MANAGEMENT_LIST}> */}
                            <Organization
                                currentTab={this.state.currentTab}
                                currentTabChange={this.onTabChange}
                                stepChange={this.onNextStep}
                                sendDetails={this.sendOrganizationDetails}
                                org={this.state.organizationDetails}
                                formValidations={this.state.orgFormValidations}
                                verify={this.state.verify}
                                editOrg={this.props.editOrganization}
                                setCreateOrEdit={this.props.setCreateOrEdit}
                            />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 1 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.GRADE_AND_SECTION_LIST}> */}
                            <Curriculums
                                stepChange={this.onNextStep}
                                editCurriculum={this.props.editOrganization}
                                currentTab={this.state.currentTab}
                                currentTabChange={this.onTabChange}
                                sendDetails={this.sendCurriculumsDetails}
                                curriculums={this.state.curriculums}
                                setCreateOrEdit={this.props.setCreateOrEdit}
                            />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 2 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.SUBJECT_MANAGEMENT_LIST}> */}
                            <Module
                                stepChange={this.onNextStep}
                                editModule={this.props.editOrganization}
                                currentTab={this.state.currentTab}
                                currentTabChange={this.onTabChange}
                                sendDetails={this.sendModules}
                                assignedModules={this.state.assignedModules}
                                setCreateOrEdit={this.props.setCreateOrEdit}
                            />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 3 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.CONTENT_MANAGEMENT_LIST}> */}
                            <AdminUser
                                stepChange={this.onNextStep}
                                setCreateOrEdit={this.props.setCreateOrEdit}
                                editAdmin={this.props.editOrganization}
                                currentTab={this.state.currentTab}
                                currentTabChange={this.onTabChange}
                                onSubmitClick={this.onSubmitClick}
                            />
                            {/* </Authorizer> */}
                        </>
                    )}
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}
export default withRouter(CreateOrg);
