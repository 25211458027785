import { Button } from 'primereact/button';
import React, { Component } from 'react';
import { AddIcon2, DeleteIconClasset, MailIcon } from '../../../svgIcons';
import { getBoardsData } from '../../../../store/actions';
import withRouter from '../../../lib/withRouter';
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import Authentication from '../../../session';
import ClassetDropdown from '../../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../../classetComponents/classetMultiSelect';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import classes from '../../../boardsMaster/classes';

class Curriculums extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curriculums: this.props.curriculums
        };
        this.service = new Service();
    }

    componentDidMount() {
        console.log(this.props.boardsDropDownOptions);
    }

    handleRemoveBoard = (index) => {
        const { curriculums } = this.state;
        if (curriculums?.boardsList?.length > 1) {
            const boardsListCopy = [...curriculums.boardsList];
            boardsListCopy.splice(index, 1);
            this.setState((prevState) => ({
                curriculums: { ...prevState.curriculums, boardsList: boardsListCopy }
            }));
        }
    };

    onChangeBoard = (boardId, indexPosition, boardName) => {
        const len = this.state.curriculums.boardsList.length;
        const Board = this.state.curriculums.boardsList[indexPosition];

        if (!boardId || boardId === '') {
            const UpdatedBoardList = this.state.curriculums.boardsList.map((each, index) => {
                if (indexPosition === index) {
                    return { ...each, boardId: '', classes: [], grade: [] };
                }
                return each;
            });

            this.setState((prevState) => ({
                curriculums: { ...prevState.curriculums, boardsList: UpdatedBoardList }
            }));

            return;
        }

        const _classes = [];
        const selectedBoard = this.props.boardsDropDownOptions?.find((board) => board.boardId === boardId);

        if (selectedBoard) {
            if (selectedBoard.classes?.length > 0) {
                selectedBoard.classes.forEach((item) => {
                    _classes.push({
                        label: item.className,
                        classId: item.classId,
                        groupId: item?.groups ? item?.groups[0]?.groupId : '',
                        groupName: item?.groups ? item?.groups[0]?.groupName : '',
                        assigned: item?.groups ? item?.groups[0]?.isGroupActivity : ''
                    });
                });
            }
        }

        const UpdatedBoardList = this.state.curriculums.boardsList.map((each, index) => {
            if (indexPosition === index) {
                return { ...each, boardId, classes: _classes, boardName: selectedBoard.boardName };
            }
            return each;
        });

        this.setState((prevState) => ({
            classes: _classes,
            curriculums: { ...prevState.curriculums, boardsList: UpdatedBoardList }
        }));
    };

    onChangeClass = (classId, indexPosition) => {
        const UpdatedClassList = this.state?.curriculums?.boardsList?.map((each, index) => {
            if (indexPosition === index) {
                return { ...each, grade: classId };
            } else {
                return each;
            }
        });

        this.setState((prevState) => ({
            curriculums: { ...prevState.curriculums, boardsList: UpdatedClassList }
        }));
    };

    sendCurriculums = () => {
        const curriculums = [];
        const len = this.state.curriculums.boardsList?.length;
        const lastBoard = this.state.curriculums.boardsList[len - 1];
        if (lastBoard?.boardId.length === 0 || lastBoard.classes?.length === 0 || lastBoard.grade?.length === 0) {
            return this.toast.show({ severity: 'error', summary: 'Fill the fields', life: 3000 });
        }
        this.props.sendDetails(this.state.curriculums);
        this.props.stepChange(this.props.currentTab + 1);
        this.props.currentTabChange(this.props.currentTab + 1);
    };

    render() {
        const { curriculums } = this.state;

        return (
            <div>
                <div className="grid mb-6 mt-2">
                    <div className="col-12 flex justify-content-end align-items-center">
                        <Button
                            className="addButtonn"
                            onClick={() => {
                                const len = curriculums.boardsList.length;
                                const lastBoard = curriculums.boardsList[len - 1];
                                if (lastBoard?.boardId.length === 0 || lastBoard.classes.length === 0 || lastBoard.grade.length === 0) {
                                    return this.toast.show({ severity: 'error', summary: 'Fill the fields', life: 3000 });
                                }

                                this.setState((prevState) => ({
                                    curriculums: { ...curriculums, boardsList: [...prevState.curriculums.boardsList, { board: '', grade: [], classes: [] }] }
                                }));
                            }}
                        >
                            <AddIcon2 />
                            <p className="">Add Curriculums</p>
                        </Button>
                    </div>

                    {curriculums.boardsList?.map((boardType, index) => (
                        <div className="flex align-content-center" key={index}>
                            <div className="flex mt-5 justify-content-evenly">
                                <div className="col-4 px-6 flex justify-content-center align-content-center">
                                    <ClassetDropdown
                                        className="dialogCardInField border-round-md shadow-2 text-xl pl-2 pt-2"
                                        options={this.props.boardsDropDownOptions}
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        value={boardType.boardId}
                                        onChange={(e) => this.onChangeBoard(e.value, index, boardType?.boardName)}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span className="text-xl ml-4 body-medium">Select Board</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="col-4 px-6 flex justify-content-center align-content-center">
                                    <ClassetMultiSelect
                                        value={boardType.grade}
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        onChange={(e) => this.onChangeClass(e.value, index)}
                                        options={boardType.classes}
                                        optionLabel="label"
                                        className="dialogCardInFiel text-xl shadow-2 pl-2"
                                        maxSelectedLabels={3}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center mt-1">
                                                <span className="text-xl ml-4 body-medium">Select Grades</span>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>

                            <div className="pt-5 flex justify-content-start align-items-center cursor-pointer" onClick={() => this.handleRemoveBoard(index)}>
                                <DeleteIconClasset width={22} height={22} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="text-center col-12 flex gap-4 justify-content-center mt-4">
                    <Button
                        label="Save and proceed to next"
                        className="confirmDialogAcceptBtn px-6 mr-5 w-30rem"
                        onClick={() => {
                            this.sendCurriculums();
                        }}
                    />
                    <Button label="Back" className="confirmDialogCancelBtn" onClick={() => this.props.currentTabChange(this.props.currentTab - 1)} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" style={{}} onClick={() => this.props.setCreateOrEdit()} />
                </div>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions) || [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});

export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(Curriculums)));
