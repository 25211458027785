import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar';

import React, { Component } from 'react'
import { getFormFields, isFormValid, onTextChange } from '../../../utile/formHelper';
import { trimObj } from '../../../utile';
import LoadingComponent from '../../loadingComponent';
import Service from '../../../services';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { baseUrlAdmin } from '../../../store/apiConstants';



const formFields = [
    {
        "Type": "Text",
        "Label": "Admission Type",
        "FieldName": "admissionType",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Admission Code",
        "FieldName": "admissionCode",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Desciption",
        "FieldName": "desciption",
        "Required": true
    }
]

export default class CreateAdmissionType extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields);
        this.state = {
            admission: this.props.editData ? this.props.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false

        }

        this.service = new Service()
    }


    footer = () => {
        return (<div>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
            <Button label={`${this.props.editData ? 'Update' : 'Add'}`} className='p-button-raised' onClick={this.onSubmitClick} />

        </div>)
    }

    onSubmitClick = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.admission));

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let body = this.state.admission
            console.log('code body', body)
            if (this.props.editData) {
                this.setState({
                    isLoading: true
                });
                      const academicYear = localStorage.getItem('userAcademicYear');
                    let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`

                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate(true))
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })

            } else {
                this.setState({
                    isLoading: true
                })
                const academicYear = localStorage.getItem('userAcademicYear');
                let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })
            }
        }
    }



    render() {
        const { formValidations, admission } = this.state
        return (
            <Dialog
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                visible={true} style={{ maxWidth: '30%', minWidth: '380px' }}
                footer={this.footer} headerClassName='text-center'
                header={`${this.props.editData ? 'Update' : 'Add'} Admission Type`}
            >
                <div className="grid mt-2">
                    <div className="col-4">
                        <p className='text-label'>Admission Type<span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8">
                        <InputText className='p-inputtext-style1'
                            value={admission.admissionType}
                            onChange={(e) => { onTextChange(e.target.value, 'admissionType', this, formFields, admission, formValidations, 'admission', 'formValidations') }}
                        />
                        {formValidations && !formValidations.fields['admissionType'].isValid && <p className="p-error">{formValidations.fields['admissionType'].errorMsg}</p>}
                    </div>
                    <div className="col-4">
                        <p className='text-label'>Admission Code<span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8">
                        <InputText className='p-inputtext-style1'
                            value={admission.admissionCode}
                            onChange={(e) => { onTextChange(e.target.value, 'admissionCode', this, formFields, admission, formValidations, 'admission', 'formValidations') }}
                        />
                        {formValidations && !formValidations.fields['admissionCode'].isValid && <p className="p-error">{formValidations.fields['admissionCode'].errorMsg}</p>}
                    </div>

                    <div className="col-4">
                        <p className='text-label'>Desciption<span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8">
                        <InputText className='p-inputtext-style1'
                            value={admission.desciption}
                            onChange={(e) => { onTextChange(e.target.value, 'desciption', this, formFields, admission, formValidations, 'admission', 'formValidations') }}
                        />
                        {formValidations && !formValidations.fields['desciption'].isValid && <p className="p-error">{formValidations.fields['desciption'].errorMsg}</p>}
                    </div>
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog>

        )
    }
}
