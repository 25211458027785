import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import Authentication from '../../../../session';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../../store/apiConstants';
import { FileTypes, warningDailogInit } from '../../../../utile';
import {
    getBoardsData
} from '../../../../store/actions';
import { Toast } from 'primereact/toast';
import _ from 'lodash';
import withRouter from '../../../lib/withRouter';
import ClassetDropdown from '../../../../classetComponents/classetDropDown';

class AssignQuizToContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: null,
            warningDialog: warningDailogInit,
            globalFilter: '',
            examToAssign: this.props.examToAssign
            //contests[3],

        }
        this.service = new Service();
    }




    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData(true);
        }
    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a,b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                content: null,
                filteredContentDetails: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: []
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopicResource = [];
        let selectedTopic = this.state.topics.filter(t => t.topicId == topicId)
        //let selectedTopicResourceTypes = selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive && r.value == FileTypes.QUIZ) : [];
        let selectedTopicResourceTypes = selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.value == FileTypes.QUIZ) : [];

        if (selectedTopicResourceTypes && selectedTopicResourceTypes.length) {
            selectedTopicResourceTypes = selectedTopicResourceTypes.map((a, index) => {
                if (index == 0) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            })
        } else {

            this.toast.show({ severity: 'error', summary: 'Please enable quiz for the selected topic in Curriculum master', detail: ' ', life: 3000 });
        }

        this.setState(
            {
                topicId,
                selectedTopicResourceTypes,
                content: null,
                filteredContentDetails: []
            }, () => {
                if (this.state.selectedTopicResourceTypes && this.state.selectedTopicResourceTypes.length) {
                    this.getContentDetails();
                }
            });
    };


    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true,

        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}?etag=true`;
        this.service.get(url, true).then((res) => {

            const etag = res && res.etag;



            if (res && res.res && res.res.Item) {
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        content: { boardId, classId, groupId, subjectId, chapterId, topicId, ...res.res.Item },
                        filteredContentDetails,
                        isLoading: false,
                        etag
                    }
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    filterResource = (contents, sTR) => {
        let selectedTopicResourceTypes = _.cloneDeep(sTR)

        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);

        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))

        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }


    onAssign = () => {
        const { examToAssign, isExamEdit } = this.state
        let contentInfo = {
            contentFileName: examToAssign.examName,
            contentFileType: "quiz",
            contentId: examToAssign.examId,
            contentName: examToAssign.examName,
            contentResourceType: "quiz",
            noOfQuestions: examToAssign.noOfQuestions,
            totalMarks: examToAssign.totalMarks,
            totalTime: examToAssign.totalTime,
            source: 'Upload'
        }


        if (isExamEdit) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Save the exam name before assign', life: 3000 });
            return
        }

        if (!contentInfo.contentName || contentInfo.contentName.trim() == '') {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Name is Required', life: 3000 });
            return
        }
        let duplicateName = false;
        let duplicateExam = false;

        let duplicateExamName = '';

        if (this.state.content.contentDetails && this.state.content.contentDetails.length > 0) {
            this.state.content.contentDetails.forEach((x) => {
                if (x.contentId === contentInfo.contentId && x.contentFileType == contentInfo.contentFileType) {
                    duplicateExam = true;
                    duplicateExamName = x.contentName
                }

                if (x.contentName.toLowerCase() === contentInfo.contentName.toLowerCase() && x.contentFileType == contentInfo.contentFileType) {
                    duplicateName = true;

                }
            });
        }
        if (duplicateName) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Name matches with another resource.. Kindly change it.', life: 3000 });
        } else if (duplicateExam) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `This quiz already assigned with the name ${duplicateExamName}`, life: 3000 });
        }

        else {
            const previousContentInfo = this.state.content.contentDetails || [];
            const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state.content;


            let filtered = previousContentInfo.filter((conent) => conent.contentFileType.toLowerCase() == contentInfo.contentFileType.toLowerCase());
            const content = {
                boardId, classId, groupId, subjectId, chapterId, topicId,
                contents: [
                    {
                        ...contentInfo,
                        priority: filtered.length + 1
                    },
                    ...previousContentInfo
                ]
            };

            //            console.log('content', content);


            this.updateContent({ ...content });
        }

    };

    updateContent = (content) => {
        this.setState({
            isLoading: true
        });


        const url = `${baseUrlForLongRunningTask}/long/content`;
        this.service.put(url, content, true, { "If-Match": this.state.etag }).then((data) => {


            if (data && data.res && data.res.success) {

                // this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Successfully modified, modified file may reflect in 10-15 mins', life: 6000 });
                this.setState({
                    isLoading: false,
                    // isShowAddDialog: false,
                    // resourseOrigin: this.state.isBaseBoard ? 'upload' : null
                }, this.props.onAssignSuccess);
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });


        })
    }

    render() {
        //   console.log('examToAssign', this.state.examToAssign);
        const { examToAssign } = this.state;
        const header = (
            
                // <div className="text-center flex justify-content-center align-items-center align-self-center">
                //     <h4>Assigned Quizs</h4>
                // </div>
                <h4>Assigned Quizs</h4>
           
        );


        return (<><Dialog
            //visible={this.state.isShowCreate}
            style={{ width: '90%', height: '90vh' }}
            //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
            modal
            blockScroll={true}
            //footer={this.createRoleDialogFooter}
            draggable={false}
            // footer={this.footer}
            // header={null}
            closeOnEscape={false}

            dismissableMask={false}
            className='add-a-resource-dialog'
            visible={true}
            closable={true}
            header="Assign Quiz to Curriculum"
            onHide={this.props.onCancel}>
           


              <div className='assign-quiz' >
                <div className='flex flex-column justify-content-start ma-no-pm'>
                    <div className='grid paperd-s1 exam-preview'>
                        <div className='col-4 '>
                            <div>
                                <p className='e-label-2'>Quiz Name</p>


                                {
                                    this.state.isExamEdit ? <><InputText value={examToAssign.examName} onChange={(e) => {
                                        this.setState((prevState) => {
                                            return {
                                                examToAssign: {
                                                    ...prevState.examToAssign,
                                                    examName: e.target.value
                                                }
                                            };
                                        });
                                    }} className='p-inputtext-style1 w-15rem' />
                                        <span className='ma-ml10 ma-pointer edit-ic ml-3' onClick={() => {
                                            this.setState({
                                                isExamEdit: false
                                            })
                                        }}><i className='pi pi-check' ></i></span>
                                    </>
                                        : <p className='exam-name'>{examToAssign.examName} <span className='ml-3 ma-pointer' onClick={() => {
                                            this.setState({
                                                isExamEdit: true
                                            })
                                        }}><i className='pi pi-pencil edit-ic' ></i></span></p>
                                }

                            </div>
                        </div> 
                    </div>        

                    <div className='grid exam-info mt-2 paperd-s1 exam-preview lg:-ml-4 xl:-ml-4 -ml-3 mt-1'>
                                <div className='col-3 lg:col-2 xl:col-2 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label text-lg'>Number Of Questions</p>
                                        <p className='e-value'>{examToAssign.noOfQuestions}</p>
                                    </div>
                                </div>
                                <div className='col-3 lg:col-2 xl:col-2 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label text-lg'>Total Time</p>
                                        <p className='e-value'>{examToAssign.totalTime} Min</p>
                                    </div>
                                </div>
                                <div className='col-3 lg:col-2 xl:col-2 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label text-lg'>Total Marks</p>
                                        <p className='e-value'>{examToAssign.totalMarks}</p>
                                    </div>
                                </div>
                                <div className='col-3 lg:col-2 xl:col-2 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label text-lg'>Question Types</p>
                                        <p className='e-value'>{(examToAssign.questionType && examToAssign.questionType.length) ? examToAssign.questionType.join(', ') : 'All'}</p>
                                    </div>
                                </div>
                    </div>
                        
                </div>
            </div>


                <div className='grid col-12 gap-2 '>
                    <ClassetDropdown value={this.state.boardId}
                        //className='lg:w-15rem xl:w-15rem w-18rem mt-2 mr-2'
                        //options={this.state.boards}
                        options={this.props.boards}
                        optionLabel="boardName"
                        optionValue="boardId"
                        onChange={(e) => this.onChangeBoard(e.value)}
                        placeholder="Select Board" />
                    <ClassetDropdown value={this.state.classId}
                        options={this.state.classes}
                        //className='lg:w-15rem xl:w-15rem w-18rem mt-2 mr-2'
                        onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                    <ClassetDropdown value={this.state.subjectId}
                        options={this.state.subjects}
                        optionLabel='subjectName'
                        optionValue='subjectId'
                        //className='lg:w-15rem xl:w-15rem w-18rem mt-2 mr-2'
                        onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                    <ClassetDropdown value={this.state.chapterId}
                        options={this.state.chapters}
                        optionLabel='chapterName'
                        optionValue='chapterId'
                        //className='lg:w-15rem xl:w-15rem w-18rem mt-2 mr-2'
                        onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                    <ClassetDropdown value={this.state.topicId}
                        options={this.state.topics}
                        optionLabel='topicName'
                        optionValue='topicId'
                        //className='lg:w-15rem xl:w-15rem w-18rem mt-2 mr-2'
                        onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                </div>

                <div className='quiz-info-assign'>




                    {/* <p>Number of Questions</p>
                    <p> {examToAssign.noOfQuestions}</p>

                    <p>Total Time</p>
                    <p> {examToAssign.totalTime}</p>

                    <p>Total Marks</p>
                    <p> {examToAssign.totalMarks}</p> */}






                    <Button disabled={!(this.state.content)} className='ma-mt20 addButtonttt' icon={'pi pi-plus-circle text-lg'} label='Assign' onClick={this.onAssign} />

                </div>




                <div className="card datatable-crud-demo ma-mt20 mt-3">
                    {/* {this.state.filteredContentDetails && this.state.filteredContentDetails.length ? <> */}

                    <DataTable
                        globalFilter={this.state.globalFilter}
                         className="dataTableValuesText"
                        //  lazy
                       showGridlines
                        //  value={this.state.roles}
                        value={this.state.filteredContentDetails || []}
                        // selection={this.state.selectedProducts}
                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                        selection={this.state.selectedContent}
                        onSelectionChange={this.onSelectContent}
                        dataKey="contentId"
                        paginator
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        rows={10}
                        responsiveLayout="scroll"
                        selectionMode="checkbox"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        header={header} 
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                        {/* <Column field="localName" header="Exam Name (in Local)" /> */}
                        <Column field="contentName" header="Exam Name" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='contentName' />
                        {/* <Column body={(rowData) => {
                            return (
                                <div className="preview-c" >

                                    <span onClick={() => {
                                        this.setState({
                                            previewContent: rowData,
                                            isShowPreview: true
                                        });
                                    }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                                </div>
                            )
                        }} field="status" header="Preview" /> */}
                    </DataTable>
                    {/* </>
                    : null} */}
                </div>


            
        </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}




const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,

});

// export default connect(mapStateToProps, {
//     getBoardsData
// })(AssignQuizToContent);

export default connect(mapStateToProps, { getBoardsData })(Authentication(withRouter(AssignQuizToContent)));