import React, { Component } from "react"
import { connect } from 'react-redux';
import Service from '../../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, warningDailogInit } from '../../../../utile';
import Authentication from '../../../session';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import _ from 'lodash';
import { ActiveTag, BoardsIconInActive, GradesIcon, InactiveTag, MailIcon, RightMarkInCircle, StarInfoBadge, WarningIcon } from '../../../svgIcons';
import LoadingComponent from '../../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../../store/apiConstants';
import withRouter from '../../../lib/withRouter';
import './styles.scss'
import { RadioButton } from "primereact/radiobutton";
import { debounce } from 'lodash';
import { getBranchesLatest } from "../../../../store/actions";
import BranchToSectionMultiDDPreFill from "../../../BaseDropdownComponents/BranchToSectionMultiDDPreFill";
import ClassetTextArea from "../../../../classetComponents/classetTextArea";
import ClassetStudentSearch from "../../../../classetComponents/classetStudentSearch";
import ClassetDropdown from "../../../../classetComponents/classetDropDown";
const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]

class AdmissionCategoryChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: [
            ],
            filterData: null,

            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],

            isUserNameSearch: false,
            isUserEnrollSearch: false,
            isRaiseDialogOpen: false,
            username: '',
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: [],
            boards: [],
            classes: [],
            groups: [],
            sections: [],
            branchLevels: {},
            searchLevel: 1,
            searchType: searchTypeOptions[0].value,
            searchValue: "",
            lazyParams: BasicLazyParams,
            users: [],
            selectedUser: [],
            globalSearch: '',
            totalRecords: 0,
            isTransferDialogOpen: false,
            transferFee: '',
            selectAll: false,
            select: false,
            selectedTransferStudents: [],
            dropdownsDataDilogData: {},
            rowData: {},
            selectedRows: {},
            transfer: null,
            flag: false,
            dropdownsData: {},
            branchId: null,
            admissionCategories: [],
            branchName: null,
            totalFee: 0, remarks: '', selectedStudentData: {}
        };
        this.debouncedApiCall = debounce(this.filterUsersData, 500);
        this.service = new Service();

    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }



    filterUsersData = (dropdownsData) => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?academicYear=${localStorage.getItem('userAcademicYear')}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;


        const payload = {
            sectionId: this.state.dropdownsData?.sectionId,
            isUserNameSearch: this.state.searchLevel == 2 && this.state.searchType == "studentName" ? true : this.state.isUserNameSearch,
            username: this.state.searchValue
        }

        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords,
                    isUserNameSearch: false,
                    isUserEnrollSearch: false,
                    searchValue: ""
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    componentDidMount() {
        if (this.state?.dropdownsData?.sectionId) {
            this.filterUsersData();

        }

        this.props.getBranchesLatest();
        this.getAdmissionCategoryOptions();

    }
    getAdmissionCategoryOptions = () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        this.service.get(url, true).then((data) => {
            if (data?.status && data?.res?.data) {
                this.setState({ admissionCategories: data?.res?.data })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the Admission category options', life: 3000 });

            }

        })
    }

    componentDidUpdate(prevProps, prevState) {


    }



    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }
    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.filterUsersData);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData: dropdownsData
        }, () => this.filterUsersData())
    }



    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true })
            } else {
                this.setState({ isUserEnrollSearch: true })
            }

        })
    }

    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {

            this.debouncedApiCall(this.state.searchValue);

        })
    }



    handleSearchLevel = (value) => {
        console.log("NAVAYA")
        this.setState({ searchLevel: value }, () => {
            // this.filterUsersData()

        })
    }






    gradeSectionBodyTemplate = (rowData) => {
        return `${rowData["custom:grade"]}-${rowData.sectionName}`;
    }






    RaiseChangeRequest = () => {
        let catName = '';
        this.state?.admissionCategories?.map(e => {
            if (e._id == this.state.selectedAdmission) {
                catName = e?.categoryName
            }
        })

        let payload = { ...this.state.selectedStudentData, academicYear: localStorage && localStorage.getItem('userAcademicYear'), admissionChangeRequest: 'PENDING', requestedAdmissionCat: this.state.selectedAdmission, requestedAdmissionName: catName , requestRaisedBy:localStorage.getItem('userId') ,  requestRaisedByName:localStorage.getItem('username')}

        delete payload._id;

        try {
            let url = `${baseUrlAdmin}/statusChange/admissionchangerequest/save`

            this.service.post(url, payload, true).then(res => {

                if (res?.status && res?.res?.status) {
                    this.setState({
                        selectedAdmission: "", isRaiseDialogOpen: false
                    })
                    this.toast.show({ severity: 'success', summary: res?.res?.message, detail: res.errMessage, life: 3000 });
                } else {

                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
            })
        } catch (error) {

        }

    }

    onChangeAdmissionCategory = (value) => {

        if (value == this.state.selectedStudentData?.admissionCategory) {
            this.toast.show({ severity: 'error', summary: 'Student Already in this Admission', life: 3000 });
        } else {
            this.setState({
                selectedAdmission: value
            })
        }
    }




    render() {
        //console.log(this.state.searchValue, "f", this.state.searchType, "studentName", "enrollmentNumber")
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>


            </div>
        );


        return (
            <div>
                <div className='ma-main  '>
                    <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                        <div className="flex">
                            <div className="flex flex-column justify-content-center align-items-center mt-3">
                                <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                            </div>
                            <div>
                                <BranchToSectionMultiDDPreFill selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                            </div>
                        </div>
                        <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                            <span className="">OR</span>
                        </div>
                        <div className="flex mt-4">
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                            </div>

                            <div className="field ml-4">
                                <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                            </div>
                        </div>
                    </div>

                    <div className="card datatable-crud-demo ma-m30  m-2">
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.users}
                            dataKey="_id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        >
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="given_name" field="given_name" header="Student Name" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="username" field="username" header="User Name" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="admissionCategory" field="admissionCategory" header="Admission Category" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchId" field="branchName" header="Branch" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="custom:board" field="custom:board" header="Board" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="custom:grade" field="custom:grade" body={this.gradeSectionBodyTemplate} header="Grade-Section" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="phone_number" field="phone_number" header="Phone Number" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' header="Status" body={(rowData, { rowIndex }) => {
                                return (<>
                                    {rowData?.status == 'Active' ? <ActiveTag /> : <InactiveTag />}
                                </>
                                );
                            }} ></Column>
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' header="Change Status" body={(rowData, { rowIndex }) => {
                                return (<>
                                    <Authorizer permId={PERMISSIONS.RAISE_STUDENT_ADMISSION_CHANGE} >
                                        <Button className='addButtonn' onClick={() => this.setState({
                                            isRaiseDialogOpen: true, selectedStudentData: rowData
                                        })} >
                                            <p className='' >Raise</p>
                                        </Button>
                                    </Authorizer>
                                </>
                                );
                            }} ></Column>


                        </DataTable>
                        <Dialog
                            visible={this.state.isRaiseDialogOpen}
                            draggable={false}
                            closeOnEscape={false}
                            style={{ width: 400 }}
                            dismissableMask={false}
                            closable={false}
                            header={() => {
                                return (<div className='mt-2 formHeadingInter'>
                                    <div className="mb-3">
                                        <StarInfoBadge />
                                    </div>
                                    <p style={{ fontWeight: 'bold', fontSize: 20 }}>Change </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 20 }}>Student Admission Category</p>
                                </div>)
                            }}
                            footer={() => (
                                <div className='flex justify-content-center mb-4'>
                                    <Button className='formSubmitButton px-4' style={{ borderRadius: '10px' }} onClick={() => { this.RaiseChangeRequest() }} >
                                        <p className='formSubmitButtonText'>Request</p>
                                    </Button>
                                    <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRaiseDialogOpen: false, selectedAdmission: '' })} >
                                        <p className='formCancelButtonText'>Cancel</p>
                                    </Button>
                                </div>
                            )}
                            onHide={() => this.setState({ isRaiseDialogOpen: false, })}
                        >

                            <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>

                                <div className="">
                                    <ClassetDropdown
                                        label={'Admission Category'}
                                        icon={<GradesIcon />}
                                        placeholder={'Select Admission Category'}
                                        options={this.state?.admissionCategories}
                                        value={this.state.selectedAdmission}
                                        onChange={(e) => this.onChangeAdmissionCategory(e.value)}
                                        optionLabel="categoryName"
                                        optionValue="_id"
                                    />
                                </div>
                            </div>

                        </Dialog>
                        <Toast ref={(el) => this.toast = el} position="bottom-right" />

                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({

    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
});
export default connect(mapStatesToProps, {
    getBranchesLatest
})(Authentication(withRouter(AdmissionCategoryChange)));