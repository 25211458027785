import React, { Component } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';

// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';
import VehicleManagement from './vehicleManagement/vehicleManagement';
import RouteManagement from './routeManagement/routeManagement';
import AssignManagementTable from '../menuTransport/AssignTransport/assignTransportTable';
import TransportSettingCreation from './transportSettingManagement/createTransfortSetting';
import { primaryColor } from '../../store/apiConstants';
import './transportStyles.scss';
import StaffManagement from './manageTransportStaff/staffManagement';
// import StaffManagement from './manageTransportStaff/staffManagement';

const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' }
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' };

const tabs = [{ label: 'Vehicle Management' }, { label: 'Route Management' }, { label: 'Assign Management' }, { label: 'Transport Fee ' }];

class TransportIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0
        };
    }

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className="ma-tab-title">Transport Management</h4>
            </React.Fragment>
        );
    };

    // leftToolbarTemplate() {
    //     return (
    //         <React.Fragment>
    //             <BreadCrumb model={items} home={home} />
    //         </React.Fragment>
    //     )
    // }

    componentDidUpdate(prevProps, prevState) {}

    render() {
        return (
            <div>
                <div className="">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    </div>
                    {/* <div className="-mt-3">
                        <Toolbar className="border-none" 
                         left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}
                </div>

                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tabheadText"
                                        onClick={() => {
                                            this.onTabChange(0);
                                        }}
                                    >
                                        Vehicle Management
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(1);
                                        }}
                                    >
                                        Manage Transport Staff
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(2);
                                        }}
                                    >
                                        Create Transport Fee
                                    </p>
                                </div>

                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(3);
                                        }}
                                    >
                                        Transport Fee Settings
                                    </p>
                                </div>
                                {/*    <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(2) }}>Assign Transport</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {this.state.currentTab == 0 && (
                        <>
                            <Authorizer permId={PERMISSIONS.VEHICLE_MANAGEMENT_LIST}>
                                <VehicleManagement />
                            </Authorizer>
                        </>
                    )}
                    {this.state.currentTab == 1 && (
                        <>
                            <Authorizer permId={PERMISSIONS.VEHICLE_MANAGEMENT_LIST}>
                                <StaffManagement />
                            </Authorizer>
                        </>
                    )}
                    {this.state.currentTab == 2 && (
                        <>
                            <Authorizer permId={PERMISSIONS.CREATE_TRANSPORT_FEE}>
                                <RouteManagement />
                            </Authorizer>
                        </>
                    )}
                   
                    {this.state.currentTab == 3 && (
                        <>
                            <Authorizer permId={PERMISSIONS.TRANSPORT_FEE}>
                                <TransportSettingCreation />
                            </Authorizer>
                        </>
                    )}
                     {/* {this.state.currentTab == 4 && (
                        <>
                            <Authorizer permId={PERMISSIONS.ASSIGN_TRANSPORT}>
                                <AssignManagementTable />
                            </Authorizer>
                        </>
                    )} */}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(TransportIndex)));
