import React from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from './AppSubmenu';
import { relativeTimeThreshold } from 'moment/moment';

const AppMenu = (props) => {
    return (
        <div style={{ width: 'auto' }} className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                <img id="app-logo" className={props.menuMode === 'slim' ? 'nav-logo-width-slim' : 'nav-logo-width'} src={props.menuMode === 'slim' ? './noun_Diamond.jpg' : './noun_Diamond.jpg'} alt="diamond layout" />
                <span className="app-name"></span>
            </Link>
            <div className="layout-menu-container">
                <AppSubmenu
                    items={props.model}
                    menuMode={props.menuMode}
                    parentMenuItemActive
                    menuActive={props.active}
                    mobileMenuActive={props.mobileMenuActive}
                    root
                    onMenuitemClick={props.onMenuitemClick}
                    onRootMenuitemClick={props.onRootMenuitemClick}
                />
            </div>
        </div>
    );
};

export default AppMenu;
