import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import SvgViewer from '../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import ClassetDropdown from '../../classetComponents/classetDropDown';

import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import './styles.scss'
// import UserInfo from './userInfo';
// import CreateUser from './createUser';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import IndividualUsageAnalytics from '../analytics/usageAnalytics/individualUsageAnalytics';
import { ActiveTag, AnalyticsIcon2, BoardsIconInActive, FlappyDiscIcon, GradesIcon, InactiveTag, MailIcon, RightMarkInCircle, TeacherCircle, WarningIcon } from '../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../internationalization';
import withRouter from '../lib/withRouter';
import InputTextB from '../customComponents/inputTextB';
import { Dropdown } from 'primereact/dropdown';
import { getBoardsData, selectClass2 } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';
import BranchToSectionMultiDDTillSecCol from '../BaseDropdownComponents/BranchToSectionMultiDDTillSecCol';
import BranchToSectionMultiDDTillSecColIn from '../BaseDropdownComponents/BranchToSectionMultiDDTillSecColIn';

class StaffAllocation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      isShowUserInfo: false,
      isShowAnalytics: false,
      selectedUser: null,
      isCreateUser: false,
      vehicleDropdown: [],
      selectedBuses: {},
      selectedStops: {},
      routeData: [],
      selectedStudentPickup: {},
      selectedStudentDrop: {},
      planId: '',
      busNo: null,
      boardId: null,
      classId: null,
      editUser: null,
      warningDialog: warningDailogInit,
      warningDialog2: warningDailogInit,
      filterData: null,
      totalRecords: 0,
      globalSearch: '',
      lazyParams: BasicLazyParams,
      dropdownsData: {},
      selectedTeacersAssignInfo: [],
      selectAll: false,
      isTransferDialogOpen: false,
      dropdownsDataDilogData: {},
      select: false,
      rowData: {}


    }
    this.service = new Service();
  }


  componentDidMount() {
    // this.props.getBranchesLatest ();
    // this.props.getBoardsData(true);
    this.getStaffData()
    console.log(this.props, "anavyaavahhhhhh")

  }





  componentDidUpdate(prevProps, prevState) {
    console.log(this.props, "anavyaava")


    if (prevProps.boards !== this.props.boards) {
      if (this.props.boards) {
        let boards = [];
        this.props.boards &&
          this.props.boards.map((item, index) => {
            boards.push({ value: item.boardId, label: item.boardName });
          });

        this.setState({
          boards: boards
        });
      }
    }

    if (prevProps.branchData !== this.props.branchData) {

      let sectionsData = {
        level1: this.props.branchData.filter((a) => a.level == 1)
      }
      this.setState({
        sectionsData
      });
    }


  }


  onChangeBoard = (boardId) => {
    this.setState({
      classes: [], classId: null, curriculumInfo: null,
      isShowSubjects: false,
    });
    const _classes = [];
    const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
    if (selectedBoard && selectedBoard?.classes && selectedBoard?.classes?.length > 0) {
      selectedBoard?.classes.forEach((item) => _classes?.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
    }
    let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName
    this.setState({
      classes: _classes,
      boardId,
      selectedSubject: [],
      selectedChapter: [],
      selectedTopic: [],
      selectedBoardName: selectedBoardName
    });
  };

  onChangeClass = (classId) => {
    let selectedClass = this.state?.classes?.find((classd) => classd.value == classId)
    this.setState({
      classId: classId,
      selectedSubject: [],
      selectedChapter: [],
      selectedTopic: [],
      selectedClassName: selectedClass?.label,
      groupId: selectedClass.groupId

    },);
  };

  getStaffData = async () => {

    console.log(this.state.dropdownsData, "this.state.dropdownsData")
    const academicYear = localStorage.getItem("userAcademicYear")
    this.setState({ isLoading: true })
    // Safely handle potential null or undefined values and ensure that `join` is called only on arrays
    let branchIds = Array.isArray(this.state?.dropdownsData?.branchIds) ? this.state.dropdownsData.branchIds.join(',') : '';
    let boardIds = Array.isArray(this.state?.dropdownsData?.boardIds) ? this.state.dropdownsData.boardIds.join(',') : '';
    let gradeIds = Array.isArray(this.state?.dropdownsData?.classIds) ? this.state.dropdownsData.classIds.join(',') : '';
    let sectionIds = Array.isArray(this.state?.dropdownsData?.sectionId) ? this.state.dropdownsData.sectionId.join(',') : '';

    // Construct the URL with the query parameters
    let url
    if (branchIds || boardIds || gradeIds || sectionIds) {
      url = `${baseUrlAdmin}/staff?branchIds=${branchIds}&boardIds=${boardIds}&gradeIds=${gradeIds}&sectionIds=${sectionIds}&academicYear=${academicYear}&limit=${10000}&page=${1}`;

    } else {
      url = `${baseUrlAdmin}/staff?academicYear=${academicYear}&limit=${10000}&page=${1}`
    }

    console.log(url); // For debugging purposes

    try {
      const res = await this.service.get(url, true);
      if (res?.res?.status && res.status) {

        console.log(res?.res?.data, "res?.res?.datares?.res?.data")

        const users = res?.res?.data?.records;

        this.setState({
          users,
          totalRecords: res?.res?.data?.totalRecords,
          isLoading: false
        });
      } else {
        this.setState({ isLoading: false })
        this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
      }
    } catch (e) {
      this.setState({ isLoading: false })
      this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
    }

  };

  onPage = (event) => {
    this.setState({ lazyParams: event }, this.getStaffData);
  }

  onCreateOrEditSuccess = () => {
    if (this.state.editUser) {

      this.setState({ isCreateUser: false, editUser: false }, () => {
        this.filterUsersData();
      })
    } else {
      this.setState({ isCreateUser: false, editUser: false })
    }
  }

  setDropdownValues = (dropdownsData) => {
    console.log(dropdownsData, "dropdownsDataNavvvvv")
    this.setState({
      dropdownsData
    }, () => {
      this.getStaffData()

      console.log(dropdownsData, "dropdownsData")
    })
  }

  onSelectionChange = (event) => {
    const value = event.value;

    console.log(value, "onSelectionChange")

    let selectedAll;
    if (value.length > 0) {




      selectedAll = (value.length === this.state.users.length)

      this.setState({
        selectedTeacersAssignInfo: event.value,
        selectAll: selectedAll,
        select: true,
        rowData: event.value


      })

    } else {

      this.setState({
        selectedTeacersAssignInfo: [],
        selectAll: false,
        select: false,
        rowData: {}


      })
    }

  }

  onSelectAllChange = (event) => {
    const selectAll = event.checked;

    console.log(selectAll, "onSelectAllChange", this.state.users)


    if (selectAll) {
      this.setState({
        selectedTeacersAssignInfo: this.state.users,
        selectAll: true,
        rowData: this.state.users
      })
    } else {
      this.setState({
        selectedTeacersAssignInfo: [],
        selectAll: false,
        isTransferDialogOpen: false,
        rowData: {}
      })
    }
  };

  setDropdownValuesDilog = (dropdownsData) => {

    this.setState({ dropdownsDataDilogData: dropdownsData }, () => {

    })
  }



  AssignSectionsToTeachers = () => {
    this.setState({
      isLoading: true,
    });

    if (this.state.rowData && this.state.dropdownsDataDilogData) {
      let payload = {
        userInfo: this.state.rowData,
        sectionIds: this.state.dropdownsDataDilogData.sectionIds,
        sectionNames: this.state.dropdownsDataDilogData?.sectionNames
      }


      const url = `${baseUrlAdmin}/staff/assign`;

      this.service.post(url, payload, true).then(res => {
        if (res && res.status && res.res.status) {

          this.setState({
            isLoading: false,
            isTransferDialogOpen: false,
            selectAll: false,
            select: false,
            selectedTeacersAssignInfo: []
          }, () => this.getStaffData());

        } else {
          this.setState({
            isLoading: false
          });
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        }
      }).catch(e => {
        this.setState({
          isLoading: false
        });
        console.log(e);
      })
    }
  }

  render() {
    const header = (
      <div className="flex justify-content-between bg-white">
        <div className="flex">
          <h4 className=''>Students List</h4>
          {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
            value={this.state.globalSearch}
            onChange={this.onGlobalSearch} />}
        </div>

        <Menu
          className='table-filter-w'
          model={[
            {
              template: (<div className='ml-2 p-2'>
                <h4 className="">View Columns</h4>
                <ul>
                  {this.state?.columns?.map((column) => {
                    return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                      checked={column.isVisible}
                    ></Checkbox>
                      <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                    </div>
                  })}
                </ul>
              </div>)
            }]
          }
          popup
          ref={el => this.menu = el} />
      </div>
    );
    const { selectedUser, isShowUserInfo, isShowAnalytics, editUser, dropdownsData } = this.state

    console.log(dropdownsData, "dropdownsDataDilogDatadropdownsDataDilogData")
    return (
      <>
        <div className="mt-5">
          {/* <BoardToSectionDD setValues={this.setDropdownValues} /> */}
          <BranchToSectionMultiDDTillSecCol setValues={this.setDropdownValues} />
        </div>
        <div className="card datatable-crud-demo mt-5">

          <div className="flex justify-content-end">

            <Button style={{ border: '1px solid #E6E7EA', marginLeft: "20px", backgroundColor: '#076eff', padding: '9px 18px', borderRadius: "15px", color: "white" }} label="AssignTeachers" className='p-button-outlined text-xl button' onClick={() => this.setState({ isTransferDialogOpen: true })} disabled={!(this.state.selectAll || this.state.select)} />

          </div>

          <DataTable ref={(el) => this.dt = el}
            className="dataTableValuesText"
            columnResizeMode="expand"
            value={this.state.users}
            dataKey="_id"
            paginator
            //lazy
            responsiveLayout="scroll"
            showGridlines
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teachers"
            globalFilter={this.state.globalFilter}
            selection={this.state?.selectedTeacersAssignInfo}
            onSelectionChange={this.onSelectionChange}
            selectAll={this.state.selectAll}
            onSelectAllChange={this.onSelectAllChange}
            selectionMode="checkbox"
            header={''}>
            <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "5rem", height: "5rem" }} alignHeader={"center"} selectionMode="multiple" exportable={false} />

            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell" body={(rowData, index) => {
              return (
                <>
                  <p>{index.rowIndex + 1}</p>
                </>
              )
            }} />
            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="username" field="username" header="Teacher Name" 
            
            body={(rowData) => {
              return <span>{rowData?.username?.slice(3)}</span>;
             
            }}
            
            />
            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="phone_number" field="user_info.phone_number" header="Phone Number" />

            <Column
              style={{ width: "15rem", height: "6rem" }}
              alignHeader={"center"}
              sortable
              headerClassName="text-color-secondary"
              bodyClassName='text-center'
              field="user_info.assignedSubjects"
              header="Grades"
              body={(rowData) => {
                if (rowData.user_info && rowData.user_info.assignedSubjects) {
                  const classNames = rowData.user_info.assignedSubjects.map(subject => subject.className).join(', ');
                  return <span>{classNames}</span>;
                }
                return <span>N/A</span>; // Fallback if no assigned subjects
              }}
            />



            <Column
              style={{ width: "15rem", height: "6rem" }}
              alignHeader={"center"}
              sortable
              headerClassName="text-color-secondary"
              bodyClassName='text-center'
              field="user_info.assignedSubjects"
              header="Subjects"
              body={(rowData) => {
                if (rowData.user_info && rowData.user_info.assignedSubjects) {
                  const classNames = rowData.user_info.assignedSubjects.map(subject => subject.subjectName).join(', ');
                  return <span>{classNames}</span>;
                }
                return <span>N/A</span>; // Fallback if no assigned subjects
              }}
            />

            <Column
              style={{ width: "15rem", height: "6rem" }}
              alignHeader={"center"}
              sortable
              headerClassName="text-color-secondary"
              bodyClassName='text-center'
              field="user_info.sectionNames"
              header="sections"
              body={(rowData) => {
                if (rowData.user_info && rowData.user_info.sectionNames) {
                  const sectionNames = rowData.user_info.sectionNames.map(sec => sec).join(', ');
                  return <span>{sectionNames}</span>;
                }
                return <span>N/A</span>; // Fallback if no assigned subjects
              }}
            />

            <Column
              style={{ width: "15rem", height: "6rem" }}
              alignHeader={"center"}
              headerClassName="text-color-secondary"
              bodyClassName='text-center'
              header="Status"
              body={(rowData, { rowIndex }) => {
                console.log("Row Data:", rowData);  // This logs the rowData to the console
                return (
                  rowData?.user_info?.status === "Active" ? <ActiveTag /> : <InactiveTag />
                );
              }}
            />

          </DataTable >
        </div >
        <Dialog
          visible={this.state.isTransferDialogOpen}
          draggable={false}
          closeOnEscape={false}
          className=''
          style={{}}
          dismissableMask={false}
          closable={true}
          header={() => {
            return (<div className='mt-2 formHeadingInter text-align-center'>
              <p style={{ fontWeight: 'bold', fontSize: 20 }}>Teacher To Section Allocation</p>
               <TeacherCircle />
            </div>)
          }}
          footer={() => (
            <div className='flex justify-content-center mb-4'>
              <Button className='formSubmitButton px-4' disabled={!this.state.dropdownsDataDilogData?.sectionIds || this.state.dropdownsDataDilogData?.sectionIds?.length === 0} style={{ borderRadius: '10px' }} onClick={() => { this.AssignSectionsToTeachers() }} >
                <p className='formSubmitButtonText'>Assign</p>
              </Button>
              <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isTransferDialogOpen: false, transfer: '' })} >
                <p className='formCancelButtonText'>Cancel</p>
              </Button>
            </div>
          )}
          onHide={() => this.setState({ isTransferDialogOpen: false, transfer: '' })}
        >

          <div className="" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <BranchToSectionMultiDDTillSecColIn setValues={this.setDropdownValuesDilog} dropdownsData={this.state?.dropdownsData} />
          </div>

        </Dialog>
        {
          this.state.isLoading && <>
            <LoadingComponent />
          </>
        }
        <Toast ref={(el) => this.toast = el} position="bottom-right" />

      </>
    )
  }
}

const mapStateToProps = (state) => ({
  boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
});

export default connect(mapStateToProps, { getBoardsData })(Authentication(withRouter(StaffAllocation)));
