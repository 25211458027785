import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { TestTypes } from '../constants';
import { Toast } from 'primereact/toast';
import QuizList from './quizList';
import DiagnosticTestList from './dignosticTestList'
import AssessmentList from './assessmentList';
import AdhocList from './adhocList';
import Authorizer, { PERMISSIONS, isAuthorized, getPermIdOfAction } from '../../session/authorizer';
import Authentication from './../../session';
import { connect } from 'react-redux';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import Subjective from './subjectiveExamList';
import AdhocT2List from './adhocT2List';
import OfflineTestList from './offlineTestList';
import withRouter from '../../lib/withRouter';

class Examslist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            examType: props.editedExamType ? props.editedExamType : null,
            testTypes: []
        };
    }



    componentDidMount() {
        if (this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-list'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-list'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }


    render() {
        //  console.log(this.state)
        return (<>
            <InputTextB info={FIELDS_INFO.TEST_TYPE} id="lXT">
                <Dropdown value={this.state.examType}
                    className='w-15rem ma-w200'
                    // disabled={this.props.onEditExam}
                    options={this.state.testTypes}
                    onChange={(e) => this.setState({ examType: e.value })}
                    placeholder="Select Test Type" />
            </InputTextB>
            <div className='ma-mt20'>
                {
                    this.state.examType == 'Quiz' && <QuizList onEditExam={this.props.onEditExam} />
                }
                {
                    this.state.examType == 'Assessment' && <AssessmentList onEditExam={this.props.onEditExam} />
                }
                {
                    this.state.examType == 'AdhocMockTest' && <AdhocList onEditExam={this.props.onEditExam} />
                }

                {
                    this.state.examType == 'SubjectiveTest' && <Subjective onEditExam={this.props.onEditExam} />
                }

                {
                    this.state.examType == 'AdhocMockTestT2' && <AdhocT2List onEditExam={this.props.onEditExam} />
                }
                {
                    this.state.examType == 'DiagnosticTest' && <DiagnosticTestList   onEditExam={this.props.onEditExam} />
                }
                {
                    this.state.examType == 'offlineTest' && <OfflineTestList onEditExam={this.props.onEditExam} />
                }
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}




const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});

export default connect(mapStatesToProps, {})(Authentication(withRouter(Examslist)));