import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';

// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { primaryColor } from '../../store/apiConstants';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../services';
import './styles.scss'
import { getPermissionList, getSelectedModules } from '../../store/actions/getPermissionsAction';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { selectPermissions } from '../../store/selectors/authorize';
import StaffAllocation from './staffAllocation';


const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }



class StaffAllocationIndex extends Component {


    constructor(props) {
        super(props);
        console.log(this.props.isLoadingss, "isLoadingisLoadingisLoading")
        this.state = {
            currentTab: 0,
            totalRecords: 0,
            globalSearch: '',
            globalFilter: '',
            columns: [
                { columnName: 'Role Name' }, { columnName: 'Role Type' }],
            roles: [],
            editRoleData: null,
            editModules: null,
            showSearch: false,
            isShowCreate: false,
            editUserData: {},
            isAdd: false,
            dialogData: {},
            warningDialog: warningDailogInit,
            lazyParams: BasicLazyParams,
            showHideHierarchyChartModal: false,
            callApi: false,
            isLoading: false
        };
        this.service = new Service();
    }


    onTabChange = (currentTab, data) => {
        if (data?.user_info?.branchs) {
            data.branches = data?.user_info?.branchs
        }
        if (data?.user_info?.boardId) {
            data.boards = data?.user_info?.boardId
        }
        this.setState({
            currentTab,
            editUserData: data
        })
    }


    componentDidMount() {
        
        console.log("anavyaavahhhhhh")
    
      }
    

    render() {
        return (
            <>
                <div>
                    <div className="">
                        <div className="grid ml-1 mr-1 h-4rem">
                            <div className="md:col-12 lg:col-12 xl:col-12">
                                <div className="grid doubts-tabs ">
                                    <div className="cursor-pointer col-12 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                        <p className="tabheadText" onClick={() => { this.onTabChange(0) }}>Teacher To Section Allocation </p>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        {
                           
                                    this.state.currentTab == 0 && <>
                                    <Authorizer permId={PERMISSIONS.STAFF_MANAGEMENT_LIST} >
                                        < StaffAllocation/>
                                    </Authorizer>
        
                                </>
                            
                        }
               
                    </div>

                </div >

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    getPermissions: () => dispatch(getPermissionList()),
    getBranchesLatestData: () => dispatch(getBranchesLatest()),
    getBoardsData: () => dispatch(getBoardsData(true))

});


const mapStatesToProps = (state) => ({
    permissionsMasterList: selectPermissions(state),

});
export default connect(mapStatesToProps, mapDispatchToProps)(Authentication(withRouter(StaffAllocationIndex)));