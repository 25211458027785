import React, { Component } from 'react'
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import InLoadingComponent from '../../loadingComponent/inlineLoading';

export default class ChapterStrength extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: true,
            chapterStrength: [

            ]
        };


        this.service = new Service();



    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/learning/getChapterStrength`;
        this.service.post(url,{}, true).then(res => {

            if (res && res.status) {
                this.setState({
                    chapterStrength: res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        this.getReport();
    }


    render() {
        const { chapterStrength, inlineLoading } = this.state;

      //  console.log(chapterStrength, 'chapterStrength')

        return (
            <div className='ma-relative'>
                <div className='resource-usage-report'>
                    <h3 className="resource-title"><img src='/new-assets/Path_3271.png' style={{ height: '14px' }} />Chapter Strength</h3>
                    <div className='trending-table-w'>
                        <table className='trending-table'>

                            {
                                chapterStrength.map(chapterR => {

                                    return <tr>
                                        <td>{chapterR.chapterName}</td>
                                        <td>{chapterR.subjectName}</td>
                                        <td>{chapterR.className}</td>
                                        <td>{(Math.round((chapterR.avgStudentScore / chapterR.avgScore) * 100))} %</td>

                                    </tr>
                                })
                            }
                            <tr>

                            </tr>
                        </table>
                    </div>
                    {

                    }
                   
                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>

        )
    }
}
