import React, { Component } from 'react'
import MathJax from 'react-mathjax-preview';
import { connect } from 'react-redux';
import {
    getImageUrl
} from './../../../store/actions';


class SingleQuestion extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        // console.log("navyacall")

        // setTimeout(() => {

        //     console.log('called')

        //     this.callMathJax();
        // }, 2000);
        this.callMathJax();

        const { attachment } = this.props;
        if (attachment && attachment.length > 2) {
            this.props.getImageUrl(attachment);
        }
    }


    callMathJax = () => {
        if (window && window.MathJax && window.MathJax.Hub) {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }

    }
    componentDidUpdate(prevProps) {

        // setTimeout(() => {

        //     console.log('called')

        //     this.callMathJax();
        // }, 2000);


        this.callMathJax();

        if (prevProps.attachment !== this.props.attachment && this.props.getAllImagesUrlInfo && !(this.props.getAllImagesUrlInfo[this.props.attachment])) {
            this.props.getImageUrl(this.props.attachment);
        }
    }

    render() {

        const { mathJaxValue, attachment, optionN, pclassName } = this.props;

        //console.log(mathJaxValue,optionN,"optionN")

        //  console.log('mathJaxValue', mathJaxValue != '')
        return (<div id={this.props.id} className="question-preview">
            {/* <MathJax math={mathJaxValue} /> */}
            {/* <p className={`${pclassName} ma-no-pm-nf`}><span className='q-keys ma-mr10 ' > {optionN ? `${optionN}. ` : ''}</span> {mathJaxValue}</p>


          
             */}

            {/* {mathJaxValue != '' ? (<div className={`${pclassName} question-d ma-no-pm-nf`}><span className='q-option-keys' > {optionN ? `${optionN}. ` : ''}</span>{mathJaxValue} </div>) : <></>} */}
            {/* <p className={`${pclassName} ma-no-pm-nf`}><span className='q-keys ma-mr10 ' > {optionN ? `${optionN}. ` : ''}</span> {mathJaxValue}</p> */}

            <div className='sp-ml10 e-instructions' dangerouslySetInnerHTML={{ __html: `<span className="q-keys ma-mr10" >${optionN ? `${optionN}. ` : ''}</span> ${mathJaxValue}` }}>
            </div>
            <div style={{ width: '100%', overflow: 'auto' }}>
                <img
                    src={this.props.getAllImagesUrlInfo[attachment]}
                    alt=""
                    className='question-img'
                />
            </div>
        </div>
        )
    }
}



const mapStateToProps = (state) => ({
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
});

export default connect(mapStateToProps, {
    getImageUrl

})(SingleQuestion);