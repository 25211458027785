import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';
import { primaryColor } from '../../store/apiConstants';
import BoardsCreation from './Boards';
import GradeSection from './Grade&Section';
import './styles.scss'
import SubjectManagement from './SubjectManagement';
import ContentManagement from './ContentManagement';





class Acadamics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Transport Management</h4>
            </React.Fragment>
        )
    }

    // leftToolbarTemplate() {
    //     return (
    //         <React.Fragment>
    //             <BreadCrumb model={items} home={home} />
    //         </React.Fragment>
    //     )
    // }


    componentDidUpdate(prevProps, prevState) {

    }

    render() {

        return (
            <div>

                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tabheadText" onClick={() => { this.onTabChange(0) }}>Boards Management</p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(1) }}>Grade & Section</p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(2) }}>Subject Management</p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(3) }}>Content Management</p>
                                </div>
                                {/* <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                                    <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {
                        this.state.currentTab == 0 && <>
                            <Authorizer permId={PERMISSIONS.BOARDS_MANAGEMENT_LIST} >
                                <BoardsCreation />
                            </Authorizer>
                        </>
                    }
                    {
                        this.state.currentTab == 1 && <>
                            <Authorizer permId={PERMISSIONS.GRADE_AND_SECTION_LIST} >
                                <GradeSection />
                            </Authorizer>
                        </>
                    }
                    {
                        this.state.currentTab == 2 && <>
                            <Authorizer permId={PERMISSIONS.SUBJECT_MANAGEMENT_LIST} >
                                <SubjectManagement />
                            </Authorizer>
                        </>
                    }
                    {
                        this.state.currentTab == 3 && <>
                            <Authorizer permId={PERMISSIONS.CONTENT_MANAGEMENT_LIST} >
                                <ContentManagement />
                            </Authorizer>
                        </>
                    }

                </div>

            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(Acadamics)));