import React, { Component } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin, primaryColor } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { BasicLazyParams } from '../../../utile';
import { CodeLinkIcon, GmailIcon, MessageIcon, WhatsAppIcon } from '../../svgIcons';
import withRouter from '../../lib/withRouter';
import EnquiryAdd from './enquiryAdd';
import '../styles.scss';
import ClassetInputText from '../../../classetComponents/classetInputText';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
class Enquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            totalRecords: 0,
            enquiryInfo: [],
            lazyParams: BasicLazyParams,
            isgetEnquiry: true,
            isEdit: false,
            isActions: false,
            selectedEnquiry: {},
            currentTab: 0,
            isAplication: false
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.getAdmissionSettingsInfo();
    }

    getAdmissionSettingsInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ admissionSettingsInfo: res?.res?.data, isLoading: false }, () => this.getEnquiryInfo());
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: '', life: 3000 });
        }
    };

    onEdit = (rowData) => {
        this.setState({
            isgetEnquiry: false,
            isEdit: true,
            isActions: false,
            isAplication: false,
            selectedEnquiry: rowData || {}
        });
    };
    onHandleClose = () => {
        this.setState(
            {
                isgetEnquiry: true,
                isEdit: false,
                isActions: false,
                isAplication: false,
                selectedEnquiry: {}
            },
            () => this.getEnquiryInfo()
        );
    };

    getEnquiryInfo = () => {
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/admissions/all?stage=${'Enquiry'}`;
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = {};
        payload.academicYear = academicYear;

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res.data.admissionRecords.length) {
                        this.setState({
                            isLoading: false,
                            enquiryInfo: res.res.data.admissionRecords
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };

    onShowActions = (rowData) => {
        this.setState({
            isgetEnquiry: true,
            isEdit: false,
            isActions: true,
            isAplication: false,
            selectedEnquiry: rowData || {}
        });
    };
    onCloseActions = () => {
        this.setState({
            isgetEnquiry: true,
            isEdit: false,
            isActions: false,
            isAplication: false,
            selectedEnquiry: {}
        });
    };

    onHandleApplication = (selectedEnquiry) => {
        const { application, examTest, registration } = this.state?.admissionSettingsInfo;
        localStorage.setItem('enquiryId', selectedEnquiry?._id);
        localStorage.setItem('applicationId', selectedEnquiry?._id);
        if (application) {
            if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_APPLICATION)) {
                this.props.navigate('/application');
            } else {
                this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
            }
        } else if (examTest) {
            if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_EXAM_TEST)) {
                this.props.navigate('/addExam');
            } else {
                this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
            }
        } else if (registration) {
            if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_REGISTRATION)) {
                this.props.navigate('/addRegistration');
            } else {
                this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
            }
        } else {
            if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_ENROLLMENT)) {
                this.props.navigate('/confirm-enrollment');
            } else {
                this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
            }
        }
    };

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };

    render() {
        return (
            <>
                <>
                    {this.state?.isgetEnquiry && (
                        <>
                            <div className="text-right mt-4 mb-2">
                                <Authorizer permId={PERMISSIONS.CREATE_ENQUIRY}>
                                    <Button icon={'pi pi-plus-circle text-lg px-1'} className="add-vehicle-btn pl-4" onClick={() => this.props.navigate('/addEnquiry')}>
                                        New Enquiry
                                    </Button>
                                </Authorizer>
                                {/* </Authorizer> */}
                            </div>
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    className="dataTableValuesText"
                                    columnResizeMode="expand"
                                    value={this.state.enquiryInfo}
                                    dataKey="id"
                                    paginator
                                    responsiveLayout="scroll"
                                    rows={10}
                                    // onPage={this.onPage}
                                    // first={this.state.lazyParams.first}
                                    // last={this.state.totalRecords}
                                    // rows={this.state.lazyParams.rows}
                                    totalRecords={this.state.totalRecords}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                >
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        header="S.No."
                                        className="datatable-cell"
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                                </>
                                            );
                                        }}
                                    />

                                    <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="firstName" field="firstName" header="First Name" />
                                    <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="lastName" field="lastName" header="Last Name" />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="contactNo"
                                        field="contactNo"
                                        header="Phone Number"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="parentName"
                                        field="parentName"
                                        header="Parent Name"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        body={(rowData) => {
                                            return (
                                                <div className="">
                                                    <p>{rowData?.enquiry?.referenceNo}</p>
                                                </div>
                                            );
                                        }}
                                        header="Reference ID"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        body={(rowData) => {
                                            return (
                                                <div className="" style={{ width: '8rem', textAlign: 'center' }}>
                                                    <p>{moment(rowData?.enquiry?.date).utc().format('DD-MM-YYYY')}</p>
                                                </div>
                                            );
                                        }}
                                        header="Enquiry Date"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="stage"
                                        field="stage"
                                        className=""
                                        header="Current Stage"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="branch"
                                        className=""
                                        field="branchName"
                                        header="Branch"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="board"
                                        className=""
                                        field="boardName"
                                        header="Board"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="grade"
                                        className=""
                                        field="gradeName"
                                        header="Grade"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        body={(rowData) => {
                                            return (
                                                <div
                                                    className="" /* onClick={() => { this.onEdit(rowData) }} */
                                                    onClick={() => {
                                                        this.onShowActions(rowData);
                                                    }}
                                                >
                                                    <CodeLinkIcon height={35} width={35} />
                                                </div>
                                            );
                                        }}
                                        field="isGps"
                                        header="Actions"
                                    />
                                </DataTable>
                            </div>
                        </>
                    )}
                    {this.state.isEdit && <EnquiryAdd selectedEnquiry={this.state.selectedEnquiry} onHandleClose={this.onHandleClose} />}

                    <Dialog
                        visible={this.state.isActions}
                        draggable={false}
                        closeOnEscape={false}
                        // position={"right"}
                        className="ma-alert-box w-8"
                        style={{ width: '400px', height: '700px' }}
                        dismissableMask={false}
                        closable={true}
                        footer={() => (
                            <div className="flex justify-content-center mb-4">
                                <Authorizer permId={PERMISSIONS.UPDATE_ENQUIRY}>
                                    <Button
                                        className="formSubmitButton px-6"
                                        style={{ borderRadius: '10px' }}
                                        onClick={() => {
                                            this.onEdit(this.state.selectedEnquiry);
                                        }}
                                    >
                                        <p className="formSubmitButtonText">Edit Enquiry</p>
                                    </Button>
                                </Authorizer>
                                {!this.state.edit && (
                                    <Button className="formSubmitButton px-8" style={{ borderRadius: '10px' }} onClick={() => this.onHandleApplication(this.state.selectedEnquiry)}>
                                        <p className="formSubmitButtonText">Go to Next Step</p>
                                    </Button>
                                )}
                            </div>
                        )}
                        onHide={() => this.onCloseActions()}
                    >
                        <div className="flex flex-column justify-content-center mx-6 my-3">
                            <div>
                                <h4 className="side-headder ">Share Link</h4>
                                <div className="grid h-4rem mt-5">
                                    <div className="md:col-6 lg:col-6 xl:col-6">
                                        <div className="grid doubts-tabs ">
                                            <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                                <p
                                                    className="tabheadText"
                                                    onClick={() => {
                                                        this.onTabChange(0);
                                                    }}
                                                >
                                                    <MessageIcon width={28} height={28} />
                                                </p>
                                            </div>
                                            <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                                <p
                                                    className="tab-p"
                                                    onClick={() => {
                                                        this.onTabChange(1);
                                                    }}
                                                >
                                                    <GmailIcon width={28} height={28} />
                                                </p>
                                            </div>
                                            <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                                <p
                                                    className="tab-p"
                                                    onClick={() => {
                                                        this.onTabChange(2);
                                                    }}
                                                >
                                                    <WhatsAppIcon width={28} height={28} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-5 md:col-5 lg:col-12 xl:col-12 px-0 mx-0">
                                        <div className="flex">
                                            <ClassetInputText width={531} height={56} keyfilter="int" className="contact-no" value={''} onChange={(e) => {}} placeholder="Enter Phone Number" />
                                            <div className="mx-3">
                                                <Button className="formSubmitButton px-6" style={{ borderRadius: '10px', textAlign: 'center', width: '154px', height: '56px' }} onClick={() => this.onCloseActions()}>
                                                    <p className="formSubmitButtonText">Send</p>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-column">
                                        <h4 className="side-headder">Direct Link</h4>
                                        <p className="sub-title">You can share direct URL link</p>
                                    </div>
                                    <div className="py-5 md:col-5 lg:col-12 xl:col-12 px-0 mx-0">
                                        <div className="flex">
                                            <ClassetInputText width={531} height={56} keyfilter="int" className="contact-no" value={''} onChange={(e) => {}} placeholder="Enter Link" />
                                            <div className="">
                                                <Button className="formSubmitButton px-6" style={{ borderRadius: '10px', textAlign: 'center', width: '154px', height: '56px' }} onClick={() => this.onCloseActions()}>
                                                    <p className="formSubmitButtonText">Send</p>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='flex   md:col-12 lg:col-12 xl:col-12'>
                                        <Button className='formSubmitButton px-6' style={{ borderRadius: '10px' }} onClick={() => { this.onEdit(this.state.selectedEnquiry) }}>
                                            <p className='formSubmitButtonText'>Edit Enquiry</p>
                                        </Button>
                                        {!this.state.edit && <Button className='formSubmitButton px-8' style={{ borderRadius: '10px' }} onClick={() => this.onHandleApplication(this.state.selectedEnquiry)} >
                                            <p className='formSubmitButtonText'>Go to Next Step</p>
                                        </Button>}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    {/* {
                        this.state.isAplication && <ApplicationCreate selectedEnquiry={this.state.selectedEnquiry} />
                    } */}
                </>

                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(Enquiry)));
