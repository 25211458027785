import React, { Component } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import Service from '../../../services';
import { baseUrlAdmin, primaryColor } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import { CodeLinkIcon, GmailIcon, MessageIcon, WarningIcon, WhatsAppIcon } from '../../svgIcons';
import { I18Next } from '../../../internationalization';
import withRouter from '../../lib/withRouter';
import moment from 'moment';
import ApplicationCreate from '../Application/application';
import ExamCreate from './exam';
import { PERMISSIONS } from '../../session/authorizer';
const items = [{ label: 'Management' }, { label: 'User Management' }, { label: 'Users List', className: 'ma-active-item' }];
class AdmissionExam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            filterData: null,
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            applicationInfo: [],
            isgetApplication: true,
            isActions: false,
            selectedApplication: {}
        };

        this.service = new Service();
    }

    componentDidMount() {
        this.getAdmissionSettingsInfo();
    }

    getAdmissionSettingsInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ admissionSettingsInfo: res?.res?.data, isLoading: false }, () => this.getAdmissionInfo());
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    getAdmissionInfo = () => {
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/admissions/all?stage=${'Exam'}`;
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = {};
        payload.academicYear = academicYear;

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res.data.admissionRecords.length) {
                        this.setState({
                            isLoading: false,
                            applicationInfo: res.res.data.admissionRecords
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };

    onEdit = (rowData) => {
        if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_EXAM_TEST)) {
            this.setState({
                isgetApplication: false,
                isEdit: true,
                isActions: false,
                selectedExam: rowData || {}
            });
        } else {
            this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
        }
    };

    onHandleRegistration = (selectedExam) => {
        if (selectedExam?.exam?.score == undefined) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Please update exam data', detail: 'Please update exam data', life: 3000 });
            return;
        }
        const { registration } = this.state?.admissionSettingsInfo;
        localStorage.setItem('applicationId', selectedExam?._id);
        if (registration) {
            if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_REGISTRATION)) {
                this.props.navigate('/addRegistration');
            } else {
                this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
            }
        } else {
            if (this.props.permissionIds?.includes(PERMISSIONS.CREATE_ENROLLMENT)) {
                this.props.navigate('/confirm-enrollment');
            } else {
                this.toast.show({ severity: 'error', summary: 'You dont have access to continue.', life: 3000 });
            }
        }
    };
    onHandleClose = () => {
        this.setState(
            {
                isgetApplication: true,
                isEdit: false,
                isActions: false,
                selectedApplication: {}
            },
            () => this.getAdmissionInfo()
        );
    };

    render() {
        return (
            <>
                <>
                    {this.state.isgetApplication && (
                        <>
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    className="dataTableValuesText"
                                    columnResizeMode="expand"
                                    value={this.state.applicationInfo}
                                    dataKey="id"
                                    paginator
                                    responsiveLayout="scroll"
                                    rows={10}
                                    totalRecords={this.state.totalRecords}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                >
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        header="S.No."
                                        className="datatable-cell"
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                                </>
                                            );
                                        }}
                                    />

                                    <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="firstName" field="firstName" header="First Name" />
                                    <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="lastName" field="lastName" header="Last Name" />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="contactNo"
                                        field="contactNo"
                                        header="Phone Number"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="parentName"
                                        field="parentName"
                                        header="Parent Name"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        body={(rowData) => {
                                            return (
                                                <div className="">
                                                    <p>{rowData?.applicationId}</p>
                                                </div>
                                            );
                                        }}
                                        header="Application ID"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        body={(rowData) => {
                                            return (
                                                <div className="" style={{ width: '8rem' }}>
                                                    <p>{moment(rowData?.exam?.date).format('DD-MM-YYYY')}</p>
                                                </div>
                                            );
                                        }}
                                        header="Applied Date"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        body={(rowData) => {
                                            return (
                                                <div className="" style={{ width: '8rem' }}>
                                                    <p>{rowData?.exam?.examType}</p>
                                                </div>
                                            );
                                        }}
                                        header="Exam Type"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="stage"
                                        field="stage"
                                        className=""
                                        header="Current Stage"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="branch"
                                        className=""
                                        field="branchName"
                                        header="Branch"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="board"
                                        className=""
                                        field="boardName"
                                        header="Board"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        filterField="grade"
                                        className=""
                                        field="gradeName"
                                        header="Grade"
                                    />
                                    <Column
                                        style={{ width: '15rem', height: '6rem' }}
                                        alignHeader={'center'}
                                        sortable
                                        headerClassName="text-color-secondary"
                                        bodyClassName="text-center"
                                        header="Actions"
                                        body={(rowData, { rowIndex }) => {
                                            return (
                                                <>
                                                    <Button id={rowData?._id} onClick={(event) => this[rowData?._id].toggle(event)} icon="pi pi-ellipsis-v" className=" p-button-text p-button-secondary ma-ml10" />
                                                    <Menu
                                                        model={[
                                                            {
                                                                label: 'Edit',
                                                                command: () => {
                                                                    this.onEdit(rowData);
                                                                }
                                                            },
                                                            {
                                                                label: 'Next Step',
                                                                command: () => this.onHandleRegistration(rowData)
                                                            }
                                                        ]}
                                                        style={{ width: '85px' }}
                                                        className="h-7rem card shadow-4 flex flex-column justify-content-center align-items-center"
                                                        popup={true}
                                                        ref={(el) => (this[rowData?._id] = el)}
                                                    />
                                                </>
                                            );
                                        }}
                                    ></Column>
                                </DataTable>
                            </div>{' '}
                        </>
                    )}
                </>
                {this.state.isEdit && <ExamCreate editExam={this.state.selectedExam} onHandleClose={this.onHandleClose} />}
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(AdmissionExam)));
