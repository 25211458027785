import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import Service from '../../../../services';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../../utile';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../../store/apiConstants';
import { Checkbox } from 'primereact/checkbox';
import SvgViewer from '../../../customComponents/svgViewer';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import moment from 'moment';
import AssignExamToSection from './assignExamToSection';
import LoadingComponent from '../../../loadingComponent';
import { ApprovalIcon, AssignIcon, ExportIcon, WarningIcon } from '../../../svgIcons';
import axios from 'axios';
import SubjectiveEvaluation from './subjectiveEvaluation';
import SubjectiveUpload from './../testsExam/offlineExamResultUpload/subjectiveUpload';

export default class Subjective extends Component {
    constructor(props) {
        super(props);

        this.state = {

            exams: [],

            columns: [{ columnName: 'Total Number Of Questions', isVisible: false, id: 'totalNoOfQuestions' },
            { columnName: 'Total Marked Questions', isVisible: false, id: 'totalMarkedQuestions' },
            { columnName: 'Total Time', isVisible: false, id: 'totalTime' },
            { columnName: 'Total Marks', isVisible: false, id: 'totalMarks' },
            ],
            showSearch: false,
            globalFilter: null,
            globalSearch: '',
            // totalRecords: 18,
            lazyParams: BasicLazyParams,
            warningDialog: warningDailogInit,
            isShowAssessTheTest: false,
            assessExamId: null
        }
        this.service = new Service();
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.onSearch);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    componentDidMount() {
        this.onSearch();

    }


    // handleDownload = (url, filename) => {

    //     axios.get(url, {
    //         responseType: 'blob',
    //     })
    //         .then((res) => {

    //             const url = window.URL.createObjectURL(
    //                 new Blob([res.data]),
    //             );
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute(
    //                 'download',
    //                 new Date().getTime().toString() + '.pdf',
    //             );

    //             // Append to html link element page
    //             document.body.appendChild(link);

    //             // Start download
    //             link.click();

    //             // Clean up and remove the link
    //             link.parentNode.removeChild(link);
    //             // fileDownload(res.data, filename)
    //         })
    // }

    handleDownload = (url, filename) => {

        axios.get(url, {
            responseType: 'blob',
         
        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }

    genAndDownloadPaper = (rowData) => {


        this.setState({
            isLoading: true
        });
        let url = `${baseUrlForLongRunningTask}/longrunning/${rowData.examId}/download-paper`;
        //let url = `http://localhost:3000/v1/data/examination/${row.rowData[0].examId}/download-paper`;

        axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        })
            .then((res) => {

                if (res && res.data && res.data.data && res.data.data.paperLink) {
                    //this.handleDownload('https://classetofflinecontent.s3.ap-south-1.amazonaws.com/' + res.data.data.paperLink)
                    this.handleDownload('https://classetofflinecontent.s3.ap-south-1.amazonaws.com/' + res.data.data.paperLink, res.data.data.paperLink)
                    this.setState({
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch(e => {

                this.setState({
                    isLoading: false
                });
            });

    }

    onSearch = () => {
        const {
            lazyParams, globalSearch
        } = this.state;
        let filters = { examType: 'SubjectiveTest' };
        this.setState({
            isLoading: true,
            questions: [],
            totalRecords: 0
        });
        this.service.post(`${baseUrlAdmin}/examination/filter?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {
            if (data && data.status && data?.res?.data) {
                this.setState({
                    totalRecords: data.res.data.totalRecords,
                    exams: data.res.data.exams,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'No Exams', detail: "No Exams Found", life: 3000 });
            }


        }).catch(e => {
            this.setState({
                isLoading: false
            })

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });


    }


    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Exam status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active.' : 'InActive'}`
            },
            editAssessmentData: data
        });
    }



    assignExam = (examToAssign) => {
        this.setState({
            isShowAssignDialog: true,
            examToAssign
        })



    }

    onAssignSuccess = () => {

        this.setState({
            isShowAssignDialog: false
        })
       // this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Successfully assigned', life: 3000 });
    }

    onConfirmExport = (examDetails) => {

        let { exportExamDetails } = this.state;

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        this.service.get(`${baseUrlForLongRunningTask}/results/get/test/examId/${exportExamDetails.examId}/examType/${exportExamDetails.examType}/export`, true).then((data) => {

            if (data && data.status) {
                this.setState({
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: data.errMessage, life: 3000 });
            }


        }).catch(e => {
            this.setState({
                isLoading: false
            })

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });


        setTimeout(() => {
            this.setState({
                isLoading: false
            });
            this.onSearch();
        }, 5000);

    };

    genAndDownloadReport = (rowData) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlForLongRunningTask}/longrunning/${rowData.examId}/download-reports`;
        axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        })
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.paperLink) {
                    this.handleDownload('https://classetofflinecontent.s3.ap-south-1.amazonaws.com/' + res.data.data.paperLink, res.data.data.paperLink)
                    this.setState({
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch(e => {

                this.setState({
                    isLoading: false
                });
            });

    }



    assessTheTest = (rowData) => {

        this.setState({
            isShowAssessTheTest: true,
            assessExamId: rowData.examId,
            examName : rowData.examName
        })

    }

    showExportConfirm = (exportExam) => {
        this.setState({
            exportExamDetails: exportExam,
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Please make sure that, everyone attempted exam. If any user submit the exam after export, you have to export again to calculate for reports.`
            },
        });
    }

    onClickUploadExamReports = (rowData) => {
        this.setState({ 
            showUploadResult: true,
            rowDetailsOfUploading : rowData
        })
    }

    render() {
        const { exams, isShowAssessTheTest } = this.state;
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Subjective Test List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ml-4 h-2rem w-15rem" onChange={this.onGlobalSearch} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} >
                            
                        </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} > */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className='ml-2'>{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>

            {
                isShowAssessTheTest ? <SubjectiveEvaluation assessExamId={this.state.assessExamId} examName = {this.state.examName} onBackClick={() => {
                    this.setState({
                        isShowAssessTheTest: false,
                        assessExamId: null,examName : ""
                    })
                }} /> : <div className="card datatable-crud-demo ma-mt20 mt-3">
                    <DataTable ref={(el) => this.dt = el}
                        //scrollable scrollHeight="500px"
                        className="dataTableValuesText"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={this.state.exams}
                        filters={this.state.lazyParams.filters}
                        paginator
                        lazy showGridlines
                        onPage={this.onPage}
                        onSort={this.onSort}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        sortField={this.state.lazyParams.sortField}
                        sortOrder={this.state.lazyParams.sortOrder}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        // paginatorLeft={() => {
                        //     return <>
                        //         <p className='avail-q'>Available Exams:  <span>{this.state.totalRecords}</span> </p>
                        //     </>
                        // }}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        //header={header}
                        >
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='examName'  field="examName" header="Exam Name" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='testType' field="testType" header="Test Type" />
                        {this.isColumnVisible('totalNoOfQuestions') && <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='totalNoOfQuestions' field="totalNoOfQuestions" header="Total Number of Questions" />}
                        {this.isColumnVisible('totalMarkedQuestions') && <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center'sortField='totalMarkedQuestions' field="totalMarkedQuestions" header="Total Marked Questions" />}
                        {this.isColumnVisible('totalTime') && <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='totalTime' field="totalTime" header="Total Time" />}
                        {this.isColumnVisible('totalMarks') && <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='totalMarks' field="totalMarks" header="Total Marks" />}
                        {/* <Column header="Export Exam" body={(rowData) => {
                            return (<>
                                {rowData.lastExportDate ? <> {`Last exported on ${moment(rowData.lastExportDate).format('MMM Do YYYY, h:mm:ss a')}, ${rowData.exportMsg}`}
                                </>
                                    : <div
                                        className="mt-2"
                                        style={{ color: '#0000008a', cursor: 'pointer' }}
                                    >
                                        Not exprorted
                                    </div>}
                            </>
                            );
                        }} ></Column> */}
                        <Column className=' ' alignHeader={"center"}  header="Actions" body={(rowData, { rowIndex }) => {
                            return (<>
                              <div className="flex justify-content-center align-items-center">
                                <Authorizer permId={PERMISSIONS.EXAM_EDIT_SUBJECTIVE} >
                                    <span data-pr-tooltip="Edit Subjective Test" data-pr-position="bottom" className={`b${rowIndex} ma-mr20`} onClick={() => { this.props.onEditExam(rowData.examId, 'SubjectiveTest') }}>
                                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.b${rowIndex}`} />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.EXAM_ASSIGN_SUBJECTIVE} >
                                    <span data-pr-tooltip="Assign Subjective Exam" data-pr-position="bottom" className={`e${rowIndex} ml-3`} onClick={() => { this.assignExam(rowData) }}>
                                        <AssignIcon height={20} width={20} />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.e${rowIndex}`} />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.EXAM_PAPER_DOWNLOAD} >
                                    <span data-pr-tooltip="Download Paper" data-pr-position="bottom" className={`e${rowIndex} ml-3`} onClick={() => { this.genAndDownloadPaper(rowData) }}>
                                        <i className='pi pi-download' style={{ fontSize: '20px' }}></i>
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.e${rowIndex}`} />
                                </Authorizer>
                                {/* <Authorizer permId={PERMISSIONS.EXAM_ADHOC_EXPORT} >
                                    <span data-pr-tooltip="Export Exam" data-pr-position="bottom" className={`ewa${rowIndex} ml-3`} onClick={() => { this.showExportConfirm(rowData) }}>
                                        <ExportIcon width={20} height={20} />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ewa${rowIndex}`} />
                                </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.EXAM_DOWNLOAD_ADHOC_REPORTS} >
                                    <span data-pr-tooltip="Download Exam Reports" data-pr-position="bottom" className={`epa${rowIndex} ml-3`} onClick={() => { this.genAndDownloadReport(rowData) }}>
                                        <ExportIcon width={20} height={20} />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.epa${rowIndex}`} />
                                </Authorizer> */}
                                <Authorizer permId={PERMISSIONS.EXAM_EVALUATE_SUBJECTIVE} >
                                    <span data-pr-tooltip="Assess the test" data-pr-position="bottom" className={`epa${rowIndex} ml-3`} onClick={() => { this.assessTheTest(rowData) }}>
                                        <ApprovalIcon height={20} width={20} />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.epa${rowIndex}`} />
                                </Authorizer>
                                {/* <Authorizer permId={PERMISSIONS.EXAM_UPLOAD_EXAM_RESULTS} >
                                    <span data-pr-tooltip="Upload Exam Results" data-pr-position="bottom" className={`epa${rowIndex} ml-3`} onClick={() => { this.onClickUploadExamReports(rowData) }}>
                                        <i className='pi pi-cloud-upload' width="30" height="30" />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.epa${rowIndex}`} />
                                </Authorizer> */}
                                </div>                       
                            </>
                            );
                        }} ></Column>
                    </DataTable>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div >
            }





            {
                this.state.isShowAssignDialog && <AssignExamToSection examType="SubjectiveTest" examToAssign={this.state.examToAssign} onCancel={() => {
                    this.setState({
                        isShowAssignDialog: false
                    })
                }}
                    onAssignSuccess={this.onAssignSuccess}

                />
            }
            {
                this.state.isLoading && <LoadingComponent />
            }

            <Dialog
                header={"Upload Subjective Exam Results"}
                draggable={false}
                closeOnEscape={false}
                className='ma-alert-box'
                blockScroll={true}
                dismissableMask={false}
                closable={true}
                visible={this.state.showUploadResult} style={{ width: '50%', height: '50vh' }}
                onHide={() => this.setState({ showUploadResult: false,rowDetailsOfUploading : {}})}
            >
                <SubjectiveUpload rowDetailsOfUploading = {this.state.rowDetailsOfUploading}/>
            </Dialog>
            <Dialog
                header={<></>}
                draggable={false}
                closeOnEscape={true}
                className='ma-alert-box'
                blockScroll={true}
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmExport} />
                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, exportExamDetails: null })}>
                <div>
                    <span className="warning-icon flex justify-content-center" >
                        <WarningIcon width={"30%"} height={"30%"} />
                    </span>
                </div>
                <p className='text-xl mt-2 text-900 font-bold flex justify-content-center'>{this.state.warningDialog.headerMsg}</p>
                <p className='text-lg text-600 text-center font-bold white-space-normal'>{this.state.warningDialog.message}</p>
            </Dialog>
        </>)
    }
}
