import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Component } from "react"
import Authorizer, { PERMISSIONS } from "../../session/authorizer";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { Column } from "primereact/column";
import SvgViewer from "../../customComponents/svgViewer";
import LoadingComponent from "../../loadingComponent";
import { BasicLazyParams } from "../../../utile";
import InputTextB from "../../customComponents/inputTextB";
import { AddIcon2, AddIconInButton, ClassetEditIcon, CodeLinkIcon, InfoIcon, MailIcon } from "../../svgIcons";
import ClassetInputText from "../../../classetComponents/classetInputText";
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import ClassetMultiSelect from "../../../classetComponents/classetMultiSelect";
import { baseUrlAdmin } from "../../../store/apiConstants";
import Service from "../../../services";
import { getFormFields, onDropDownChange, onTextChange } from "../../../utile/formHelper";
import { admissionTypes } from "../../../constants";
import { connect } from 'react-redux';
import withRouter from "../../lib/withRouter";
import Authentication from '../../session/index.js';
import { getBoardsData } from "../../../store/actions/sp_boardsActions.js";
import _ from 'lodash';
import BranchToSectionDD from "../../BaseDropdownComponents/BranchToSectionDD.js";
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]
const createFeeCategoryFields = require('./createFeeCategory.json');

class FeeCategory extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFeeCategoryFields, this.props.editData);



        this.state = {
            feetypes: [],
            formValidations: this.formFields.formValidations,
            feeCategory: [],
            categoryAssignInfo: [],
            feeCategoryFormData: {},
            openCreateFeeCategoryDialog: false,
            openFeeStructureScheduleDialog: false,
            lazyParams: BasicLazyParams,
            branchId: '',
            boardId: '',
            classId: '',
            sectionId: '',
            selectedCategoryAssignInfo: [],
            selectAll: false

        }
        this.service = new Service()


    }



    onHandleFeeCategoryDialog = () => {

        this.setState({
            openCreateFeeCategoryDialog: true

        })
    }

    onHideCreateFeeCategoryDialog = () => {
        this.setState({
            openCreateFeeCategoryDialog: false,
            feeCategoryFormData: {}


        })

    }

    onHandleFeeStructureSchedule = () => {
        this.setState({ openFeeStructureScheduleDialog: true })
    }
    onHideFeeStructureScheduleDialog = () => {
        this.setState({ openFeeStructureScheduleDialog: false })

    }
    componentDidMount() {
        this.props.getBoardsData(true);
        // this.props.getBranchesLatest(true);
        this.getFeeTypes()
        // this.getFeeCategoryAssignInfo();
        this.getFeeCategory()



    }
    onChangeBranch = () => {
        this.setState({ branchId: 'f3fee05c-5d87-4a5b-986a-ac474dfb7d49' })
    }
    onChangeBoards = () => {
        this.setState({ boardId: 'b1c0f61f-f03b-4c75-ae90-3a07ba15dee7' })

    }
    onChangeGrade = () => {
        this.setState({ classId: 'bc583606-6943-4d94-9334-f99bc0409bd8' })


    }
    onChangeSection = () => {
        this.setState({ sectionId: 'bf805df2-854a-48f3-9635-eef60253538d' })
    }

    onChangeAdmission = (v) => {
        let url = `${baseUrlAdmin}/fee-types/filtered`
        let payload = {
            admissionType: v
        }
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data.map((each) => {
                return {
                    label: each.feeType,
                    value: each._id
                }
            })

            if (data?.status && data?.res?.data) {
                this.setState({
                    feeType: options
                })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee type options', life: 3000 });

            }
        })


    }
    getFeeCategory = () => {
        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/fee-category?academicYear=${localStorage.getItem('userAcademicYear')}`
        this.service.get(url, true).then((data) => {
            if (data?.status && data?.res?.data?.response
            ) {
                this.setState({
                    feeCategory: data?.res?.data?.response,
                    isLoading: false
                })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Getting Fee Category', life: 3000 });
                this.setState({
                    // feeCategory: data?.res?.data?.response,
                    isLoading: false
                })

            }
        })

    }
    onSumbmitFeeCategory = () => {
        this.setState({ isLoading: false })
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let data = this.state.feeCategoryFormData
        let isBoard = (data.boardId) ? data.classId !== "" && data.classId !== undefined : true
        let flag = (data.categoryName !== "" && data.categoryName !== undefined && data.categoryCode !== undefined && data.categoryCode !== ""
            && data.feeTypes !== "" && data.feeTypes !== undefined
        ) ? true : false
        let url = `${baseUrlAdmin}/fee-category`;
        let payload = this.state.feeCategoryFormData
        payload.status = true
        payload.isGrade = (data.boardId && data.classId) ? true : false
        payload.academicYear = AcademicYear

        if (data._id) {
            payload._id = data._id
            if (flag && isBoard) {
                this.service.put(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({
                            feeCategoryFormData: {},
                            // editDialog: false,
                            isLoading: false,
                            openCreateFeeCategoryDialog: false
                        })
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        this.getFeeCategory()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                    }
                },);
            } else {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Enter All The Require Fields', life: 3000 });
            }

        } else {
            if (flag && isBoard) {
                this.service.post(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({
                            feeCategoryFormData: {},
                            // editDialog: false,
                            isLoading: false,
                            openCreateFeeCategoryDialog: false
                        })
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        this.getFeeCategory()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                    }
                },);
            } else {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Enter All The Require Fields', life: 3000 });
            }
        }

    }


    getFeeCategoryAssignInfo = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = {
            "branchId": "f3fee05c-5d87-4a5b-986a-ac474dfb7d49",
            "boardId": "b1c0f61f-f03b-4c75-ae90-3a07ba15dee7",
            "classId": "bc583606-6943-4d94-9334-f99bc0409bd8",
            "sectionId": "bf805df2-854a-48f3-9635-eef60253538d"
        }
        payload.academicYear = academicYear
        let url = `${baseUrlAdmin}/fee-category/assignInfo`;
        this.service.post(url, payload, true).then(data => {
            if (data.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data
                })
                // this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Fee Category AssignInfo fetched successfully', life: 3000 });
            } else {
                // this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });

            }
        });

    }
    feeCategoryAssign = () => {

    }
    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        })
    }

    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = (value.length === this.state.categoryAssignInfo.length)
        this.setState({
            selectedCategoryAssignInfo: event.value,
            selectAll: selectedAll

        })

    }

    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedCategoryAssignInfo: this.state.categoryAssignInfo,
                selectAll: true
            })
        } else {
            this.setState({
                selectedCategoryAssignInfo: [],
                selectAll: false
            })
        }
    };

    onChangeToggle = (e, each) => {
        let data = each
        let flag = (data.categoryName !== "" && data.categoryName !== undefined && data.categoryCode !== undefined && data.categoryCode !== ""
            && data.feeTypes !== "" && data.feeTypes !== undefined) ? true : false
        let url = `${baseUrlAdmin}/fee-category`;
        let payload = each
        payload.status = e.target.value
        if (flag) {
            this.service.put(url, payload, true).then(data => {
                if (data.status) {
                    this.getFeeCategory()
                    this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Fee Category Status Updated successfully', life: 3000 });

                } else {
                    this.getFeeCategory()
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: data.errMessage, life: 3000 });

                }
            },);
        } else {
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating the fee Category', life: 3000 });
        }
    }

    onEdit = (each) => {
        if (each.isFeeStructureCreated) {
            this.toast?.show({ severity: 'error', summary: 'Error', detail: `Can't edit , Fee Structure already created for ${each.categoryName}  category`, life: 3000 });

        } else {
            this.setState({ openCreateFeeCategoryDialog: true, feeCategoryFormData: each })
        }

    }

    getFeeTypes = () => {
        let url = `${baseUrlAdmin}/fee-types?academicYear=${localStorage.getItem('userAcademicYear')}`;
        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data.res.status && data.res.data.response) {

                    this.setState({
                        feeTypes: data?.res?.data?.response,
                    })
                }
            }
        });
    }



    render() {
        const { feeCategoryFormData, formValidations, } = this.state;

        return (

            <>
                <div className="admin-management">

                    <div className="grid mb-6 mt-2 mr-2">
                        <div className='col-11 flex justify-content-end align-items-center'>
                            <Authorizer permId={PERMISSIONS.CREATE_FEE_CATEGORY} >
                                <Button className="addButtonn" onClick={this.onHandleFeeCategoryDialog}>
                                    <p >Add Fee Category</p>
                                    <AddIcon2 />

                                </Button>
                            </Authorizer>

                        </div>
                    </div>
                    <div className="grid col-12">
                        {this.state?.feeCategory?.map((each, index) => {
                            return (

                                <div className="mx-6 card cursor-pointer col-2 doubts-tab-w nowrap" key={index}
                                    style={this.state.currentTab === 0 ? { height: '170px', width: '320px' } : { height: '170px', width: '320px' }}
                                >
                                    <div className="flex flex-column justify-content-center align-items-center nowrap">
                                        <div className="mt-1 w-full flex justify-content-end align-items-center ">
                                            <Authorizer permId={PERMISSIONS.UPDATE_FEE_CATEGORY} >
                                                <InputSwitch
                                                    className='mt-1'
                                                    onChange={(e) => this.onChangeToggle(e, each)}
                                                    checked={each?.status}
                                                />
                                            </Authorizer>
                                        </div>
                                        <div className="w-full flex flex-column justify-content-center mt-3 ml-3">
                                            <h3 className='Montserrat24'>{each?.categoryName}</h3>
                                            <p className='poppins24'>{each?.feeTypes?.length} Fee types</p>
                                        </div>
                                        <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center' onClick={() => { this.onEdit(each) }}>
                                            <Authorizer permId={PERMISSIONS.UPDATE_FEE_CATEGORY} >
                                                <ClassetEditIcon height={32} width={32} />
                                            </Authorizer>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                    {this.state.isLoading && <LoadingComponent />}
                </div>
                <Dialog
                    isOpen={this.state.openCreateFeeCategoryDialog}
                    visible={this.state.openCreateFeeCategoryDialog}
                    // style={{ width: '25%', height: '70%', position: 'fixed', right: 0, top: '20%', bottom: '10%' }}
                    style={{ position: 'fixed', right: '0', height: '800px' }}
                    // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    draggable={false}
                    onHide={this.onHideCreateFeeCategoryDialog}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2 mb-4'>
                            <h3 className="formhead text-center">Add Fee Category</h3>
                        </div>)
                    }}
                // footer={() => (
                //     <div className='flex justify-content-center mb-4'>

                //         <Button className='submitButton w-7' onClick={this.onSumbmitFeeCategory} >
                //             <AddIcon2 />
                //             <p className='submitButtonText'>Add</p>
                //         </Button>
                //         <Button className='cancelButtonn w-4'
                //             onClick={this.onHideCreateFeeCategoryDialog}
                //         >
                //             <p className='cancelButtonnText'>Cancel</p>
                //         </Button>
                //     </div>
                // )}

                >
                    <div className="mb-5 ml-5 mr-5">

                        <div className="" >
                            <div className=" col-12">
                                <label className='label mb-2'>Category Name<span className='ma-required'>*</span></label>

                                <div className="mt-2 flex">
                                    <ClassetInputText
                                        icon={<CodeLinkIcon height={22} />}
                                        id="categoryName"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Category Name"
                                        infoIcon={<InfoIcon />}
                                        value={feeCategoryFormData.categoryName}
                                        onChange={(e) => onTextChange(e.target.value, 'categoryName', this, createFeeCategoryFields, feeCategoryFormData, formValidations, 'feeCategoryFormData', formValidations)}

                                    />
                                    <div className="">
                                        {/* <Tooltip target=".custom-target-title ">
                                            <div className='flex justify-content-center align-items-center'>
                                                <i className="pi pi-info-circle  mr-3"></i>
                                                <div>
                                                    <p className='font-semibold text-sm'>Title of the Term


                                                    </p>
                                                    <p className="-mt-1">Title will be displayed throughout</p>
                                                </div>
                                            </div>
                                        </Tooltip> */}

                                        <i className="custom-target-title  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                            data-pr-position="right"
                                            data-pr-at="right+5 center"
                                            data-pr-my="left center-2"
                                            style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                        </i>
                                    </div>
                                </div>
                                {formValidations && !formValidations.fields['categoryName'].isValid && <p className="p-error">{formValidations.fields['categoryName'].errorMsg}</p>}



                            </div>
                            <div className="col-12">
                                <label className='label'>Category Code<span className='ma-required'>*</span></label>
                                <div className="mt-2 flex">
                                    <ClassetInputText
                                        id='categoryCode'
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Category Code"
                                        infoIcon={<InfoIcon />}
                                        value={feeCategoryFormData.categoryCode}
                                        onChange={(e) => onTextChange(e.target.value, 'categoryCode', this, createFeeCategoryFields, feeCategoryFormData, formValidations, 'feeCategoryFormData', formValidations)}

                                    />
                                    <div className="">
                                        {/* <Tooltip target=".custom-target-title ">
                                            <div className='flex justify-content-center align-items-center'>
                                                <i className="pi pi-info-circle  mr-3"></i>
                                                <div>
                                                    <p className='font-semibold text-sm'>Title of the Term


                                                    </p>
                                                    <p className="-mt-1">Title will be displayed throughout</p>
                                                </div>
                                            </div>
                                        </Tooltip> */}

                                        <i className="custom-target-title  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                            data-pr-position="right"
                                            data-pr-at="right+5 center"
                                            data-pr-my="left center-2"
                                            style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                        </i>
                                    </div>
                                </div>
                                {formValidations && !formValidations.fields['categoryCode'].isValid && <p className="p-error">{formValidations.fields['categoryCode'].errorMsg}</p>}


                            </div>
                            <div className="col-12 ">
                                <label className='label'>Fee Types<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetMultiSelect
                                        id='feeTypes'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state.feeTypes}
                                        value={feeCategoryFormData.feeTypes}
                                        optionValue='_id'
                                        optionLabel='feeType'


                                        onChange={(e) => onDropDownChange(e.target.value, 'feeTypes', this, createFeeCategoryFields, feeCategoryFormData, formValidations, 'feeCategoryFormData', formValidations)}
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Fee Type</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['feeTypes'].isValid && <p className="p-error">{formValidations.fields['feeTypes'].errorMsg}</p>}


                            </div>


                            <div className="col-12 ">
                                <label className='label'>Board</label>
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id='boardId'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.props.boards}
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        value={feeCategoryFormData.boardId}
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'boardId', this, createFeeCategoryFields, feeCategoryFormData, formValidations, 'feeCategoryFormData', formValidations)
                                            // this.setState({section:true})
                                        }
                                        }
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Board</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['boardId'].isValid && <p className="p-error">{formValidations.fields['boardId'].errorMsg}</p>}


                            </div>
                            {feeCategoryFormData.boardId &&
                                <div className="col-12 ">
                                    <label className='label'>Grade</label>
                                    <div className="mt-2">
                                        <ClassetDropdown
                                            id='classId'
                                            icon={<MailIcon />}
                                            className="text-xl"
                                            height="48px"
                                            width="368px"
                                            options={this.props.boards.find((board) => board.boardId === feeCategoryFormData?.boardId)?.classes}
                                            optionLabel="className"
                                            optionValue="classId"

                                            value={feeCategoryFormData.classId}
                                            onChange={(e) => onDropDownChange(e.target.value, 'classId', this, createFeeCategoryFields, feeCategoryFormData, formValidations, 'feeCategoryFormData', formValidations)}
                                            placeholder={(
                                                <div>
                                                    <span className='text-xl'>Select Grade</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {formValidations && !formValidations.fields['classId'].isValid && <p className="p-error">{formValidations.fields['classId'].errorMsg}</p>}


                                </div>}

                            <div className='flex justify-content-center mt-8'>

                                <Button className='submitButton w-7 mr-2' onClick={this.onSumbmitFeeCategory} >
                                    <AddIcon2 />
                                    <p className='submitButtonText'>Add</p>
                                </Button>
                                <Button className='cancelButtonn w-4'
                                    onClick={this.onHideCreateFeeCategoryDialog}
                                >
                                    <p className='cancelButtonnText'>Cancel</p>
                                </Button>
                            </div>



                        </div>
                    </div>


                </Dialog>
                {/* 
                <Dialog
                    isOpen={this.state.openFeeStructureScheduleDialog}
                    visible={this.state.openFeeStructureScheduleDialog}
                    style={{ width: '65vw', height: '80vh', }}
                    draggable={false}
                    onHide={this.onHideFeeStructureScheduleDialog}
                    closable={true}
                    header={() => {
                        return (<div className='mt-4 mb-4 p-4'>
                            <h3 className="text-center formhead">Fee Structure&Schedule</h3>
                            <div className="grid mb-2 mt-4" >
                                <BranchToSectionDD setValues={this.setDropdownValues} />
                            </div>


                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4 mt-4'>
                            <Button className='submitButton w-4'
                                onClick={this.feeCategoryAssign} >
                                <p className='submitButtonText'>Assign</p>
                            </Button>
                            <Button className='cancelButton w-3'
                                onClick={this.onHideFeeStructureScheduleDialog} >
                                <p className='cancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}


                >
                    <div className="p-5">

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.categoryAssignInfo}
                            dataKey="boardId"
                            paginator
                            rows={10}
                            // showGridlines
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            // globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            // header={header}
                            sortable
                            selection={this.state?.selectedCategoryAssignInfo}
                            onSelectionChange={this.onSelectionChange}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.onSelectAllChange}
                        // selectionMode="checkbox"

                        >

                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                            <Column field="branchName" header="Brach Name" className="datatable-cel" sortable />
                            <Column field="boardName" header="Board Name" className="datatable-cel" sortable />
                            <Column field="className" header="Grade Name" className="datatable-cel" sortable />
                            {this.state.classId !== "" && <Column field="sectionName" header="Section Name" className="datatable-cel" sortable />}
                            {this.state.sectionId === "" && <Column field="totalStudents" header="Total Students" className="datatable-cel" sortable />}
                            {this.state.sectionId !== "" &&
                                <Column field="username" header="Students" className="datatable-cel" sortable />}



                        </DataTable>

                        <Toast ref={(el) => this.toast = el} position="bottom-right" />


                    </div >



                    <Toast ref={(el) => this.toast = el} position="bottom-right" />





                </Dialog> */}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}



const mapStatesToProps = (state) => ({
    boards: state.boardsData.boards,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(FeeCategory)));