import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';

import './styles.scss'
import { Button } from 'primereact/button';
// import { AddIcon2, DownloadIcon, MailIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
// import { BasicLazyParams } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { BasicLazyParams } from '../../../../utile';
import { AddIcon2, BoardsIconInActive, ClassetEditIcon, DownloadIcon, MailIcon, QuestionMarkIcon, RefreshIcon, UserIcon } from '../../../svgIcons';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import Authorizer, { PERMISSIONS, isAuthorized, getPermIdOfAction } from '../../../session/authorizer';
import Authentication from '../../../session';
import { connect } from 'react-redux';
import withRouter from '../../../lib/withRouter';
import { TestTypes } from '../../Examination/constants';
import ClassetDropdown from '../../../../classetComponents/classetDropDown';
import { getBoardsData } from '../../../../store/actions';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import QuizList from './quizList';
import DiagnosticTestList from './dignosticTestList'
import AssessmentList from './assessmentList';
import Subjective from './subjectiveExamList';
import AdhocT2List from './adhocT2List';
import OfflineTestList from './offlineTestList';
import AdhocList from './adhocList';
import "./styles.scss"
import CreateTest from '../../../examination/examsCreation';

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]
const branchData = [
    {
        branchName: "Branch A",
        branchHead: "John Doe",
        contactNumber: "123-456-7890",
        creationDate: "2023-01-15",
        totalStaff: "X-A",
        totalStudents: 300,
    },
    {
        branchName: "Branch B",
        branchHead: "Jane Smith",
        contactNumber: "987-654-3210",
        creationDate: "2022-09-20",
        totalStaff: "X-A",
        totalStudents: 250,
    },
    {
        branchName: "Branch C",
        branchHead: "Alice Johnson",
        contactNumber: "456-789-0123",
        creationDate: "2023-05-10",
        totalStaff: "X-A",
        totalStudents: 350,
    },
    {
        branchName: "Branch D",
        branchHead: "Michael Brown",
        contactNumber: "789-012-3456",
        creationDate: "2021-11-30",
        totalStaff: "X-A",
        totalStudents: 280,
    },
    {
        branchName: "Branch E",
        branchHead: "Emily Wilson",
        contactNumber: "234-567-8901",
        creationDate: "2022-07-25",
        totalStaff: "X-A",
        totalStudents: 320,
    },
    {
        branchName: "Branch F",
        branchHead: "David Lee",
        contactNumber: "890-123-4567",
        creationDate: "2024-02-18",
        totalStaff: "X-A",
        totalStudents: 270,
    },
    {
        branchName: "Branch G",
        branchHead: "Sophia Garcia",
        contactNumber: "345-678-9012",
        creationDate: "2023-09-05",
        totalStaff: "X-A",
        totalStudents: 290,
    },
    {
        branchName: "Branch H",
        branchHead: "James Rodriguez",
        contactNumber: "012-345-6789",
        creationDate: "2021-12-20",
        totalStaff: "X-A",
        totalStudents: 330,
    },
    {
        branchName: "Branch I",
        branchHead: "Olivia Martinez",
        contactNumber: "678-901-2345",
        creationDate: "2022-04-10",
        totalStaff: "X-A",
        totalStudents: 260,
    },
    {
        branchName: "Branch J",
        branchHead: "Ethan Taylor",
        contactNumber: "901-234-5678",
        creationDate: "2023-11-15",
        totalStaff: "X-A",
        totalStudents: 310,
    },
];
const testFormat = [{ value: 1, label: "Open Test" }, { value: 2, label: "Closed Test" }]

const testTemplates = [
    { value: 1, label: "NEET 2024" },
    { value: 2, label: "CBSE" },
    { value: 3, label: "Custom" },
]




const testData = [
    {
        examName: "Exam Name 1",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 2",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 3",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 4",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 5",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 6",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 7",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 8",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 9",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 10",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
];
export class TestsandExam extends Component {
    constructor(props) {
        super(props)


        this.state = {
            lazyParams: BasicLazyParams,


            openUserDialog: false,
            openEditTestDialog: false,
            openCreateDialog: false,

            searchType: 1,
            searchInput: "",
            teacher: [{ id: 1, title: '', description: '' }],
            selfSelection: true,
            autoSelection: true,
            onEdit: false,
            onhide: false,
            testFormats: testFormat,
            testFormatType: null,
            startDate: null,
            testTemplates: testTemplates,
            selectedTestTemplate: null,
            ///me added


            examType: null,
            editExamType: null,
            editExamId: null,
            editedExamType: null,
            testTypes: []
        }
    }

    componentDidMount() {
        //console.log(this.props, "ppppppp", this.props.permissionIds)

        if (this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {
                

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-list'), this.props.userInfo))) {
                        console.log("navya", t)
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }

        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-list'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }

    onExamEditSuccess = () => {

        this.setState((prevState) => {

            return {
                editExamType: null,
                editExamId: null,
                editedExamType: prevState.editExamType
            };
        });

        this.toast.show({ severity: 'success', summary: 'Created', detail: `Edited exam record successfully`, life: 3000 });


    }

    onEditExam = (rowdata, editExamType) => {
        const editExamId = rowdata?.examId
        console.log(editExamId, editExamType, "editExamId, editExamTypeNavya")
        this.setState({
            editExamId,
            editExamType
        })
    }

    handleUser = () => {
        this.setState({ openUserDialog: true })
    }
    onHideUserDialog = () => {
        this.setState({ openUserDialog: false })
    }

    handleEditTest = () => {
        this.setState({ openEditTestDialog: true })
    }

    onHideEditDialog = () => {
        this.setState({ openEditTestDialog: false })
    }

    question = [
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" }
    ]

    handleSelfSelection = () => {
        this.setState(prevState => ({
            onEdit: prevState.selfSelection,
            autoSelection: false,
            onGenerateQuestions: false,
            onhide: true

        }))


    }
    handleGenerateQuestions = () => {
        this.setState(prevState => ({
            onEdit: prevState.selfSelection,
            autoSelection: false,
            onhide: true,
            onGenerateQuestions: true

        }))


    }
    handleAutoPaperGenerate = () => {
        this.setState(prevState => ({
            onEdit: prevState.autoSelection,
            selfSelection: false,
            onGenerateQuestions: false,
            onhide: true

        }))


    }
    handleOnEdit = () => {
        this.setState(prevState => ({
            onhide: false,
            selfSelection: true,
            autoSelection: true,
            onGenerateQuestions: false,


        }));


    }


    render() {
        const { selectedTab, editExamId, editExamType, editedExamType } = this.state;

        return (
            <>
                <div className=''>
                    <div className='grid mt-2'>
                        <div className="col-3">
                            <ClassetDropdown required={true} label={'Select Test Type'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Exam Type'} onChange={(e) => this.setState({ examType: e.value })} options={this.state.testTypes}
                                value={this.state.examType} className=' no-border ' />
                        </div>
                    </div>



                    <div className='mt-4'>

                        {(this.state.editExamType && editExamId ) && <CreateTest onExamEditSuccess={this.onExamEditSuccess} editExamId={editExamId} editExamType={editExamType} /> }
                        {
                           (this.state.examType == 'Quiz' &&  !(this.state.editExamType && editExamId )) && <QuizList editExamType={this.state.editExamType} onEditExam={this.onEditExam} />
                        }
                        {
                            (this.state.examType == 'Assessment' &&  !(this.state.editExamType && editExamId )) &&  <AssessmentList editExamType={this.state.editExamType} onEditExam={this.onEditExam} />
                        }
                        {
                            (this.state.examType == 'AdhocMockTest' && !(this.state.editExamType && editExamId )) &&  <AdhocList editExamType={this.state.editExamType} onEditExam={this.onEditExam} />
                        }

                        {
                            (this.state.examType == 'SubjectiveTest' &&  !(this.state.editExamType && editExamId )) && <Subjective  editExamType={this.state.editExamType} onEditExam={this.onEditExam} />
                        }

                        {
                            (this.state.examType == 'AdhocMockTestT2' &&  !(this.state.editExamType && editExamId )) && <AdhocT2List  editExamType={this.state.editExamType} onEditExam={this.onEditExam} />
                        }
                        {
                            (this.state.examType == 'DiagnosticTest' && !(this.state.editExamType && editExamId )) && <DiagnosticTestList editExamType={this.state.editExamType} onEditExam={this.onEditExam} />
                        }
                        {
                            this.state.examType == 'offlineTest' && !(this.state.editExamType && editExamId ) && <OfflineTestList onEditExam={this.onEditExam} />
                        }


                        {this.state.examType === null && (<div className="card datatable-crud-demo ma-m30">

                            <DataTable value={[]} className="dataTableValuesText">
                                <Column sortable sortField='examName' headerClassName="text-color-secondary" bodyClassName='text-center' field="examName" header="Exam Name" />
                                <Column sortable sortField='boardName' headerClassName="text-color-secondary" bodyClassName='text-center' field="boardName" header="Board Name" />
                                <Column sortable sortField='className' headerClassName="text-color-secondary" bodyClassName='text-center' field="className" header="Class Name" />
                                <Column sortable sortField='noOfQuestions' headerClassName="text-color-secondary" bodyClassName='text-center' field="noOfQuestions" header="Number of Questions" />
                                <Column sortable sortField='totalTime' headerClassName="text-color-secondary" bodyClassName='text-center' field="totalTime" header="Total Time (Mins)" />
                                <Column sortField='totalMarks' headerClassName="text-color-secondary" bodyClassName='text-center' field="totalMarks" header="Total Marks " />
                            </DataTable>

                        </div>)}

                    </div>

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}




const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});



export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(TestsandExam)));
