import React, { Component } from "react"
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';

import { BasicLazyParams, trimObj, warningDailogInit } from '../../utile';
import _ from 'lodash';
import { AcademicSessionCalIcon, AddIcon2, AssignFormIcon, CalenderIcon, CancelRed, ClassetEditIcon, CodeLinkIcon, CrossInBox, DeleteIconClasset, DeleteIconClassetFull, GreenCheck, InfoIcon, MailIcon, MoneyIcon, RightMarkInCircle, StarInfoBadge, } from '../svgIcons';
import { baseUrlAdmin, baseUrlForLongRunningTask, } from '../../store/apiConstants';
import ClassetDropdown from "../../classetComponents/classetDropDown";
import ClassetInputText from "../../classetComponents/classetInputText";
import ClassetMultiSelect from "../../classetComponents/classetMultiSelect";
import { paymentTerms, paymentModes } from "../../constants";
import { getFormFields, onTextChange, onDropDownChange, onToggleChange, isFormValid, onChoiceChange, isFieldValid } from "../../utile/formHelper";
import BranchToSectionDD from "../BaseDropdownComponents/BranchToSectionDD";
import ClassetCalendar from "../../classetComponents/classetCalender";
import ClassetTextArea from "../../classetComponents/classetTextArea";
import InputTextB from "../customComponents/inputTextB";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import FileUploadLambda from "../fileUpload/fileUploadLambda";
import AttachPreviewDelete from "../questionBank/common/attachPreviewDelete";
import reactStringReplace from "react-string-replace";
import CkEditorComponent from "../questionBank/common/ckEditor";
import notification from "../notification";
import moment from "moment/moment";
import LoadingComponent from '../loadingComponent';
import { getImageUrl } from "../../store/actions";

const NotificationTypes = [{ label: 'MockTest Report', value: 'MockTest Report' }, { label: 'Calendar', value: 'Calendar' }]

const NOTIFICATION_MODES = {
    SMS: 'sms',
    ALERT: 'alert',
    EMAIL: 'email',
    WHATS_APP: 'whatsapp'
}
const smstemps = [
    {
        "_id": "62cd0eacacff7519001a8c8b",
        "name": "sda tfyud dfsuda safua ftuaysdftuasyd fuydftasyudf",
        "content": "fduaoidsfo iasd foasidf osiday fhoasdyfasoidfyha sfyioofasoyif sodifyh aosdf yhsiod faosdf dsoaif oai foasd fyoasdyf hoi fasodi osiduf aodf oisd foasidf uaosid"
    },
    {
        "_id": "62cd08cb2eda2074e163a460",
        "name": "Assignment sms",
        "content": "Dear Parent,{#var#}has scored{#var#}in{#var#}Assignment,{#var#}conducted\non{#var#}.{#var#}"
    },
    {
        "_id": "62cd04702eda2074e163a43b",
        "name": "OTP for registration2",
        "content": "Dear User, The OTP for your request is {#var#}."
    },
    {
        "_id": "62cd0eadacff7519001a8c8e",
        "name": "sda tfyud dfsuda safua ftuaysdftuasyd fuydftasyudf",
        "content": "fduaoidsfo iasd foasidf"
    },
    {
        "_id": "62cd06c2ea4d201446baa5c1",
        "name": "app download msg",
        "content": "Dear Student, Please download the {#var#} from google play store ( Available\non Android only) App URL: {#var#} Your Login Details are UserName:{#var#}\nPassword:{#var#} An iOS version will be released soon Regards {#var#}"
    },
    {
        "_id": "62cd109591b3c1284ad8d5a5",
        "name": "notification",
        "content": "du iasouf asiof oaisfosi dyaosi ydaoisdy foid foasd foasidfy osia foasdi fyoasid fyoasidy oasiyd faosid fyasody foasid fyosiday fosidf oasidyf oasidfodsifu oisafu aosdfoasdu foasidu aoi aosifuasodifasidfju asdkoais udfoiadsoai"
    },
    {
        "_id": "62ccfbc3232e5298fd579254",
        "name": "absent msg",
        "content": "Dear Parent, This is to inform you that your ward {#var#}., is absent for\n{#var#}. out of {#var#}. conducted on {#var#}.. {#var#}."
    }
]
const whatsapptemps = [
    {
        "_id": "62d8f11abe808b2a08cc3e2a",
        "body": "Thanks for choosing {{1}} powered by *MAcademy*",
        "name": "welcome_msg"
    },
    {
        "_id": "62d8f177be808b2a08cc3e38",
        "body": "Your {{1}} is scheduled at {{2}}.\nThanks.",
        "name": "exam_schedule"
    },
    {
        "_id": "62d9213cecc111657d49fa58",
        "name": "hello_world",
        "body": "Welcome and congratulations!! This message demonstrates your ability to send a message notification from WhatsApp Business Platform’s Cloud API. Thank you for taking the time to test with us."
    }
]

var formFields = require('./createNotification.json');
class CommunicationNotification extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields);
        this.state = {
            feeTypes: [],
            feeTypeFormData: {},
            lazyParams: BasicLazyParams,
            feeTypeDataTable: true,
            isDialogOpenForCreateNotification: false,
            feeStructureAssignDialog: false,
            editDialog: false,
            feeCategoriesOptions: [],
            admissionCategories: [],
            //--
            notification: this.props?.editData?._id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: '',
            currentStep: 0,
            filterData: null,
            textMsgs: {},
            smsTemplates: [],
            whatsAppTemplates: [],
            isTemplatesRequested: false,
            smsTemplateContent: "",
            whatsAppTemplateBody: "",
            columns: [
                { columnName: 'No of Users', isVisible: true, id: 'usersCount' },


            ],
            editNofication: null,
            notificationsData: [],
            dropdownsData: {},
            isRejectDialogOpen: false,
            isDialogOpenForApproveStudent: false,
            isRejectTransferDialogOpen: false,
            remarks: '',
            status: '',
            rowData: {},
            isViewNotification: false


        }

        this.service = new Service()
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getNotifications);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getNotifications);
    }
    getNotifications = () => {
        this.setState({
            isLoading: true
        });
        let { lazyParams, globalSearch } = this.state;

        const url = `${baseUrlAdmin}/notification-manager?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res.status) {
                this.setState({
                    notificationsData: res.res.data,
                    totalRecords: res.res.totalRecords,
                    isLoading: false
                });
                this.toast?.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            //  console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {

        this.getNotifications();


    }



    handleShare = (rowData) => {
        this.setState({
            isViewNotification: true,
            rowData: rowData

        })
    }





    onSubmitNotification = () => {


        const { remarks, status } = this.state;
        let payload = this.state?.rowData;
        if (this.state?.rowData?._id) {
            this.setState({ isLoading: true })
            payload._id = this.state?.rowData?._id;
            payload.status = status
            payload.remarks = remarks
            let url = `${baseUrlForLongRunningTask}/notification-manager/update`;
            this.service.post(url, payload, true).then(data => {
                if (data?.status) {
                    this.setState({
                        isLoading: false,
                        isDialogOpenForApproveStudent: false,
                        isRejectTransferDialogOpen: false
                    })
                    this.getNotifications()
                    this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });

                }

            }).catch(err => {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating the fee type', life: 3000 });
            });


        }


    }


    onPage = (event) => {
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);

    }



    render() {
        const { currentStep, notification, formValidations, textMsgs } = this.state;
        return (
            <div>
                <div className='admin-management'>
                    <div className="grid mb-6 mt-2">
                        <div className='col-12 flex justify-content-end align-items-center'>

                        </div>
                    </div>

                    <div className="ma-m30">
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.notificationsData}
                            dataKey="id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Notifications"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"

                        >

                            <Column header="S.No." style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{index.rowIndex + 1}</p>
                                    </>
                                )
                            }} />
                            <Column field="name" header="Name of the Event" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />


                            <Column header="Date and Time" sortField='user_info.name' field="name" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center'
                                body={(rowData) => {
                                    return <>
                                        {moment(rowData.scheduleTime).format('MMM Do YYYY, h:mm:ss a')}</>
                                }} />
                            <Column header="Notification Type" field="type" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />


                            <Column field="usersCount" header="No of Users" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />
                            <Column field="mode" header="Notification Mode" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="View" body={(rowData, { rowIndex }) => {
                                return (<>
                                    <div className="flex">

                                        <span data-pr-tooltip="" data-pr-position="" className={`ma-ml10 awl${rowIndex}`}>
                                            <Button className='formShowButton' onClick={() => this.handleShare(rowData)}>
                                                <p className='formShowButtonText' >View</p>
                                            </Button>
                                        </span>
                                    </div>
                                </>
                                );
                            }} ></Column>



                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Approve /Reject" body={(rowData, { rowIndex }) => {
                                return (<>
                                    <div className="flex justify-content-evenly">
                                        <div className="cursor-pointer" 

                                        onClick={() =>
                                            this.setState({
                                                isDialogOpenForApproveStudent: true, rowData: rowData
                                            })
                                        }>
                                        <GreenCheck />
                                    </div>

                                    <div className="cursor-pointer" onClick={() =>
                                        this.setState({
                                            isRejectTransferDialogOpen: true, rowData: rowData
                                        })
                                    }>
                                        <CancelRed />
                                    </div>

                                </div >
                                </>
                        );
                            }} ></Column>



                </DataTable>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

                {this.state.isLoading && <LoadingComponent />}
            </div>
                </div >

                <Dialog
                    visible={this.state.isDialogOpenForApproveStudent}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (<div className='mt-2 formHeadingInter'>
                            <div className="mb-3">

                                <RightMarkInCircle />
                            </div>
                            <p style={{ fontWeight: 'bold', fontSize: 20 }} className="sideHeading">Approve Notification </p>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button className='formSubmitButton px-4' style={{ borderRadius: '10px', backgroundColor: "#7CDD56", border: 'none' }} onClick={() => {
                                this.setState({
                                    status: 'APPROVE'
                                },()=>{this.onSubmitNotification()})
                            }} >
                                <p className='formSubmitButtonText'>Approve</p>
                            </Button>
                            <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isDialogOpenForApproveStudent: false, remarks: '' })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForApproveStudent: false, })}
                >


                    <p className="topicName ml-10" style={{ color: 'black', alignSelf: 'center', padding: 10 }}>                            <p>Date : {moment(this.state?.rowData?.scheduleTime).format('MMMM Do, YYYY')}</p>
                        {/* <p>Enrollment no: 23M108708</p> */}
                        <p>Notification Title : {this.state?.rowData?.name}</p>
                        <p>Notification Mode : {this.state?.rowData?.mode}</p>

                    </p>
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>

                        <div className="">
                            <ClassetTextArea
                                width={'100%'}
                                style={{ height: "119px" }}
                                label={'Remarks'}
                                placeholder="Remarks"
                                value={this.state.remarks}
                                onChange={(e) => this.setState({ remarks: e.target.value })}
                            />
                        </div>
                    </div>

                </Dialog>
                <Dialog
                    visible={this.state.isRejectTransferDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}



                    header={() => {
                        return (<div className='mt-2 formHeadingInter'>
                            <div className="mb-3">
                                <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                            </div>
                            <p style={{ fontWeight: 'bold', fontSize: 20 }} className="sideHeading">Reject Notification </p>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button className='formSubmitButton px-4' style={{ borderRadius: '10px', backgroundColor: "#BF0031", border: 'none' }} onClick={() => {
                                this.setState({
                                    status: 'REJECT'
                                },()=>{this.onSubmitNotification()})
                            }} >
                                <p className='formSubmitButtonText'>Reject</p>
                            </Button>
                            <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejectTransferDialogOpen: false, remarks: '' })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRejectTransferDialogOpen: false, })}
                >


                    <p className="topicName ml-10" style={{ color: 'black', alignSelf: 'center', padding: 10 }}>                            <p>Date : {moment(this.state?.rowData?.scheduleTime).format('MMMM Do, YYYY')}</p>
                        {/* <p>Enrollment no: 23M108708</p> */}
                        <p>Notification Title : {this.state?.rowData?.name}</p>
                        <p>Notification Mode : {this.state?.rowData?.mode}</p>

                    </p>


                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>

                        <div className="">
                            <ClassetTextArea
                                width={'100%'}
                                style={{ height: "119px" }}
                                label={'Remarks'}
                                placeholder="Remarks"
                                value={this.state.remarks}
                                onChange={(e) => this.setState({ remarks: e.target.value })}
                            />
                        </div>
                    </div>

                </Dialog>

                <Dialog
                    visible={this.state.isViewNotification}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 700 }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2 formHeadingInter'>
                           
                            <p style={{ fontWeight: 'bold', fontSize: 20 }} className="sideHeading">View Notification </p>

                        </div>)
                    }}
                    
                    onHide={() => this.setState({ isViewNotification: false, })}
                >


                    <p className="topicName ml-10" style={{ color: 'black', alignSelf: 'center', padding: 10 }}>                            
                        {/* <p>Enrollment no: 23M108708</p> */}
                        {/* <p>Notification Title : {this.state?.rowData?.name}</p>
                        <p>Notification Mode : {this.state?.rowData?.mode}</p> */}
                        {/* <p>Notification Mode : {this.state?.rowData?.message}</p> */}

                        <p>{this.state?.rowData?.message}</p>

                    </p>


                   

                </Dialog>
                


                <Toast ref={(el) => this.toast = el} position="bottom-right" />
        {
            this.state.isLoading && <>
                <LoadingComponent />
            </>
        }
            </div >
        )
    }


}


const mapStateToProps = (state) => ({
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
    emailConfig: state.orgData.emailConfig,
    smsConfig: state.orgData.smsConfig,
    whatsAppConfig: state.orgData.whatsAppConfig,
    orgData: state.orgData
});

const mapDispatchToProps = (dispatch) => ({
    getImageUrl: (url) => dispatch(getImageUrl(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationNotification);

