import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import Authentication from './../../session';
import { getBranchesLatest } from '../../../store/actions';
import { RadioButton } from 'primereact/radiobutton';
import ClassetStudentSearch from '../../../classetComponents/classetStudentSearch';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { ActiveTag, CancelRed, CrossInBox, DownloadIcon, GreenCheck, InactiveTag, MailIcon, RightMarkInCircle } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import moment from 'moment';
import { BasicLazyParams } from '../../../utile';
import { Tag } from 'primereact/tag';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { Dialog } from 'primereact/dialog';
import '../styles.scss';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
class RequestedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchId: null,
            branches: [],
            lazyParams: BasicLazyParams,
            requestedRouteInfo: []
        };
        this.service = new Service();
    }
    componentDidMount() {
        this.props.getBranchesLatest(true);
    }
    getRequestedRouteInfo = async () => {
        this.setState({ isLoading: true, requestedRouteInfo: [] });
        let lazyParams = this.state.lazyParams;
        let academicYear = localStorage.getItem('userAcademicYear');
        const url = `${baseUrlAdmin}/transport-route-approval/get-pending-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`;
        let branchId = this.state.branchId;
        let payload = {
            academicYear,
            branchId
        };
        try {
            const res = await this.service.post(url, payload, true);
            console.log(res);
            if (res && res.status && res.status) {
                if (res.res?.records && res.res?.records?.length) {
                    this.setState({
                        isLoading: false,
                        requestedRouteInfo: res.res.records,
                        totalRecords: res.res.totalRecords
                    });
                } else if (res.res?.records?.length == 0) {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'success', summary: 'No records found.', detail: '', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        } catch (err) {
            this.setState({ isLoading: false });
            console.log(err);
        }
    };
    getStudentsInfo = () => {
        let { lazyParams, globalSearch, filterData } = this.state;
        this.setState({ isLoading: true, studentInfo: [] });
        const url = `${baseUrlAdmin}/concession-approval/get-concession-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        let academicYear = localStorage.getItem('userAcademicYear');
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            gradeId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo
        };

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res?.data && res.res?.data?.length) {
                        this.setState({
                            isLoading: false,
                            studentInfo: res.res.data,
                            totalRecords: res.res.totalRecords
                        });
                    } else if (res.res?.data?.length == 0) {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'success', summary: 'No records found.', detail: '', life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };

    onRouteApprove = (status) => {
        this.setState({
            isLoading: true
        });
        let payload = {
            ...this.state.selectedRoute,
            isApproved: status,
            remarks: this.state.remarks
        };
        let url = `${baseUrlAdmin}/transport-route-approval/approve-route`;
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isRejected: false,
                        isApproved: false,
                        selectedRoute: null,
                        remarks: ''
                    },()=>this.getRequestedRouteInfo());
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            });
    };

    onHandleRequestedRouteApprove = (rowData, isConfirmed) => {
        this.setState({
            isApproved: isConfirmed ? true : false,
            isRejected: !isConfirmed ? true : false,
            selectedRoute: rowData
        });
    };
    onChangeBranch = (branchId) => {
        this.setState({ branchId: branchId });
        this.getRequestedRouteInfo();
    };
    render() {
        return (
            <div className="ma-main">
                <div className="col-span-1 my-3 mx-5">
                <p className="add-vehicle-field-label">
                Branch
                </p>
                    <div className="flex  justify-content-start  align-items-center">
                      
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="name"
                            optionValue="key"
                            value={this.state.branchId}
                            options={this.props.branchData}
                            onChange={(e) =>
                                this.setState(
                                    {
                                        branchId: e.target.value
                                    },
                                    () => this.getRequestedRouteInfo()
                                )
                            }
                            className="w-full"
                            placeholder={
                                <div className="flex justify-content-start  align-items-center">
                                    <span className="">Select Branch</span>
                                </div>
                            }
                        />
                    </div>
                </div>
                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.requestedRouteInfo}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column alignHeader={'center'} sortable bodyClassName="text-center" header="Vehicle Name" field="vehicleName"></Column>
                    <Column alignHeader={'center'} sortable bodyClassName="text-center" header="Vehicle No" field="registration_number"></Column>
                    <Column
                        filterField="routeNumber"
                        field="routeNumber"
                        sortable
                        bodyClassName="text-center"
                        header="Route Number"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <p>{rowData?.stops[0]?.routeNo}</p>;
                        }}
                    />
                    <Column
                        filterField="stops"
                        field="stops"
                        sortable
                        bodyClassName="text-center"
                        header="Stops"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <p>{rowData?.stops?.length}</p>;
                        }}
                    />
                    <Column
                        filterField="pickUpLocation"
                        field="pickUpLocation"
                        sortable
                        bodyClassName="text-center"
                        header="Pick Up Location"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <p>{rowData?.stops[0]?.stopName}</p>;
                        }}
                    />
                    <Column
                        filterField="dropLocation"
                        field="dropLocation"
                        sortable
                        bodyClassName="text-center"
                        header="Drop Location"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <p>{rowData?.stops[rowData?.stops?.length - 1]?.stopName}</p>;
                        }}
                    />
                    <Column
                        filterField="dropLocation"
                        field="dropLocation"
                        sortable
                        bodyClassName="text-center"
                        header="Route Type"
                        alignHeader="center"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}
                                >
                                    <Button
                                        className="formCancelButton"
                                        style={{
                                            backgroundColor: rowData?.isSpecialRoute ? '#E1F7D8' : '#E1F4FF',
                                            borderColor: 'lightgray',
                                            borderWidth: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 15,
                                            padding: 0,
                                            height: '32px',
                                            width: '75px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <p
                                            className="sideHeading"
                                            style={{
                                                fontSize: 12,
                                                lineHeight: '16px',
                                                color: rowData?.isSpecialRoute ? '#1E6D00' : '#0033CC',
                                                margin: 0
                                            }}
                                        >
                                            {rowData?.isSpecialRoute ? 'SPECIAL' : 'REGULAR'}
                                        </p>
                                    </Button>
                                </div>
                            );
                        }}
                    />

                    <Column
                        style={{ width: '15rem', height: '6rem' }}
                        alignHeader={'center'}
                        sortable
                        headerClassName="text-color-secondary"
                        bodyClassName="text-center"
                        className=" "
                        header="Approve / Reject"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    {rowData?.isRefundApproved == undefined && (
                                        <div className="flex justify-content-center gap-2">
                                            <div className="cursor-pointer" onClick={() => this.onHandleRequestedRouteApprove(rowData, true)}>
                                                <GreenCheck />
                                            </div>

                                            <div className="cursor-pointer" onClick={() => this.onHandleRequestedRouteApprove(rowData, false)}>
                                                <CancelRed />
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    />
                </DataTable>
                <Dialog
                    visible={this.state.isRejected}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Reject Route</p>
                                {/* <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Pick Up Location : {this.state.selectedRoute?.feeType}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Route Number : {this.state.selectedRoute?.totalAmount}</p> */}
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#BF0031', border: 'none' }}
                                onClick={() => {
                                    this.onRouteApprove(false);
                                }}
                            >
                                <p className="formSubmitButtonText">Reject</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejected: false, selectedRoute: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRejected: false, selectedRoute: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isApproved}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Approve Route </p>
                                {/*  <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Pickup Location : {this.state.requestedRouteInfo?.pickUpLocation}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Route No : {this.state.requestedRouteInfo?.routeNumber}</p> */}
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.onRouteApprove(true);
                                }}
                            >
                                <p className="formSubmitButtonText">Approve</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isApproved: false, selectedRoute: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isApproved: false, selectedRoute: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._idF
});
export default connect(mapStateToProps, {
    getBranchesLatest
})(Authentication(withRouter(RequestedRoute)));
