import React, { Component } from 'react';
import ClassetMultiSelect from '../../../../classetComponents/classetMultiSelect';
import { MailIcon } from '../../../svgIcons';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../../loadingComponent';

export default class Module extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: [],
            assignedModules: this.props.assignedModules
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.getModules();
    }

    getModules = async () => {
        this.setState({ isLoading: true });
        try {
            const url = `${baseUrlAdmin}/authorize/getPermissions`;
            const res = await this.service.get(url, true);
            console.log(res);
            if (res && res.res && res.res.data) {
                this.setState({
                    filterData: res.res.data,
                    totalRecords: res.res.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
            }
        } catch (err) {
            console.log(err);
        }
    };
    isChecked = (id) => {
        return this.state.assignedModules.some((module) => module.id === id);
    };
    handleCheckBoxChange = (id, groupName) => {
        this.setState((prevState) => {
            const isAssigned = prevState.assignedModules.find((module) => module.id === id);

            let updatedModules;
            if (isAssigned) {
                updatedModules = prevState.assignedModules.filter((module) => module.id !== id);
            } else {
                updatedModules = [...prevState.assignedModules, { id, groupName, isSelected: true }];
            }

            return { assignedModules: updatedModules };
        });
    };

    sendAssignedModules = () => {
        console.log(this.state.assignedModules);
        this.props.sendDetails(this.state.assignedModules);
        this.props.stepChange(this.props.currentTab + 1);
        this.props.currentTabChange(this.props.currentTab + 1);
    };

    render() {
        const { filterData } = this.state;
        return (
            <div className="flex align-items-start flex-column px-5">
                <h3>Assign Module Access</h3>
                <div className="col-3 card ml-3 mt-3">
                    <DataTable ref={(el) => (this.dt = el)} responsiveLayout="scroll" className="datatable-cell" value={filterData.length > 0 && filterData}>
                        <Column
                            header="Enabled"
                            headerClassName="datatable-header"
                            filterField="id"
                            field="id"
                            className="col-1"
                            body={(rowData) => <Checkbox inputId={rowData.id} checked={this.isChecked(rowData.id)} value={rowData.id} onChange={() => this.handleCheckBoxChange(rowData.id, rowData.groupName)} />}
                        />
                        <Column header="Module" headerClassName="datatable-header" filterField="groupName" field="groupName" className="col-4" />
                    </DataTable>
                </div>
                <div className="text-center col-12 flex gap-4 justify-content-center mt-4">
                    <Button label="Save and proceed to next" className="confirmDialogAcceptBtn px-6 mr-5 w-30rem" onClick={() => this.sendAssignedModules()} />
                    <Button label="Back" className="confirmDialogCancelBtn" style={{}} onClick={() => this.props.currentTabChange(this.props.currentTab - 1)} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" style={{}} onClick={() => this.props.setCreateOrEdit()} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}
