
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import Service from '../../../../services';
import { BasicLazyParams, exportToCSV, FileTypes, warningDailogInit } from '../../../../../utile';
import {
    getBoardsData,
    getBranchesLatest
} from '../../../../../store/actions';
import _ from 'lodash';
import Authentication from '../../../../session';
import { connect } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../../../store/apiConstants';
import LoadingComponent from '../../../../loadingComponent';
import { InputText } from 'primereact/inputtext';
import * as XLSX from 'xlsx';
import withRouter from '../../../../lib/withRouter';


const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};



class AdhocExamResultUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {

            examId: null,
            sectionIds: [],
            selectedTab: 1,
            data: []

        };
        this.fileUploadRef = React.createRef();

        this.service = new Service();
    }

    componentDidMount() {


        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }

    componentDidUpdate(prevProps) {



        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }


    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        //   console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: [],
            selectedSection: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };


    exportCSV = (items) => {
        this.dt.exportCSV();
        // setTimeout(() => {
        //     this.dt.exportCSV();
        // }, 1000);

    }

    changeTab = (tab) => {
        this.setState({
            selectedTab: tab,

        });
    }

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }


    downloadSheet = () => {

        if (this.state.sectionIds && this.state.sectionIds.length) {


            this.setState({
                isLoading: true
            })


            let thisObj = this;
            const url = `${baseUrlForLongRunningTask}/examination/adhoc-offline-upload-users`;
            this.service.post(url, { examId: this.props.uploadResultExam.examId, sectionIds: this.state.sectionIds }, true).then(res => {
                if (res && res.status) {
                    if (res?.res?.data?.length) {
                        thisObj.setState({
                            isLoading: false,
                            data: res.res.data
                        }, () => {

                            thisObj.exportCSV(res.res.data)

                        });
                        thisObj.toast.show({ severity: 'success', summary: 'Success', detail: 'Exam assigned usrers found, please fill the sheet and upload.', life: 3000 });
                        //  thisObj.exportCSV(res.res.data)
                    } else {
                        thisObj.setState({
                            isLoading: false
                        })
                        thisObj.toast.show({ severity: 'error', summary: 'No users', detail: 'Exam is not assigned to any users in the selected sections', life: 3000 });
                    }



                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })




        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Select Section Ids', life: 3000 });
        }





        //this.exportCSV(this.state.items);
    }


    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }


            
            this.setState({ users: newJsonSheet });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };

    uploadExamResults = () => {
        if (this.state.users && this.state.users.length) {
            this.setState({
                isLoading: true
            })

            let thisObj = this;
            const url = `${baseUrlForLongRunningTask}/examination/upload-adhoc-results`;
            this.service.post(url, { users: this.state.users }, true).then(res => {
                if (res && res.status) {

                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Uploaded results successfully.', life: 3000 });

                    this.setState({
                        isLoading: false,
                        users: [],
                        data: []
                    })

                    
                    thisObj.fileUploadRef.current.value = null;

                    //thisObj.props.onHide();


                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })




        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please upload users', life: 3000 });
        }

    }


    render() {
        const { selectedTab } = this.state;
      //  console.log(this.state, 'ddddd')
        return (
            <>
                <Dialog
                    blockScroll={true}
                    //visible={this.state.isShowCreate}
                    style={{ width: '80%', height: '90vh' }}
                    header={"Upload offline exam results"}
                    modal

                    draggable={false}
                    // footer={this.footer}
                    onHide={this.props.onHide}

                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                >
                    <div className='question-bank'>
                        <div className='qb-tabs'>
                            <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Download Students</span>
                            <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Upload Results</span>
                        </div>
                    </div>
                    {/* <AddQustion /> */}

                    {
                        selectedTab == 1 && <>
                            <div>

                                <h3>{this.props.uploadResultExam.examName}</h3>
                                {
                                    this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                        return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                            className={`ma-mr20 ma-mt10 ma-w200`}
                                            value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            // className='ma-w100p'
                                            //    disabled={this.state.hideUpdateButton}
                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                            onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                            placeholder={`--Select ${level.levelName}--`} /> : <MultiSelect
                                            className='ma-m-lr10 ma-w200'
                                            value={this.state.sectionIds ? this.state.sectionIds : []}
                                            // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            onChange={(e) => this.onMultiChange(e.value)}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            placeholder={`--Select ${level.levelName}--`}

                                        // onChange={(e) => setCities(e.value)} 
                                        />
                                        )
                                    }) : null
                                }
                            </div>

                            <Button label="Download Sheet" className='ma-mt20' onClick={this.downloadSheet} />


                        </>
                    }

                    {
                        selectedTab == 2 && <>
                            <div className=" bulk-upload ma-mt30 ">
                                <p className='spread-title'>Users Spreadsheet</p>
                                <InputText
                                    className='p-inputtext-style1'
                                    value={this.state.fileSelected} disabled />
                                {/* <span className='file-attach-name'>{this.state.fileSelected} </span> */}
                                <label htmlFor="file" className='select-file'>Select File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    hidden={true}
                                    accept={SheetJSFT}
                                    ref={this.fileUploadRef}
                                    onChange={this.handleChange}
                                //  disabled={this.props.bulkUserUploadRequested}
                                />
                                <div className='ma-clearFix'></div>

                                <Button label="Upload data" className='ma-mt10'
                                    //  disabled={this.props.bulkUserUploadRequested}
                                    onClick={() => {
                                        if (this.state.users && this.state.users.length) {
                                            this.uploadExamResults();
                                        }
                                    }}
                                    icon="pi pi-cloud-upload"
                                />
                            </div>

                        </>
                    }










                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.data}
                        style={{ display: "none" }}
                    >
                        {
                            this.state.data && this.state.data.length ? Object.keys(this.state.data[0]).map(item => {
                                return <Column field={item} header={item} />
                            }) : null
                        }

                    </DataTable>

                </Dialog>

                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </ >
        )
    }
}




const mapStateToProps = (state) => ({

    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {

    getBranchesLatest
})(Authentication(withRouter(AdhocExamResultUpload)));