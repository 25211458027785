import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Component } from 'react';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import SvgViewer from '../../customComponents/svgViewer';
import LoadingComponent from '../../loadingComponent';
import { BasicLazyParams, convertUtc } from '../../../utile';
import { AddIcon2, AddIconInButton, AddUserIcon, CalendarIcon, CalenderCheckIcon, ClassetEditIcon, CodeLinkIcon, DecrementIcon, IncrementIcon, InfoIcon, MailIcon } from '../../svgIcons';
import { DateComponent } from '@fullcalendar/core';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { v4 as uuidv4 } from 'uuid';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import { cloneDeep } from 'lodash';

class FeeStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feeTypes: [],
            openCreateFeeStructureDialog: false,
            lazyParams: BasicLazyParams,
            feeSchedule: false,
            feeCategoriesOptions: [],
            categoryName: '',
            scheduleName: '',
            feeStructure: [],
            feeStructuresArray: [],
            feeStructureAssignDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false,
            branchId: '',
            boardId: '',
            classId: '',
            sectionId: '',
            feeStructureName: '',
            // key:'',
            feeStructureDetails: {},
            paymentTerms: []
        };
        this.service = new Service();
        this.minDate = new Date();
    }

    componentDidMount() {
        this.getFeeStructures();
        this.getFeeCategoryOptions();
        this.getFeeInstallments();
    }
    componentDidUpdate() {
        // if (this.state.categoryName === "") {
        //     let frstVal = this.state?.feeCategoriesOptions[0]
        //     this.setState({ categoryName: frstVal?._id })
        //     this.getFeeTypesForFeeStructure(frstVal?._id)
        // }
    }

    getFeeInstallments = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/feeInstallments?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ paymentTerms: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };
    getFeeCategoryOptions = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = {};
        payload.academicYear = academicYear;

        let url = `${baseUrlAdmin}/fee-category/filtered`;
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each.categoryName,
                    value: each._id
                };
            });

            if (data?.status && data?.res?.data) {
                this.setState({ feeCategoriesOptions: data?.res?.data });

                // this.setState({feeCategoriesOptions:options})
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee category options', life: 3000 });
            }
        });
    };

    getFeeStructures = () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fee-structure?academicYear=${academicYear}`;
        this.service.get(url, true).then((data) => {
            if (data.status) {
                //check respone
                if (data.res.status && data?.res?.data) {
                    this.setState({
                        feeStructuresArray: data?.res?.data, //set feestructures
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    };
    onChangeStatus = (value, each) => {
        let id = each._id;
        let payload = {};
        payload.status = value;

        let url = `${baseUrlAdmin}/fee-structure/${id}/status`;
        this.service
            .put(url, payload, true)
            .then((data) => {
                if (data.status) {
                    this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                }
                this.getFeeStructures();
            })
            .catch((e) => {
                this.toast?.show({ severity: 'error', summary: 'Getting Error While updating status', detail: 'Error' });
            });
    };
    onHandleFeeStructureDialog = () => {
        this.setState({
            openCreateFeeStructureDialog: true
        });
    };

    onHideCreateFeeStructureDialog = () => {
        this.setState({
            openCreateFeeStructureDialog: false,
            categoryName: '',
            scheduleName: '',
            feeTypes: [],
            isEdit: false
        });
    };
    onhandleFeeSchedule = () => {
        let feeTypes = this.state.feeTypes;
        //
        // let allObjectsFlag = feeTypes?.every((each) => {
        //     if (each.paymentTerms !== "" && each.totalFee !== "" && each.totalFee !== undefined && each.paymentTerms !== undefined) {
        //         return true
        //     }
        //     return false

        // })

        let allObjectsFlag = feeTypes?.every((each) => {
            if (each.isTransportFeeType && each.paymentTerms !== '' && each.paymentTerms !== undefined) {
                return true;
            } else if (each.paymentTerms !== '' && each.paymentTerms !== undefined && each.totalFee !== '' && each.totalFee !== undefined) {
                return true;
            } else {
                return false;
            }
        });

        let flag = feeTypes?.length >= 1 && this.state.scheduleName && this.state.categoryName;
        if (flag && allObjectsFlag) {
            let feestructure = [];

            feeTypes?.map((each) => {
                let termLabel = this.state.paymentTerms.find((term) => term._id === each.paymentTerms);
                let installmentTerm = this.state.paymentTerms.findIndex((obj) => obj._id === termLabel?._id);
                const createFeeStructureObject = (i) => ({
                    feeType: each.feeType,
                    feeTypeId: each.feeTypeId,
                    term: i + 1,
                    paymentTerm: i === '' ? 'One Term' : `Term ${i + 1}`,
                    totalFee: each.isTransportFeeType ? Number(0) : each.totalFee,
                    isDifferentialFee: each.isDifferentialFee ? each.isDifferentialFee : false,
                    isTransportFeeType: each.isTransportFeeType ? true : false,
                    // isPercentage:each.isDifferentialFee && each.isTransportFeeType ? true: false,
                    // isPercentage:false,
                    isValue: each.isTransportFeeType ? false : true,
                    // termWisePercentageOrValue: ,
                    admissionType: each.admissionType,
                    priority: each.priority,
                    id: uuidv4(),
                    paymentTermId: termLabel?._id
                    // paymentTerms:each.paymentTerms
                });
                if (installmentTerm >= 0) {
                    for (let i = 0; i < installmentTerm + 1; i++) {
                        feestructure.push(createFeeStructureObject(i));
                    }
                }
            });
            this.setState({
                openCreateFeeStructureDialog: false,
                feeSchedule: true,
                feeStructure: feestructure
            });
        } else {
            this.toast.show({ severity: 'error', summary: 'Please Enter All the required Fields', detail: 'Validation failed' });
        }
    };
    onHideFeeScheduleDiaog = () => {
        this.setState({
            feeSchedule: false,
            scheduleName: '',
            categoryName: '',
            feeTypes: '',
            isEdit: false
        });
    };
    handleBackButton = () => {
        this.setState({
            feeSchedule: false,
            openCreateFeeStructureDialog: true
        });
    };
    getFeeTypesForFeeStructure = (id) => {
        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/fee-structure/${id}`;

        this.service.get(url, true).then((data) => {
            let newValues = data?.res?.data?.feeTypes.map((each, index) => {
                return { ...each, priority: index + 1 };
            });

            if (data.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    feeTypes: newValues,
                    isLoading: false
                });
                // this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Fee Category AssignInfo fetched successfully', life: 3000 });
            } else {
                // this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });
            }
        });
    };

    handleAdmissionTypeChange = (e, rowData) => {
        const updatedFeeTypes = this.state.feeTypes?.map((feeType) => {
            if (feeType.feeTypeId === rowData.feeTypeId) {
                return { ...feeType, admissionType: e.value };
            }
            return feeType;
        });
        this.setState({ feeTypes: updatedFeeTypes });
    };

    handlePaymentTermsChange = (e, rowData) => {
        const updatedFeeTypes = this.state.feeTypes?.map((feeType) => {
            if (feeType.feeTypeId === rowData.feeTypeId) {
                return { ...feeType, paymentTerms: e.value };
            }
            return feeType;
        });
        this.setState({ feeTypes: updatedFeeTypes });
    };

    handlePaymentInstallmentChange = (e, rowData) => {
        let dueDate;

        const term = this.state.paymentTerms.find((term) => term?._id === e.value);
        if (term) {
            dueDate = new Date (term.endDate);
        }

        const updatedInstallments = this.state.feeStructure?.map((installment) => {
            if (installment.id === rowData.id) {
                return { ...installment, installmentId: e.value,dueDate };
            }
            return installment;
        });

        this.setState({ feeStructure: updatedInstallments });
    };

    handleTotalFeeChange = (e, rowData) => {
        const updatedFeeTypes = this.state.feeTypes?.map((feeType) => {
            if (feeType.feeTypeId === rowData.feeTypeId) {
                return { ...feeType, totalFee: Number(e.target.value) };
            }
            return feeType;
        });
        this.setState({ feeTypes: updatedFeeTypes });
    };
    percentageValue = (assignedFee, totalFee) => {
        return Number((totalFee / 100) * assignedFee);
    };

    handlePercentageValue = (rowData) => {
        const updatedfeeStructure = this.state.feeStructure?.map((each) => {
            if (each.id === rowData.id) {
                return { ...each, termWisePercentageOrValue: '', termWiseActualValue: '', isValue: !each.isValue };
            }
            return each;
        });
        this.setState({ feeStructure: updatedfeeStructure });
    };
    handlePaymentTerm = (e, rowData) => {
        const updatedfeeStructure = this.state.feeStructure?.map((each) => {
            if (each.id === rowData.id) {
                return { ...each, paymentTerm: e.value };
                // return { ...each, paymentTerms: e.value };
            }
            return each;
        });
        this.setState({ feeStructure: updatedfeeStructure });
    };

    handleTermWisePercentageValue = (e, rowData) => {
        let flag = rowData.isValue;
        if (!flag && Number(e.target.value) > 100) {
            return;
        }

        let actualValue;
        if (!flag  && rowData.isTransportFeeType) {
            actualValue = '';
        } else if (flag) {
            actualValue = Number(e.target.value);
            actualValue = Number(parseFloat(actualValue).toFixed(2));
        } else {
            actualValue = this.percentageValue(Number(e.target.value), Number(rowData.totalFee));
            actualValue = Number(parseFloat(actualValue).toFixed(2));
        }
        const updatedfeeStructure = this.state.feeStructure?.map((each) => {
            if (each.id === rowData.id) {
                return {
                    ...each,
                    termWisePercentageOrValue: Number(e.target.value),
                    termWiseActualValue: actualValue
                };
            }
            return each;
        });
        this.setState({ feeStructure: updatedfeeStructure });
    };

    handleDueDate = (e, rowData) => {
        const updatedfeeStructure = this.state.feeStructure?.map((each) => {
            if (each.id === rowData.id) {
                return { ...each, dueDate: e.value };
            }
            return each;
        });
        this.setState({ feeStructure: updatedfeeStructure });
    };
    areTermWiseDueDatesConsistent = (feeArray) => {
        const termDueDates = {};

        for (const fee of feeArray) {
            const term = fee.term;
            const dueDate = new Date(fee.dueDate)?.toISOString();

            if (!termDueDates[term]) {
                termDueDates[term] = dueDate;
            } else if (termDueDates[term] !== dueDate) {
                return false;
            }
        }

        return true;
    };
    checkTransportPercentageConsistency = (data) => {
        const transportFeeTypes = data.filter((item) => item.isTransportFeeType);

        const groupedByFeeType = transportFeeTypes.reduce((acc, item) => {
            const { feeTypeId, termWisePercentageOrValue } = item;
            if (!acc[feeTypeId]) {
                acc[feeTypeId] = {
                    feeTypeId,
                    totalPercentage: 0,
                    items: []
                };
            }
            acc[feeTypeId].totalPercentage += item.termWisePercentageOrValue;
            acc[feeTypeId].items.push(item);
            return acc;
        }, {});

        const wrongGroups = Object.values(groupedByFeeType).filter((group) => group.totalPercentage !== 100);
        const wrongObjects = wrongGroups.flatMap((group) => group.items);
        return wrongObjects;
    };
    isInstallmentsDifferent = (feeStructureForPayload) => {
        const set = new Set();
        for (let i = 0; i < feeStructureForPayload.length; i++) {
            if (set.has(feeStructureForPayload[i].installmentId + ':' + feeStructureForPayload[i].feeTypeId)) {
                return false;
            }
            set.add(feeStructureForPayload[i].installmentId + ':' + feeStructureForPayload[i].feeTypeId);
        }
        return true;
    };
    isInstallmentDatesSorted = (feeStructureForPayload) => {
        let map = new Map();

        for (let i = 0; i < feeStructureForPayload?.length; i++) {
            for (let j = 0; j < this.state.paymentTerms?.length; j++) {
                const installment = feeStructureForPayload[i];
                const paymentTerm = this.state.paymentTerms[j];
                if (installment.installmentId === paymentTerm._id) {
                    const curr = new Date(paymentTerm.endDate);
                    if (map.has(installment.feeTypeId)) {
                        const prevArr = map.get(installment.feeTypeId);
                        if (prevArr[prevArr.length - 1] >= curr) return false;
                        prevArr.push(curr);
                    } else {
                        map.set(installment.feeTypeId, [curr]);
                    }
                }
            }
        }

        return true;
    };

    submitFeeStructure = () => {
        let feeStructureForPayload = cloneDeep(this.state.feeStructure);
        feeStructureForPayload.forEach((e) => {
            e.dueDate = convertUtc(e.dueDate);
        });
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fee-structure`;
        let updateUrl = `${baseUrlAdmin}/fee-structure/update`;
        let isInstallmentsDifferent = this.isInstallmentsDifferent(feeStructureForPayload);
        let isInstallmentDatesSorted = this.isInstallmentDatesSorted(feeStructureForPayload);
        let flag = feeStructureForPayload.every((each) => {
            if (each.dueDate !== undefined && each.dueData !== '' && each.termWisePercentageOrValue !== '' && each.termWisePercentageOrValue !== undefined &&  each.isTransportFeeType) {
                return true;
            } else if (
                each.termWiseActualValue !== '' &&
                each.termWiseActualValue !== undefined &&
                each.dueDate !== undefined &&
                each.dueDate !== '' &&
                each.termWisePercentageOrValue !== '' &&
                each.termWisePercentageOrValue !== undefined &&
                !(each.isTransportFeeType)
            ) {
                return true;
            } else {
                return false;
            }
        });
        let finalResult = this.calculateFeeTypeSums(feeStructureForPayload);
        let flag2 = finalResult.every((each) => {
            if (each.sum === each.totalFee) {
                return true;
            }
            return false;
        });

        let flag3 = finalResult.every((each) => {
            if ( each.isTransportFeeType === true) {
                if ( each.isTransportFeeType === true && each.totaltransportPercentage === Number(100)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });

        let flag4 = this.areTermWiseDueDatesConsistent(feeStructureForPayload);

        let WrongFeeStructures = this.checkTransportPercentageConsistency(feeStructureForPayload);

        let flag5 = WrongFeeStructures?.length ? false : true;

        let payload = {
            scheduleName: this.state.scheduleName,
            feeCategory: this.state.categoryName,
            stepOne: this.state.feeTypes,
            stepTwo: feeStructureForPayload
        };
        payload.academicYear = AcademicYear;
        if (flag && flag2 && flag3 && flag5 && isInstallmentsDifferent && isInstallmentDatesSorted) {
            if (this.state.isEdit) {
                payload._id = this.state.feestructureId;

                this.service.put(updateUrl, payload, true).then((data) => {
                    if (data.status && data.res.status) {
                        this.setState({
                            isLoading: false,
                            feeSchedule: false,
                            openCreateFeeStructureDialog: false,
                            scheduleName: '',
                            categoryName: '',
                            feeTypes: '',
                            feeStructure: '',
                            isEdit: false
                        });
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        this.getFeeStructures();
                    } else {
                        this.setState({ isLoading: false });
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data.errMessage, life: 3000 });
                    }
                });
            } else {
                this.service.post(url, payload, true).then((data) => {
                    if (data.status && data.res.status) {
                        this.setState({
                            isLoading: false,
                            feeSchedule: false,
                            openCreateFeeStructureDialog: false,
                            scheduleName: '',
                            categoryName: '',
                            feeTypes: '',
                            feeStructure: '',
                            isEdit: false
                        });

                        this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        this.getFeeStructures();
                    } else {
                        this.setState({ isLoading: false });
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data.errMessage, life: 3000 });
                    }
                });
            }
        } else {
            if (!flag) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: 'Please Enter All the required Fields', life: 3000 });
            } else if (!flag2) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: 'Please Equal the Total terms fee to the FeeType Total', life: 3000 });
            } else if (!flag5) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: ` ${WrongFeeStructures[0]?.feeType} fee types percentage sum is not equal to 100 `, life: 3000 });
            } else if (!flag3) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: 'Please Enter 100 percentage for Transport fee', life: 3000 });
            }
            // else if (!flag4) {
            //     this.setState({ isLoading: false });
            //     this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: 'Due dates are inconsistent', life: 3000 });
            // }
            else if (!isInstallmentsDifferent) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: 'Similar installment dates cannot be selected', life: 3000 });
            } else if (!isInstallmentDatesSorted) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Validation failed', detail: 'Installment dates are not sorted', life: 3000 });
            }
        }
    };
    onRowReorder = (e) => {
        let rowValues = e.value;
        let newValues = rowValues.map((each, index) => {
            return { ...each, priority: index + 1 };
        });
        this.setState({
            feeTypes: newValues
        });
        this.toast.show({ severity: 'success', summary: 'Rows Reordered', life: 3000 });
    };
    calculateFeeTypeSums(array) {
        if (Array.isArray(array)) {
            let feeTypeSums = {};
            array?.forEach((item) => {
                if (feeTypeSums.hasOwnProperty(item.feeTypeId)) {
                    if (item.isTransportFeeType === true) {
                        feeTypeSums[item.feeTypeId].totaltransportPercentage = (feeTypeSums[item.feeTypeId].totaltransportPercentage || 0) + (item.termWisePercentageOrValue || 0);
                    } else {
                        feeTypeSums[item.feeTypeId].sum += Number(item.termWiseActualValue || 0);
                    }
                } else {
                    feeTypeSums[item.feeTypeId] = {
                        feeType: item.feeType,
                        totalFee: item.totalFee,
                        sum: Number(item.termWiseActualValue || 0),
                        isDifferentialFee: item.isDifferentialFee,
                        isTransportFeeType: item.isTransportFeeType,
                        totaltransportPercentage:  item.isTransportFeeType ? item.termWisePercentageOrValue || 0 : ''
                    };
                }
            });

            let result = [];
            for (let feeTypeId in feeTypeSums) {
                result.push({
                    feeTypeId: feeTypeId,
                    feeType: feeTypeSums[feeTypeId].feeType,
                    totalFee: Number(feeTypeSums[feeTypeId].totalFee).toFixed(2),
                    sum: Number(feeTypeSums[feeTypeId].sum).toFixed(2),
                    isDifferentialFee: feeTypeSums[feeTypeId].isDifferentialFee,
                    isTransportFeeType: feeTypeSums[feeTypeId].isTransportFeeType,
                    totaltransportPercentage: feeTypeSums[feeTypeId]?.totaltransportPercentage
                });
            }
            return result;
        }
    }
    onHandleAssignDialog = (each) => {
        this.setState({
            feeStructureAssignDialog: true,
            feeStructureName: each?.scheduleName,
            feeStructureDetails: each
        });
    };
    onHideAssignDialog = () => {
        this.setState({
            feeStructureAssignDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false
        });
    };

    onEdit = (each) => {
        if (each.isFeeStructureAssined) {
            this.toast?.show({ severity: 'error', summary: 'Error', detail: `Fee Structure already assigned to students ,  you can't Update.`, life: 3000 });
        } else {
            this.setState({
                openCreateFeeStructureDialog: true,
                scheduleName: each.scheduleName,
                categoryName: each.feeCategory,
                feeTypes: each.stepOne,
                isEdit: true,
                feestructureId: each._id
            });
        }
    };

    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            () => {
                this.getFeeCategoryAssignInfo(dropdownsData.branchId, dropdownsData.boardId, dropdownsData.classId, dropdownsData.sectionId);
            }
        );
    };

    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let payload = {
            branchId: branchId,
            boardId: boardId,
            classId: classId,
            sectionId: sectionId,
            feeStructureId: this.state.feeStructureDetails._id
        };
        payload.academicYear = AcademicYear;
        let url = `${baseUrlAdmin}/fee-structure/assignInfo`;
        this.service.post(url, payload, true).then((data) => {
            if (data.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });
            }
        });
    };
    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = value.length === this.state.categoryAssignInfo.length;
        this.setState({
            selectedCategoryAssignInfo: event.value,
            selectAll: selectedAll
        });
    };

    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedCategoryAssignInfo: this.state.categoryAssignInfo,
                selectAll: true
            });
        } else {
            this.setState({
                selectedCategoryAssignInfo: [],
                selectAll: false
            });
        }
    };
    feeStructureAssign = () => {
        if (
            this.state.feeStructureDetails?.feeCatBoardId != '' &&
            this.state.feeStructureDetails?.feeCatGradeId != '' &&
            this.state?.dropdownsData?.boardId != this.state.feeStructureDetails?.feeCatBoardId &&
            this.state?.dropdownsData?.classId != this.state.feeStructureDetails?.feeCatGradeId
        ) {
            this.toast?.show({ severity: 'error', summary: 'Error', detail: `The selected board and class are not same as the selected fee category`, life: 3000 });
        } else {
            this.setState({ isLoading: true });
            let inputKey = this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId';
            let feeTypes = [];
            this.state.feeStructureDetails?.stepOne?.map((each) => feeTypes.push(each?.feeTypeId));

            let assignInfo = this.state.selectedCategoryAssignInfo.map((each) => {
                return {
                    [inputKey]: each[inputKey]
                };
            });
            if (this.state.selectedCategoryAssignInfo?.length) {
                let payload = {
                    branchId: this.state.dropdownsData.branchId,
                    boardId: this.state.dropdownsData.boardId,
                    classId: this.state.dropdownsData.classId,
                    sectionId: this.state.dropdownsData.sectionId,
                    academicYear: this.state.feeStructureDetails.academicYear,
                    feeCategoryId: this.state.feeStructureDetails.feeCategory,
                    feeStructureId: this.state.feeStructureDetails._id,
                    feeTypes: feeTypes,
                    assignedInfo: assignInfo
                };
                let url = `${baseUrlAdmin}/fee-structure/assign`;

                this.service
                    .post(url, payload, true)
                    .then((res) => {
                        if (res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    isLoading: false,
                                    feeStructureAssignDialog: false,
                                    feeStructureDetails: '',
                                    selectedCategoryAssignInfo: '',
                                    selectAll: false,
                                    categoryAssignInfo: ''
                                },
                                () => {
                                    this.getFeeStructures();
                                }
                            );
                            this.toast.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });
                        } else {
                            this.setState({ isLoading: false });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                    });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: 'Please select Atleast one record', life: 3000 });
            }
        }
    };

    render() {
        const { feeTypes, feeStructure } = this.state;
        // console.log(feeTypes)
        return (
            <>
                <div className="admin-management">
                    <div className="grid mb-6 mt-2 mr-2">
                        <div className="col-11 flex justify-content-end align-items-center">
                            <Authorizer permId={PERMISSIONS.CREATE_FEE_STRUCTURE}>
                                <Button onClick={this.onHandleFeeStructureDialog} className="addButtonn">
                                    <p className="">Add Fee Structure</p>
                                    <AddIcon2 />
                                </Button>
                            </Authorizer>
                        </div>
                    </div>
                    <div className="grid col-12">
                        {this.state.feeStructuresArray?.map((each, index) => {
                            return (
                                <div className="mx-6 card cursor-pointer col-2 doubts-tab-w nowrap" key={index} style={this.state.currentTab === 0 ? { height: '170px', width: '320px' } : { height: '170px', width: '320px' }}>
                                    <div className="flex flex-column justify-content-center align-items-center nowrap">
                                        <div className="mt-1 w-full flex justify-content-end align-items-center">
                                            <Authorizer permId={PERMISSIONS.UPDATE_FEE_STRUCTURE}>
                                                <InputSwitch
                                                    className="mt-1"
                                                    onChange={(e) => {
                                                        this.onChangeStatus(e.target.value, each);
                                                    }}
                                                    // checked={each.switch}
                                                    checked={each.status}
                                                />
                                            </Authorizer>
                                        </div>
                                        <div className="w-full flex flex-column justify-content-end mt-3 ml-3 mb-3">
                                            <h3 className="font-bold">{each?.scheduleName}</h3>
                                        </div>

                                        <div className="flex  " style={{ alignSelf: 'flex-end' }}>
                                            <div
                                                className="-mt-1 mr-3 w-full  "
                                                onClick={() => {
                                                    this.onEdit(each);
                                                }}
                                            >
                                                <Authorizer permId={PERMISSIONS.UPDATE_FEE_STRUCTURE}>
                                                    <ClassetEditIcon height={32} width={32} />
                                                </Authorizer>
                                            </div>
                                            <div className="-mt-1 mr-3 w-full " onClick={(e) => this.onHandleAssignDialog(each)}>
                                                <Authorizer permId={PERMISSIONS.ASSIGN_FEE_STRUCTURE}>
                                                    <AddUserIcon width={32} height={32} color={'#000000'} />
                                                </Authorizer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {this.state.isLoading && <LoadingComponent />}
                </div>

                <Dialog
                    isOpen={this.state.openCreateFeeStructureDialog}
                    visible={this.state.openCreateFeeStructureDialog}
                    // style={{ width: '70vw', height: '80vh', }}
                    // breakpoints={{ '1024px': '90vw', '1366px': '90vw' }}
                    // style={{ width: '100vw'}}
                    draggable={false}
                    onHide={this.onHideCreateFeeStructureDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mb-2 p-2">
                                <h3 className="text-center formhead ">Fee Structure & Schedule</h3>
                            </div>
                        );
                    }}
                    footer={() => <div className="flex justify-content-center mb-4 mt-4 p-2"></div>}
                >
                    <div className="mb-5 ml-5 mr-5 p-2">
                        <div className="">
                            <div className="grid mb-3">
                                <div className="col-4">
                                    <label className="label">
                                        Schedule Name<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={22} />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Schedule Name"
                                            value={this.state.scheduleName}
                                            onChange={(e) => this.setState({ scheduleName: e.target.value, scheduleNameErrorMsg: true })}
                                        />
                                    </div>
                                    {/* {(this.state.scheduleName==="" && !this.state.falg) &&<p className="p-error">Please Enter Schedule Name</p>} */}
                                </div>
                                <div className="col-4 ">
                                    <label className="label">
                                        Fee Categories<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetDropdown
                                            icon={<MailIcon />}
                                            id="feeCategories"
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={this.state.feeCategoriesOptions}
                                            optionValue="_id"
                                            optionLabel="categoryName"
                                            value={this.state?.categoryName}
                                            onChange={(e) => {
                                                this.setState({ categoryName: e.value, categoryNameErrorMsg: true, feeTypes: [] },() =>e.value && this.getFeeTypesForFeeStructure(e.value));
                                            }}
                                            placeholder={
                                                <div>
                                                    <span className="">Select Fee Category</span>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={feeTypes}
                                dataKey="feeTypeId"
                                paginator
                                rows={10}
                                // showGridlines
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                responsiveLayout="scroll"
                                // header={header}
                                reorderableRows
                                onRowReorder={this.onRowReorder}
                            >
                                <Column rowReorder style={{ width: '3em' }} />
                                <Column
                                    style={{ width: '3em' }}
                                    header="Priority"
                                    field="priority"
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                            </>
                                        );
                                    }}
                                />

                                <Column field="feeType" header="Fee Type" className="datatable-cel" />
                                <Column
                                    header="Admission Category"
                                    className="datatable-cel"
                                    field="admissionType"
                                    body={(rowData, index) => {
                                        // const labels = admissionTypes
                                        //     .filter(type => rowData?.admissionType.includes(type.value))
                                        //     .map(type => type.label);
                                        return (
                                            <>
                                                {/* <Dropdown placeholder="Select Student Type" /> */}
                                                {/* <ClassetMultiSelect
                                                icon={<MailIcon />}
                                                className="text-xl"
                                                height="48px"
                                                width="343px"
                                                value={rowData?.admissionType}
                                                options={admissionTypes}
                                                onChange={(e) => this.handleAdmissionTypeChange(e, rowData)}
                                                placeholder={(
                                                    <div>
                                                        <span className='text-xl'>Select Admission Type</span>
                                                    </div>
                                                )}

                                            /> */}
                                                <div>
                                                    {rowData?.admissionTypes?.map((label, index) => (
                                                        <div key={index}>{label}</div>
                                                    ))}
                                                </div>
                                            </>
                                        );
                                    }}
                                />
                                <Column
                                    header="No .of Payment Terms"
                                    className="datatable-cel"
                                    field=""
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <ClassetDropdown
                                                    icon={<MailIcon />}
                                                    className="text-xl"
                                                    height="48px"
                                                    width="227px"
                                                    options={this.state.paymentTerms.map((term, index) => ({
                                                        label: `Term ${index + 1}`,
                                                        value: term?._id
                                                    }))}
                                                    value={rowData?.paymentTerms}
                                                    onChange={(e) => this.handlePaymentTermsChange(e, rowData)}
                                                    placeholder={
                                                        <div>
                                                            <span className="text-xl">Payment Terms</span>
                                                        </div>
                                                    }
                                                />
                                            </>
                                        );
                                    }}
                                />
                                <Column
                                    header="Total Fee"
                                    className="datatable-cel"
                                    field=""
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <ClassetInputText
                                                    className="text-xl"
                                                    height="48px"
                                                    width="227px"
                                                    value={rowData.totalFee}
                                                    // value={feeTypes.totalFee}
                                                    onChange={(e) => this.handleTotalFeeChange(e, rowData)}
                                                    placeholder="Total Fee"
                                                    keyfilter="int"
                                                    disabled={rowData.isTransportFeeType}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </DataTable>

                            <div className="flex justify-content-center mb-4 mt-4 p-2">
                                <Button label="Proceed to Fee Schedule" className="p-button-primary submitButtonn mr-4 button text-lg " onClick={this.onhandleFeeSchedule} />
                                <Button className="cancelButtonn w-2" onClick={this.onHideCreateFeeStructureDialog}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>

                            {this.state.isLoading && <LoadingComponent />}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    isOpen={this.state.feeSchedule}
                    visible={this.state.feeSchedule}
                    style={{ width: '90vw', height: '80vh' }}
                    breakpoints={{ '1024px': '90vw', '1366px': '90vw' }}
                    draggable={false}
                    onHide={this.onHideFeeScheduleDiaog}
                    closable={true}
                    header={() => {
                        return (
                            <>
                                <div className="mt-4 pl-5 pr-5">
                                    <h3 className="text-center formhead ">Fee Structure & Schedule</h3>
                                </div>
                            </>
                        );
                    }}
                    footer={() => <div className="flex justify-content-center "></div>}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div className="">
                            <div className="grid mt-2">
                                <div className="col-4">
                                    <label className="label">
                                        Schedule Name<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            icon={<CodeLinkIcon />}
                                            className="text-xl"
                                            height="48px"
                                            width="368px"
                                            placeholder="Schedule Name"
                                            value={this.state.scheduleName}
                                            onChange={(e) => this.setState({ scheduleName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label className="label">
                                        Fee Category<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetDropdown
                                            icon={<MailIcon />}
                                            className="text-xl"
                                            height="48px"
                                            width="368px"
                                            optionValue="_id"
                                            optionLabel="categoryName"
                                            options={this.state.feeCategoriesOptions}
                                            value={this.state?.categoryName}
                                            disabled={true}
                                            onChange={(e) => {
                                                this.setState({ categoryName: e.value });
                                            }}
                                            placeholder={
                                                <div>
                                                    <span className="text-xl">Select Fee Category</span>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex col-12" style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                    {this.calculateFeeTypeSums(this.state?.feeStructure)?.map((each, index) => (
                                        <h3
                                            key={index}
                                            className="feeText"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignSelf: 'center',
                                                marginLeft: 10,
                                                marginTop: 21,
                                                marginRight: 10,
                                                marginBottom: 0
                                            }}
                                        >
                                            {!( each.isTransportFeeType) && `${each.feeType}:`}
                                            <span style={{ color: each.sum === each.totalFee ? 'blue' : 'red', textDecorationColor: each.sum === each.totalFee ? 'blue' : 'red' }}>
                                                {!(each.isTransportFeeType) && !isNaN(each.sum) ? each.sum : ''}
                                            </span>
                                            <span>{!(each.isTransportFeeType) && `/${each.totalFee}`}</span>
                                        </h3>
                                    ))}
                                </div>
                            </div>

                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.feeStructure}
                                dataKey="id"
                                paginator
                                rows={10}
                                // showGridlines
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                responsiveLayout="scroll"
                                // header={header}
                            >
                                <Column field="feeType" header="Fee Type" className="datatable-cel" headerStyle={{ width: '3rem' }} />
                                <Column
                                    header="Fee Term Name"
                                    className="datatable-cel"
                                    field="feeTerm"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <ClassetInputText className="text-xl" height="48px" width="216px" placeholder="Fee Term Name" value={rowData.paymentTerm} onChange={(e) => this.handlePaymentTerm(e, rowData)} disabled={true} />
                                            </>
                                        );
                                    }}
                                />
                                {/* <Column header="Fee Term Name" className="datatable-cel" field="" body={(rowData, index) => {
                                    return (
                                        <>
                                            <ClassetDropdown
                                                icon={<MailIcon />}
                                                className="text-xl"
                                                height="48px"
                                                width="216px"
                                                options={paymentTerms}
                                                value={rowData.paymentTerms}
                                                onChange={(e) => this.handlePaymentTerm(e, rowData)}
                                                placeholder={(
                                                    <div>
                                                        <span className='text-xl'>Payment Terms</span>
                                                    </div>
                                                )}

                                            />
                                        </>
                                    )
                                }} /> */}
                                <Column
                                    header="Percentage/Value"
                                    className="  datatable-cel "
                                    field="isPercentage"
                                    body={(rowData, index) => {
                                        return (
                                            <div style={{ display: 'flex', alignSelf: 'center' }}>
                                                <InputSwitch
                                                    className="custom-inputswitch "
                                                    checked={rowData.isValue}
                                                    onChange={(e) => this.handlePercentageValue(rowData)}
                                                    disabled={rowData.isTransportFeeType &&  !rowData.isValue}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                <Column
                                    header="Term Wise Percentage/Value"
                                    field="termWisePercentageOrValue"
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <ClassetInputText
                                                    // icon={<CodeLinkIcon />}
                                                    className="text-xl"
                                                    height="48px"
                                                    width="216px"
                                                    placeholder={rowData.isValue ? 'Enter Value' : 'Enter Percentage '}
                                                    value={rowData.termWisePercentageOrValue}
                                                    onChange={(e) => this.handleTermWisePercentageValue(e, rowData)}
                                                    keyfilter="int"
                                                />
                                            </>
                                        );
                                    }}
                                />
                                <Column
                                    field="termWiseActualValue"
                                    header="Term Wise Actual Value"
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <div>{rowData?.termWiseActualValue}</div>
                                            </>
                                        );
                                    }}
                                />
                                <Column
                                    header="Installment "
                                    className="datatable-cel"
                                    field=""
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <ClassetDropdown
                                                    icon={<MailIcon />}
                                                    className="text-xl"
                                                    height="48px"
                                                    width="227px"
                                                    options={this.state.paymentTerms.map((term, index) => ({
                                                        label: `${term.title} - (${new Date(term.endDate).toISOString().split('T')[0]})`,
                                                        value: term._id
                                                    }))}
                                                    value={rowData?.installmentId}
                                                    onChange={(e) => this.handlePaymentInstallmentChange(e, rowData)}
                                                    placeholder={
                                                        <div>
                                                            <span className="text-xl">Payment Terms</span>
                                                        </div>
                                                    }
                                                />
                                            </>
                                        );
                                    }}
                                />

                                {/*  <Column header="Due Date" className="datatable-cel" field="dueDate" body={(rowData, index) => {
                                    return (
                                        <>
                                            <div>
                                                <ClassetCalendar
                                                    calendarMode="single"
                                                    className="text-xl"
                                                    height="48px"
                                                    width="216px"
                                                    icon={<CalenderCheckIcon />}
                                                    value={rowData.dueDate}
                                                    onChange={(e) => this.handleDueDate(e, rowData)}
                                                    minDate={this.minDate}
                                                />
                                            </div>

                                        </>
                                    )
                                }} /> */}
                            </DataTable>
                            <div className="flex justify-content-center mb-4 mt-4">
                                <Button label="Back" className="p-button-primary submitButton mr-4 button px-6 text-lg w-2" onClick={this.handleBackButton} />

                                <Button label={this.state.isEdit ? 'Update & Save' : 'Create & Save'} className="p-button-primary submitButton mr-4 button px-6 text-lg w-2" onClick={this.submitFeeStructure} />
                                <Button className="cancelButtonn w-2" onClick={this.onHideFeeScheduleDiaog}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>

                            {/* {this.state.isLoading && <LoadingComponent />} */}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    isOpen={this.state.feeStructureAssignDialog}
                    visible={this.state.feeStructureAssignDialog}
                    style={{ width: '80vw', height: '80vh' }}
                    draggable={false}
                    onHide={this.onHideAssignDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-4">
                                <h3 className="text-center formhead">Fee Structure Assign</h3>
                            </div>
                        );
                    }}
                    footer={() => <div></div>}
                >
                    <div className="p-5">
                        <div className="">
                            <div className="grid mb-2 ">
                                <BranchToSectionDD setValues={this.setDropdownValues} />

                                <div className="col-3 ml-1">
                                    <ClassetInputText
                                        icon={<CodeLinkIcon />}
                                        id="categoryName"
                                        className="text-xl "
                                        // height="48px"
                                        // width="262px"
                                        placeholder="Structure Name"
                                        value={this.state?.feeStructureName}
                                        infoIcon={<InfoIcon />}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.categoryAssignInfo}
                            dataKey={this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId'}
                            paginator
                            rows={10}
                            // showGridlines
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            // globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            // header={header}
                            sortable
                            selection={this.state?.selectedCategoryAssignInfo}
                            onSelectionChange={this.onSelectionChange}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.onSelectAllChange}
                            selectionMode="checkbox"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                            <Column field="branchName" header="Brach Name" className="datatable-cel" sortable />
                            <Column field="boardName" header="Board Name" className="datatable-cel" sortable />
                            <Column field="className" header="Grade Name" className="datatable-cel" sortable />
                            {this.state?.dropdownsData?.classId ? <Column field="sectionName" header="Section Name" className="datatable-cel" sortable /> : <></>}
                            {this.state?.dropdownsData?.sectionId ? <></> : <Column field="totalStudents" header="Total Students" className="datatable-cel" sortable />}
                            {this.state?.dropdownsData?.sectionId ? <></> : <Column field="unAssignedCount" header="Unassigned Students" className="datatable-cel" sortable />}
                            {this.state?.dropdownsData?.sectionId ? (
                                <Column
                                    field="username"
                                    header="Students"
                                    className="datatable-cel"
                                    body={(rowData) => {
                                        return <p>{rowData?.username?.slice(3)}</p>;
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </DataTable>
                        <div className="flex justify-content-center mb-4 mt-4">
                            <Button className="submitButtonn w-2 " onClick={this.feeStructureAssign}>
                                <p className="submitButtonnText">Assign</p>
                            </Button>
                            <Button className="cancelButtonn w-2 ml-3" onClick={this.onHideAssignDialog}>
                                <p className="cancelButtonnText">Cancel</p>
                            </Button>
                        </div>
                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                    <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

export default FeeStructure;
