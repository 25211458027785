import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EventService from '../../../service/EventService';
import { Dropdown } from 'primereact/dropdown';
import { AddIconInButtonFull, CalenderIcon, ClockIcon, CodeLinkIcon, MailIcon, WarningIcon } from '../../svgIcons';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { getFormFields, isFormValid, onDropDownChange, onTextChange, onToggleChange } from '../../../utile/formHelper';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import BoardToSectionDDCol from '../../BaseDropdownComponents/BoardToSectionDDCol';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import moment from 'moment/moment';
import { CodeStarNotifications } from 'aws-sdk';
import LoadingComponent from '../../loadingComponent';

const createFields = require('./academicCalender.json');


const EverntTypeOptions = [
    { value: 'AE', label: "Academic Event" },
    { value: 'SE', label: "Sports Event" },
    { value: 'CE', label: "Cultural Event" },
    { value: 'ME', label: "Miscellaneous Event" },
    { value: 'EE', label: "Examination" },
    { value: 'HE', label: 'Holiday' }
]

const repeatypeOptions = [
    { value: 'DAILY', label: "Daily" },
    { value: 'WEEKLY', label: "Weekly" },
    { value: 'MONTHLY', label: "Monthly" },
    { value: 'YEARLY', label: "Yearly" },

]
class AcademicCalendar extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(createFields, this.props.editData);
        this.state = {
            formValidations: this.formFields?.formValidations,
            formData: {},
            eventDialog: false,
            clickedEvent: null,
            changedEvent: { title: '', start: null, end: null, allDay: null },
            events: null,
            isDialogOpenForAddEvent: false,
            selectedEvent: '',
            dropdownsData: {},
            oldevents: null,
            isLoading: false,
            dialogBox: { show: false, msg: '' },
            id:null
        };
        this.service = new Service();

    }

    componentDidMount() {
        const eventService = new EventService();
        eventService.getEvents().then((data) => this.setState({ oldevents: data }));
        this.getEventCalener()


    }

    deleteEvent = () => {

       
        const url = `${baseUrlAdmin}/event-calender/${this.state?.id}`;

        this.setState({
            isLoading: true
        })
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    dialogBox: { show: false, msg: '' }
                }, () => {
                    this.getEventCalener();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.res.message, life: 3000 });
            }

        }).catch(e => {


            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
        })



    }

    getEventCalener = () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/event-calender/calenders`;
        this.service.get(url, true).then(res => {


            if (res && res.res && res.res.data) {
                this.setState({
                    isLoading: false,
                    events: res.res.data,

                });
            } else {
                this.setState({
                    isLoading: false,
                    events: []
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.res.message, life: 3000 });
            }
        }).catch((e) => {
            this.setState({
                isLoading: false,
                events: []
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
        });
    }

    eventClick = () => {

        this.deleteEvent()

    };

    save = () => {

        const { clickedEvent, changedEvent } = this.state;


        if (changedEvent) {

            clickedEvent.setProp('title', changedEvent.title);
            clickedEvent.setStart(changedEvent.start);
            clickedEvent.setEnd(changedEvent.end);
            clickedEvent.setAllDay(changedEvent.allDay);
        } else {
            console.error("No event selected.");
        }

        this.setState({ isDialogOpenForAddEvent: false });


    };

    reset = () => {
        const { clickedEvent } = this.state;
        const { title, start, end } = clickedEvent;
        this.setState({ changedEvent: { title, start, end, allDay: null } });
    };
    setDropdownValues = (dropdownsData) => {
        console.log(dropdownsData, "dropdownsDatadropdownsData")
        this.setState({
            dropdownsData: dropdownsData
        },
            // () => { this.getStudents()}
        )
    }
    onCancel = () => {
        this.setState({
            isDialogOpenForAddEvent: false,
            formData: {},
            fullDayEvent: '',
            startTime: "",
            endTime: '',
            date: '',
            startOn: '',
            endsOn: '',
            repeatype: ''


        })
    }

    addDateFormat = (datetime, eventDate) => {
        const startMoment = moment(datetime);
        const eventDateMoment = moment(eventDate);


        startMoment.year(eventDateMoment.year());
        startMoment.month(eventDateMoment.month());
        startMoment.date(eventDateMoment.date());


        const newStart = startMoment.toISOString();

        return newStart;
    }


    onAddEvent = () => {
        const { title, start, end, allDay } = this.state.changedEvent;



        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = this.state.formData;



        payload.academicYear = academicYear;
        payload.boardId = this.state.dropdownsData.boardId
        payload.branchId = this.state.dropdownsData.branchId
        payload.classId = this.state.dropdownsData.classId
        payload.sectionId = this.state.dropdownsData.sectionId
        payload.selectedBoardName = this.state.dropdownsData.selectedBoardName
        payload.selectedClassName = this.state.dropdownsData.selectedClassName
        payload.selectedbranchName = this.state.dropdownsData.selectedbranchName
        payload.selectedsectionName = this.state.dropdownsData.selectedsectionName
        payload.title = title



        if (payload.eventDate && start) {
            payload.start = this.addDateFormat(start, payload.eventDate)
        }

        if (payload.eventDate && end) {
            payload.end = this.addDateFormat(start, payload.eventDate)
        }

        if ((payload.fdEvent !== undefined && payload.fdEvent == true) || this.state.formData.eventType === 'HE' || this.state.formData.eventType === 'EE') {
            const startMoment = moment(payload.eventDate).format('YYYY-MM-DD');
            payload.start = startMoment;
            payload.end = startMoment;
        }

        if (payload.eventType == 'AE') {
            payload.rendering = 'background'
            payload.color = '#419720'

        } else if (payload.eventType == 'SE') {
            payload.color = '#FAB710'

        } else if (payload.eventType == 'CE') {
            payload.color = '#A020F0'

        } else if (payload.eventType == 'ME') {
            payload.color = '#E6E8EA'
        } else if (payload.eventType == 'EE') {
            payload.color = '#BF0031'
        } else {
            payload.color = '#268900'
        }

        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/event-calender/create`
        const formStatus = isFormValid(createFields, this.formFields.formValidations, payload);
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, });
            isCompleteFormValid = false;
        }
        console.log(payload, "dattttt")

        if (isCompleteFormValid) {
            console.log(payload, "payload")
            this.service.post(url, payload, true).then(data => {
                if (data.status) {
                    this.setState({
                        isLoading: false,
                        isDialogOpenForAddEvent: false,
                        formData: {},
                        changedEvent: { title: '', start: null, end: null, allDay: null },


                    }, () => { this.getEventCalener() })
                    this.toast.show({ severity: 'success', summary: '', detail: data?.res?.message, life: 3000 });


                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error', life: 3000 });

                }

            }).catch(e => {
                this.setState({ isLoading: false })
            })
        } else {


        }
    }


    showConfirmDialog = (e) => {

        let id;
        if (e.event._def?.extendedProps?._id) {
            id = e.event._def.extendedProps._id;
        }
        this.setState({
            dialogBox: { show: true, msg: '' },
            id:id
        });
    }


    render() {
        const { eventDialog, changedEvent, events, formData, formValidations } = this.state

        return (
            <>
                <div className='grid'>
                    <div className='col-2 card shadow-5 my-5 '>
                        <div className='flex justify-content-center  '>
                            
                            <Button className='shadow-5' style={{ borderRadius: 10, backgroundColor: '#076EFF', }} onClick={() => this.setState({ isDialogOpenForAddEvent: true })}>
                                <p className='addButtonClass' style={{ fontSize: '12px' }}>Add Event</p>
                            </Button>
                        </div>
                        <div className='mt-8'>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ borderColor: '#076EFF', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Today</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px', backgroundColor: '#F8F9FA' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Sunday</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ borderColor: '#419720', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Academic Event</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px', backgroundColor: '#268900' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Holiday</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#BF0031', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Examination</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#FAB710', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Sports Event</p>
                                </div>
                            </div>

                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#A020F0', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Cultural Event</p>
                                </div>
                            </div>


                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Miscellaneous Event</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10">
                        {/* <div className="col-12"> */}
                        <div className="calendar-demo">
                            <FullCalendar
                                eventClick={this.showConfirmDialog}
                                initialDate={moment(new Date()).format('YYYY-MM-DD')}
                                initialView="dayGridMonth"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{ left: 'title', center: '', right: 'prev,next' }}
                                editable
                                selectable
                                selectMirror
                                dayMaxEvents
                                events={events}

                            />

                        </div>
                        {/* </div> */}
                    </div>
                </div>

                <Dialog
                    visible={this.state.isDialogOpenForAddEvent}
                    draggable={false}
                    // closeOnEscape={false}
                    style={{ position: 'fixed', right: '0', height: '100%' }}
                    closable={true}
                    className="pay-fee"

                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="sideHeading" style={{ fontSize: 36, color: 'black' }}>Add Event </h4>

                        </div>)
                    }}
                    onHide={this.onCancel}
                >
                    <div className='mb-5 ml-5 mr-5' >
                        <div className="">
                            <div className="col-12 ">
                                <label className='label mb-2 '>Eventname<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='title'
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        height="48px"
                                        width="361px"
                                        placeholder="Enter Event name"

                                        value={changedEvent.title}

                                        onChange={(e) => this.setState({
                                            changedEvent: {
                                                ...this.state.changedEvent,
                                                title: e.target.value,
                                            },
                                        })}
                                    // value={formData.title}
                                    // disabled={true}
                                    //onChange={(e) => onTextChange(e.target.value, 'title', this, createFields, formData, formValidations, 'formData', formValidations)}

                                    />
                                </div>
                                {/* {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>} */}
                            </div>
                            <div className="col-12">
                                <label className='label mb-2 '>Event Type<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id='eventType'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="361px"
                                        options={EverntTypeOptions}
                                        value={formData.eventType}
                                        onChange={(e) => { onDropDownChange(e.target.value, 'eventType', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Event Type</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['eventType'].isValid && <p className="p-error">{formValidations.fields['eventType'].errorMsg}</p>}
                            </div>


                            {formData.eventType && <>
                                {(formData.eventType == 'AE' || formData.eventType == 'SE' || formData.eventType == 'CE' || formData.eventType == 'ME') && <div className='col-12'>
                                    <div className="flex">
                                        <InputSwitch
                                            id="fdEvent"
                                            className=''
                                            checked={!formData?.recurring && formData?.fdEvent}
                                            value={formData?.fdEvent}
                                            onChange={(e) => {
                                                onToggleChange(e.value, 'fdEvent', this, createFields, formData, formValidations, 'formData', formValidations)
                                                this.setState({ fullDayEvent: false })
                                            }}

                                        />
                                        <label className='label  ml-2 '>Full Day Event</label>
                                    </div>
                                </div>}
                                {(!formData?.fdEvent && (formData.eventType == 'AE' || formData.eventType == 'SE' || formData.eventType == 'CE' || formData.eventType == 'ME')) && <div className="col-12 flex">
                                    <div>
                                        <label className='label mb-2 '>Start Time<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<ClockIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="100px"
                                                placeholder="Select Start Time"
                                                calendarMode="single"
                                                timeOnly
                                                id="start"
                                                // value={formData?.start}
                                                // onChange={(e) => { onTextChange(e.value, 'start', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                                value={changedEvent.start}

                                                onChange={(e) => this.setState({
                                                    changedEvent: {
                                                        ...this.state.changedEvent,
                                                        start: e.target.value,
                                                    },
                                                })}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                    <div className="ml-4">
                                        <label className='label mb-2 '>End Time<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<ClockIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="100px"
                                                placeholder="Select End Time"
                                                calendarMode="single"
                                                timeOnly
                                                // value={formData?.end}
                                                id="end"


                                                // onChange={(e) => { onTextChange(e.value, 'end', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                                value={changedEvent.end}

                                                onChange={(e) => this.setState({
                                                    changedEvent: {
                                                        ...this.state.changedEvent,
                                                        end: e.target.value,
                                                    },
                                                })}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                </div>}


                                {!(
                                    formData?.recurring &&
                                    (formData.eventType === 'HE' || formData.eventType === 'EE')
                                ) && (
                                        <div className="col-12 ">

                                            <label className='label mb-2 '>Date<span className='ma-required'>*</span></label>
                                            <div className="mt-2">
                                                <ClassetCalendar
                                                    icon={<CalenderIcon height={20} />}
                                                    className=""
                                                    height="48px"
                                                    width="361px"
                                                    placeholder="Select Date"
                                                    calendarMode="single"
                                                    id="eventDate"
                                                    value={formData?.eventDate}

                                                    onChange={(e) => { onTextChange(e.target.value, 'eventDate', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                                />
                                            </div>
                                            {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                        </div>
                                    )}

                                <div className='col-12'>
                                    <div className="flex">
                                        <InputSwitch
                                            id='recurring'
                                            className=''
                                            checked={formData?.recurring}
                                            value={formData?.recurring}
                                            onChange={(e) => {
                                                onToggleChange(e.value, 'recurring', this, createFields, formData, formValidations, 'formData', formValidations)
                                                this.setState({ fullDayEvent: false })

                                            }}

                                        />
                                        <label className='label  ml-2 '>Recurring</label>
                                    </div>
                                </div>
                                {formData?.recurring && <>

                                    <div className="col-12 ">
                                        <label className='label mb-2 '>Start On<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<CalenderIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                placeholder="Select Date"
                                                calendarMode="single"
                                                id="recstartDate"
                                                value={this.state.startOn}
                                                onChange={(e) => { onTextChange(e.target.value, 'recstartDate', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                    <div className="col-12 ">
                                        <label className='label mb-2 '>Ends On<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<CalenderIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                placeholder="Select Date"
                                                calendarMode="single"
                                                id="recendDate"
                                                // timeOnly
                                                value={this.state.endsOn}
                                                //onChange={(e) => this.setState({ endsOn: e.value })}

                                                onChange={(e) => { onTextChange(e.target.value, 'recendDate', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                    <div className="col-12">
                                        <label className='label mb-2 '>Repeat<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                id='repeat'
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                options={repeatypeOptions}

                                                value={formData.repeat}

                                                onChange={(e) => { onDropDownChange(e.target.value, 'repeat', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                                placeholder={(
                                                    <div>
                                                        <span className=''>Select Repet</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['eventType'].isValid && <p className="p-error">{formValidations.fields['eventType'].errorMsg}</p>} */}
                                    </div>
                                </>}



                                {(formData.eventType === 'AE' || formData.eventType === 'SE' || formData.eventType === 'CE' || formData.eventType === 'ME') &&
                                    <div className="col-12 ">
                                        <label className='label'>Event Description<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetTextArea
                                                id="eventDescrption"
                                                className='border-round-md   border-none '
                                                height={50}
                                                value={formData?.description}
                                                onChange={(e) => { onTextChange(e.target.value, 'eventDescrption', this, createFields, formData, formValidations, 'formData', 'formValidations') }}
                                                placeholder="Description" />

                                        </div>
                                        {/* {formValidations && !formValidations.fields['description']?.isValid && <p className="p-error text-lg ">{formValidations.fields['description']?.errorMsg}</p>} */}

                                    </div>}

                                {(formData.eventType === 'AE' || formData.eventType === 'SE' || formData.eventType === 'CE' || formData.eventType === 'ME' && (!formData?.recurring)) &&

                                    <BoardToSectionDDCol setValues={this.setDropdownValues} Required={false} />}
                            </>

                            }
                            <div className="mt-3" style={{ display: 'flex' }}>
                                <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }}
                                    //onClick={() => { this.setState({ isDialogOpenForAddEvent: true}) }}
                                    onClick={this.onAddEvent}
                                >
                                    <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />

                                    <p className='submitButtonnText'>Add</p>
                                </Button>
                                <Button style={{ border: 0, borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}
                                    onClick={this.onCancel}
                                // onClick={() => { this.setState({ isDialogOpenForAddEvent: false }) }}
                                >
                                    <p className='cancelButtonnText'>Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.dialogBox.show}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div className="flex justify-content-center align-items-center"> 
                            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ dialogBox: { show: false, msg: ' ' } }) }} />
                            <Button label="Delete" className='ma-m-lr10' onClick={this.eventClick} />
                        </div>)
                    }}
                    onHide={() => this.setState({ dialogBox: { show: false, msg: '' } })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>

                   
                   
                    <p className='text-lg text-600 text-center font-bold white-space-normal'>Are you sure, You want to delete event?</p>
                 
                </Dialog>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        );
    }
}

export default AcademicCalendar
