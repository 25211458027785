import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onTextChange, onDropDownChange } from '../../../utile/formHelper';
import _, { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Button } from 'primereact/button';
import Service from './../../services';
import { Toast } from 'primereact/toast';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import { LocationMarker, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import ClassetCalendar from '../../../classetComponents/classetCalender';
const createRouteFields = require('./createRoute.json');
let formFields = createRouteFields;

class CreateSpecialRoute extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createRouteFields);
        this.state = {
            route: this.props?.dataForAddRoutes?._id ? this.props?.dataForAddRoutes : this.formFields.data,
            formValidations: this.formFields.formValidations,
            stopsList: [],
            focused: false,
            inputFocused: false,
            boards: [],
            classes: [],
            academicYears: [],
            vehicleDropdowns: [],
            feeTypeDropDown: [],
            stops: [{ id: 1, name: '', lat: '', lng: '', pickUpTime: '', dropTime: '' }],
            isShowCreateSuccess: false,
            hideUpdateButton: false
        };

        this.service = new Service();
    }

    componentDidMount() {
        if (this.props.dataForAddRoutes?._id) {
            const modifiedStops = this.props.dataForAddRoutes?.stops.map((stop) => {
                return {
                    ...stop,
                    dropTime: new Date(stop.dropTime),
                    pickUpTime: new Date(stop.pickUpTime)
                };
            });
            this.setState(
                {
                    route: {
                        branchId: this.props.dataForAddRoutes.branchId,
                        vehicleId: this.props.dataForAddRoutes.vehicleId,
                        routeName: this.props.dataForAddRoutes.routeName,
                        registration_number: this.props.dataForAddRoutes.vehicleId
                    },
                    stops: modifiedStops
                },
                () => this.getDropdownsData(true)
            );
        }
    }

    getDropdownsData = async (editMode) => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state.route.branchId
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                const vehicles = res?.res?.data?.vehicles?.map((vehicles) => ({
                    label: vehicles.vehicleName,
                    value: vehicles._id,
                    registrationNumber: vehicles.vehicleRegistrationNo
                }));
                const fee = res?.res?.data?.feePlans?.map((fee) => ({
                    label: fee.feeName,
                    value: fee._id
                }));
                this.setState({ vehicleDropdowns: vehicles, feeTypeDropDown: fee, isLoading: false }, () => {
                    if (editMode) {
                        this.setState((prevState) => {
                            return {
                                route: {
                                    ...prevState.route,
                                    vehicleId: this.props.dataForAddRoutes.vehicleId
                                },
                                isLoading: false
                            };
                        });
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'some Error', life: 3000 });
        }
    };

    isValid = (value) => value !== undefined && value !== null && value !== '';

    onCreateRoute = async () => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        const fieldData = cloneDeep(this.state.route);
        let vehicle = this.state.vehicleDropdowns.find((el) => el.value == fieldData?.vehicleId);
        if (this.props?.dataForAddRoutes?._id) {
            const stopData = this.state?.stops || [];
            let stopsForApi = stopData?.map((stop) => {
                let stopObject = {
                    areaName: stop.areaName,
                    routeNo: stop.routeNo,
                    stopName: stop.stopName,
                    pickUpTime: stop.pickUpTime,
                    dropTime: stop.dropTime,
                    locationLink: stop.locationLink,
                    transportFee : stop?.transportFee ?? 0
                };
                if (stop._id) {
                    stopObject._id = stop._id;
                }
                return stopObject;
            });
            const allValid = stopsForApi?.every((stop) => Object.values(stop).every(this.isValid));
            if (!allValid) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter all the required fields', life: 3000 });
                return;
            }
            const payloadData = {
                routeId: this.props?.dataForAddRoutes?._id,
                stop: stopsForApi || [],
                academicYear: academicYear,
                branchId: this.props?.dataForAddRoutes?.branchId,
                registration_number: fieldData?.registration_number,
                vehicleName: vehicle?.label,
                isSpecialRoute: true
            };
            let url = `${baseUrlAdmin}/transport-stops-approval/raise-stop-approvals`;
            try {
                const res = await this.service.post(url, payloadData, true);
                if (res?.res?.status && res.status) {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                    this.props.onCancel();
                    this.props.getRoutesData();
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                }
            } catch (e) {
                this.setState({
                    isLoading: false
                });
                console.log(e);
                this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
            }
        } else {
            let body = cloneDeep(this.state.route);
            let vehicle = this.state.vehicleDropdowns.find((el) => el.value == body?.vehicleId);
            const stopData = this.state?.stops || [];
            let stopsForApi = stopData?.map((stop) => {
                let stopObject = {
                    areaName: stop.areaName,
                    routeNo: stop.routeNo,
                    stopName: stop.stopName,
                    pickUpTime: stop.pickUpTime,
                    dropTime: stop.dropTime,
                    locationLink: stop.locationLink,
                    transportFee : stop?.transportFee ?? 0
                };
                if (stop._id) {
                    stopObject._id = stop._id;
                }
                return stopObject;
            });
            const allValid = stopsForApi?.every((stop) => Object.values(stop).every(this.isValid));
            if (!allValid) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter all the required fields', life: 3000 });
                return;
            }
            const formStatus = isFormValid(createRouteFields, this.formFields.formValidations, body);

            if (!formStatus.formValidations.isFormValid) {
                this.setState({ formValidations: formStatus.formValidations });
            } else {
                const payload = {
                    vehicleId: body?.vehicleId,
                    routeName: body?.routeName,
                    stops: stopsForApi || [],
                    academicYear: academicYear,
                    branchId: body?.branchId,
                    registration_number: body?.registration_number,
                    vehicleName: vehicle?.label,
                    isSpecialRoute: true
                };
                let url = `${baseUrlAdmin}/transport-route-approval/raise-route-approvals`;
                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({
                            isLoading: false
                        });
                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel();
                        this.props.getRoutesData();
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    }
                } catch (e) {
                    this.setState({
                        isLoading: false
                    });
                    console.log(e);
                    this.props.toastMessage.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }
        }
    };

    addStop = () => {
        const stopsForApi = this.state.stops?.map((stop) => ({
            areaName: stop.areaName,
            routeNo: stop.routeNo,
            stopName: stop.stopName,
            pickUpTime: stop.pickUpTime,
            dropTime: stop.dropTime,
            locationLink: stop.locationLink
        }));
        const allValid = stopsForApi?.every((stop) => Object.values(stop).every(this.isValid));

        if (!allValid) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter all the required fields', life: 3000 });
            return;
        } else {
            const newStop = { id: this.state.stops.length + 1, name: '', lat: '', lng: '', pickUpTime: '', dropTime: '' };
            this.setState((prevState) => ({
                stops: [...prevState.stops, newStop]
            }));
        }
    };

    removeStop = (index) => {
        if (this.state.stops.length > 1) {
            const stops = this.state.stops.filter((_, i) => i !== index);
            this.setState({ stops });
        }
    };

    handleInputChange = (index, field, value) => {
        const stops = [...this.state.stops];

        stops[index][field] = value;
        this.setState({ stops });
    };

    render() {
        const { route, formValidations, stops } = this.state;
        const { onCancel } = this.props;
        return (
            <>
                <div className="mt-3">
                    <div className="grid lg:col-11 mx-5 gap-5">
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">
                                Branch<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="name"
                                    optionValue="key"
                                    className="w-full"
                                    value={route?.branchId}
                                    options={this.props.branchData}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    onChange={async (e) => {
                                        await onDropDownChange(e.target.value, 'branchId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        await this.getDropdownsData(false);
                                    }}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Select Branch</span>
                                        </div>
                                    }
                                />
                            </div>
                            {formValidations && !formValidations.fields['branchId'].isValid && <p className="p-error">{formValidations.fields['branchId'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1">
                            <p className="add-vehicle-field-label">
                                Vehicle Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    className=" h-3rem w-full h-3rem no-border pt-1 "
                                    placeholder="Select Vehicle Name"
                                    value={route.vehicleId}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    options={this.state.vehicleDropdowns}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        onDropDownChange(selectedValue, 'vehicleId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        const selectedVehicle = this.state.vehicleDropdowns.find((vehicle) => vehicle.value === selectedValue);
                                        if (selectedVehicle) {
                                            route['registration_number'] = selectedVehicle.registrationNumber;
                                            this.setState({
                                                route: { ...route, vehicleId: selectedValue }
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['vehicleId'].isValid && <p className="p-error">{formValidations.fields['vehicleId'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1">
                            <p className="add-vehicle-field-label">
                                Route Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    style={{ border: 'none' }}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    className="  h-3rem w-full"
                                    value={route.routeName}
                                    onChange={(e) => onTextChange(e.target.value, 'routeName', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Route Name"
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['routeName'].isValid && <p className="p-error">{formValidations.fields['routeName'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1">
                            <p className="add-vehicle-field-label">
                                Registered Vehicle Number<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" border-round-md add-vehicle-field-input h-3rem  w-full"
                                    value={this.props?.dataForAddRoutes?._id ? this.props?.dataForAddRoutes?.vehicleRegistrationNo : route.registration_number}
                                    disabled={true}
                                    onChange={(e) => onTextChange(e.target.value, 'registration_number', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Registered Vehicle Number"
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '2px', backgroundColor: '#B1B1B1' }}></div>

                    {this.state.stops.map((stop, index) => (
                        <div key={index} className="grid lg:col-11 mx-5 mt-5 column-gap-8 row-gap-3 border-top-1 border-left-2 border-right-1 border-bottom-2 border-300 border-round-lg">
                            <div className="col-span-1">
                                <p className="add-vehicle-field-label">
                                    Area Name<span className="ma-required">*</span>
                                </p>
                                <ClassetInputText className="w-10" value={stop.areaName} onChange={(e) => this.handleInputChange(index, 'areaName', e.target.value)} placeholder="Area Name" />
                            </div>

                            <div className="col-span-1">
                                <p className="add-vehicle-field-label">
                                    Route No<span className="ma-required">*</span>
                                </p>
                                <ClassetInputText className="w-10" value={stop.routeNo} onChange={(e) => this.handleInputChange(index, 'routeNo', e.target.value)} placeholder="Route No" />
                            </div>

                            <div className="col-span-1">
                                <p className="add-vehicle-field-label">
                                    Stop Name<span className="ma-required">*</span>
                                </p>
                                <ClassetInputText className="w-10" value={stop.stopName} onChange={(e) => this.handleInputChange(index, 'stopName', e.target.value)} placeholder="Stop Name" />
                            </div>

                            <div className="col-span-1">
                                <p className="add-vehicle-field-label">
                                    Location Link<span className="ma-required">*</span>
                                </p>
                                <ClassetInputText icon={<LocationMarker />} className="w-10" value={stop.locationLink} onChange={(e) => this.handleInputChange(index, 'locationLink', e.target.value)} placeholder="Location Link" />
                            </div>

                            <div className="col-span-1">
                                <p className="add-vehicle-field-label">
                                    Pick up time<span className="ma-required">*</span>
                                </p>
                                <ClassetCalendar value={stop?.pickUpTime} calendarMode="single" onChange={(e) => this.handleInputChange(index, 'pickUpTime', e.value)} placeholder="Pick Up Time" timeOnly />
                            </div>

                            <div className="col-span-1">
                                <p className="add-vehicle-field-label">
                                    Drop time<span className="ma-required">*</span>
                                </p>
                                <ClassetCalendar className="w-10" calendarMode="single" value={stop?.dropTime} onChange={(e) => this.handleInputChange(index, 'dropTime', e.value)} placeholder="Drop Time" timeOnly hourFormat="24" />
                            </div>
                        </div>
                    ))}
                    <Button label="Add Stop" icon={'pi pi-plus-circle text-black text-lg '} style={{ borderRadius: 12, backgroundColor: '#F4F5F6', border: 'none', color: 'black' }} className=" p-3 font-semibold ml-4 mt-4" onClick={this.addStop} />
                </div>

                <div className="text-center mt-8">
                    <Button label={'Add Special Route'} className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={this.onCreateRoute} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={onCancel} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {(this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(withRouter(CreateSpecialRoute));
