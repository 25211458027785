import React, { Component } from 'react';
import { connect } from 'react-redux';
import Service from '../../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams } from '../../../../utile';
import Authentication from '.././../../session';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { ActiveTag, InactiveTag, StarInfoBadge } from '../../../svgIcons';
import LoadingComponent from '../../../loadingComponent';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import withRouter from '../../../lib/withRouter';
import './styles.scss';
import { RadioButton } from 'primereact/radiobutton';
import { debounce } from 'lodash';
import { getBranchesLatest } from '../../../../store/actions';
import BranchToSectionMultiDDPreFill from '../../../BaseDropdownComponents/BranchToSectionMultiDDPreFill';
import ClassetTextArea from '../../../../classetComponents/classetTextArea';
import ClassetStudentSearch from '../../../../classetComponents/classetStudentSearch';

class StatusChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: [],
            lazyParams: BasicLazyParams,
            totalRecords: 0,
            searchLevel: 1,
            searchType: 'studentName',
            searchValue: '',
            isRaiseDialogOpen: false,
            remarks: '',
            selectedStudentData: {},
            dropdownsData: {}
        };
        this.debouncedApiCall = debounce(this.filterUsersData, 500);
        this.service = new Service();
    }

    reFormUsersObj = (users) => {
        return users.map((user) => ({
            ...user,
            ...(user.user_info || {})
        }));
    };

    filterUsersData = async () => {
        try {
            this.setState({ isLoading: true, users: [] });

            const { lazyParams, searchLevel, searchType, searchValue, dropdownsData } = this.state;
            const url = `${baseUrlAdmin}/user/filter/allusers?academicYear=${localStorage.getItem('userAcademicYear')}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}`;

            const payload = {
                sectionId: dropdownsData?.sectionId,
                isUserNameSearch: searchLevel === 2 && searchType === 'studentName',
                username: searchValue
            };

            const response = await this.service.post(url, payload, true);
           
            if (response?.status && response?.res?.status) {
                const formattedUsers = this.reFormUsersObj(response.res.Items);
                this.setState({
                    users: formattedUsers,
                    totalRecords: response.res.totalRecords,
                    searchValue: '',
                    isLoading: false
                });
            } else {
                throw new Error(response.errMessage || 'Failed to fetch users');
            }
        } catch (error) {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message,
                life: 3000
            });
            this.setState({ isLoading: false });
        }
    };

    componentDidMount() {
        if (this.state?.dropdownsData?.sectionId) {
            this.filterUsersData();
        }
        this.props.getBranchesLatest();
    }

    onPage = (event) => {
        this.setState({ lazyParams: { ...this.state.lazyParams, ...event } }, this.filterUsersData);
    };

    setDropdownValues = (dropdownsData) => {
        this.setState({ dropdownsData }, this.filterUsersData);
    };

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value });
    };

    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {
            this.debouncedApiCall();
        });
    };

    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value });
    };

    RaiseChangeRequest = async () => {
        try {
            const { selectedStudentData, remarks } = this.state;

            if (!remarks.trim()) {
                throw new Error('Please provide remarks');
            }

            const payload = {
                ...selectedStudentData,
                statusChangeRemarks: remarks,
                academicYear: localStorage.getItem('userAcademicYear'),
                statusChangeRequest: 'PENDING',
                requestRaisedBy: localStorage.getItem('userId'),
                requestRaisedByName: localStorage.getItem('username')
            };
            delete payload._id;

            const response = await this.service.post(`${baseUrlAdmin}/statusChange/save`, payload, true);

            if (response?.status && response?.res?.status) {
                this.setState({ remarks: '', isRaiseDialogOpen: false });
                this.toast.show({
                    severity: 'success',
                    summary: response?.res?.message,
                    life: 3000
                });
                this.filterUsersData(); 
            } else {
                throw new Error(response.errMessage || 'Failed to raise change request');
            }
        } catch (error) {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message,
                life: 3000
            });
        }
    };

    render() {
        const { users, isLoading, lazyParams, totalRecords, searchLevel, isRaiseDialogOpen, remarks } = this.state;

        return (
            <div className="ma-main">
                <div className="datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="search1" onChange={() => this.handleSearchLevel(1)} checked={searchLevel === 1} />
                        </div>
                        <div>
                            <BranchToSectionMultiDDPreFill selectedValue={searchLevel} setValues={this.setDropdownValues} />
                        </div>
                    </div>

                    <div style={{ marginLeft: '100px' }} className="flex justify-content-start align-items-center">
                        <span>OR</span>
                    </div>

                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="search2" onChange={() => this.handleSearchLevel(2)} checked={searchLevel === 2} />
                        </div>
                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>
                </div>

                <div className="card datatable-crud-demo ma-m30 m-2">
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={users}
                        dataKey="_id"
                        paginator
                        lazy
                        className="dataTableValuesText"
                        responsiveLayout="scroll"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        onPage={this.onPage}
                        first={lazyParams.first}
                        last={totalRecords}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    >
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} headerClassName="text-color-secondary" bodyClassName="text-center" filterField="given_name" field="given_name" header="Student Name" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} headerClassName="text-color-secondary" bodyClassName="text-center" filterField="username" field="username" header="User Name" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} headerClassName="text-color-secondary" bodyClassName="text-center" filterField="branchId" field="branchName" header="Branch" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} headerClassName="text-color-secondary" bodyClassName="text-center" filterField="custom:board" field="custom:board" header="Board" />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="custom:grade"
                            field="custom:grade"
                            body={(rowData) => `${rowData['custom:grade']}-${rowData.sectionName}`}
                            header="Grade-Section"
                        />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} headerClassName="text-color-secondary" bodyClassName="text-center" filterField="phone_number" field="phone_number" header="Phone Number" />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            header="Status"
                            body={(rowData) => (rowData?.status === 'Active' ? <ActiveTag /> : <InactiveTag />)}
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            header="Change Status"
                            body={(rowData) => (
                                <Authorizer permId={PERMISSIONS.RAISE_STUDENT_STATUS_CHANGE}>
                                    {rowData?.studentStatusChange !== 'INTRANSIT' ? (
                                        <Button
                                            className="addButtonn"
                                            onClick={() =>
                                                this.setState({
                                                    isRaiseDialogOpen: true,
                                                    selectedStudentData: rowData
                                                })
                                            }
                                        >
                                            <p>Raise</p>
                                        </Button>
                                    ) : (
                                        <Button className="addButtonn" disabled>
                                            <p>Request Raised</p>
                                        </Button>
                                    )}
                                </Authorizer>
                            )}
                        />
                    </DataTable>

                    <Dialog
                        visible={isRaiseDialogOpen}
                        draggable={false}
                        closeOnEscape={false}
                        style={{ width: 400 }}
                        dismissableMask={false}
                        closable={false}
                        header={() => (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <StarInfoBadge />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Change </p>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Student Status</p>
                            </div>
                        )}
                        footer={() => (
                            <div className="flex justify-content-center mb-4">
                                <Button className="formSubmitButton px-4" style={{ borderRadius: '10px' }} onClick={this.RaiseChangeRequest}>
                                    <p className="formSubmitButtonText">Request</p>
                                </Button>
                                <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRaiseDialogOpen: false, remarks: '' })}>
                                    <p className="formCancelButtonText">Cancel</p>
                                </Button>
                            </div>
                        )}
                        onHide={() => this.setState({ isRaiseDialogOpen: false })}
                    >
                        <div className="m-3 pt-3">
                            <div>
                                <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Reason for change:'} placeholder="Remarks" value={remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                            </div>
                        </div>
                    </Dialog>

                    <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                    {isLoading && <LoadingComponent />}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    branchData: (state.branchDataLatest?.data?.data || []).filter((each) => each.level === 1) || []
});

export default connect(mapStatesToProps, {
    getBranchesLatest
})(Authentication(withRouter(StatusChange)));
