import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import './styles.scss'
import { Button } from 'primereact/button';
import { AddIcon2, DownloadIcon, MailIcon, TickBgIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BasicLazyParams } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';
import { RadioButton } from 'primereact/radiobutton';
import ClassetStudentSearch from '../../classetComponents/classetStudentSearch';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import ClassetTextArea from '../../classetComponents/classetTextArea';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { debounce } from 'lodash';

const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]

export class UnAssignFee extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            openUnAssignDialog: false,
            searchLevel: 1,
            searchValue: "",
            students: [],
            searchType: searchTypeOptions[0].value,
            studentId: '',
            data: [],
            unAssignInfo: {}
        }
        this.debouncedApiCall = debounce(this.getData, 500)
        this.service = new Service()
    }

    onHandleUnassign = (rowdata) => {
        // console.log(rowdata,"unassign")
        this.setState({
            openUnAssignDialog: true,
            unAssignInfo: rowdata

        });
    }

    onHandleCancelUnAssign = () => {
        this.setState({
            openUnAssignDialog: false,
            unAssignInfo: {}

        })
    }
    getStudents = () => {
        this.setState({ isLoading: true })
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fine/student`
        let payload = {
            "branchId": this.state.dropdownsData?.branchId,
            "boardId": this.state.dropdownsData?.boardId,
            "classId": this.state.dropdownsData?.classId,
            "sectionId": this.state.dropdownsData?.sectionId,
            "academicYear": academicYear
        }
        this.service.post(url, payload, true).then(data => {
            const studentsData = data?.res?.data.map(each => {
                return {
                    label: each?.name,
                    value: each?.user_id
                }
            })

            if (data?.status) {
                this.setState({
                    students: studentsData,
                    isLoading: false
                })

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error Occured', life: 3000 });

        })

    }
    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value }
        )
    }

    setDropdownValues = (dropdownsData) => {
        console.log(dropdownsData, "dropdownsData")
        this.setState({
            dropdownsData,
            data: [],
            studentId: ''
        }, () => { this.getStudents() })
    }

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true, })
            } else {
                this.setState({ isUserEnrollSearch: true, })
            }

        })
    }

    handleSetSearchValue = (value) => {
        this.setState({
            searchValue: value,
            dropdownsData: {
                boardId: '',
                branchId: "",
                classId: '',
                sectionId: '',
            }
        }, () => {
            this.debouncedApiCall()

        })
    }
    onStudentChange = (e) => {
        this.setState({ studentId: e.value }, () => {
            this.getData()
        })

    }

    getData = () => {
        this.setState({ isLoading: true })
        let academicYear = localStorage.getItem('userAcademicYear')
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-unassign/fee-types?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : ""
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : ""

        let payload = {
            "branchId": this.state?.dropdownsData?.branchId,
            "boardId": this.state?.dropdownsData?.boardId,
            "classId": this.state?.dropdownsData?.classId,
            "sectionId": this.state?.dropdownsData?.sectionId,
            "studentId": this.state?.studentId,
            "academicYear": academicYear,
            "username": username,
            "enrollmentNo": enrollmentNo


        }
        this.service?.post(url, payload, true).then(data => {
            if (data?.status && data?.res?.data?.length) {
                // const totalStudentDue=
                this.setState({
                    data: data?.res?.data || [],
                    isLoading: false,
                    totalRecords: data?.res?.totalRecords || 0,

                })

            } else if (data?.status && !data?.res?.data?.length) {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'No Fee Structure Assigned to Student', life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error Occured', life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error Occured', life: 3000 });

        })

    }
    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            }
        }, this.getData)
    }

    handleUnassign = () => {
        this.setState({ isLoading: true })
        let academicYear = localStorage.getItem('userAcademicYear')
        this.setState({ isLoading: true })
        let payload = {
            ...this.state.unAssignInfo,
            user_id: this.state.studentId,
            academicYear: academicYear,
            remarks: this.state.remarks,
            isAssigned: false,
            boardId: this.state?.dropdownsData?.boardId,
            branchId: this.state?.dropdownsData?.branchId,
            gradeId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            boardName: this.state?.dropdownsData?.selectedBoardName,
            className: this.state?.dropdownsData?.selectedClassName,
            branchName: this.state?.dropdownsData?.selectedbranchName,
            sectionName: this.state?.dropdownsData?.selectedsectionName,

        }
        let url = `${baseUrlAdmin}/fee-unassign/add-unassign`
        this.service.post(url, payload, true).then((data) => {
            if (data.status) {
                this.setState({
                    isLoading: false, openUnAssignDialog: false,
                    remarks: ''
                }, () => this.getData())
                this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });

            }
            else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error Occured', life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error Occured', life: 3000 });

        })



    }


    render() {
        return (
            <div className="m-2">
                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BoardToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                        </div>


                    </div>
                    <div className="col-12 ml-5">
                        <label className='label'>Student<span className='ma-required'>*</span></label>
                        {/* <div className="flex"> */}
                        <div className='mt-2 flex'>
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id='paymentMode'
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                onChange={this.onStudentChange}
                                placeholder={(
                                    <div>
                                        <span className=''>Select Student</span>
                                    </div>
                                )}
                            />
                            {this.state.isLoading && <LoadingComponent />}

                        </div>
                        {/* </div> */}
                    </div>

                </div>

                <DataTable ref={(el) => this.dt = el}
                    // value={this.state.feeTypeData}   
                    value={this.state.data}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >

                    <Column filterField="" field="feeType" sortable bodyClassName='text-center' header="Fee Type" alignHeader={"center"} />
                    <Column filterField="" field="totalAmount" sortable bodyClassName='text-center' header="Total Fee" alignHeader={"center"} />
                    <Column filterField="" field="concession" sortable bodyClassName='text-center' header="Concession" alignHeader={"center"} />
                    <Column filterField="" field="fineAmt" sortable bodyClassName='text-center' header="Fine Amount" alignHeader={"center"} />
                    <Column filterField="" field="dueAmount" sortable bodyClassName='text-center' header="Assigned Fee" alignHeader={"center"} />
                    <Column filterField="" field="amountPaid" sortable bodyClassName='text-center' header="Paid Fee" alignHeader={"center"} />
                    <Column sortable bodyClassName='text-center' header="Action" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                        return (
                            <div className=''>
                                <Button disabled={!(rowData.amountPaid === 0 && rowData.fineAmt === 0)}
                                    onClick={(e) => this.onHandleUnassign(rowData)}
                                    style={{ backgroundColor: '#076EFF', borderRadius: '12px' }}
                                //    className="submitButtonn"

                                >
                                    <p className='payBtn'

                                    >UnAssign</p>
                                </Button>
                            </div>



                        )
                    }} />
                </DataTable>
                <Dialog
                    blockScroll={false}
                    visible={this.state.openUnAssignDialog}
                    draggable={false}
                    // closeOnEscape={false}
                    style={{ width: "400px", height: "500px" }}
                    // dismissableMask={false}
                    closable={true}
                    onHide={this.onHandleCancelUnAssign}
                    footer={() => {
                        return (
                            <div className='flex justify-content-center'>
                                <Button label="Unassign" className='confirmDialogAcceptBtn mr-4  px-6' onClick={this.handleUnassign} />
                                <Button style={{ color: "black" }} label="Cancel" className='confirmDialogCancelBtn px-6' onClick={this.onHandleCancelUnAssign}

                                />

                            </div>
                        );
                    }}
                >
                    <div className='text-center'>
                        <TickBgIcon width={32} height={32} color="#268900" />
                        <h4 className="confirmDialogHeader">Confirm Unassign Fee</h4>
                        <p className="text-xl">
                            Fee Type :{this.state.unAssignInfo?.feeType}
                        </p>
                        <p className="text-xl">
                            Assigned Fee : {this.state.unAssignInfo?.dueAmount}
                        </p>


                    </div>
                    <div className="m-2">
                        <div className="col-12">
                            <label className='label mb-2 '>Remarks<span className='ma-required'></span></label>
                            <div className="mt-2">
                                <ClassetTextArea
                                    className=""
                                    height="48px"
                                    width="368px"
                                    placeholder="Enter Remarks"
                                    value={this.state.remarks}
                                    onChange={(e) => this.setState({ remarks: e.target.value })}


                                />
                            </div>

                        </div>

                    </div>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}




            </div>
        )
    }
}
export default UnAssignFee