import React, { Component } from 'react';
import Authentication from '../../session';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import { primaryColor } from '../../../store/apiConstants';
import RequestedRoute from './RequestedRoute';
import RequestedStop from './RequestedStop';

class TransportRouteTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0
        };
    }

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className="ma-tab-title">Transport Management</h4>
            </React.Fragment>
        );
    };
    componentDidUpdate(prevProps, prevState) {}
    render() {
        return (
            <div>
                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-6 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tabheadText"
                                        onClick={() => {
                                            this.onTabChange(0);
                                        }}
                                    >
                                        Requested Route
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-6 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(1);
                                        }}
                                    >
                                        Requested Stop
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.currentTab == 0 && (
                        <>
                            <RequestedRoute />
                        </>
                    )}
                       {this.state.currentTab == 1 && (
                        <>
                            <RequestedStop />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(TransportRouteTabs)));
