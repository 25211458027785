import React, { Component } from 'react';
import { getFormFields, isFormValid, onTextChange, isFieldValid, onNumberChange, onEmailChange, onDropDownChange } from '../../../utile/formHelper';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import { ClockIcon, DeleteIconClasset, LocationMarker, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';

const assignTransportFields = require('./assignTransport.json');
let formFields = assignTransportFields;

export default class assignTransport extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(assignTransportFields);
        this.state = {
            formValidations: this.formFields.formValidations,
            studentInfo: this.props?.dataForAddRoutes,
            locationInfo: {}
        };
        this.service = new Service();
    }
    componentDidMount() {
        const { studentInfo } = this.state;
        if (this.props?.dataForAddRoutes?.locationLink) {
            this.setState({
                locationInfo: {
                    locationLink: studentInfo?.locationLink,
                    dropTime: new Date(studentInfo?.dropTime),
                    pickUpTime: new Date(studentInfo?.pickUpTime)
                }
            });
        }
    }
    areAllPropertiesFilled(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
                    return false;
                }
            }
        }
        return true;
    }
    onRaiseRequest = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        const { locationInfo, studentInfo } = this.state;
        const { selectedHierarchy } = this.props;
        let checkData = await this.areAllPropertiesFilled(locationInfo);
        if (!checkData) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter all the required fields', life: 3000 });
        }
        delete studentInfo?.routeInfo?.stops;
        studentInfo.selectedPickUpStop = studentInfo?.selectedPickupStop ?? studentInfo.selectedPickUpStop 
         let payload = {
            academicYear,
            ...locationInfo,
            ...studentInfo,
            ...selectedHierarchy
        };

        let url = `${baseUrlAdmin}/transport-assign-approval/assign/request`;
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({
                    isLoading: false
                });

                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: res?.res?.message,
                    life: 3000
                });

                if (res.status) {
                    await this.props.onCancel();
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    render() {
        const { studentInfo, locationInfo } = this.state;
        const { onCancel } = this.props;
        return (
            <>
                <div className="mt-3">
                    <div className="grid lg:col-11 mx-5 gap-5">
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">Student Name</p>
                            <div className="flex">
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#667A85'} />} className="w-full" value={studentInfo?.name} disabled={true} />
                            </div>
                        </div>
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">Enrollment No</p>
                            <div className="flex">
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#667A85'} />} className="w-full" value={studentInfo?.enrollmentId} disabled={true} />
                            </div>
                        </div>
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">Route No</p>
                            <div className="flex">
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#667A85'} />} className="w-full" value={studentInfo?.routeName} disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '2px', backgroundColor: '#B1B1B1' }} className="mt-2"></div>
                    <div className="grid lg:col-11 mx-5 gap-5 mt-3">
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">Vehicle Number</p>
                            <div className="flex">
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#667A85'} />} className="w-full" value={studentInfo?.vehicleName} disabled={true} />
                            </div>
                        </div>
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">Registration Number</p>
                            <div className="flex">
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#667A85'} />} className="w-full" value={studentInfo?.vehicleRegistrationNo} disabled={true} />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <p className="add-vehicle-field-label">Location Link</p>
                            <ClassetInputText
                                icon={<LocationMarker />}
                                className="w-10"
                                value={locationInfo?.locationLink}
                                onChange={(e) =>
                                    this.setState((prevState) => ({
                                        locationInfo: {
                                            ...prevState.locationInfo,
                                            locationLink: e.target.value
                                        }
                                    }))
                                }
                                placeholder="Location Link"
                            />
                        </div>

                        <div className="col-span-1">
                            <p className="add-vehicle-field-label">
                                Pick up time<span className="ma-required">*</span>
                            </p>
                            <ClassetCalendar
                                value={studentInfo?.selectedPickUpStop ? new Date(studentInfo?.selectedPickUpStop?.pickUpTime): new Date(studentInfo?.selectedPickupStop?.pickUpTime)}
                                calendarMode="single"
                                disabled={true}
                                placeholder="Pick Up Time"
                                timeOnly
                            />
                        </div>
                        <div className="col-span-1">
                            <p className="add-vehicle-field-label">
                                Drop time<span className="ma-required">*</span>
                            </p>
                            <ClassetCalendar
                                className="w-10"
                                calendarMode="single"
                                value={new Date(studentInfo?.selectedDropStop?.dropTime)}
                                disabled={true}
                                placeholder="Drop Time"
                                timeOnly
                                hourFormat="24"
                            />
                        </div>
                    </div>
                    <div className="text-center mt-8">
                        <Button label="Assign" className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={this.onRaiseRequest} />
                        <Button label="Cancel" className="confirmDialogCancelBtn" onClick={onCancel} />
                    </div>
                    <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                    {(this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && (
                        <>
                            <LoadingComponent />
                        </>
                    )}
                </div>
            </>
        );
    }
}
