import React, { Component } from 'react';
import { AddIconInButtonFull, CalenderCheckIcon, MailIcon, MinusSquareIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Divider } from 'primereact/divider';
import '../styles.scss';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import withRouter from '../../lib/withRouter';
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { getBoardsData } from '../../../store/actions';
import Authentication from '../../session';
import { baseUrlAdmin } from '../../../store/apiConstants';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'react-formio/lib/types';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { BasicLazyParams } from '../../../utile';
class TimeTableSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: [],
            lazyParams: BasicLazyParams,
            category: {
                description: '',
                categoryName: '',
                boardsList: [{ board: '', grade: [], classes: [] }]
            },
            substituations: []
        };
        this.service = new Service();
    }
    subs = [
        { data: 'Substitute with Teacher in  Same grade and subject' },
        { data: 'Substitute with Teacher in same grade  and different subject' },
        { data: 'Substitute with Teacher in Same subject across all grades' },
        { data: 'Substitute with Teacher in any grade and any subject' },
        { data: 'Substitute with any non-admin employee present today' }
    ];
    componentDidMount() {
        this.getAdmissionTypesData();
        let newSubs = this.subs.map((each, index) => {
            return { ...each, priority: index + 1 };
        });
        console.log(newSubs);
        this.setState({ substituations: newSubs });
    }
    onRowReorder = (e) => {
        let rowValues = e.value;
        console.log(rowValues, 'rowValuesbeforepriority');
        let newValues = rowValues.map((each, index) => {
            return { ...each, priority: index + 1 };
        });
        console.log(rowValues, 'rowValuesafterpriority');
        this.setState({
            substituations: newValues
        });

        this.toast.show({ severity: 'success', summary: 'Rows Reordered', life: 3000 });
    };
    getAdmissionTypesData = async () => {
        this.setState({ isLoading: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ categories: res?.res?.data, isLoading: false });
                console.log(res.res.data);
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };
    onChangeBoard = (boardId, indexPosition) => {
        const _classes = [];
        const UpdatedBoardList = this.state.category.boardsList.map((each, index) => {
            if (indexPosition == index) {
                const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
                if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                    selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                }
                return { ...each, board: boardId, classes: _classes };
            } else {
                return each;
            }
        });

        this.setState((prevState) => ({
            classes: _classes,
            category: { ...prevState.category, boardsList: UpdatedBoardList }
        }));
    };
    onChangeClass = (classId, indexPosition) => {
        const UpdatedClassList = this.state?.category?.boardsList?.map((each, index) => {
            if (indexPosition == index) {
                return { ...each, grade: classId };
            } else {
                return each;
            }
        });

        this.setState((prevState) => ({
            category: { ...prevState.category, boardsList: UpdatedClassList }
        }));
    };

    render() {
        const { category } = this.state;
        return (
            <div className="poppins24">
                <div className="col-12 flex justify-content-end align-items-center">
                    <Button
                        style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                        className="flex align-items-center gap-1"
                        // onClick={() => {
                        //     this.setState({ isDialogOpenForCreateConcession: true });
                        // }}
                    >
                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                        <p className=" text-xl">Save</p>
                    </Button>
                </div>
                <div className="generalsetting-card">
                    <div className="flex justify-content-between" style={{ color: 'black', fontWeight: 'bolder' }}>
                        <div>
                            <p className="cardhead px-3">Minimum no. of Periods per Teacher</p>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Minimum Periods per week" />
                                {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                    required={true}
                                    width={'343px'}
                                    // onChange={(e) => this.onChangeSubject(e.value, index)}
                                    className=""
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Break TIme</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <p className="cardhead pl-3">Maximum no. of Periods per Teacher</p>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Maximum Periods per week" />
                                {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                    required={true}
                                    width={'343px'}
                                    // onChange={(e) => this.onChangeSubject(e.value, index)}
                                    className=""
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Break TIme</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Divider></Divider>
                    <div className="flex justify-content-between" style={{ color: 'black', fontWeight: 'bolder' }}>
                        <div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                    className="border-round-md  border-none"
                                    // formHelperError={formValidations && !formValidations.fields['userNameOrId']?.isValid && formStatus}
                                    // value={user.userNameOrId}
                                    // onChange={(e) => {
                                    //     onTextChange(e.target.value, 'userNameOrId', this, createUserFields, user, formValidations, 'user', 'formValidations');
                                    // }}
                                    placeholder="Minimum Periods per week"
                                />
                                {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                    required={true}
                                    width={'343px'}
                                    // onChange={(e) => this.onChangeSubject(e.value, index)}
                                    className=""
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Break TIme</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div style={{ marginRight: '92px' }}>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Maximum Periods per week" />
                                {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                    required={true}
                                    width={'343px'}
                                    // onChange={(e) => this.onChangeSubject(e.value, index)}
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Break Time</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="generalsetting-card">
                    <div className="flex gap-5 align-items-center px-3">
                        <p className="cardhead">Subject to Period Limits</p>
                        <div className="flex gap-3 align-items-center">
                            <InputSwitch className="" id="partialPayment" />
                            <p style={{ fontSize: '18px', color: 'black' }}>All Subjects are equal</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <ClassetInputText label="Total Periods per week - Subject" icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Subject - Total Periods" />
                        {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                    </div>
                </div>
                <div className="generalsetting-card">
                    <div className="flex gap-5 align-items-center px-3">
                        <p className="cardhead">Continous Periods</p>
                        <InputSwitch className="" id="partialPayment" />
                    </div>
                    <div className="grid px-3 align-items-center">
                        <div className="col-3">
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                label="Max no.of continous periods-Subject"
                                optionLabel="subjectName"
                                optionValue="subjectId"
                                required={true}
                                width={'343px'}
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                maxSelectedLabels={3}
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Periods</span>
                                    </div>
                                }
                            />{' '}
                            {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                        </div>

                        {category.boardsList?.map((boardType, index) => (
                            <div className="flex mt-5 justify-content-evenly">
                                <div className="col-4 px-6 flex justify-content-center align-content-center">
                                    <ClassetDropdown
                                        className="dialogCardInField border-round-md shadow-2 text-xl pl-2 pt-2"
                                        options={this.props.boards}
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        value={boardType.board}
                                        onChange={(e) => this.onChangeBoard(e.value, index)}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className="text-xl ml-4 body-medium">Select Board</span>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-4 px-6 flex justify-content-center align-content-center">
                                    <ClassetMultiSelect
                                        value={boardType.grade}
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        onChange={(e) => this.onChangeClass(e.value, index)}
                                        options={boardType.classes}
                                        optionLabel="label"
                                        className="dialogCardInFiel text-xl shadow-2 pl-2"
                                        maxSelectedLabels={3}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center mt-1">
                                                <span className="text-xl ml-4 body-medium">Select Grades</span>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="generalsetting-card">
                    {/* <DataTable ref={(el) => (this.dt = el)} value={this.state.substituations} dataKey="data" globalFilter={this.state.globalFilter} responsiveLayout="scroll" reorderableRows onRowReorder={this.onRowReorder}>
                        <Column rowReorder style={{ width: '3em' }} />
                        <Column
                            style={{ width: '3em' }}
                            field="priority"
                            className="datatable-cel"
                            body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                    </>
                                );
                            }}
                        />

                        <Column field="data" header={<p className="cardhead">Substitutions</p>} className="datatable-cel" />
                    </DataTable> */}

                    <DataTable value={this.state.substituations} reorderableRows onRowReorder={this.onRowReorder} onColReorder={this.onColReorder} responsiveLayout="scroll">
                        <Column rowReorder style={{ width: '3em' }} />
                        {/* {dynamicColumns} */}
                        <Column field="data" header={<p className="cardhead">Substitutions</p>} className="datatable-cel" />
                    </DataTable>

                    {/* <div className="flex flex-column gap-3 mt-3 px-3">
                        <div className="flex gap-1 align-items-center">
                            <ClassetDropdown
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                width={'60px'}
                            />

                            <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} width={'500px'} className="border-round-md  border-none" placeholder="Subject - Total Periods" />
                        </div>
                        <div className="flex gap-1 align-items-center">
                            <ClassetDropdown
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                width={'60px'}
                            />

                            <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} width={'500px'} className="border-round-md  border-none" placeholder="Subject - Total Periods" />
                        </div>
                        <div className="flex gap-1 align-items-center">
                            <ClassetDropdown
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                width={'60px'}
                            />

                            <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} width={'500px'} className="border-round-md  border-none" placeholder="Subject - Total Periods" />
                        </div>
                        <div className="flex gap-1 align-items-center">
                            <ClassetDropdown
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                width={'60px'}
                            />

                            <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} width={'500px'} className="border-round-md  border-none" placeholder="Subject - Total Periods" />
                        </div>
                        <div className="flex gap-1 align-items-center">
                            <ClassetDropdown
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                width={'60px'}
                            />

                            <ClassetInputText icon={<MailIcon width={24} height={24} color={'#000000'} />} width={'500px'} className="border-round-md  border-none" placeholder="Subject - Total Periods" />
                        </div>
                    </div> */}
                    <Divider />
                    <div className="flex justify-content-between py-5 align-items-center">
                        <div className="flex gap-5 px-3 align-items-start">
                            <InputSwitch className="" />
                            <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                <p style={{ color: 'black' }}>Add Exception for Substitutions</p>
                                <p>Select Employees that are exempted from Substitution</p>
                            </div>
                        </div>
                        <div>
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                label="Add Employee Exceptions"
                                optionLabel="subjectName"
                                optionValue="subjectId"
                                required={true}
                                width={'343px'}
                                // onChange={(e) => this.onChangeSubject(e.value, index)}
                                className=""
                                maxSelectedLabels={3}
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Exempted Employees</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions) || [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(TimeTableSettings)));
