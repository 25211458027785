import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import VideoLink from './videoLink';
import ContentPlayer from '../../contentMaster/contentPlayers';

export default class VideosLinking extends Component {
    constructor(props) {
        super(props);


        this.state = {
            isShowLinkVideo: false,

        }

    }

    onAssignVideos = (videos) => {

        this.setState({
            isShowLinkVideo: false
        }, () => this.props.onAssignVideos(videos))

    }



    render() {

        //  console.log(this.state)
        const { videoLinking } = this.props;
        const header = (
            <div className="bg-white p-4">
                <div className="ma-tbl-left">
                    {/* <Button icon="pi pi-plus" iconPos='left' label={'Link Video'} className='p-button-raised' onClick={() => { this.setState({ isShowLinkVideo: true }) }} /> */}
                    <Button
                        label="Link Video"
                        icon={'pi pi-plus-circle text-lg'}
                        className='AddButton px-4 py-2 mb-3 '
                        style={{ width: '170px' }}
                        onClick={() => { this.setState({ isShowLinkVideo: true }) }}
                    />
                    {/* {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />} */}
                </div>

            </div>
        );
        return (
            <div className="datatable-crud-demo vidoes-linked">
                <DataTable
                    header={header}
                    value={videoLinking || []}
                    dataKey="contentId"
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    rows={10}
                    responsiveLayout="scroll"
                    selectionMode="checkbox"
                    className='dataTableValuesText'
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                >
                    <Column alignHeader={"center"} bodyClassName='text-center' sortable field="contentName" header="Resource Name" />
                    <Column alignHeader={"center"} bodyClassName='text-center' sortable body={(rowData) => {
                        return (
                            <div className="preview-c" >

                                <span onClick={() => {
                                    this.setState({
                                        previewContent: rowData,
                                        isShowPreview: true
                                    });
                                }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                            </div>
                        )
                    }} field="status" header="Preview" />
                    <Column alignHeader={"center"} bodyClassName='text-center' sortable body={(rowData) => {
                        return (
                            <div className="preview-c" >

                                <span onClick={() => {
                                    this.props.removeLinkedVideo(rowData)
                                }} > <i className='pi pi-times ' /></span>
                            </div>
                        )
                    }} field="status" header="Remove" />
                </DataTable>

                {this.state.isShowLinkVideo && <VideoLink
                    onHide={() => {
                        this.setState({
                            isShowLinkVideo: false
                        })
                    }}
                    selectedTopicResourceTypes={[
                        { label: 'VIDEO', value: 'mp4', isActive: true, isSelected: true },
                        // { label: "PPT", value: "zip", isActive: true, isSelected: true },
                        // { label: "PDF", value: "pdf", isActive: true, isSelected: true },
                        // { label: "QUIZ", value: "quiz", isActive: true, isSelected: true }
                    ]}

                    contentInfo={videoLinking || []}
                    resourseOrigin="upload"
                    //  selectedType={this.state.selectedType}
                    onSelectContent={this.props.onSelectContent}
                    onAssignVideos={this.onAssignVideos}
                />}

                {this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                    this.setState({
                        isShowPreview: false
                    })
                }} contentInfo={this.state.previewContent} />}
            </div>
        )
    }
}
