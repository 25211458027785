import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { BasicLazyParams } from '../../../../utile';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { Checkbox } from 'primereact/checkbox';
import SvgViewer from '../../../customComponents/svgViewer';
import AssignDiagnosticTestToContent from './assignDignoseTestToChapter';
import LoadingComponent from '../../../loadingComponent';
import { AddUserIcon, ArrowClockWise, AssignIcon, ClassetDownloadIcon, ClassetEditIcon, ClassetKeyIcon, ClassetUploadIcon, EditIcon } from '../../../svgIcons';
import CreateText from '../createTest/index'
import withRouter from '../../../lib/withRouter';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import BranchToSectionDD from '../../../BaseDropdownComponents/BranchToSectionDD';

class DiagnosticTestList extends Component {
    constructor(props) {
        super(props);

        this.state = {

            exams: [],
            studentsAssignDialog: false,
            columns: [{ columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
            { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
            { columnName: 'Exam Type', isVisible: false, id: 'examType' },
            { columnName: 'Source', isVisible: false, id: 'source' },
            { columnName: 'Status', isVisible: false, id: 'status' },
            { columnName: 'Question Type', isVisible: false, id: 'questionType' }
            ],
            showSearch: false,
            globalFilter: null,
            globalSearch: '',
            // totalRecords: 18,
            lazyParams: BasicLazyParams,
            editExamId: null,
            editExamType: null,
            selectedStudents: [],
            dropdownValues: []
        }
        this.service = new Service();
    }
    // onEditExam = (editExamId, editExamType) => {

    //     console.log(editExamId, editExamType,"editExamId, editExamType")
    //      this.setState({
    //          editExamId,
    //          editExamType
    //      })
    //  }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.onSearch);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    componentDidMount() {
        this.onSearch();

    }


    onSearch = () => {

        const {
            lazyParams, globalSearch
        } = this.state;


        let filters = { examType: 'DiagnosticTest' };


        this.setState({
            isLoading: true,
            questions: [],
            totalRecords: 0
        });


        this.service.post(`${baseUrlAdmin}/examination/filter?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {
            if (data && data.status && data?.res?.data) {
                this.setState({
                    totalRecords: data.res.data.totalRecords,
                    exams: data.res.data.exams,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'No Exams', detail: "No Exams Found", life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });


    }

    assignExam = (examToAssign) => {
        this.setState({
            isShowAssignDialog: true,
            examToAssign
        })
    }

    onAssignSuccess = () => {


        this.setState({
            isShowAssignDialog: false
        })

        this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Successfully assigned dignose test to chapter', life: 6000 });
    }

    onEdit = (rowdata) => {
        console.log(rowdata, "rowdatarowdata")
        if (rowdata && rowdata != undefined) {
            this.props.navigate('/CreateTest', { state: { editdata: rowdata } });
        }

    };

    studentsAssign = () => {
        this.setState({
            studentsAssignDialog: true
        })
    }

    onCloseStudentsAssign = () => {
        this.setState({
            studentsAssignDialog: false,
            selectedQuestions: []
        })
    }

    setDropdownValues = (dropdownsData) => {
        console.log(dropdownsData, "ddddd")
        this.setState({
            dropdownsData,
        }, () => { this.getFeeCategoryAssignInfo(dropdownsData?.branchId, dropdownsData?.boardId, dropdownsData?.classId, dropdownsData?.sectionId) })
    }

    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let payload = {
            "branchId": branchId,
            "boardId": boardId,
            "classId": classId,
            "sectionId": sectionId

        }
        payload.academicYear = AcademicYear
        let url = `${baseUrlAdmin}/fee-structure/assignInfo`;
        this.service.post(url, payload, true).then(data => {
            if (data?.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data,
                    isLoading: false

                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });
            }
        });

    }

    onSelectionChange = (e) => {
        console.log('Selected rows:', e.value);  // Check if multiple rows are being selected
        this.setState({ selectedStudents: e.value });  // Correctly update the state with all selected rows
    }

    onAssignStudents = () => {


        const curriculum = {
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
        };
        const studensId = this.state?.selectedStudents?.map((e) => e._id)
        const payload = {
            "assignIds": studensId,
            "curriculum": curriculum,
            "examType": this.state?.selectedStudents[0].examType
        }

        const dd = this.props

        let url = `${baseUrlAdmin}/examination/assigntosection`;
        this.service.post(url, payload, true).then(res => {
            if (res.status && res?.res?.success) {
                this.toast.show({ severity: 'success', summary: 'created succesfully', detail: "", life: 3000 });
                this.setState({
                    exam: {},
                    selectedQuestions: [],
                });
                this.onCloseStudentsAssign()

            } else {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }


    render() {
        const { exams } = this.state;
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Digonstic Test List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ml-4  h-2rem w-15rem" onChange={this.onGlobalSearch} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} > */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>
            <div className="card datatable-crud-demo ma-mt20 mt-3">
                <DataTable ref={(el) => this.dt = el}
                    //scrollable scrollHeight="500px"
                    responsiveLayout="scroll"
                    dataKey="_id" showGridlines
                    className="dataTableValuesText"
                    value={this.state.exams}
                    filters={this.state.lazyParams.filters}
                    // loading={this.props.isLoading}
                    // paginatorLeft={() => {
                    //     return <>
                    //         <p className='avail-q'>Total Reports:  <span>{this.state.totalRecords}</span> </p>
                    //     </>
                    // }}
                    paginator
                    lazy
                    onPage={this.onPage}
                    onSort={this.onSort}
                    onFilter={this.onFilter}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    sortField={this.state.lazyParams.sortField}
                    sortOrder={this.state.lazyParams.sortOrder}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    selectionMode={'checkbox'}
                    selection={this.state.selectedStudents}  // Set the selected questions
                    onSelectionChange={(e) => this.setState({ selectedStudents: e.value })}

                    //paginatorTemplate={paginatorTemplate}
                    //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                // globalFilter={this.state.globalFilter}
                //header={header}
                >
                    <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='examName' field="examName" header="Exam Name" />
                    <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='boardName' headerStyle={{ width: '140px' }} field="boardName" header="Board Name" />
                    <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='className' field="className" header="Class Name" />
                    <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='noOfQuestions' field="noOfQuestions" header="Number of Questions" />
                    <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='totalTime' field="totalTime" header="Total Time (Mins)" />
                    <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' sortField='totalMarks' field="totalMarks" header="Total Marks " />
                    <Column className=' ' alignHeader={"center"} header="Actions" body={(rowData, { rowIndex }) => {
                        return (<>
                            {/* {console.log('rowData', rowData)} */}
                            <div className="flex justify-content-center align-items-center gap-4">
                                {/* <Authorizer permId={PERMISSIONS.EXAM_EDIT_QUIZ} > */}
                                <span data-pr-tooltip="Edit Exam" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={() => { this.onEdit(rowData, 'DiagnosticTest') }}>
                                    <ClassetEditIcon height={30} width={30} />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.lk${rowIndex}`} />
                                <span data-pr-tooltip="Upload Key" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={{}}>
                                    <ClassetKeyIcon height={30} width={30} />
                                </span>
                                <span data-pr-tooltip="Re-Evaluate" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={{}}>
                                    <ArrowClockWise width={25} height={25} color={'#000000'} />
                                </span>
                                <span data-pr-tooltip="Download" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={{}}>
                                    <ClassetDownloadIcon width={30} height={30} color={'#000000'} />
                                </span>
                                <span data-pr-tooltip="Assign Students" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={this.studentsAssign}>
                                    <AddUserIcon width={32} height={32} color={'#000000'} />
                                </span>
                                <span data-pr-tooltip="Upload" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={{}}>
                                    <ClassetUploadIcon width={32} height={32} color={'#000000'} />
                                </span>
                                {/* <span data-pr-tooltip="Assign Digonstic Test" data-pr-position="bottom" className={`lk${rowIndex} ml-3`} onClick={() => { this.assignExam(rowData) }}>
                                    <AssignIcon height={30} width={30} />
                                </span> */}
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.mk${rowIndex}`} />
                                {/* </Authorizer> */}
                            </div>
                        </>
                        );
                    }} ></Column>
                    {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                </DataTable>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>

            <Dialog
                visible={this.state.studentsAssignDialog}
                onHide={this.onCloseStudentsAssign}
                style={{ width: '96%', height: '96vh' }}
                header="Assign Students" headerClassName='text-center'
                modal
                // footer={() => {
                //     return (<div className='ma-mt20'>
                //         <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseStudentsAssign} />
                //         <Button label={`Assign`} className='ma-m-lr10 p-button-raised' onClick={this.assignQuestionsToTopic} />

                //     </div>)
                // }}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                dismissableMask={false}
                closable={true}
            >
                <div className="p-5">
                    <div className=''>
                        <div className="grid mb-2 " >

                            <BranchToSectionDD setValues={this.setDropdownValues} />

                            {/* <div className="col-3 ml-1">
                                    <ClassetInputText

                                        icon={<CodeLinkIcon />}
                                        id="categoryName"
                                        className="text-xl "
                                        // height="48px"
                                        // width="262px"
                                        placeholder="Structure Name"
                                        value={this.state?.feeStructureName}
                                        infoIcon={<InfoIcon />}
                                        disabled={true}
                                    />
                                </div> */}
                        </div>

                    </div>

                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.categoryAssignInfo}
                        dataKey='_id'
                        paginator
                        rows={10}
                        // showGridlines
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        // globalFilter={this.state.globalFilter}
                        responsiveLayout="scroll"
                        // header={header}
                        sortable
                        selection={this.state.selectedQuestions}  // Set the selected questions
                        onSelectionChange={(e) => this.setState({ selectedQuestions: e.value })}
                        selectionMode="checkbox"

                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                        <Column field="branchName" header="Grade-Section" className="datatable-cel" sortable />
                        <Column field="boardName" header="Exam Name" className="datatable-cel" sortable />
                        <Column field="className" header="Total Students" className="datatable-cel" sortable />
                        <Column field="className" header="Board" className="datatable-cel" sortable />
                        <Column field="className" header="Branch" className="datatable-cel" sortable />

                    </DataTable>
                    <div className='flex justify-content-center mb-4 mt-4'>
                        <Button className='submitButtonn w-2 '

                            onClick={this.onAssignStudents}
                        >
                            <p className='submitButtonnText'>Assign</p>
                        </Button>
                        <Button className='cancelButtonn w-2 ml-3'
                            onClick={this.onCloseStudentsAssign} >
                            <p className='cancelButtonnText'>Cancel</p>
                        </Button>
                    </div>

                </div >

            </Dialog>
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
        </>)
    }
}

export default withRouter(DiagnosticTestList);
