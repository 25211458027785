import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from "../../../utile/formHelper";
import moment from "moment";
import ClassetInputText from "../../../classetComponents/classetInputText";
import { CodeLinkIcon, MailIcon } from "../../svgIcons";
import ClassetTextArea from "../../../classetComponents/classetTextArea";
import Service from "../../../services";
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import { baseUrlAdmin } from "../../../store/apiConstants";
import LoadingComponent from "../../loadingComponent";

const TeacherDairyFields = require('./teacherDairyFormat.json');


const fieldTypeoptions = [{ label: 'Numeric', value: 'Numeric' },
{ label: 'Alphanumeric', value: 'alphanumeric' },
{ label: 'Dates', value: 'dates' }
]

class TeacherDairyFormat extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(TeacherDairyFields);
        console.log(this.formFields.data, "dtatttttt")
        this.state = {

            teacherDairyData: this.props?.editData?._id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            teachers: this.props?.editData?._id ? this.props?.editData?.tctemplate : [{ title: '', fieldType: '', description: '' }],
            formData: {},
            editDialog: false,
            editAdmissionCategory: {},
            titleColm: "",
            descriptionError: "",
            fieldTypeError: "",
            dynamicFieldsError: "",
            rowCount: 1,

        }

        this.service = new Service();
    }




    handleTextChange = (value, field, index) => {
        const teachers = [...this.state.teachers];
        teachers[index] = {
            ...teachers[index],
            [field]: value
        };
        this.setState({ teachers });
    };


    handleDropdownChange = (value, field, index) => {
        const teachers = [...this.state.teachers];
        teachers[index] = {
            ...teachers[index],
            [field]: value
        };
        this.setState({ teachers });
    };


    addTeacherRow = () => {
        this.setState((prevState) => ({
            teachers: [
                ...prevState.teachers,
                { title: '', fieldType: '', description: '' }
            ],
            rowCount: prevState.rowCount + 1,
        }));
    };


    removeTeacherRow = (index) => {
        const teachers = [...this.state.teachers];
        teachers.splice(index, 1);
        this.setState((prevState) => ({ teachers, rowCount: prevState.rowCount > 1 ? prevState.rowCount - 1 : 1 }));
    };

    saverrrrrr = () => {
        this.setState({ isLoading: true })
        const { teachers } = this.state

        console.log(this.state.teachers, "this.state.teachers")


        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = this.state.teacherDairyData;
        payload.academicYear = academicYear
        payload.tctemplate = this.state.teachers
        const formStatus = isFormValid(TeacherDairyFields, this.formFields.formValidations, payload);
        let isCompleteFormValid = true
        teachers && teachers.map((each, index) => {
            if (each.title == '' || each.fieldType == '' || each.description == '') {
                this.setState({ dynamicFieldsError: `Please Add atleast One teacher Diary Template` });
                isCompleteFormValid = false;
            }
        })
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, }); 
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            console.log(payload, "payload", this.state.teachers)
            if (!this.props?.editData?._id) {
                let url = `${baseUrlAdmin}/teacher-template/create`
                this.service.post(url, payload, true).then(res => {
                    if (res.status) {
                        this.setState({
                            isLoading: false,
                            formData: {},
                            editDialog: false

                        }, () => { })
                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel()
                        this.props.getTemplatesData()


                    } else {
                        this.setState({ isLoading: false })

                        this.props.toastMessaget.show({ severity: 'error', summary: 'Some error occured', detail: 'Error', life: 3000 });

                    }

                }).catch(e => {
                    this.setState({ isLoading: false })
                })
            } else if (this.props?.editData?._id) {
                let url = `${baseUrlAdmin}/teacher-template/${this.props?.editData?._id}`;
                this.service.put(url, payload, true).then(res => {
                    if (res?.status) {
                        this.setState({
                            isLoading: false,
                            formData: {},
                            editDialog: false

                        })
                        this.props.toastMessage.show({ severity: 'success', summary: 'Updated successfully', detail: res?.res?.message, life: 3000 });

                        this.props.onCancel()
                        this.props.getTemplatesData()
                    } else {
                        this.setState({ isLoading: false })
                        this.props.toastMessage?.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });

                    }

                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.props.toastMessage?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating the fee type', life: 3000 });
                });

            }
        }

    }

    save = () => {
        //console.log(this.state.teachers,"ppppp")
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = { ...this.state.teacherDairyData, academicYear, tctemplate: this.state.teachers };

        // Initial validation status
        const formStatus = isFormValid(TeacherDairyFields, this.formFields.formValidations, payload);
        let isCompleteFormValid = formStatus.formValidations.isFormValid;
        let dynamicFieldsError = '';

        // Check each teacher object for empty fields
        this.state.teachers.forEach((each) => {
            if (each.title === '' || each.fieldType === '' || each.description === '') {
                dynamicFieldsError = 'Please add at least one Teacher Diary Template with all fields filled.';
                isCompleteFormValid = false;
            }
        });

        // Update the state with dynamic field errors and form validation status
        this.setState({ dynamicFieldsError, formValidations: formStatus.formValidations });

        // Proceed if the form is completely valid
        if (isCompleteFormValid) {
            console.log(payload, "payload", this.state.teachers);
            if (!this.props?.editData?._id) {
                let url = `${baseUrlAdmin}/teacher-template/create`;
                this.service.post(url, payload, true).then(res => {
                    if (res.status) {
                        this.setState({
                            isLoading: false,
                            formData: {},
                            editDialog: false
                        });
                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel();
                        this.props.getTemplatesData();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.toastMessage.show({ severity: 'error', summary: 'Some error occurred', detail: 'Error', life: 3000 });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                });
            } else if (this.props?.editData?._id) {
                let url = `${baseUrlAdmin}/teacher-template/${this.props?.editData?._id}`;
                this.service.put(url, payload, true).then(res => {
                    if (res?.status) {
                        this.setState({
                            isLoading: false,
                            formData: {},
                            editDialog: false
                        });
                        this.props.toastMessage.show({ severity: 'success', summary: 'Updated successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel();
                        this.props.getTemplatesData();
                    } else {
                        this.setState({ isLoading: false });
                        this.props.toastMessage?.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false });
                    this.props.toastMessage?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating the fee type', life: 3000 });
                });
            }
        }
    }



    componentDidMount() {



    }

    hasEmptyFields(teachers) {
        teachers && teachers.some(
            (teacher) => teacher.title == '' || teacher.fieldType == '' || teacher.description ==''
        );
    }

    render() {
        const { formValidations, teacherDairyData, teachers, rowCount, dynamicFieldsError } = this.state
        console.log(teachers, "teachersteachers", teachers.length)
        return (
            <>
                <div className="p-5">
                    <div className="grid">
                        <div className="col-3 mr-2">
                            <label className='label'>Teacher Dairy Title<span className='ma-required'>*</span></label>
                            <div className='mt-2'>
                                <ClassetInputText
                                    icon={<MailIcon height={24} />}
                                    id="tctitle"
                                    className='border-round-md   border-none '
                                    value={teacherDairyData.tctitle}
                                    onChange={(e) => { onTextChange(e.target.value, 'tctitle', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                    placeholder="Teacher Dairy Title" />
                            </div>
                            {formValidations && !formValidations.fields['tctitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['tctitle'].errorMsg}</p>}

                        </div>
                        <div className="col-6 ">
                            <label className='label'>Description<span className='ma-required'>*</span></label>
                            <div className='mt-2'>
                                <ClassetTextArea
                                    id="tcdescription"
                                    className='border-round-md   border-none '
                                    height={50}
                                    value={teacherDairyData.tcdescription}
                                    onChange={(e) => { onTextChange(e.target.value, 'tcdescription', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                    placeholder="Description" />

                            </div>
                            {formValidations && !formValidations.fields['tcdescription'].isValid && <p className="p-error text-lg ">{formValidations.fields['tcdescription'].errorMsg}</p>}

                        </div>


                    </div>
                    <div className="">
                        <div className="flex justify-content-between ">
                            <h3 className="teacherDairyHead">Teacher Dairy Format</h3>

                            <Button className="addButtonTeacher" onClick={this.save}>
                                <p >Save Dairy Format</p>
                            </Button>
                        </div>

                    </div>
                    {this.state.teachers?.map((teacher, index) => (
                        <div className="grid" key={index}>
                            <div className="col-3">
                                <label className='label'>Column {index + 1} Title<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetInputText
                                        icon={<MailIcon height={24} />}
                                        id="title"
                                        className='border-round-md   border-none '
                                        value={teacher?.title}
                                        onChange={(e) => this.handleTextChange(e.target.value, 'title', index)}

                                        // onChange={(e) => {
                                        //     onTextChange(e.target.value, 'title', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations')
                                        // }}
                                        placeholder={`Title of Column ${index + 1}`} />
                                </div>
                                {/* {formValidations && !formValidations.fields['title']?.isValid && <p className="p-error text-lg ">{formValidations.fields['title']?.errorMsg}</p>} */}

                            </div>
                            <div className="col-3">
                                <label className='label'>Field Type<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        icon={<MailIcon height={24} />}
                                        id="fieldType"
                                        className='border-round-md   border-none '
                                        value={teacher?.fieldType}
                                        options={fieldTypeoptions}
                                        onChange={(e) => this.handleDropdownChange(e.target.value, 'fieldType', index)}
                                        // onChange={(e) => { onDropDownChange(e.target.value, 'fieldType', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                        placeholder={
                                            <div>
                                                <span>
                                                    Field Type
                                                </span>
                                            </div>} />
                                </div>

                                {/* {formValidations && !formValidations.fields['fieldType']?.isValid && <p className="p-error text-lg ">{formValidations.fields['fieldType']?.errorMsg}</p>} */}

                            </div>
                            <div className="col-3 ">
                                <label className='label'>Description<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetTextArea
                                        id="description"
                                        className='border-round-md   border-none '
                                        height={50}
                                        value={teacher?.description}
                                        onChange={(e) => this.handleTextChange(e.target.value, 'description', index)}
                                        // onChange={(e) => { onTextChange(e.target.value, 'description', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                        placeholder="Description" />

                                </div>

                                {/* {formValidations && !formValidations.fields['description']?.isValid && <p className="p-error text-lg ">{formValidations.fields['description']?.errorMsg}</p>} */}

                            </div>
                        </div>
                    ))}
                    {/* <Button style={{ borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', }} onClick={this.addTeacher} className='addColumnButton ml-4'
                    // disabled={! teacherDairyData?.evary(each=>(each.title && each.fieldType && each.description))}

                    >
                        <p >Add Column</p>
                    </Button> */}
                    {((this.hasEmptyFields(teachers) || teachers.length >= 1)) && <p className="p-error text-lg my-2">{dynamicFieldsError}</p>}

                    <div className="flex justify-content-start align-content-start ">


                        <Button className="addColumnButton" onClick={this.addTeacherRow} style={{ marginRight: '10px' }} >
                            <p >Add Column</p>
                        </Button>

                        <Button className="addColumnButton" onClick={this.removeTeacherRow} disabled={rowCount <= 1}>
                            <p >Remove Column</p>
                        </Button>
                    </div>
                </div>
                {/* {
                    (this.state.isLoading &&
                        <LoadingComponent />
                    )
                } */}
            </>
        )
    }

}
export default TeacherDairyFormat