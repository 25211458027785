import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onTextChange, onDropDownChange } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Button } from 'primereact/button';
import Service from './../../services';
import { Toast } from 'primereact/toast';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { FileAttachmentIcon, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import FileUpload from '../../fileUpload';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { trimObj } from '../../../utile';

class EditStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffInfo: this.props.editData,
            isLoading: false
        };
        this.service = new Service();
    }

    _onProgress = (filesInfo) => {};
    _onProgress1 = (filesInfo) => {};

    _afterFilesUploaded = (files) => {};

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onFilsUploadError1 = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onLicenceFrontPhotoSuccess = (fileName, data) => {
        this.addAttachment('licenceFrontPhoto', fileName, data);
    };
    _afterLicenceFilesUploaded = (files) => {};

    _onLicenceBackPhototPhotoSuccess = (fileName, data) => {
        this.addAttachment('licenceBackPhoto', fileName, data);
    };
    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => ({
            staffInfo: {
                ...prevState.staffInfo,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };
    updateStaff = () => {
        this.setState({
            isLoading: true
        });
        const { staffInfo } = this.state;

        let isCompleteFormValid = true;

        const requiredFields = [
            { key: 'licenceNo', message: 'Licence No is required' },
            { key: 'licenceExpiryDate', message: 'Licence expiry Date is required' },
            { key: 'licenceType', message: 'Licence Type is required' },
            { key: 'licenceBackPhoto', message: 'Licence Back Photo is required' },
            { key: 'licenceFrontPhoto', message: 'Licence Front Photo is required' },
            { key: 'address', message: 'Address is required' }
        ];

        let missingFields = [];

        // Check for missing fields if designation is DRIVER
        if (staffInfo?.designation === 'DRIVER') {
            for (const field of requiredFields) {
                if (!staffInfo?.[field.key]) {
                    missingFields.push(field.message);
                }
            }

            if (missingFields.length === requiredFields.length) {
                // All required fields are missing
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'All fields are required', life: 3000 });
                return;
            } else if (missingFields.length > 0) {
                // Show specific missing fields
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: missingFields.join(', '), life: 3000 });
                return;
            }
        }
        if (isCompleteFormValid) {
            let url = `${baseUrlAdmin}/transport-staff`;
            const academicYear = localStorage.getItem('userAcademicYear');
            this.service
                .put(url, staffInfo, true)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                        this.setState(
                            {
                                isLoading: false
                            },
                            () => this.props.onCancel()
                        );
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });
                });
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };
    render() {
        const { staffInfo } = this.state;
        return (
            <>
                <div className="grid mt-2 md:col-11 lg:col-11 column-gap-8 row-gap-5 mx-5">
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            First Name<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                // id="title"
                                // width={250}
                                className="text-xl add-vehicle-field-input w-full"
                                value={staffInfo.firstName}
                                placeholder="Enter First Name"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Date of Birth<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md add-vehicle-field-input text-xl w-full"
                                // width={250}
                                calendarMode={'single'}
                                value={staffInfo.dateOfBirth && new Date(staffInfo.dateOfBirth)}
                                placeholder="Select DOB"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Mobile Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                // width={250}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                className="text-xl add-vehicle-field-input w-full"
                                value={staffInfo.mobileNo}
                                placeholder="Enter Mobile Number"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ height: '2px', backgroundColor: '#B1B1B1' }} className='my-2'></div>
                <div className="grid mt-2 md:col-11 lg:col-11 column-gap-8 row-gap-5 mx-5">
                   
                    {this.state.staffInfo?.designation === 'DRIVER' && (
                        <>
                            <div className="col-span-1 ">
                                <p className="transport-fee-form-label">
                                    Licence No<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <ClassetInputText
                                        // width={250}
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        className="text-xl add-vehicle-field-input w-full"
                                        value={staffInfo.licenceNo}
                                        onChange={(e) => {
                                            this.setState((prevState) => ({ staffInfo: { ...prevState.staffInfo, licenceNo: e.target.value } }));
                                        }}
                                        placeholder="Enter Licence No"
                                    />
                                </div>
                            </div>
                            <div className="col-span-1 ">
                                <p className="transport-fee-form-label">
                                    Licence Expiry Date<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <ClassetCalendar
                                        // width={250}
                                        calendarMode={'single'}
                                        // icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        className="text-xl add-vehicle-field-input w-full"
                                        value={staffInfo.licenceExpiryDate && new Date(staffInfo.licenceExpiryDate)}
                                        onChange={(e) => {
                                            this.setState((prevState) => ({ staffInfo: { ...prevState.staffInfo, licenceExpiryDate: new Date(e.target.value) } }));
                                        }}
                                        placeholder="Enter Licence Expiry Date"
                                    />
                                </div>
                            </div>

                            <div className="col-span-1">
                                <p className="transport-fee-form-label">
                                    Upload Licence Front Photo<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <FileUpload
                                        id={'licenceFrontPhoto'}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        onSucess={this._onLicenceFrontPhotoSuccess}
                                        //  Reset={this.state.Reset}
                                        maxFileSize={307200}
                                        onError={this._onFilsUploadError}
                                        accept={'images'}
                                        title={'Images'}
                                        afterFilesUploaded={this._afterFilesUploaded}
                                        className="border-solid"
                                    >
                                        {' '}
                                        <ClassetInputText
                                            // width={250}
                                            icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                            className="text-xl add-vehicle-field-input w-full"
                                            value={staffInfo.licenceFrontPhoto?.fileName}
                                            placeholder="Upload Photo"
                                            disabled={true}
                                        />
                                    </FileUpload>
                                    {/*   */}
                                </div>
                            </div>
                            <div className="col-span-1">
                                <p className="transport-fee-form-label">
                                    Upload Licence Back Photo<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <FileUpload
                                        id={'licenceBackPhoto'}
                                        multiple={false}
                                        onProgress={this._onProgress1}
                                        onSucess={this._onLicenceBackPhototPhotoSuccess}
                                        //  Reset={this.state.Reset}
                                        maxFileSize={307200}
                                        onError={this._onFilsUploadError1}
                                        accept={'images'}
                                        title={'Images'}
                                        afterFilesUploaded={this._afterLicenceFilesUploaded}
                                        className="border-solid"
                                    >
                                        {' '}
                                        <ClassetInputText
                                            // width={250}
                                            icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                            className="add-vehicle-field-input w-full "
                                            value={staffInfo.licenceBackPhoto?.fileName}
                                            placeholder="Upload Photo"
                                            disabled={true}
                                        />
                                    </FileUpload>
                                    {/*   */}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-span-1">
                        <p className="transport-fee-form-label">
                            Address<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetTextArea
                                placeholder="Address"
                                className=""
                                width={550}
                                value={staffInfo.address}
                                onChange={(e) => {
                                    this.setState((prevState) => ({ staffInfo: { ...prevState.staffInfo, address: e.target.value } }));
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center mb-4 gap-3 mt-8">
                    <Button label={this.props.editData ? 'Update Staff' : 'Add Staff'} className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={() => this.updateStaff()} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={this.props.onCancel} />
                </div>
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        []
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(withRouter(EditStaff));
