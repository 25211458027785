import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { dispatchRequestUpdateSubject, dispatchReceivedUpdateSubject,dispatchErrorUpdateSubject} from './../actions/updateSubjectActions';
import uuidv4 from 'uuid/v4';
import { baseUrlAdmin } from '../apiConstants';

const dispatchRequestUpdateChapter = data => ({
    type: ActionTypes.REQUEST_UPDATE_CHAPTER,
    data
})

const dispatchReceivedUpdateChapter = res => ({
    type: ActionTypes.RECEIVED_UPDATE_CHAPTER,
    res
})

const dispatchErrorUpdateChapter = err => ({
    type: ActionTypes.ERROR_UPDATE_CHAPTER,
    err
})

const dispatchClearUpdateChapter = () => ({
    type: ActionTypes.CLEAR_UPDATE_CHAPTER_DATA
})

export const clearUpdateChapterData = () => dispatchClearUpdateChapter();


export const updateChapter = (chapterId, chapterName, chapterDescription, method, chapterNumber, selectedChapterLongDescription, chapterCode, isChapterActive ) => (dispatch, getState) => {

  const selectedOrganizationDataIds = getState().selectedOrganizationDataIds;
  const curriculumData = getState().curriculumData;
  const { boardId, classId, groupId } = selectedOrganizationDataIds;

  const etag = getState().organizationData && getState().organizationData.etag;
  let _subjects = curriculumData && curriculumData.response && curriculumData.response.Item && curriculumData.response.Item.subjects;

  const subjectData = curriculumData && curriculumData.subjectInfo && curriculumData.subjectInfo.length && curriculumData.subjectInfo[0];
  const subjectId = subjectData && subjectData.subjectId
  const _chapters = subjectData && subjectData.chapters || [];    
   
  let newSubjects = [];

  const updateChapterWithSubjectPost = () => {
    _subjects.map(sub => {
        if(sub.subjectId === subjectId) {             
          const chapters = [];
          sub.chapters && sub.chapters.length && 
              sub.chapters.map(chapter => {
                 const _topics = chapter.topics.find(x => x.isDefault);
                 const otherTopics = chapter.topics.filter(x => !x.isDefault)
                  if(chapter.chapterId === chapterId) {
                      if(_topics.isDefault){
                          chapters.push({
                              ...chapter,
                              chapterName,
                              chapterDescription,
                              chapterLongDescription: selectedChapterLongDescription,
                              chapterCode,
                              chapterNumber,
                              isChapterActive, 
                              topics:[
                                ...otherTopics,
                                {
                                topicCode: chapterCode,
                                topicId: _topics.topicId,
                                topicName: chapterName,
                                isDefault: true     
                                }
                              ]
                          })
                      }else{
                        chapters.push({
                            ...chapter,
                            chapterName,
                            chapterDescription,
                            chapterLongDescription: selectedChapterLongDescription,
                            chapterCode,
                            chapterNumber,
                            isChapterActive
                        })
                      }
                     
                  }else{
                      chapters.push(chapter);
                  }
              })
              newSubjects.push({
                  ...sub,
                  chapters
              })
        }else{
            newSubjects.push(sub);
        }
  
    })

    const subjectData = {
        boardId,
        classId,
        groupId,
        subjects: newSubjects
      }
    if(newSubjects && newSubjects.length) {        
        dispatch(dispatchRequestUpdateSubject());
        axios({
            method: 'post',
            url:  `${baseUrlAdmin}/subject`,
            data: subjectData,
        }).then(({data}) => {

            dispatch(dispatchReceivedUpdateSubject(data));
        }).catch(e => {
            dispatch(dispatchErrorUpdateSubject(e));
        });
    }
  }
  
  const deleteChapterWithSubjectPost = () => {
    _subjects.map(sub => {
        if(sub.subjectId === subjectId) {             
          const chapters = [];
          sub.chapters && sub.chapters.length && 
              sub.chapters.map(chapter => {
                  if(chapter.chapterId === chapterId) {
                  }else{
                      chapters.push(chapter);
                  }
              })
              newSubjects.push({
                  ...sub,
                  chapters
              })
        }else{
            newSubjects.push(sub);
        }
  
    })

    
    const subjectData = {
        boardId,
        classId,
        groupId,
        subjects: newSubjects
    }
 
   
    if(newSubjects && newSubjects.length) {        
        dispatch(dispatchRequestUpdateSubject());
        axios({
            method: 'post',
            url:  `${baseUrlAdmin}/subject`,
            data: subjectData,
        }).then(({data}) => {
            dispatch(dispatchReceivedUpdateSubject(data));
        }).catch(e => {
            dispatch(dispatchErrorUpdateSubject(e));
        });
    }
  }

  const addChapterWithSubjectPost = () => {
   const chapter= {
        chapterId,
        chapterName,
        chapterDescription,
        chapterNumber,  
        chapterLongDescription: selectedChapterLongDescription, 
        chapterCode,
        isChapterActive,
        topics: [{
            topicCode: chapterCode,
            topicId: uuidv4(),
            topicName: chapterName,
            isDefault: true
        }]
    }

    _subjects.map(sub => {
        if(sub.subjectId === subjectId) {             
          let chapters;
          if(sub.chapters && sub.chapters.length) {
              chapters = [...sub.chapters, chapter];
              newSubjects.push({
                  ...sub,
                  chapters
              })
          }else{
              newSubjects.push({
                  ...sub,
                  chapters: [{...chapter}]
              })
          }
        }else{
            newSubjects.push(sub);
        }
  
    })

    const subjectData = {
        boardId,
        classId,
        groupId,
        subjects: newSubjects
    }

    if(newSubjects && newSubjects.length) {        
        dispatch(dispatchRequestUpdateSubject());
        axios({
            method: 'post',
            url:  `${baseUrlAdmin}/subject`,
            data: subjectData,
        }).then(({data}) => {
            dispatch(dispatchReceivedUpdateSubject(data));
        }).catch(e => {
            dispatch(dispatchErrorUpdateSubject(e));
        });
    }
  }

    let chapters = [];
    switch(method) {
      case 'updateChapter':
      updateChapterWithSubjectPost();
      return;
      if(_chapters && _chapters.length) {        
        _chapters.map(chapter => {
          if(chapter.chapterId === chapterId) {
            chapters.push({...chapter, chapterName, chapterDescription})
          }else{
            chapters.push(chapter);
          }
        } )    
      }else{
        chapters.push({
          chapterId,
          chapterName,
          chapterDescription,
          topics: []
        })
      }
      break;
      case 'addChapter':  
      addChapterWithSubjectPost();
      return;
        chapters=[..._chapters, {
          chapterId,
          chapterName,
          chapterDescription,
          chapterNumber,
          topics: []
        }]
      break;
      case 'deleteChapter':
        deleteChapterWithSubjectPost();
      break;
      default: return
    }


    const _data = {
        boardId, 
        classId, 
        groupId,
        subjectId,
        chapters
    }
    dispatch(dispatchRequestUpdateChapter()); 
}


