export const QuestionTargets = [{ id: 'Normal', value: 'Normal' },
    { id: 'Adhoc', value: 'Adhoc' }]
    
    export const QuestionTypeKeys = { SCCT: 'SCCT', MCCT: 'MCCT', INT: 'INT', NUM: 'NUM', FB: 'FB', PSGS: 'PSGS', PSGM: 'PSGM', DESC: 'DESC', PQS: 'PQS', PQM: 'PQM' };
    export const QuestionTypeKeysWithAQ = { SCCT: 'SCCT', MCCT: 'MCCT', INT: 'INT', NUM: 'NUM', FB: 'FB', PSGS: 'PSGS', PSGM: 'PSGM', DESC: 'DESC', PQS: 'PQS', PQM: 'PQM', VSAQ: 'VSAQ', SAQ: 'SAQ', LAQ: 'LAQ' };
    
    export const QuestionTypes = [{
        value: 'SCCT',
        label: 'SCCT'
    },
    {
        value: 'MCCT',
        label: 'MCCT'
    },
    {
        value: 'INT',
        label: 'INT'
    },
    {
        value: 'NUM',
        label: 'NUM'
    },
    {
        value: 'FB',
        label: 'FB'
    },
    {
        value: 'PSGS',
        label: 'PSGS'
    },
    {
        value: 'PSGM',
        label: 'PSGM'
    }
    ];
    
    export const QuestionTypesWithAQ = [{
        value: 'SCCT',
        label: 'SCCT'
    },
    {
        value: 'MCCT',
        label: 'MCCT'
    },
    {
        value: 'INT',
        label: 'INT'
    },
    {
        value: 'NUM',
        label: 'NUM'
    },
    {
        value: 'FB',
        label: 'FB'
    },
    {
        value: 'PSGS',
        label: 'PSGS'
    },
    {
        value: 'PSGM',
        label: 'PSGM'
    },
    {
        value: 'VSAQ',
        label: 'VSAQ'
    },
    {
        value: 'SAQ',
        label: 'SAQ'
    },
    {
        value: 'LAQ',
        label: 'LAQ'
    }
    ];
    
    
    
    export const Difficulty = [{
        label: "Easy",
        value: "Easy"
    },
    {
        label: "Medium",
        value: "Medium"
    },
    {
        label: "Hard",
        value: "Hard"
    }
    ]
    
    export const SkillTypes = [{
        label: 'Critical Thinking',
        value: 'Critical Thinking'
    },
    {
        label: 'Problem Solving',
        value: 'Problem Solving'
    },
    {
        label: 'Analytical Thinking',
        value: 'Analytical Thinking'
    },
    {
        label: 'Conceptual Clarity',
        value: 'Conceptual Clarity'
    },
    {
        label: 'Knowledge',
        value: 'Knowledge'
    },
    {
        label: 'Observation',
        value: 'Observation'
    },
    {
        label: 'Understanding',
        value: 'Understanding'
    },
    ]
    
    export const Status = [{
        label: 'In Review',
        value: 'In Review'
    },
    {
        label: 'Created',
        value: 'Created'
    }
    ]
    
    
    export const ExamTypes = [{
        value: 'JEE Advanced',
        label: 'JEE Advanced'
    },
    {
        value: 'JEE Mains',
        label: 'JEE Mains'
    },
    {
        value: 'NEET',
        label: 'NEET'
    },
    {
        value: 'EAMCET(ENGG)',
        label: 'EAMCET(ENGG)'
    },
    {
        value: 'EAMCET(MEDIC)',
        label: 'EAMCET(MEDIC)'
    },
    {
        value: 'AFMC',
        label: 'AFMC'
    },
    {
        value: 'JIPMER',
        label: 'JIPMER'
    },
    {
        value: 'NTSE',
        label: 'NTSE'
    },
    {
        value: 'KVPY',
        label: 'KVPY'
    },
    {
        value: 'OLYMPIAD',
        label: 'OLYMPIAD'
    }
    
    
    ];
    export const QuestionSource = [{
        value: 'Meluha',
        label: 'Meluha'
    },
    {
        value: 'Gayathri',
        label: 'Gayathri'
    },
    {
        value: 'Engineering Physics, Malik and Singh, tata Mc Graw Hill',
        label: 'Engineering Physics, Malik and Singh, tata Mc Graw Hill'
    },
    {
        value: 'Engineering Physics, Naidu, Pearson',
        label: 'Engineering Physics, Naidu, Pearson'
    },
    {
        value: 'Mechanics, Mathur, S.Chand Publishing',
        label: 'Mechanics, Mathur, S.Chand Publishing'
    },
    {
        value: 'Classical Mechanics, Upadhyaya, Himalaya Publishing House',
        label: 'Classical Mechanics, Upadhyaya, Himalaya Publishing House'
    },
    {
        value: 'Classical Mechanics, G.Aruldhas, PHI',
        label: 'Classical Mechanics, G.Aruldhas, PHI'
    },
    {
        value: 'Engineering Chemistry, Satyaprakesh & Manisha Agrawal, khanna Book Publishing, Delhi',
        label: 'Engineering Chemistry, Satyaprakesh & Manisha Agrawal, khanna Book Publishing, Delhi'
    },
    {
        value: 'A Text Book Of Engg. Chemistry, Sashi Chawala, Dhanapat Rai & Co.(p)Ltd',
        label: 'A Text Book Of Engg. Chemistry, Sashi Chawala, Dhanapat Rai & Co.(p)Ltd'
    },
    {
        value: 'Essentials of Physical Chemistry, Bahi & Tuli, S.Chand publishing',
        label: 'Essentials of Physical Chemistry, Bahi & Tuli, S.Chand publishing'
    },
    {
        value: 'Applied Chemistry, Sunita Rattan, Kataria',
        label: 'Applied Chemistry, Sunita Rattan, Kataria'
    },
    {
        value: 'Engineering Chemistry, Baskar, Wiley',
        label: 'Engineering Chemistry, Baskar, Wiley'
    },
    {
        value: 'Advanced Engineering Mathematics, Chandrika Prasad & Reena Garg, Khanna Book Publishing Co.(p)Ltd., Delhi (ISBN:9789386173522)',
        label: 'Advanced Engineering Mathematics, Chandrika Prasad & Reena Garg, Khanna Book Publishing Co.(p)Ltd., Delhi (ISBN:9789386173522)'
    },
    {
        value: 'Engineering Mathematics for first year, veeraranjan T., Tata McGraw-Hill',
        label: 'Engineering Mathematics for first year, veeraranjan T., Tata McGraw-Hill'
    },
    {
        value: 'Higher Engineering Mathematics, Ramana B.V., Tata Mc Graw',
        label: 'Higher Engineering Mathematics, Ramana B.V., Tata Mc Graw'
    },
    {
        value: 'Advanced Engineering Mathematics,Chandrika Prasad & Reena Garg, Khanna Book Publishing',
        label: 'Advanced Engineering Mathematics,Chandrika Prasad & Reena Garg, Khanna Book Publishing'
    },
    {
        value: 'Arihant 43 Years Chapter wise IIT JEE Solved Papers',
        label: 'Arihant 43 Years Chapter wise IIT JEE Solved Papers'
    },
    {
        value: 'Complete Mathematics (JEE ADAVANCED) by TATA McGRAW HILL',
        label: 'Complete Mathematics (JEE ADAVANCED) by TATA McGRAW HILL'
    },
    {
        value: 'Comprehensive Chemistry (JEE ADAVANCED) 2019 by TATA McGRAW HILL',
        label: 'Comprehensive Chemistry (JEE ADAVANCED) 2019 by TATA McGRAW HILL'
    },
    {
        value: 'Comprehensive Mathematics (JEE ADAVANCED) 2019 by TATA McGRAW HILL',
        label: 'Comprehensive Mathematics (JEE ADAVANCED) 2019 by TATA McGRAW HILL'
    },
    {
        value: 'Comprehensive Physics (JEE ADAVANCED) 2019 by TATA McGRAW HILL',
        label: 'Comprehensive Physics (JEE ADAVANCED) 2019 by TATA McGRAW HILL'
    },
    {
        value: '33 Years of NEET Solved papers (1988-2020) by Disha',
        label: '33 Years of NEET Solved papers (1988-2020) by Disha'
    },
    {
        value: 'Arihant Biology Vol 1 Master the NCERT',
        label: 'Arihant Biology Vol 1 Master the NCERT'
    },
    {
        value: 'Arihant Biology Vol 2 Master the NCERT',
        label: 'Arihant Biology Vol 2 Master the NCERT'
    },
    {
        value: 'Arihant Chemistry Vol 1 Master the NCERT',
        label: 'Arihant Chemistry Vol 1 Master the NCERT'
    },
    {
        value: 'Arihant Chemistry Vol 2 Master the NCERT',
        label: 'Arihant Chemistry Vol 2 Master the NCERT'
    },
    {
        value: 'Arihant Physics Vol 1 Master the NCERT',
        label: 'Arihant Physics Vol 1 Master the NCERT'
    },
    {
        value: 'Arihant Physics Vol 2 Master the NCERT',
        label: 'Arihant Physics Vol 2 Master the NCERT'
    },
    {
        value: 'Arihant Skills in Mathematics',
        label: 'Arihant Skills in Mathematics'
    },
    {
        value: 'Kshetra Foundation Material',
        label: 'Kshetra Foundation Material'
    },
    {
        value: 'Objective NCERT Extract by DISHA',
        label: 'Objective NCERT Extract by DISHA'
    },
    {
        value: 'Cengage Learning - Exam crack series',
        label: 'Cengage Learning - Exam crack series'
    },
    {
        value: 'Pearson Publications',
        label: 'Pearson Publications'
    },
    {
        value: 'Wiley JEE Mains Practice Problems',
        label: 'Wiley JEE Mains Practice Problems'
    },{
        value: 'GRB Publications',
        label: 'GRB Publications'
    },{
        value: 'Universal Book Depot',
        label: 'Universal Book Depot'
    },{
        value: 'Prabhat Paperbacks',
        label: 'Prabhat Paperbacks'
    },
    {
        value: 'Modern ABC',
        label: 'Modern ABC'
    },
    
    ]
    