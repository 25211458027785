import React, { Component } from 'react';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, convertUtc, trimObj, warningDailogInit } from '../../../utile';
import _, { cloneDeep } from 'lodash';
import { AddIcon2, AddIconInButtonFull, AssignFormIcon, BackArrowIcon, BoardsIconInActive, CalenderIcon, ClassetEditIcon, DeleteIconClasset, FormatsIcon, InfoIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin } from '../../../store/apiConstants';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { InputSwitch } from 'primereact/inputswitch';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import moment from 'moment';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { getBranchesLatest } from '../../../store/actions';
import { connect } from 'react-redux';

const autoApprovalTimes = [{ value: 4, label: '4 hours' }, { value: 8, label: '8 hours' }, { value: 12, label: '12 hours' }, { value: 24, label: '24 hours' }, ,];

class SettingsApprovalsAssignTransport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approvals: [],
            formData: {},
            lazyParams: BasicLazyParams,
            isDialogOpenForCreate: false,
            editDialog: false,
            totalRecords: 0,
            createdDate: new Date(),
            dropdownsData: null,
            expDate: new Date(new Date().setFullYear(new Date().getFullYear() + 4)),
            selectedApprovalTime: 0,
            dropdownsData: null,
            approvalDataUser: {},
            levels: [{}]
        };

        this.service = new Service();
    }
    formatDateE = (rowData) => {
        return <p> {moment(rowData['expiredDate']).format('MM/DD/YYYY')}</p>;
    };
    formatDateC = (rowData) => {
        return <p> {moment(rowData['createdDate']).format('MM/DD/YYYY')}</p>;
    };

    getApprovals = async () => {
        this.setState({ isLoading: true });
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/settings-approvals?approvalType=assignTransport&limit=${lazyParams.rows}&page=${lazyParams.page + 1}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({
                    approvals: res?.res?.data?.data,
                    totalRecords: res?.res?.data?.totalRecords,
                    isLoading: false
                });
            } else {
                const error = 'Some error occurred';
                this.setState({ error, isLoading: false, approvals: [] });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ e, isLoading: false, approvals: [] });
        }
    };

    getRoles = () => {
        this.setState({
            isLoading: true
        });
        let lazyParams = this.state.lazyParams;
        let globalSearch = this.state.globalSearch;
        let url = `${baseUrlAdmin}/authorize/getRoles?limit=${100000}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.res && res.res.data) {
                    this.setState({
                        roles: res.res.data,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
            });
    };

    getUsersApi = async () => {
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/admin/getAllUserAdmin?limit=100000&page=1&search=`;
        try {
            const res = await this.service.get(url, true);
            if (res.status) {
                this.setState({
                    usersList: res?.res?.userData?.data,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            // this.toast.show({ severity: 'error', summary: 'Error', detail: '', life: 3000 });
        }
    };

    componentDidMount() {
        this.getApprovals();
        this.getRoles();
        this.getUsersApi();
        this.props.getBranchesLatest();
    }

    handleOpenCreate = () => {
        this.setState({ isDialogOpenForCreate: true });
    };

    onInfo = (rowData) => {
        let allLevelUsers = rowData?.levels?.map((level) => level.users).flat();
        this.setState({ allLevelUsers: allLevelUsers, isShowUsersList: true, selectedApprover: rowData });
    };

    onEditClick = (rowData) => {
        const { levels } = rowData;

        const updatedLevels = levels.map((level) => ({
            ...level,
            users: level?.users?.map((user) => user?.userId),
            ddUsers: this.state.usersList?.filter((user) => user?.user_info?.roles?.some((role) => level?.roles?.includes(role)))
        }));

        if (updatedLevels?.length) {
            this.setState({
                isDialogOpenForCreate: true,
                edit: true,
                expDate: new Date(rowData.expiredDate),
                branchId: rowData?.branchId,
                selectedbranchName: rowData?.selectedbranchName,
                levels: updatedLevels,
                editData: rowData
            });
        }
    };

    openAutoApprovalDialog = (rowData) => {
        this.setState({ isDialogOpenForAutoApproval: true, approvalDataUser: rowData });
    };

    cancelAutoApproval = (rowData) => {
        this.setState({ approvalDataUser: rowData }, () => this.autoApprovalRole(false));
    };
    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getApprovals);
    };

    onSubmitClick = () => {
        let flag = true;

        let levels = cloneDeep(this.state.levels);

        if (this.state.branchId) {
            levels?.forEach((level, index) => {
                if (level.users?.length) {
                    level.users = level?.users
                        ?.map((e) => level.ddUsers?.find((u) => u.user_id == e))
                        ?.map((e) => {
                            delete level.ddUsers;
                            return { userName: e.username, userId: e.user_id, status: true, level: index + 1 };
                        });
                    level.level = index + 1;
                } else {
                    flag = false;
                }
            });
        } else {
            flag = false;
        }

        if (flag) {
            let bodyForApi = {
                type: 'assignTransport',
                expiredDate: convertUtc(this.state.expDate),
                branchId: this.state.branchId,
                selectedbranchName: this.state.selectedbranchName,
                levels: levels,
                autoApproval: {
                    status: false,
                    approvalHours: 0
                }
            };
            let url = `${baseUrlAdmin}/settings-approvals/`;
            if (this.state.edit) {
                bodyForApi._id = this.state.editData?._id;
                url = `${baseUrlAdmin}/settings-approvals/update`;
            }
            this.setState({
                isLoading: true
            });
            this.service
                .post(url, bodyForApi, true)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState(
                            {
                                isLoading: false,
                                aprovalData: {},
                                expDate: new Date(new Date().setFullYear(new Date().getFullYear() + 4)),
                                createdDate: new Date(),
                                isDialogOpenForCreate: false,
                                edit: false,
                                levels: [{}],
                                branchId: null,
                                selectedbranchName: ''
                            },
                            () => this.getApprovals()
                        );
                        this.toast.show({
                            severity: 'success',
                            summary: 'Approval Data Saved Successfully',
                            life: 3000
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    console.log(err, 'err');
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
                });
        } else {
            this.toast.show({ severity: 'error', summary: 'Please Select Required Fields', life: 3000 });
        }
    };

    onChangeRole = (selectedRoles, levelIndex) => {
        let userssList = this.state.usersList?.filter((user) => user?.user_info?.roles?.some((role) => selectedRoles.includes(role)));
        let levels = [...this.state.levels];
        levels[levelIndex].ddUsers = userssList;
        levels[levelIndex].roles = selectedRoles;
        this.setState({
            users: userssList,
            levels: levels
        });
    };

    onChangeUser = (selectedUsers, levelIndex) => {
        let levels = [...this.state.levels];

        const userExistsInLevels = selectedUsers?.some((selectedUser) => levels?.some((level, index) => (levelIndex == index ? false : level?.users?.includes(selectedUser))));

        if (userExistsInLevels) {
            this.toast?.show({ severity: 'error', summary: 'Some Selected Users Exist In Another Level', detail: '', life: 5000 });
        } else {
            levels[levelIndex].users = selectedUsers;
            this.setState({
                levels: levels
            });
        }
    };

    autoApprovalRole = (status) => {
        let body = {
            _id: this.state.approvalDataUser?._id,
            autoApproval: {
                status: status,
                approvalHours: this.state.selectedApprovalTime
            }
        };
        let url = `${baseUrlAdmin}/settings-approvals`;
        this.service
            .put(url, body, true)
            .then((res) => {
                if (res?.status) {
                    this.toast?.show({ severity: 'info', summary: 'Success', detail: ' Updated Successfully', life: 3000 });
                    this.setState({ isDialogOpenForAutoApproval: false, selectedApprovalTime: 0 }, () => this.getApprovals());
                } else {
                    this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
                    this.setState({ isDialogOpenForAutoApproval: false, selectedApprovalTime: 0 }, () => this.getApprovals());
                }
            })
            .catch((err) => {
                this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 5000 });
            });
    };

    changeUserStatus = (rowData, roleData) => {
        let body = {
            _id: this.state.selectedApprover._id,
            userData: rowData
        };
        let url = `${baseUrlAdmin}/settings-approvals/change-user-status`;
        this.setState({ isLoading: true });
        this.service
            .put(url, body, true)
            .then((res) => {
                if (res?.status) {
                    let data = cloneDeep(this.state.allLevelUsers);
                    data?.forEach((element) => {
                        if (element.userId === rowData.userId) {
                            element.status = !rowData.status;
                        }
                    });
                    this.setState({ isLoading: false, allLevelUsers: data }, () => {
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: ' Updated Successfully', life: 3000 });
                    });
                } else {
                    this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
                    this.setState({ isDialogOpenForAutoApproval: false, selectedApprovalTime: 0, isLoading: false }, () => this.getApprovals());
                }
            })
            .catch((err) => {
                this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
                this.setState({ isDialogOpenForAutoApproval: false, selectedApprovalTime: 0, isLoading: false }, () => this.getApprovals());
            });
    };

    onChangeBranch = (branchId) => {
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId)?.name;
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName
        });
    };

    addLevels = (i) => {
        let canCreateNewLevel = true;
        this.state.levels?.map((level) => {
            if (!level?.users?.length) {
                canCreateNewLevel = false;
            }
        });
        if (canCreateNewLevel) {
            this.setState((prevState) => {
                const newLevels = [...prevState.levels];
                newLevels.splice(i + 1, 0, {});
                return {
                    levels: newLevels
                };
            });
        }
    };

    removeLevels = (i) => {
        if (this.state.levels.length > 1) {
            const filterAssignObjs = this.state.levels.filter((_, index) => index !== i);
            this.setState({ levels: filterAssignObjs });
        }
    };
    render() {
        const { formValidations, aprovalData } = this.state;
        return (
            <>
                <div className="admin-management">
                    <div className="grid mb-6 mt-2">
                        <Authorizer permId={PERMISSIONS.CREATE_STATUS_CHANGE_APPROVAL}>
                            {this.state.isShowUsersList != true && (
                                <div className="col-12 flex justify-content-end align-items-center">
                                    <Button onClick={this.handleOpenCreate} className="addButtonn">
                                        <AddIcon2 />
                                        <p className="">Create Approver</p>
                                    </Button>
                                </div>
                            )}
                        </Authorizer>
                        {this.state.isShowUsersList == true && (
                            <div className="col-12 flex justify-content-end align-items-center">
                                <Button onClick={() => this.setState({ isShowUsersList: false }, () => this.getApprovals())} className="addButtonn">
                                    <BackArrowIcon />
                                    <p className="">Back</p>
                                </Button>
                            </div>
                        )}
                    </div>
                    {this.state.isShowUsersList != true && (
                        <div className="ma-m30">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.approvals}
                                dataKey="_id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} aprovalData"
                                globalFilter={this.state.globalFilter}
                                responsiveLayout="scroll"
                            >
                                <Column
                                    header="S.No."
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <p>{index.rowIndex + 1}</p>
                                            </>
                                        );
                                    }}
                                />
                                {/* <Column field="roleName" header="Name of the Role" className="datatable-cel" /> */}
                                <Column className="datatable-cel" field="selectedbranchName" filterField="selectedbranchName" header="Branch Name" />

                                <Column className="datatable-cel" field="createdDate" body={this.formatDateC} filterField="createdDate" header="Created On" />
                                <Column className="datatable-cel" field="expiredDate" header="Expiry On" body={this.formatDateE} />
                                <Column
                                    header="Total Levels"
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <div>
                                                <div key={index}>{rowData?.levels?.length}</div>
                                            </div>
                                        );
                                    }}
                                />
                                <Column
                                    className="datatable-cel"
                                    header="Auto approval"
                                    body={(rowData, { rowIndex }) => {
                                        return (
                                            <>
                                                <div className="flex">
                                                    <InputSwitch
                                                        onChange={(e) => {
                                                            rowData?.autoApproval?.status ? this.cancelAutoApproval(rowData) : this.openAutoApprovalDialog(rowData);
                                                        }}
                                                        checked={rowData?.autoApproval?.status}
                                                    />
                                                </div>
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    className="datatable-cel"
                                    header="Action"
                                    body={(rowData, { rowIndex }) => {
                                        return (
                                            <>
                                                <div className="flex ">
                                                    <Authorizer permId={PERMISSIONS.UPDATE_STATUS_CHANGE_APPROVAL}>
                                                        <span
                                                            data-pr-tooltip="Edit aprovalData"
                                                            data-pr-position=""
                                                            className={`ma-ml10 awl${rowIndex} `}
                                                            onClick={() => {
                                                                this.onEditClick(rowData);
                                                            }}
                                                        >
                                                            <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                        </span>
                                                    </Authorizer>
                                                    <Authorizer permId={PERMISSIONS.UPDATE_STATUS_CHANGE_APPROVAL}>
                                                        <span
                                                            data-pr-tooltip="Info aprovalData"
                                                            data-pr-position=""
                                                            className={`ma-ml10 awl${rowIndex} ml-2`}
                                                            onClick={() => {
                                                                this.onInfo(rowData);
                                                            }}
                                                        >
                                                            <InfoIcon width={32} height={32} color={'#000000'} />
                                                        </span>
                                                    </Authorizer>
                                                </div>
                                            </>
                                        );
                                    }}
                                ></Column>
                            </DataTable>

                            <Toast ref={(el) => (this.toast = el)} position="bottom-right" />

                            {this.state.isLoading && <LoadingComponent />}
                        </div>
                    )}
                    {this.state.isShowUsersList == true && (
                        <div className="ma-m30">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.allLevelUsers}
                                dataKey="_id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate=""
                                currentPageReportTemplate=""
                                globalFilter={this.state.globalFilter}
                                responsiveLayout="scroll"
                            >
                                <Column
                                    header="S.No."
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <p>{index.rowIndex + 1}</p>
                                            </>
                                        );
                                    }}
                                />

                                <Column
                                    header="Level"
                                    className="datatable-cel"
                                    body={(rowData, index) => {
                                        return (
                                            <>
                                                <p>Level -{'  ' + rowData?.level}</p>
                                            </>
                                        );
                                    }}
                                />

                                <Column field="userName" header="Name of the User" className="datatable-cel" />

                                <Column
                                    className="datatable-cel"
                                    header="Status"
                                    body={(rowData, { rowIndex }) => {
                                        return (
                                            <>
                                                <div className="flex">
                                                    <InputSwitch
                                                        onChange={(e) => {
                                                            this.changeUserStatus(rowData);
                                                        }}
                                                        checked={rowData?.status}
                                                    />
                                                </div>
                                            </>
                                        );
                                    }}
                                ></Column>
                            </DataTable>

                            <Toast ref={(el) => (this.toast = el)} position="bottom-right" />

                            {this.state.isLoading && <LoadingComponent />}
                        </div>
                    )}
                </div>
                <Dialog
                    visible={this.state.isDialogOpenForCreate}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box w-8"
                    style={{ width: '500px', height: '700px' }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2">
                                <h4 className="formHeadingInter">{this.state.edit ? 'Update Approver' : 'Create Approver'}</h4>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button className="formSubmitButton px-6" style={{ borderRadius: '10px', marginRight: 10 }} onClick={this.onSubmitClick}>
                                <p className="formSubmitButtonText"> {this.state.edit ? 'Update' : 'Create'}</p>
                            </Button>
                            <Button
                                className="formCancelButton px-6"
                                style={{ borderRadius: '10px' }}
                                onClick={() =>
                                    this.setState({
                                        dateRange: null,
                                        usersData: {},
                                        isDialogOpenForCreate: false,
                                        edit: false,
                                        aprovalData: {},
                                        users: [],
                                        expDate: new Date(new Date().setFullYear(new Date().getFullYear() + 4)),
                                        createdDate: new Date(),
                                        dropdownsData: {},
                                        levels: [{}],
                                        branchId: null,
                                        selectedbranchName: ''
                                    })
                                }
                            >
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() =>
                        this.setState({
                            dateRange: null,
                            usersData: {},
                            isDialogOpenForCreate: false,
                            edit: false,
                            aprovalData: {},
                            users: [],
                            expDate: new Date(new Date().setFullYear(new Date().getFullYear() + 4)),
                            createdDate: new Date(),
                            dropdownsData: {},
                            levels: [{}],
                            branchId: null,
                            selectedbranchName: ''
                        })
                    }
                >
                    <div className="flex flex-column justify-content-center mx-6">
                        <div className="grid col-12 justify-content-center">
                            <div className="col-6">
                                <ClassetDropdown
                                    width={'443px'}
                                    disabled={this.state.edit}
                                    required={true}
                                    label={'Branch'}
                                    icon={<BoardsIconInActive height={16.5} width={19.5} />}
                                    placeholder={'Select Branch'}
                                    options={this.props.branchData}
                                    value={this.state.branchId}
                                    onChange={(e) => this.onChangeBranch(e.value)}
                                    optionLabel="name"
                                    optionValue="key"
                                />
                            </div>
                            <div className="col-6">
                                <ClassetCalendar
                                    width={'443px'}
                                    required={true}
                                    label={'Expiry Date'}
                                    icon={<CalenderIcon height={20} />}
                                    value={this.state.expDate}
                                    minDate={new Date()}
                                    onChange={(e) => {
                                        this.setState({ expDate: e.value });
                                    }}
                                    placeholder={'Expiry Date'}
                                    calendarMode="single"
                                />
                            </div>
                            {this.state.levels?.map((level, index) => {
                                return (
                                    <>
                                        <div className=" col-5">
                                            <ClassetMultiSelect
                                                icon={<AssignFormIcon />}
                                                label={'Role'}
                                                required
                                                value={level?.roles}
                                                placeholder="Select Role"
                                                width={'443px'}
                                                options={this.state?.roles}
                                                optionLabel="roleName"
                                                optionValue="_id"
                                                onChange={(e) => {
                                                    this.onChangeRole(e.target.value, index);
                                                }}
                                            />
                                        </div>
                                        <div className="col-5">
                                            <ClassetMultiSelect
                                                icon={<AssignFormIcon />}
                                                label={'Name'}
                                                required
                                                width={'443px'}
                                                value={level?.users}
                                                options={level?.ddUsers}
                                                optionLabel="username"
                                                optionValue="user_id"
                                                placeholder="Select Name"
                                                onChange={(e) => {
                                                    this.onChangeUser(e.target.value, index);
                                                }}
                                            />
                                        </div>
                                        <div className="cursor-pointer col-1 pl-4 pt-5 flex justify-content-start align-items-center" onClick={() => this.addLevels(index)}>
                                            <AddIconInButtonFull />
                                        </div>
                                        <div className="cursor-pointer col-1 pl-4 pt-5 flex justify-content-start align-items-center" onClick={() => this.removeLevels(index)}>
                                            <DeleteIconClasset width={22} height={22} />
                                        </div>
                                    </>
                                );
                            })}

                            {/* <BranchToSectionMultiDDTillSec setValues={this.setDropdownValues} dropdownsData={this.state?.dropdownsData} /> */}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isDialogOpenForAutoApproval}
                    draggable={false}
                    closeOnEscape={false}
                    className=""
                    style={{ height: '400px', width: '500px' }}
                    dismissableMask={false}
                    closable={true}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                onClick={() => (this.state.selectedApprovalTime ? this.autoApprovalRole(true) : this.toast?.show({ severity: 'error', summary: 'Please select Approval time', detail: 'Some error occured', life: 3000 }))}
                            >
                                <p className="formSubmitButtonText">Create</p>
                            </Button>
                            <Button className="formCancelButton px-4" onClick={() => this.setState({ isDialogOpenForAutoApproval: false, selectedApprovalTime: 0 })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForAutoApproval: false, selectedApprovalTime: 0 })}
                >
                    <div className="mt-3" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <div className="">
                            <ClassetDropdown
                                title="Session"
                                required={true}
                                label={'Select Auto Approval Time'}
                                icon={<FormatsIcon />}
                                placeholder={'Time'}
                                options={autoApprovalTimes}
                                value={this.state.selectedApprovalTime}
                                onChange={(e) => this.setState({ selectedApprovalTime: e.value })}
                            />
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStatesToProps = (state) => ({
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        []
});
export default connect(mapStatesToProps, { getBranchesLatest })(SettingsApprovalsAssignTransport);
