import React, { Component } from 'react';
import { DeleteIconClasset, MailIcon } from '../../svgIcons';
import { Button } from 'primereact/button';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { cloneDeep } from 'lodash';

const createFormFields = require('./timeTableTemplate.json');

export default class TimeTableTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFormFields);

        this.state = {
            breaks: [{}],
            tableData: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false // Initialize isLoading state
        };
    }

    addBreaks = () => {
        this.setState((prevState) => ({
            breaks: [...prevState.breaks, {}]
        }));
    };

    removeBreaks = (i) => {
        if (this.state.breaks.length > 1) {
            const filterAssignObjs = this.state.breaks.filter((_, index) => index !== i);
            this.setState({ breaks: filterAssignObjs });
        }
    };

    addTimeTableTemplate = () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        let bodyForApi = cloneDeep(this.state.tableData); // Corrected from 'vehicle' to 'tableData'

        const formStatus = isFormValid(createFormFields, this.formFields.formValidations, bodyForApi);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
                isLoading: false
            });
        } else {
            console.log(bodyForApi);
            // Call your API here to save the template
        }
    };

    render() {
        const { tableData, formValidations } = this.state;

        return (
            <div className="poppins24">
                <div className="grid mt-5 px-7">
                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetInputText
                            icon={<MailIcon width={24} height={24} color={'#000000'} />}
                            className="border-round-md border-none"
                            label="Name of the Template"
                            required={true}
                            value={tableData?.nameOfTheTemplate}
                            onChange={(e) => {
                                onTextChange(e.target.value, 'nameOfTheTemplate', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder="Template Name"
                        />
                        {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetCalendar value={tableData?.startTime} calendarMode="single" label="Start Time" placeholder="Start Time" timeOnly />
                        {formValidations && !formValidations.fields['startTime'].isValid && <p className="p-error">{formValidations.fields['startTime'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetCalendar
                            value={tableData?.endTime} // Corrected 'endTIme' to 'endTime'
                            calendarMode="single"
                            label="End Time"
                            placeholder="End Time"
                            timeOnly
                        />
                        {formValidations && !formValidations.fields['endTime'].isValid && <p className="p-error">{formValidations.fields['endTime'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            label={'Total Periods'}
                            required={true}
                            value={tableData.totalPeriods}
                            onChange={(e) => {
                                onDropDownChange(e.target.value, 'totalPeriods', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span>Total Periods</span>
                                </div>
                            }
                        />
                        {formValidations && !formValidations.fields['totalPeriods'].isValid && <p className="p-error">{formValidations.fields['totalPeriods'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            label={'Periods Length'}
                            required={true}
                            value={tableData.periodsLength}
                            onChange={(e) => {
                                onDropDownChange(e.target.value, 'periodsLength', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span>Periods Length</span>
                                </div>
                            }
                        />
                        {formValidations && !formValidations.fields['periodsLength'].isValid && <p className="p-error">{formValidations.fields['periodsLength'].errorMsg}</p>}
                    </div>

                    <div className="col-12">
                        <p className="text-3xl" style={{ textDecoration: 'underline', fontWeight: 'bolder', color: 'black' }}>
                            Add Breaks
                        </p>
                        {this.state.breaks.map((obj, index) => (
                            <div className="grid py-0 -mt-2" key={index}>
                                <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={this.state.boardsForAssignOptions} // Ensure this state is populated
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        label={'Type of Break'}
                                        required={true}
                                        className="no-border border-round-md shadow-2"
                                        onChange={(e) => this.onChangeBoard(e.value, index)}
                                        value={obj?.boardId}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span>Type of Break</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={obj?.gradeOptions} // Ensure gradeOptions is defined in obj
                                        optionLabel="label"
                                        optionValue="value"
                                        label={'Break Length'}
                                        required={true}
                                        onChange={(e) => this.onChangeClass(e.value, index)}
                                        className="no-border border-round-md shadow-2"
                                        value={obj?.classId}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span>Break Length</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="grid xl:col-4 lg:col-4 md:col-4 col-12">
                                    <div className="col-10">
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            value={obj?.subjectId}
                                            options={obj?.subjectOptions?.subjects} // Ensure subjectOptions is defined in obj
                                            label="Break Time"
                                            optionLabel="subjectName"
                                            optionValue="subjectId"
                                            required={true}
                                            onChange={(e) => this.onChangeSubject(e.value, index)}
                                            className=""
                                            placeholder={
                                                <div className="flex justify-content-start align-items-center">
                                                    <span>Break Time</span>
                                                </div>
                                            }
                                        />
                                    </div>

                                    <div className="col-2 pl-4 pt-5 flex justify-content-start align-items-center cursor-pointer" onClick={() => this.removeBreaks(index)}>
                                        <DeleteIconClasset width={22} height={22} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="mt-2 flex justify-content-between align-content-center">
                            <Button className="mt-2" style={{ borderRadius: 10, backgroundColor: '#F4F5F6', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', color: 'black' }} onClick={this.addBreaks}>
                                <p className="text-xl">Add Break</p>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="flex justify-content-center align-items-center mt-8">
                    <Button label="Add Time Table Template" className="confirmDialogAcceptBtn px-6 mr-5 w-21rem" onClick={this.addTimeTableTemplate} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" />
                </div>
            </div>
        );
    }
}
