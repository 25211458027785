import React, { Component } from 'react';
import ClassetInputText from '../../../../classetComponents/classetInputText';
import { getFormFields, isFormValid, onTextChange } from '../../../../utile/formHelper';
import { MailIcon } from '../../../svgIcons';
import { Button } from 'primereact/button';
import { trimObj } from '../../../../utile';
import { Toast } from 'primereact/toast';
const createUserFields = require('./adminUser.json');
const formFields = createUserFields;

export default class AdminUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createUserFields);
        this.state = {
            userDetails: this.props.editAdmin?.superAdmin || this.formFields.data,
            formValidations: this.formFields.formValidations
        };
    }
    componentDidMount() {
        console.log(this.props);
    }
    assign = () => {
        const { userDetails } = this.state;
        const formStatus = isFormValid(createUserFields, this.formFields.formValidations, trimObj(userDetails));
        this.setState({ isLoading: true });
        if (!this.props.editAdmin) {
            if (!formStatus.isFormValid) {
                return this.toast.show({
                    severity: 'error',
                    summary: 'Please Fill All Required Fields',
                    life: 3000
                });
            }
            if (userDetails.password !== userDetails.confirmPassword) {
                return this.toast.show({
                    severity: 'error',
                    summary: 'Password and Confirm Password should be same',
                    life: 3000
                });
            }
            delete userDetails.confirmPassword;
        }

        this.props.onSubmitClick(userDetails);
        // this.props.onSubmitClick();
    };
    render() {
        const { userDetails, formValidations } = this.state;
        return (
            <div className="flex flex-column align-items-start mt-5">
                <h3 style={{ color: 'black' }} className="px-5">
                    Create Super Admin
                </h3>
                <div className="col-3 px-5">
                    <p className="transport-fee-form-label">
                        Super Admin Username<span className="ma-required">*</span>
                    </p>
                    <div className="flex">
                        <ClassetInputText
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            className="text-xl w-full"
                            value={userDetails.username}
                            onChange={(e) => {
                                onTextChange(e.target.value, 'username', this, formFields, userDetails, formValidations, 'userDetails', 'formValidations');
                            }}
                            placeholder="Admin"
                        />
                    </div>
                    {formValidations && !formValidations.fields['username'].isValid && <p className="p-error text-lg">{formValidations.fields['username'].errorMsg}</p>}
                </div>
                {!this.props.editAdmin && (
                    <>
                        <div className="col-3 px-5">
                            <p className="transport-fee-form-label">
                                Password<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    value={userDetails.password}
                                    type={'password'}
                                    // options={this.state.academicSession}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'password', this, formFields, userDetails, formValidations, 'userDetails', 'formValidations');
                                    }}
                                    placeholder="Password"
                                />
                            </div>
                            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error text-lg">{formValidations.fields['password'].errorMsg}</p>}
                        </div>
                        <div className="col-3 px-5">
                            <p className="transport-fee-form-label">
                                Confirm Password<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl w-full"
                                    value={userDetails.confirmPassword}
                                    type={'password'}
                                    // options={this.state.academicSession}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'confirmPassword', this, formFields, userDetails, formValidations, 'userDetails', 'formValidations');
                                    }}
                                    placeholder="Confirm Password"
                                />
                            </div>
                            {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error text-lg">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                        </div>
                    </>
                )}

                <div className="text-center col-12 flex gap-4 justify-content-center mt-4">
                    <Button
                        label={this.props.editAdmin ? 'Update' : 'Assign'}
                        className="confirmDialogAcceptBtn px-6 mr-5 w-20rem"
                        onClick={async () => {
                            this.assign();
                        }}
                    />
                    <Button label="Back" className="confirmDialogCancelBtn" style={{}} onClick={() => this.props.currentTabChange(this.props.currentTab - 1)} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" style={{}} onClick={() => this.props.setCreateOrEdit()} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}
