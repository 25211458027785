import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import Authentication from '../../session';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import {  MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { getFormFields, isFormValid, onDropDownChange, onEmailChange, onTextChange } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import PopulateDynamicForm from '../../../classetComponents/classetPopulateDynamicForm';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { convertUtc, trimObj } from '../../../utile';
import LoadingComponent from '../../loadingComponent';


const createEnquiryFields = require('./enquiryAdd.json');

const formFields = createEnquiryFields
class EnquiryAdd extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createEnquiryFields);
        this.state = {
            isLoading: false,
            feePlans: [],
            academicCategory: [],
            boards: [],
            knowAboutUs: [
                { label: 'Online', value: "Online" },
                { label: 'Self', value: "Self" },
                { label: 'family', value: "family" },
                { label: 'Advertisement', value: "Advertisement" }
            ],
            gender: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
            ],
            parentTypes: [
                { label: 'Father', value: "Father" },
                { label: 'Mother', value: "Mother" },
                { label: 'Guardian', value: "Guardian" }
            ],
            pickupFee: 'average',
            preferences: false,
            refundable: false,
            refundErr: '',
            enquiry: this.props?.selectedEnquiry?._id ? this.props?.selectedEnquiry?.enquiry : this.formFields.data,
            formValidations: this.formFields.formValidations,
            addDifferentialFeeAllow: false,
            isEdit: false,
            dob: null, selectedClassesList: []
        }
        this.service = new Service();
    }


    async componentDidMount() {
        await this.getAdmissionSettingsInfo()
        await this.getCouncellorsInfo()
        await this.props.getBranchesLatest()
        await this.props.getBoardsData(true);
        await this.getAdmissionTypeDropdown()
        await this.getAcademicSessions()



        const storedFields = localStorage.getItem('fields');

        if (storedFields) {

            const parsedFields = JSON.parse(storedFields);
            this.setState(prevState => ({
                enquiry: {
                    ...prevState.enquiry,
                    customFields: parsedFields
                }
            }));
        }
        if (this.props?.selectedEnquiry?._id && this.props?.selectedEnquiry?.branch) {
            this.setState({
                isEdit: true,
                dob: new Date(this.props?.selectedEnquiry?.dob)
            })
            this.onChangeBranch(this.props?.selectedEnquiry?.branch)
            this.onChangeBoard(this.props?.selectedEnquiry?.board)
        }





    }

    getCouncellorsInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/admin/getFilteredUseres`;
        try {
            let payload = {
                academicYear,
                type: "counsellor"
            }
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ counsellorInfo: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }

    getAdmissionSettingsInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ admissionSettingsInfo: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }




    getAdmissionTypeDropdown = async () => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admision => ({
                    label: admision.categoryName,
                    value: admision._id
                }));

                this.setState({ academicCategory: result, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };


    getAcademicSessions = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/academic-years/filter`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admision => ({
                    label: admision.academicCode,
                    value: admision._id
                }));
                this.setState({ academicSession: result, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };


    deleteDifferentialFee = (index) => {
        const updatedFeeTypes = this.state.transportFeeTypes.filter((_, i) => i !== index);
        this.setState({ transportFeeTypes: updatedFeeTypes });
    };

    handleInputChange = (index, field, value) => {
        const feeType = [...this.state.transportFeeTypes];
        feeType[index][field] = value;
        this.setState({ feeType });
    };


    handleRadioButtonChange = (option) => {
        this.setState({ pickupFee: option });
    }

    onHandleNavigate = (isContinue) => {

        const { application, examTest, registration } = this.state?.admissionSettingsInfo

        let createdId = this.state.createdId
        if (isContinue && createdId) {
            localStorage.setItem('enquiryId', createdId)
            localStorage.setItem('applicationId', createdId)
            if (application) {
                this.props.navigate('/application')
            }
            else if (examTest) {
                this.props.navigate('/addExam')
            } else if (registration) {
                this.props.navigate('/addRegistration')
            }
            else {
                this.props.navigate('/confirm-enrollment')
            }
        } else {
            !this.state.isEdit ? this.props.navigate('/enquiry') : this.setState({ isEdit: false }, () => this.props.onHandleClose())
        }
    }

    onClickSaveAndExit = (isContinue) => {
        this.setState({
            isLoading: true
        });
        const { application, examTest, registration, enrollment } = this.state?.admissionSettingsInfo
        const { enquiry, dob } = this.state
        if(!dob){
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please check DOB.', life: 3000 });
            return 
        }
        let dateOfBirth = dob || new Date()
        enquiry.dob = convertUtc(dateOfBirth)
        const formStatus = isFormValid(createEnquiryFields, this.formFields.formValidations, trimObj(enquiry));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        let filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        if (!filter.test(enquiry?.emailId) || !filter.test(enquiry?.parentEmailId)) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please check email id format.', detail: 'Please check email id format.', life: 3000 });
            return
        }
        if (isCompleteFormValid) {

            let academicYear = this.state.academicSession.find(el => el?.value == this.state.enquiry?.academicSession)?.label
            let admissionCategoryName = this.state.academicCategory.find(el => el?.value == this.state.enquiry?.academicCategory)?.label
            let payload = enquiry
            payload.stage = "Enquiry"
            payload.currentStage = "Enquiry"
            payload.academicYearCode = academicYear
            payload.admissionCategoryName = admissionCategoryName
            payload.branchName = this.state?.selectedbranchName || ""
            payload.boardName = this.state?.selectedBoardName || ""
            let selectedClass = this.state?.classes?.find(el => el.classId == payload?.grade)
            payload.gradeName = selectedClass?.className
            payload.groupId = this.state.groupId

            if (application && isContinue) {
                payload.stage = "Application"
            }
            else if (examTest && isContinue) {
                payload.stage = "Exam"
            } else if (registration && isContinue) {
                payload.stage = "Registration"
                payload.registration = {
                    siblings: [
                        { name: "", gender: "", classStudying: "", schoolStudying: "" }
                    ],
                    prevAcadamicDetails: [
                        { name: "", classStudied: "", schoolStudied: "", percentageOrGpa: "" }
                    ],
                }
            }
            else if (enrollment && isContinue) {
                payload.stage = "Enrollment"
            }

            if (!this.props?.selectedEnquiry?._id) {
                const url = `${baseUrlAdmin}/admissions`

                this.service.post(url, payload, true).then(res => {
                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                            createdId: res?.res?.data
                        }, () => { this.onHandleNavigate(isContinue) });
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    console.log(e);
                })
            } else {
                payload.currentStage = "Enquiry"
                payload._id = this.props?.selectedEnquiry?._id
                const url = `${baseUrlAdmin}/admissions/update`
                this.service.put(url, payload, true).then(res => {
                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false,
                        }, () => { this.onHandleNavigate(isContinue) });
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    console.log(e);
                })
            }
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: "Please fill all the required fields.", life: 3000 });
        }


    }


    onChangeBranch = (branchId) => {
        this.setState({
            boards: [], classes: [],
        });
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId)?.name;
        let filteredBoards = this.props.boards.filter(board => board.assignedBranches.includes(branchId));
        let boardsInfo = this.state.admissionSettingsInfo?.boards
        let finalBoardsList = []
        for (const sBoards of boardsInfo) {
            for (let i = 0; i < filteredBoards.length; i++) {
                const boardId = filteredBoards[i]?.boardId;
                if (sBoards === boardId) finalBoardsList.push(filteredBoards[i])

            }
        }
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            boards: finalBoardsList || [],
            classes: [],
            boardId: '',
            selectedBoardName: '',
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
        });
    };


    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], sections: [],
        });
        const selectedBoard = this.state.boards && this.props.boards.find((board) => board.boardId === boardId);
        let classesInfo = this.state.admissionSettingsInfo?.grades
        let finalClassesList = []
        for (const sClass of classesInfo) {
            for (let i = 0; i < selectedBoard?.classes.length; i++) {
                const classId = selectedBoard?.classes[i]?.classId;
                if (sClass === classId) finalClassesList.push(selectedBoard?.classes[i])
            }
        }
        this.setState({
            classes: [],
            boardId,
            selectedBoardName: selectedBoard?.boardName,
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            selectedClassesList: finalClassesList
        }, () => this.getAgeCriteriaInfo(this.state?.branchId, this.state?.boardId, this.state.selectedClassesList));
    };

    getAgeCriteriaInfo = async (branchId, boardId, finalClassesList) => {
        this.setState({
            isLoading: true
        });
        const { dob, enquiry, admissionSettingsInfo, ageCriteriaInfo } = this.state
        let url = `${baseUrlAdmin}/admissions/agecriteria?boardId=${boardId}&branchId=${branchId}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {

                this.setState({ ageCriteriaInfo: res?.res?.data, isLoading: false }, () => this.onSetClasses(finalClassesList));
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    }

    onChangeClass = (classId) => {
        const { classes } = this.state
        let selectedClassId = classes.find(el => el.classId === classId)
        let groupId = selectedClassId?.groups[0]?.groupId
        let groupName = selectedClassId?.groups[0]?.groupName
        this.setState({
            groupId: groupId,
        })
    }

    onSetClasses = () => {
        const { dob, enquiry, ageCriteriaInfo, admissionSettingsInfo, selectedClassesList } = this.state
        if (admissionSettingsInfo?.ageCriteria) {
            let classesInfo
            if (dob && enquiry?.board) {
                classesInfo = ageCriteriaInfo.filter(cls => {
                    const bornBeforeDate = new Date(cls.bornBefore);
                    const bornAfterDate = new Date(cls.bornAfter);

                    return dob >= bornAfterDate && dob <= bornBeforeDate;
                });
                const classIds = new Set(classesInfo.map(item => item.classId));


                const matchedClasses = selectedClassesList && selectedClassesList?.filter(item => classIds.has(item.classId));
                if (!matchedClasses.length) {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Age not eligible for any grades', detail: "Age not eligible for any grades", life: 3000 });
                } else {
                    this.setState({
                        classes: matchedClasses
                    })
                }
            } else if (!dob) {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Please select DOB', detail: "Please select DOB", life: 3000 });
            } else {
                this.setState({
                    classes: selectedClassesList
                })
            }

        } else {
            this.setState({
                classes: selectedClassesList
            })
        }


    }

    render() {
        const { enquiry, formValidations } = this.state;
        return (
            <>
                <div className=''>
                    <div className="grid  md:col-12 lg:rid-col-12 mx-5 column-gap-8 row-gap-5" >
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Academic Session<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.academicSession}
                                    options={this.state.academicSession}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicSession', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Select Academic Session'
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Admission Category<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={343}
                                    height={48}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.academicCategory}
                                    options={this.state.academicCategory}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicCategory', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Admission Category'
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicCategory'].isValid && <p className="p-error text-lg">{formValidations.fields['academicCategory'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Branch<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={343}
                                    height={48}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='name'
                                    optionValue='key'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.branch}
                                    options={this.props.branchData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'branch', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations'); this.onChangeBranch(e.target.value) }}
                                    placeholder='Select Branch'
                                />
                            </div>
                            {formValidations && !formValidations.fields['branch'].isValid && <p className="p-error text-lg">{formValidations.fields['branch'].errorMsg}</p>}
                        </div>


                    </div>


                    <div className="grid mt-2 md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5"  >

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student First Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    width={250}
                                    className='  add-vehicle-field-input w-full '
                                    value={enquiry.firstName}
                                    onChange={(e) => {
                                        if (/^[a-zA-Z]*$/.test(e.target.value)) {
                                            onTextChange(e.target.value, 'firstName', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations');
                                        }
                                    }}
                                    placeholder="Student First Name" />
                            </div>
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student Last Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    width={250}
                                    className='  add-vehicle-field-input w-full '
                                    value={enquiry.lastName}
                                    onChange={(e) => {
                                        if (/^[a-zA-Z]*$/.test(e.target.value)) {
                                            onTextChange(e.target.value, 'lastName', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')
                                        }
                                    }}
                                    placeholder="Student Last Name" />
                            </div>
                            {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error text-lg">{formValidations.fields['lastName'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Contact no<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    width={250}
                                    keyfilter='int'
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={enquiry.contactNo}
                                    onChange={(e) => onTextChange(e.target.value, 'contactNo', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Contact no" />
                            </div>
                            {formValidations && !formValidations.fields['contactNo'].isValid && <p className="p-error text-lg">{formValidations.fields['contactNo'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    width={250}

                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={enquiry.emailId}
                                    onChange={(e) => onEmailChange(e.target.value, 'emailId', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Email ID" />
                            </div>
                            {formValidations && !formValidations.fields['emailId'].isValid && <p className="p-error text-lg">{formValidations.fields['emailId'].errorMsg}</p>}

                        </div>



                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Gender<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}

                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.gender}
                                    options={this.state.gender}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'gender', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Select Gender'
                                />
                            </div>
                            {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error text-lg">{formValidations.fields['gender'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Date of Birth<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetCalendar
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md add-vehicle-field-input text-xl w-full'
                                    calendarMode={'single'}
                                    value={this.state.dob ? new Date(this.state.dob) : new Date()}
                                    onChange={(e) => { this.setState({ dob: e.value }, () => this.onSetClasses()) }}
                                    placeholder="DOB" 
                                    maxDate ={new Date()}
                                    />
                            </div>


                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Adhaar Card<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    width={250}
                                    keyfilter='int'

                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={enquiry.adhaarNo}
                                    onChange={(e) => onTextChange(e.target.value, 'adhaarNo', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Adhaar Card No." />
                            </div>
                            {formValidations && !formValidations.fields['adhaarNo'].isValid && <p className="p-error text-lg">{formValidations.fields['adhaarNo'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Board<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='boardName'
                                    optionValue='boardId'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.board}
                                    options={this.state.boards}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'board', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations'); this.onChangeBoard(e.target.value) }}
                                    placeholder='Select Board'
                                />
                            </div>
                            {formValidations && !formValidations.fields['board'].isValid && <p className="p-error text-lg">{formValidations.fields['board'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Grade<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='className'
                                    optionValue='classId'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.grade}
                                    options={this.state?.classes}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'grade', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations'); this.onChangeClass(e.target.value) }}
                                    placeholder='Select Grade'
                                />
                            </div>
                            {formValidations && !formValidations.fields['grade'].isValid && <p className="p-error text-lg">{formValidations.fields['grade'].errorMsg}</p>}

                        </div>



                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Previous School<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    width={250}

                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={enquiry.previousSchool}
                                    onChange={(e) => onTextChange(e.target.value, 'previousSchool', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Previous School" />
                            </div>
                            {formValidations && !formValidations.fields['previousSchool'].isValid && <p className="p-error text-lg">{formValidations.fields['previousSchool'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Previous Board<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='boardName'
                                    optionValue='boardId'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.previousBoard}
                                    options={this.props.boards}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'previousBoard', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Select Previous Board'
                                />
                            </div>
                            {formValidations && !formValidations.fields['previousBoard'].isValid && <p className="p-error text-lg">{formValidations.fields['previousBoard'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Parent Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    width={250}

                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={enquiry.parentName}
                                    onChange={(e) => onTextChange(e.target.value, 'parentName', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Parent Name" />
                            </div>
                            {formValidations && !formValidations.fields['parentName'].isValid && <p className="p-error text-lg">{formValidations.fields['parentName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Parent Type<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.parentType}
                                    options={this.state.parentTypes}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'parentType', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Parent Type'
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentType'].isValid && <p className="p-error text-lg">{formValidations.fields['parentType'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Parent Contact No.<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    width={250}
                                    keyfilter='int'

                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={enquiry.parentContact}
                                    onChange={(e) => onTextChange(e.target.value, 'parentContact', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Contact No." />

                            </div>
                            {formValidations && !formValidations.fields['parentContact'].isValid && <p className="p-error text-lg">{formValidations.fields['parentContact'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Parent Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className='flex'>
                                    <ClassetInputText
                                        width={250}
                                        icon={<MailIcon width={24} height={24} color={"black"} />}
                                        className='  add-vehicle-field-input w-full '
                                        value={enquiry.parentEmailId}
                                        onChange={(e) => onEmailChange(e.target.value, 'parentEmailId', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                        placeholder="Parent Email Id" />
                                </div>
                            </div>
                            {formValidations && !formValidations.fields['parentEmailId'].isValid && <p className="p-error text-lg">{formValidations.fields['parentEmailId'].errorMsg}</p>}

                        </div>
                    </div>


                    <div className="grid md:col-12 lg:col-12 column-gap-8 row-gap-5 mx-5 my-2">
                        <div className="" >
                            <p className="transport-fee-form-label">Occupation<span className="ma-required">*</span></p>
                            <div className="flex">
                                <ClassetInputText
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={enquiry.occupation}
                                    onChange={(e) => onTextChange(e.target.value, 'occupation', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Occupation" />
                            </div>
                            {formValidations && !formValidations.fields['occupation'].isValid && <p className="p-error text-lg">{formValidations.fields['occupation'].errorMsg}</p>}

                        </div>
                        <div className="" >
                            <p className="transport-fee-form-label">Remarks<span className="ma-required">*</span></p>
                            <div className="flex">
                                <ClassetTextArea
                                    placeholder='Remarks'
                                    className=""
                                    width={680}
                                    value={enquiry.remarks}
                                    onChange={(e) => onTextChange(e.target.value, 'remarks', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                />
                            </div>
                            {formValidations && !formValidations.fields['remarks'].isValid && <p className="p-error text-lg">{formValidations.fields['remarks'].errorMsg}</p>}
                        </div>
                    </div>


                    <div className="grid mt-5 md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >

                        <div className="">
                            <p className='transport-fee-form-label'>How did you know about us?<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.knowAboutUs}
                                    options={this.state.knowAboutUs}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'knowAboutUs', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Select '
                                />
                            </div>
                            {formValidations && !formValidations.fields['knowAboutUs'].isValid && <p className="p-error text-lg">{formValidations.fields['knowAboutUs'].errorMsg}</p>}

                        </div>
                        <div className=" ">
                            <p className='transport-fee-form-label'>Reference Counsellors Name<span className='ma-required'></span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={enquiry.counsellorsName}
                                    onChange={(e) => onTextChange(e.target.value, 'counsellorsName', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations')}
                                    placeholder="Enter Counsellor Name" />
                            </div>
                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Assigned Counsellor<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='userName'
                                    optionValue='user_id'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={enquiry.assignCounsellor}
                                    options={this.state.counsellorInfo}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'assignCounsellor', this, formFields, enquiry, formValidations, 'enquiry', 'formValidations') }}
                                    placeholder='Select'
                                />
                            </div>
                            {formValidations && !formValidations.fields['assignCounsellor'].isValid && <p className="p-error text-lg">{formValidations.fields['assignCounsellor'].errorMsg}</p>}

                        </div>

                        {enquiry?.customFields && Array.isArray(enquiry?.customFields) && enquiry?.customFields.length > 0 && <PopulateDynamicForm fieldArray={enquiry?.customFields} />}

                    </div>

                    <div className="text-center mt-8">

                        <Button icon={'pi pi-plus-circle text-lg '} label={!this.state.isEdit ? 'Save Enquiry' : 'Update Enquiry'} onClick={() => this.onClickSaveAndExit(false)} className='confirmDialogAcceptBtn  mr-5 w-18rem p-5' />
                        {!this.state.isEdit && <Button icon={'pi pi-plus-circle text-lg '} label='Save and Proceed to Next Step' onClick={() => this.onClickSaveAndExit(true)} className='confirmDialogAcceptBtn  mr-5 w-24rem p-5' />}
                        <Button label='Cancel' className='confirmDialogAcceptBtn p-5' onClick={() => !this.state.isEdit ? this.props.navigate('/enquiry') : this.setState({ isEdit: false, application: this.formFields.data }, () => this.props.onHandleClose())} />

                    </div>
                </div>
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(EnquiryAdd)));