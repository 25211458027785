import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid } from '../../../../utile/formHelper';
import InputTextB from './../../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../../constants';
//import './styles.scss'
import { trimObj } from '../../../../utile';
import { onTextChange } from '../../../../utile/formHelper';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import LoadingComponent from '../../../loadingComponent';
import { Toast } from 'primereact/toast';


const formFields = [
    {
        "Type": "Text",
        "Label": "Name",
        "FieldName": "name",
        "Required": true,
        "MaxLength": 50
    },
    {
        "Type": "Text",
        "Label": "Content",
        "FieldName": "content",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Template Id",
        "FieldName": "templateId",
        "Required": true
    }

]

class AddTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editTemplate || {});
        this.state = {
            template: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }
        this.service = new Service();
    }

    footer = () => {
        return (<div>
            <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${this.props.editTemplate ? 'Update' : 'Add'}`} className='p-button-raised' onClick={this.onSubmitClick} />

        </div>)
    }

    onSubmitClick = () => {
        let { template } = this.state;
        let _template = trimObj(template);
        const formStatus = isFormValid(formFields, this.formFields.formValidations, _template);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            _template.orgId = this.props.orgId;


            let url = `${baseUrlAdmin}/smstemplate`;
            this.setState({
                isLoading: true
            });
            this.service[_template._id ? 'put' : 'post'](url, _template, true).then(res => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        template: {
                            name: '',
                            content: '',
                            templateId: ''
                        }
                    }, () => {
                        this.props.onCreateOrEditSuccess(_template._id ? true : false)
                    });
                    //   this.toast.show({ severity: 'success', summary: 'ORG updated', detail: 'Organization successfully updated', life: 3000 });

                    // thisObj.props.onCreateOrEditSuccess();

                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                console.log(e);
            })


        }
    };


    onCloseDialog = () => {
        this.props.hideAddTemplateDialog();

    }


    render() {
        const { template, formValidations } = this.state;

        return (
            <Dialog header={`${this.props.editTemplate ? 'Edit' : 'Add'} Template`}
                blockScroll={true} headerClassName='text-center'
                draggable={false}
                footer={this.footer}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                style={{
                    width: '60%',
                }}
                closable={false}
                onHide={() => this.setState({ showCreateDialog: false })}>
                <div className="grid subject-add-dialog">
                    <div className="col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Name <span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TEMPLATE_NAME} id="topicn">
                            <InputText value={template.name} onChange={(e) => {
                                onTextChange(e.target.value, 'name', this, formFields, template, formValidations, 'template', 'formValidations')
                            }} className='w-10 p-inputtext-style1 ma-w100p' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                    </div>
                </div>
                <div className="grid subject-add-dialog">
                    <div className="col-4 p-md-4 p-lg-4">
                        <p className='text-label' >Content<span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TEMPALTE_CONTENT} id="topicn">
                            <InputTextarea value={template.content}
                                rows={8}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'content', this, formFields, template, formValidations, 'template', 'formValidations')
                                }} className='w-10 p-inputtext-style1 ma-w100p ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['content'].isValid && <p className="p-error">{formValidations.fields['content'].errorMsg}</p>}
                    </div>
                </div>
                <div className="grid subject-add-dialog">
                    <div className="col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Template Id</p>
                    </div>
                    <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TEMPLATE_ID} id="topicn">
                            <InputText value={template.templateId} onChange={(e) => {
                                onTextChange(e.target.value, 'templateId', this, formFields, template, formValidations, 'template', 'formValidations')
                            }} className='w-10 p-inputtext-style1 ma-w100p ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
                    </div>
                </div>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Dialog >
        )
    }
}




export default AddTemplate;