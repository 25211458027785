import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { cloneDeep } from 'lodash';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../utile/formHelper';
import Authentication from '../../session';
import { baseUrlAdmin } from '../../store/apiConstants';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { ClassetEditIcon, MailIcon, PlainCalenderIcon, TickBgIcon } from '../svgIcons';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetCalendar from '../../classetComponents/classetCalender';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import './styles.scss';
const createFeeInstallmentFields = require('./feeInstallment.json');

class FeeInstallments extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFeeInstallmentFields);
        this.state = {
            currentTab: 0,
            term: this.formFields.data,
            formValidations: this.formFields.formValidations,
            IsDailogBoxopenForYearChange: false,
            isLoading: false,
            rangeDate: [],
            dateError: false,
            termsData: [],
            editTerm: {},
            isDialogOpenForCreateTerm: false,
            title: '',
            description: ''
        };
        this.service = new Service();
    }

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };

    getAcademicTermsData = async (year) => {
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/feeInstallments?academicYear=${year}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ termsData: res?.res?.data, isLoading: false });
            } else {
                const error = 'Some error occurred';
                this.setState({ error, isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ e, isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };

    componentDidMount = () => {
        const AcademicYear = localStorage.getItem('userAcademicYear');
        this.getAcademicTermsData(AcademicYear);
    };

    onCreateTerm = async () => {
        this.setState({ isLoading: true });
        let body = cloneDeep(this.state.term);
        body.academicDate = this.state.rangeDate;
        const formStatus = isFormValid(createFeeInstallmentFields, this.formFields.formValidations, body);
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            if (!body.academicDate?.length) {
                this.setState({ formValidations: formStatus.formValidations, dateError: true });
                isCompleteFormValid = false;
            } else {
                this.setState({ formValidations: formStatus.formValidations });
                isCompleteFormValid = false;
            }
        }
        if (isCompleteFormValid) {
            let academicYear = localStorage.getItem('userAcademicYear');
            if (this.state.editTerm._id) {
                const payload = {
                    _id: this.state.editTerm._id,
                    title: body.academicTitle,
                    startDate: body.academicDate[0],
                    endDate: body.academicDate[1],
                    branchId: body.academicBranch,
                    academicYear: body.academicYear ?? academicYear
                };
                let url = `${baseUrlAdmin}/feeInstallments/`;

                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({ isDialogOpenForCreateTerm: false, term: {}, rangeDate: [], editTerm: {} });
                        const AcademicYear = localStorage.getItem('userAcademicYear');
                        this.getAcademicTermsData(AcademicYear);
                        this.toast.show({ severity: 'success', summary: 'Term Updated successfully', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                }
            } else {
                const payload = {
                    title: body.academicTitle,
                    startDate: body.academicDate[0],
                    endDate: body.academicDate[1],
                    branchId: body.academicBranch,
                    academicYear: academicYear
                };
                let url = `${baseUrlAdmin}/feeInstallments/`;

                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({ isDialogOpenForCreateTerm: false, term: {}, rangeDate: [], editTerm: {} });
                        const AcademicYear = localStorage.getItem('userAcademicYear');
                        this.getAcademicTermsData(AcademicYear);
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({ isLoading: false });
                        const error = 'Some error occurred';
                        this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
                    }
                } catch (e) {
                    this.setState({ isLoading: false });
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }
        } else {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
        }
    };

    handleEditRequest = (term) => {
        const filteredFields = {
            academicTitle: term?.title,
            academicSession: term?.academicYear,
            academicBranch: term?.branchId,
            description: term?.description
        };
        this.setState({
            editTerm: term,
            term: filteredFields,
            rangeDate: [new Date(term?.startDate), new Date(term?.endDate)],
            isDialogOpenForCreateTerm: true
        });
    };

    render() {
        const { term, formValidations, feeinstallment } = this.state;
        return (
            <div className="mt-2">
                <div className="admin-management ml-5">
                    <div style={{ marginBottom: '110px' }}>
                        <div className=" grid flex flex-column justify-content-center align-content-center">
                            <div className="mt-3 md:col-12 lg:col-12 xl:col-12">
                                <div className="grid mb-6 mr-6">
                                    <div className="col-12 flex justify-content-end align-items-center">
                                        <Authorizer permId={PERMISSIONS.CREATE_TERM}>
                                            <Button
                                                label="ADD"
                                                icon={'pi pi-plus-circle text-lg opacity-60'}
                                                className="AddButton px-4 py-2"
                                                onClick={() => {
                                                    this.setState({ isDialogOpenForCreateTerm: true });
                                                }}
                                            />
                                        </Authorizer>
                                    </div>
                                </div>
                                {this.state.termsData?.length && !this.state.isLoading ? (
                                    <div className="grid doubts-tabs flex lg:justify-content-start xl:justify-content-start sm:justify-content-center xs:justify-content-center align-items-Satrt">
                                        {this.state.termsData?.map((term, index) => (
                                            <div key={term._id} className="mx-6 card cardSize cursor-pointer col-3 doubts-tab-w">
                                                <div className="flex flex-column justify-content-center align-items-center">
                                                    <div className="mt-1 w-full flex justify-content-end align-items-center"></div>
                                                    <div className="flex flex-column justify-content-center align-items-center mt-3 ml-3">
                                                        <h3 className="termCardHeadText">{term?.title}</h3>
                                                        <p className="termCardDatesSize text-xl">
                                                            {new Date(term?.startDate).toLocaleDateString('en-GB')}-{new Date(term?.endDate).toLocaleDateString('en-GB')}
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-content-start">
                                                        <p className="surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis w-17rem text-wrap text-center termCardPara text-xl -mt-2">{term.description}</p>
                                                    </div>
                                                    {!term.isUsed && (
                                                        <Authorizer permId={PERMISSIONS.UPDATE_TERM}>
                                                            <div
                                                                className="-mt-5 mr-5 mb-3 w-full flex justify-content-end align-items-center"
                                                                onClick={() => {
                                                                    this.handleEditRequest(term);
                                                                }}
                                                            >
                                                                <ClassetEditIcon width={32} height={35} color={'#000000'} />
                                                            </div>
                                                        </Authorizer>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    !this.state.isLoading && (
                                        <div className="mt-8 card h-20rem w-full  flex flex-column justify-content-center align-items-center">
                                            <h3>No Fee Installments Found</h3>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    visible={this.state.isDialogOpenForCreateTerm}
                    draggable={false}
                    closeOnEscape={false}
                    className="lg:w-11 lg:mx-2"
                    style={{
                        maxWidth: '1000px',
                        width: '75%',
                        maxHeight: '90%',
                        height: 'auto'
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2">
                                <h3 className="text-center text-black-alpha-90 font-bold">{`${this.state.editTerm?._id ? 'Update' : 'Add'}`} Fee Installments</h3>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button label="Accept" className="confirmDialogAcceptBtn mr-4  px-6" onClick={() => this.onCreateTerm()} />
                            <Button label="Cancel" className="confirmDialogCancelBtn px-6" onClick={() => this.setState({ term: {}, isDialogOpenForCreateTerm: false, rangeDate: [], editTerm: {} })} />
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForCreateTerm: false, rangeDate: [], term: {}, editTerm: {} })}
                >
                    <div className="flex  flex-column justify-content-center align-items-center align-content-center mt-4">
                        <div className="grid">
                            <div className="col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="title" className="block groupFormLabelText">
                                        Title <span className="ma-required">*</span>
                                    </label>
                                    <div className="flex">
                                        <ClassetInputText
                                            icon={<MailIcon width={24} height={24} color={'black'} />}
                                            className=" border-round-md groupFormInputText  border-none "
                                            value={term.academicTitle}
                                            onChange={(e) => {
                                                onTextChange(e.target.value, 'academicTitle', this, createFeeInstallmentFields, term, formValidations, 'term', 'formValidations');
                                            }}
                                            placeholder="Title"
                                        />
                                        <div className="">
                                            <Tooltip target=".custom-target-title ">
                                                <div className="flex justify-content-center align-items-center">
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className="font-semibold text-sm">Title of the Term</p>
                                                        <p className="-mt-1">Title will be displayed throughout</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i
                                                className="custom-target-title  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                            ></i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['academicTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['academicTitle'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className=" col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="date" className="block groupFormLabelText">
                                        Start Date<span className="ma-required">*</span>
                                    </label>
                                    <div className="flex">
                                        <ClassetCalendar
                                            icon={<PlainCalenderIcon width={24} height={24} color={'grey'} />}
                                            className="border-calendar"
                                            width={'343px'}
                                            height={'48px'}
                                            inputClassName="font-semibold "
                                            value={this.state.rangeDate}
                                            selectionMode="range"
                                            readOnlyInput
                                            hideOnRangeSelection
                                            onChange={(e) => {
                                                this.setState({ rangeDate: e.value });
                                            }}
                                            placeholder="Pick Date"
                                        />
                                        <div className="">
                                            <Tooltip target=".custom-target-date ">
                                                <div className="flex justify-content-center align-items-center">
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className="font-semibold text-sm">Select To And From Dates</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i
                                                className="custom-target-date  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                            ></i>
                                        </div>
                                    </div>
                                    {this.state.rangeDate?.length == 0 && this.state.dateError && <p className="p-error text-lg">{'Date Range Is Required'}</p>}
                                </div>
                            </div>
                            {/*  <div className="col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <p className='ma-label-s2 groupFormLabelText'>Branch<span className='ma-required'>*</span></p>
                                    <div className='flex mt-2'>
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            optionLabel="name"
                                            optionValue="key"
                                            className=' no-border '
                                            options={this.props.branchData}
                                            value={term.academicBranch}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'academicBranch', this, createFeeInstallmentFields, term, formValidations, 'term', 'formValidations') }}

                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=''>Select Branch</span>
                                                </div>
                                            )}
                                        />
                                        <div className="">
                                            <Tooltip target=".custom-target-board ">
                                                <div className='flex justify-content-center align-items-center'>
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className='font-semibold text-sm'>Select Branch
                                                        </p>
                                                        <p className="-mt-1">This installment will be created in the selected Branch</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i className="custom-target-board  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                            </i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['academicBranch'].isValid && <p className="p-error text-lg">{formValidations.fields['academicBranch'].errorMsg}</p>}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    blockScroll={true}
                    visible={this.state.IsDailogBoxopenForYearChange}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: '400px', height: '350px' }}
                    dismissableMask={false}
                    closable={false}
                    onHide={() => {
                        this.setState({ IsDailogBoxopenForYearChange: false });
                    }}
                    footer={() => {
                        return (
                            <div className="flex justify-content-center">
                                <Button label="Accept" className="confirmDialogAcceptBtn mr-4  px-6" onClick={() => {}} />
                                <Button
                                    style={{ color: 'black' }}
                                    label="Cancel"
                                    className="confirmDialogCancelBtn px-6"
                                    onClick={() => {
                                        this.setState({ IsDailogBoxopenForYearChange: false });
                                    }}
                                />
                            </div>
                        );
                    }}
                >
                    <div className="text-center">
                        <TickBgIcon width={32} height={32} color="#268900" />
                        <h4 className="confirmDialogHeader">Confirm Academic Term</h4>
                        <p className="confirmDialogPara">Confirming this Academic session will automatically deactivate the previously active AY</p>
                    </div>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
    boardsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions) || [],
    boards: state.boardsData.boards || [],
    common: state.common,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    academicSessionsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.academicSessionsDropDownOptions) || []
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(FeeInstallments)));
