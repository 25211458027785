import React, { Component } from 'react'
import _, { cloneDeep, constant } from 'lodash';
import Service from './../../services';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../utile/formHelper';
import { BasicLazyParams, trimObj } from '../../utile';
import { AssignFormIcon, MailIcon } from '../svgIcons';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetTextArea from '../../classetComponents/classetTextArea';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask, } from '../../store/apiConstants';
import ClassetDropdown from "../../classetComponents/classetDropDown";
import { AcademicSessionCalIcon } from '../svgIcons';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';

const createRoleFields = require('./communication.json');
const TemplateTypes = [{ label: 'Template One', value: 'Template One' }, { label: 'Template Second', value: 'Template Second' }]
const SenderTypes = [{ label: 'Send One', value: 'Send One' }, { label: 'Send Second', value: 'Send Second' }]
const formFields = [
    {
        "Type": "Text",
        "Label": "Name",
        "FieldName": "name",
        "Required": false,
        "MaxLength": 50
    },
    {
        "Type": "Text",
        "Label": "Content",
        "FieldName": "content",
        "Required": false
    },
    {
        "Type": "Select",
        "Label": "Template Id",
        "FieldName": "templateId",
        "Required": false
    },
    {
        "Type": "MultiSelect",
        "Label": "Sender Id",
        "FieldName": "senderId",
        "Required": false
    }
    // },
    // {
    //     "Type": "Select",
    //     "Label": "Create Sender Id",
    //     "FieldName": "createsenderId",
    //     "Required": false
    // }
    

]
class DltTemplate extends Component {

    constructor(props) {
        super(props);

        this.formFields = getFormFields(formFields);
        this.state = {

            dltTemplate: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            editDialog: false,
            focused: false,
            searchValue: '',
            senderTypes: SenderTypes,
            globalFilter: "",
            isShowAdd: false,
            columns: [],
            lazyParams: BasicLazyParams,
            globalSearch: '',
            templates: []


        }

        this.service = new Service();
    }

    handleFocus = () => {
        this.setState({
            focused: true,
            searchValue: ""
        });
    };


    componentDidMount() {
        this.getTemplates()

    }



    getTemplates = () => {
        this.setState({
            isLoading: true,
            templates: [],
            totalRecords: 0
        });
        let { lazyParams, globalSearch } = this.state;
        let url = `${baseUrlAdmin}/smstemplate?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        dltTemplate: res.res.data && res.res.data.templates && res.res.data.templates[0]||{},
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
                isShowConfigChange: false,
            }
        }, this.getTemplates);
    }

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTemplates);
    }

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTemplates);
    }

    onFilter = (event) => {
        event['first'] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getTemplates);
    }



    onSubmitClick = () => {
        const { dltTemplate } = this.state;
        const academicYear = localStorage.getItem('userAcademicYear')
        let payload = this.state.dltTemplate;

        payload.academicYear = academicYear
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(payload));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations});

        }
        else {
            if (!this.state.dltTemplate?._id) {
                this.setState({ isLoading: true })
                let url = `${baseUrlAdmin}/smstemplate`;
                this.service.post(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({
                            isLoading: false,
                            dltTemplate: {}
                        })
                        this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        this.getTemplates()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                    }
                })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Creating the fee type', life: 3000 });
                    });
            }
            else if (this.state?.dltTemplate?._id) {
                this.setState({ isLoading: true })
                payload._id = this.state?.dltTemplate?._id;
                let url = `${baseUrlAdmin}/smstemplate`;
                this.service.put(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({
                            isLoading: false,
                            dltTemplate: {},
                        })
                        this.getTemplates()
                        this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                    }

                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating the fee type', life: 3000 });
                });


            }

        }
    }


    // onSubmitCreate = () => {
    //     if (this.state?.dltTemplate?.createsenderId?.trim()) {
    //         let { dltTemplate } = this.state;
    //         this.setState(prevState => ({
    //             senderTypes: [
    //                 ...prevState.senderTypes,
    //                 { label: dltTemplate.createsenderId, value: dltTemplate.createsenderId }
    //             ],
    //             dltTemplate: {
    //                 ...prevState.dltTemplate,
    //                 createsenderId: '' // Clear only the createsenderId field
    //             }
    //         }));
    //     }
    // };


    render() {


        const { dltTemplate, formValidations } = this.state;

        return (
            <div>
                <div className=' grid col-12  mt-4'>

                    <div className='grid col-12'>
                        <div className="col-6">

                            <p className='transport-fee-form-label'>Template Name<span className='ma-required'>*</span></p>

                            <ClassetInputText width={'600px'} icon={<MailIcon />} placeholder={'Template Name'} value={dltTemplate?.name} onChange={(e) => { onTextChange(e.target.value, 'name', this, createRoleFields, dltTemplate, formValidations, 'dltTemplate', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error font-semibold">{formValidations.fields['name'].errorMsg}</p>}

                        </div>

                        <div className="col-6">
                            <p className='transport-fee-form-label'>DLT Template ID<span className='ma-required'>*</span></p>

                            <ClassetDropdown

                                width={'600px'}
                                //label={'DLT Template ID'}
                                icon={<AcademicSessionCalIcon />}
                                placeholder={'DLT Template ID'}
                                options={TemplateTypes}
                                value={dltTemplate?.templateId}
                                onChange={(e) => {
                                    onDropDownChange(e.value, 'templateId', this, formFields, dltTemplate, formValidations, 'dltTemplate', 'formValidations')
                                }}
                            />
                            {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}

                        </div>
                    </div>


                    <div className=' col-12'>
                        <div className='col-6 p-0 mt-3 '>
                            <p className='transport-fee-form-label'>Message Type<span className='ma-required'>*</span></p>


                            <ClassetTextArea
                                width={1000}
                                height={200}

                                value={dltTemplate?.content}
                                onChange={(e) => { onTextChange(e.target.value, 'content', this, createRoleFields, dltTemplate, formValidations, 'dltTemplate', 'formValidations') }}

                            />
                            {formValidations && !formValidations.fields['content'].isValid && <p className="p-error">{formValidations.fields['content'].errorMsg}</p>}
                            <p>(Note: Kindly Type Message content in the text box area. Avoid copy/paste and special characters.)</p>

                        </div>
                    </div>
                    <div className='grid col-12'>

                        <div className="col-6">
                            <p className='transport-fee-form-label'>Sender ID<span className='ma-required'>*</span></p>

                            <ClassetMultiSelect

                                width={'600px'}

                                icon={<AcademicSessionCalIcon />}
                                placeholder={'Sender ID'}
                                options={this.state?.senderTypes}
                                value={dltTemplate?.senderId}
                                onChange={(e) => {
                                    onDropDownChange(e.value, 'senderId', this, formFields, dltTemplate, formValidations, 'dltTemplate', 'formValidations')
                                }}
                            />
                            {formValidations && !formValidations.fields['senderId'].isValid && <p className="p-error">{formValidations.fields['senderId'].errorMsg}</p>}

                        </div>

                        {/* <div className="col-6">
                            <p className='transport-fee-form-label'>Create Sender ID<span className='ma-required'>*</span></p>
                            <div className="flex">
                                <div style={{ width: "660px", height: "48px" }}
                                    className={classNames('p-inputgroup', 'custom-input-group', `border-300 shadow-2 border-round-lg flex justify-content-center align-items-center`, { 'custom-input-focused': this.state.focused })}
                                    onClick={this.handleFocus}
                                >
                                    <InputText
                                        className='text-lg  no-border border-none'
                                        style={{ width: "489px", height: "48px" }}
                                        value={dltTemplate.createsenderId}
                                        onChange={(e) => {
                                            onTextChange(e.target.value, 'createsenderId', this, formFields, dltTemplate, formValidations, 'dltTemplate', 'formValidations')
                                        }} placeholder="Sender ID" />




                                    <Button className='submitButton'
                                        onClick={this.onSubmitCreate}>
                                        <p className='submitButtonText'>{`create`}</p>
                                    </Button>



                                </div>
                            </div>
                        </div> */}

                    </div>



                </div>




                <div className=" mt-4 ml-3">
                    <div className="button-wrapper">

                        <Button className='submitButton'
                            onClick={this.onSubmitClick}

                        >
                            <p className='submitButtonText'>{`${this.state.editDialog ? 'Update' : 'Save'}`}</p>
                        </Button>

                        <Button
                            className="cancelButton"

                            onClick={() => { }}>
                            <p className='cancelButtonText'>Cancel</p>

                        </Button>

                    </div>
                </div>


                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(DltTemplate);
