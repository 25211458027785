import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react';
import { Column } from 'react-formio/lib/types';
import { BasicLazyParams } from '../../../utile';
import { AddIconInButtonFull, ArrowClockWise, MailIcon } from '../../svgIcons';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
const timeTableData = [
    {
        day: 'Monday',
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        day: 'Tuesday',
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        day: 'Wednesday',
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        day: 'Thursday',
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        day: 'Friday',
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        day: 'Saturday',
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    }
];
export default class CreateTimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: BasicLazyParams,
            timeTableData: timeTableData,
            openCreateFeeDialog: false,
            searchType: 1,
            searchInput: ''
        };
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        });
    };
    render() {
        return (
            <div className="poppins24">
                <div className="col-12 flex justify-content-end align-items-center">
                    <Button
                        style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                        className="flex align-items-center gap-1"
                        // onClick={() => {
                        //     this.setState({ isDialogOpenForCreateConcession: true });
                        // }}
                    >
                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                        <p className=" text-xl">Save</p>
                    </Button>
                </div>

                <div>
                    <div className="grid m-2">
                        <div className=" col-3">
                            <ClassetDropdown
                                required={true}
                                label={'TimeTableTemplate'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select TimeTable Template'}
                                options={this.state?.branches}
                                value={this.state.branchId}
                                onChange={(e) => this.onChangeBranch(e.value)}
                                optionLabel="branchName"
                                optionValue="branchId"
                                disabled={this.props.selectedValue === 2}
                            />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown
                                required={true}
                                label={'Board'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Board'}
                                options={this.props.boards}
                                value={this.state?.boardId}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                optionLabel="boardName"
                                optionValue="boardId"
                                disabled={this.props.selectedValue === 2}
                            />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown
                                required={true}
                                label={'Grade'}
                                icon={<MailIcon />}
                                placeholder={'Select Grade'}
                                options={this.state?.classes}
                                value={this.state.classId}
                                onChange={(e) => this.onChangeClass(e.value)}
                                optionLabel="className"
                                optionValue="classId"
                                disabled={this.props.selectedValue === 2}
                            />
                        </div>

                        <div className=" col-3">
                            <ClassetDropdown
                                required={true}
                                label={'Section'}
                                icon={<MailIcon />}
                                placeholder={'Select Section'}
                                options={this.state?.sections}
                                value={this.state.sectionId}
                                onChange={(e) => this.onChangeSection(e.value)}
                                optionLabel="sectionName"
                                optionValue="sectionId"
                                disabled={this.props.selectedValue === 2}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex align-items-center gap-5">
                    <div className="flex mt-1">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4" style={{ fontWeight: 'bolder' }}>
                            Manual Creation
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4" style={{ fontWeight: 'bolder' }}>
                            Automatic Creation
                        </div>
                    </div>
                </div>

                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.timeTableData}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesTextt"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column
                        field="fee_type"
                        bodyClassName="text-center"
                        header="Days / Periods"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            onClick={() => {
                                                this.openCreateSubstituion(rowData);
                                            }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.day}</p>
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        field="approval"
                        bodyClassName="text-center"
                        header="Period 1"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        field="total_fee"
                        bodyClassName="text-center"
                        header="Period 2"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        field="concession"
                        bodyClassName="text-center"
                        header="Period 3"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        field="assigned_fee"
                        bodyClassName="text-center"
                        header="Period 4"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        field="paid_fee"
                        bodyClassName="text-center"
                        header="Period 5"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        field="paid_fee"
                        bodyClassName="text-center"
                        header="Period 6"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        field="paid_fee"
                        bodyClassName="text-center"
                        header="Period 7"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        field="paid_fee"
                        bodyClassName="text-center"
                        header="Period 8"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                        <span
                                            data-pr-tooltip="Edit User"
                                            data-pr-position=""
                                            className={''}
                                            // onClick={() => {
                                            //     this.openCreateSubstituion(rowData);
                                            // }}
                                        >
                                            <ArrowClockWise width={10} height={10} color={'#000000'} />
                                        </span>
                                    </div>
                                    <p>{rowData?.approval}</p>
                                </>
                            );
                        }}
                    />
                </DataTable>
            </div>
        );
    }
}
