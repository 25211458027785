import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import '../style.scss';
import { MailIcon, TickBgIcon, ArrowClockWise, InputIcon, CodeLinkIcon, CalendarIcon } from '../../svgIcons';
import { BasicLazyParams } from '../../../utile';
import { MultiSelect } from 'primereact/multiselect';
import BranchToSectionDDCol from '../../BaseDropdownComponents/BranchToSectionDDCol';
import BoardToSectionDDCol from '../../BaseDropdownComponents/BoardToSectionDDCol';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const options = [
    { value: 1, label: 'Fee Type Based' },
    { value: 2, label: 'Term Based' }];




const feeTypeData = [
    {
        fee_type: "Monday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Tuesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Wednesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Thursday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Friday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Saturday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },


];
const searchOptions = [
    { value: 1, label: "Student Name" },
    { value: 2, label: "Enrollment Number" }
]

const paymentType = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Cheque' },
    { value: 3, label: 'DD' },
    { value: 4, label: 'Online' },
    { value: 5, label: 'Credit Card' },
    { value: 6, label: 'Debit Card' },
    { value: 7, label: 'QR Code' },
]
export class RequestSubstitution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            feeTypeData: feeTypeData,
            paymentType: paymentType,
            openCreateSubstituionDialog: false,
            searchType: 1,
            searchInput: ""
        }
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData: dropdownsData
        },
        )
    }

    openCreateSubstituion = () => {
        this.setState({
            openCreateSubstituionDialog: true,
        })
    }

    render() {
        return (
            <div className="m-2">




                <div className='grid m-2 mb-6'>
                    <BoardToSectionDD setValues={this.setDropdownValues} />
                </div>


                {
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.feeTypeData}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >

                        <Column field="fee_type" bodyClassName='text-center' header="Days / Periods" alignHeader={"center"} />
                        <Column field="approval" bodyClassName='text-center' header="Period 1" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="total_fee" bodyClassName='text-center' header="Period 2" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="concession" bodyClassName='text-center' header="Period 3" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="assigned_fee" bodyClassName='text-center' header="Period 4" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 5" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 6" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 7" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 8" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>




                    </DataTable>

                }

                <Dialog
                    visible={this.state.openCreateSubstituionDialog}
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    className='ma-alert-box w-3 mr-0'
                    style={{ width: "300px", height: "600px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h3 className="formhead text-center ">Request Substitution</h3>
                        </div>)
                    }}
                    footer={() => (
                        <div className="mt-3" style={{ display: 'flex' }}>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ isDialogOpenForCreateConcession: true }) }}>
                                <p className='text-xl ml-2'>Request Substitution</p>
                            </Button>
                            <Button style={{ borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ openCreateSubstituionDialog: false }) }}>
                                <p className='text-xl'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ openCreateSubstituionDialog: false })}
                >
                    <div className='flex flex-column justify-content-center mx-3'>

                        <div className='mt-3'>
                            <ClassetTextArea
                                style={{ height: "119px" }}
                                label={'Reason for Substitution'}
                                required={true}
                                placeholder="Add Remarks" />
                        </div>

                        <div className='mt-3' >
                            <ClassetInputText
                                label={'Period Number'}
                                id='title'
                                icon={<MailIcon />}
                                placeholder="Period"
                                disabled={true}
                            />
                        </div>

                        <div className='mt-3' >
                            <ClassetInputText
                                label={'Day of the week '}
                                id='title'
                                icon={<MailIcon />}
                                disabled={true}
                                placeholder="Period"
                            />
                        </div>

                        <div className="mt-3">
                            <ClassetCalendar
                                icon={<MailIcon />}
                                className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                                calendarMode={'single'}
                                placeholder={'Date'}
                                disabled={true}
                                label={'Date'}

                            />

                        </div>
                        <div className="mt-3">
                            <BoardToSectionDDCol setValues={this.setDropdownValues} boardDisable={true} classDisable={true} branchDisable={true} sectionDisable={true} />
                        </div>
                    </div>
                </Dialog>




            </div>
        )
    }
}






export default RequestSubstitution