import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import withRouter from '../../lib/withRouter';
import { getBoardsData } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import AssignTransport from './assignTransport';

class AssignManagementTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignTransportForm: false,
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            vehicleDropdown: [],
            selectedBuses: {},
            selectedStops: {},
            routeData: [],
            selectedStudentPickup: {},
            selectedStudentDrop: {},
            planId: '',
            busNo: null,
            boardId: null,
            classId: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        };
        this.service = new Service();
    }

    componentDidMount() {}

    onCancel = () => {
        this.setState(
            {
                assignTransportForm: false,
                dataForAddRoutes: {}
            },
            () => this.getRoutesDataForDropdown()
        );
    };

    getRoutesDataForDropdown = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/transport-route/get-routes?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            academicYear: academicYear
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                const routeName = res?.res?.data?.routeData.map((routeName) => ({
                    label: routeName.routeName,
                    value: routeName._id
                }));
                this.setState({ vehicleDropdown: routeName, routeData: res?.res?.data?.routeData, isLoading: false ,transportSettings :res?.res.data.transportSettings}, () => this.getAssignTransportData());
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });

                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.branchData !== this.props.branchData) {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            };
            this.setState({
                sectionsData
            });
        }
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [],
            classId: null,
            curriculumInfo: null,
            isShowSubjects: false
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard?.classes && selectedBoard?.classes?.length > 0) {
            selectedBoard?.classes.forEach((item) => _classes?.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName;
        this.setState({
            classes: _classes,
            boardId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedBoardName: selectedBoardName
        });
    };

    onChangeClass = (classId) => {
        let selectedClass = this.state?.classes?.find((classd) => classd.value == classId);
        this.setState({
            classId: classId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedClassName: selectedClass?.label,
            groupId: selectedClass.groupId
        });
    };

    getAssignTransportData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });

        let url = `${baseUrlAdmin}/transport-route/students/getforRoutes?boardId=${this.state.dropdownsData.boardId}&gradeId=${this.state.dropdownsData.classId}&sectionId=${this.state.dropdownsData.sectionId}&academicYear=${academicYear}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const users = res?.res?.data?.addmissionTransport;
                const selectedBuses = {};
                const selectedStops = {};
                const selectedStudentPickup = {};
                const selectedStudentDrop = {};

                users.forEach((user, index) => {
                    selectedBuses[index] = user.transportRoute;
                    selectedStudentPickup[index] = user.pickStop;
                    selectedStudentDrop[index] = user.dropStop;
                    const route = this.state.routeData.find((route) => route._id === user.transportRoute);
                    if (route) {
                        selectedStops[index] = route.stops;
                    }
                });

                this.setState({
                    users,
                    selectedBuses,
                    selectedStops,
                    selectedStudentPickup,
                    selectedStudentDrop,
                    isLoading: false
                },);
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.filterUsersData);
    };


    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            async () => {
                await this.getRoutesDataForDropdown();
            }
        );
    };
    onAssignTransport = (rowData) => {
        this.setState({ assignTransportForm: true, dataForAddRoutes: rowData });
    };

    render() {
        return (
            <>
                <div className="mt-2 flex justify-content-start align-items-center">
                    <BoardToSectionDD setValues={this.setDropdownValues} />
                </div>
                <div className="card datatable-crud-demo mt-2">
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        responsiveLayout="scroll"
                        showGridlines
                        onPage={this.onPage}
                        rows={10}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}
                    >
                        <Column
                            style={{ width: '10rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            header="S.No."
                            className="datatable-cell"
                            body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                    </>
                                );
                            }}
                        />

                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="studentName" field="name" header="Student Name" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="parent" field="parent" header="Parent Name" />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="contactDetails"
                            field="phone_number"
                            header="Contact Details"
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="Approval Status"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <Button
                                        className="formCancelButton"
                                        style={{
                                            backgroundColor: rowData?.isAssigned ? '#E1F7D8' : '#FFECF1',
                                            borderColor: 'lightgray',
                                            borderWidth: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 15,
                                            padding: 0,
                                            height: '32px',
                                            width: '100px'
                                        }}
                                    >
                                        <p
                                            className="sideHeading"
                                            style={{
                                                fontSize: 12,
                                                lineHeight: '16px',
                                                color: rowData?.isAssigned ? '#1E6D00' : '#BF0031'
                                            }}
                                        >
                                            {rowData?.isAssigned ? 'ASSIGNED' : 'NOT ASSIGNED'}
                                        </p>
                                    </Button>
                                );
                            }}
                        ></Column>
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="Route No"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <ClassetDropdown
                                        optionLabel={'label'}
                                        optionValue={'value'}
                                        width={'169px'}
                                        height={'48px'}
                                        className="text-xl add-vehicle-field-input"
                                        value={this.state.selectedBuses[rowIndex] || null}
                                        options={this.state.vehicleDropdown}
                                        onChange={(e) => {
                                            const selectedRouteId = e.target.value;
                                            const selectedBuses = { ...this.state.selectedBuses, [rowIndex]: selectedRouteId };
                                            const selectedRoute = this.state.routeData.find((route) => route._id === selectedRouteId);
                                            const selectedStops = { ...this.state.selectedStops, [rowIndex]: selectedRoute ? selectedRoute.stops : [] };
                                            this.setState({ selectedBuses, selectedStops });
                                        }}
                                        disabled={rowData?.isClosed === false}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className="text-lg pl-1"> Select Bus</span>
                                            </div>
                                        }
                                    />
                                );
                            }}
                        ></Column>
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="PickUp Location"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <ClassetDropdown
                                        optionLabel={'label'}
                                        optionValue={'value'}
                                        width={'169px'}
                                        height={'48px'}
                                        className="text-xl add-vehicle-field-input"
                                        value={this.state?.selectedStudentPickup[rowIndex] || null}
                                        options={
                                            this.state?.selectedStops[rowIndex]?.map((stop) => ({
                                                label: stop.stopName,
                                                value: stop._id
                                            })) || []
                                        }
                                        disabled={rowData?.isClosed === false}
                                        onChange={(e) => {
                                            const selectedStudentPickup = { ...this.state.selectedStudentPickup, [rowIndex]: e.target.value };
                                            const routeName = this.state.routeData.find((route) => route._id === this.state.selectedBuses[rowIndex]);
                                            let selectedPickUpStop = routeName?.stops?.find((stops) => stops._id === e.target.value);
                                            this.setState((prevState) => ({
                                                selectedStudentPickup: selectedStudentPickup,
                                                users: prevState.users.map((user, index) =>
                                                    index === rowIndex
                                                        ? {
                                                              ...user,
                                                              routeNo: e.target.value,
                                                              routeName: routeName?.routeName,
                                                              vehicleRegistrationNo: routeName?.vehicleRegistrationNo,
                                                              vehicleName: routeName?.vehicleName,
                                                              selectedPickUpStop,
                                                              routeInfo: routeName
                                                          }
                                                        : user
                                                )
                                            }));
                                        }}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className="text-lg pl-1"> Select Location</span>
                                            </div>
                                        }
                                    />
                                );
                            }}
                        ></Column>
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="Drop Location"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <ClassetDropdown
                                        optionLabel={'label'}
                                        optionValue={'value'}
                                        width={'169px'}
                                        height={'48px'}
                                        className="text-xl add-vehicle-field-input"
                                        value={this.state?.selectedStudentDrop[rowIndex] || null}
                                        options={
                                            this.state?.selectedStops[rowIndex]?.map((stop) => ({
                                                label: stop.stopName,
                                                value: stop._id
                                            })) || []
                                        }
                                     /*    onChange={(e) => {
                                            const selectedStudentDrop = { ...this.state.selectedStudentDrop, [rowIndex]: e.target.value };
                                            this.setState({ selectedStudentDrop });
                                        }} */

                                        onChange={(e) => {
                                            const selectedStudentDrop = { ...this.state.selectedStudentDrop, [rowIndex]: e.target.value };
                                            const routeName = this.state.routeData.find((route) => route._id === this.state.selectedBuses[rowIndex]);
                                            let selectedDropStop = routeName?.stops?.find((stops) => stops._id === e.target.value);
                                            this.setState((prevState) => ({
                                                selectedStudentDrop: selectedStudentDrop,
                                                users: prevState.users.map((user, index) =>
                                                    index === rowIndex
                                                        ? {
                                                              ...user,
                                                              routeNo: e.target.value,
                                                              routeName: routeName?.routeName,
                                                              vehicleRegistrationNo: routeName?.vehicleRegistrationNo,
                                                              vehicleName: routeName?.vehicleName,
                                                              selectedDropStop,
                                                              routeInfo: routeName
                                                          }
                                                        : user
                                                )
                                            }));
                                        }}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className="text-lg pl-1"> Select Location</span>
                                            </div>
                                        }
                                    />
                                );
                            }}
                        ></Column>
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="Assign"
                            body={(rowData, { rowIndex }) => {
                                const style = {
                                    color: 'black',
                                    backgroundColor: rowData?.transportRoute ? '#C4F3C7' : '#F1BFB8',
                                    padding: '0.5rem',
                                    borderRadius: '7px'
                                };
                                return (
                                    <Button
                                        label="Assign"
                                        disabled={!this.state.selectedBuses[rowIndex] && !this.state?.selectedStudentPickup[rowIndex] ? true : false}
                                        onClick={() => this.onAssignTransport(rowData, rowIndex)}
                                        className="confirmDialogAcceptBtn  mr-5 "
                                    />
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
                <Dialog
                    blockScroll={true}
                    visible={this.state.assignTransportForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    dismissableMask={false}
                    closable={true}
                    style={{
                        width: '90%',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    header={() => {
                        return <h2 className="text-center text-5xl mb-4 font-bold">Assign Transport</h2>;
                    }}
                    onHide={() => {
                        this.setState({
                            assignTransportForm: false,
                            dataForAddRoutes: {}
                        });
                    }}
                >
                    <div className="">
                        <AssignTransport onCancel={this.onCancel} selectedHierarchy={this.state?.dropdownsData} dataForAddRoutes={this.state.dataForAddRoutes} transportSettings={this.state.transportSettings || {}}/>
                    </div>
                </Dialog>

                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha')
});

export default connect(mapStateToProps, { getBoardsData })(Authentication(withRouter(AssignManagementTable)));
