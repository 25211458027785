import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './ClassetComponents.scss';  // Import the CSS file
import ClassetInputText from './classetInputText';
import { CalendarIcon, ClipIcon, MailIcon } from '../components/svgIcons';
import ClassetCalendar from './classetCalender';
import ClassetDropdown from './classetDropDown';

const fieldOptions = [
    { label: 'Text', value: 'text', },
    { label: 'Number', value: 'number' },
    { label: 'Date', value: 'date' }
];

class DynamicForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: props.parsedFields?props.parsedFields:[],
            labelFormBefore: ''
        };
    }

    addField = () => {
        this.setState((prevState) => ({
            fields: [...prevState.fields, { type: 'text', value: '' }]
        }));
    };


    removeField = (index) => {
        this.setState((prevState) => ({
            fields: prevState.fields.filter((_, i) => i !== index)
            
        }),() => {
            localStorage.setItem('fields', JSON.stringify(this.state.fields))
            console.log("navyaset", this.state.fields)
        });
    };

    save = () => {
        console.log("navya", this.state.fields)
        

    };

    handleFieldChange = (index, type, value, label) => {
        
        console.log(type, "index", label, value)
        const fields = [...this.state.fields];
        console.log(fields, "fieldsfields")
        fields[index][type] = value;
        // this.setState({ fields });
        this.setState({ fields }, () => {
            localStorage.setItem('fields', JSON.stringify(fields));
        });
    };

    renderField = (field, index) => {
        const { type, value, label } = field;
        const { labelFormBefore } = this.state
        console.log(type, "type", label)
        switch (type) {
            case 'text':

                return <>


                    <div style={{ marginTop: 18 }}>

                        <ClassetInputText
                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                            className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                            height="48px"
                            width='368px'
                            value={value}
                            onChange={(e) => { this.handleFieldChange(index, 'value', e.target.value, label) }}
                            placeholder={label}

                        />


                    </div>
                </>
                    ;
            case 'number':
                return <>

                    <div style={{ marginTop: 18 }}>

                        <ClassetInputText
                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                            className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                            height="48px"
                            width='368px'
                            value={value}
                            type='number'
                            onChange={(e) => { this.handleFieldChange(index, 'value', e.target.value, label) }}
                            placeholder={label}
                        //label={'kkkee'}


                        />

                    </div>

                </>

            case 'date':
                return <>

                    <div style={{ marginTop: 18 }}>


                        <ClassetCalendar
                            icon={<CalendarIcon width={24} height={24} color={"black"} />}
                            className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                            calendarMode={'single'}
                            value={new Date(value)}
                            onChange={(e) => this.handleFieldChange(index, 'value', e.value, label)}
                            placeholder={label}
                        // label={'kkkee22'}

                        />
                    </div>
                </>
            default:
                return null;
        }
    };

    render() {
        return (
            <div>
                <h3>Custom Fields</h3>
                {this.state.fields.map((field, index) => (
                    <div key={index} className="grid col-12">

                        <div className="col-3">
                            <ClassetDropdown
                                className='border-round-md no-border custom-field-focused border-none pl-3 body-medium '
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                value={field.type}
                                options={fieldOptions}
                                onChange={(e) => this.handleFieldChange(index, 'type', e.value)}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className='text-xl'>Select  Type</span>
                                    </div>
                                )}
                                label={`Select  Type`}
                            //labelClassName="m-15"
                            />
                        </div>


                        <div className="col-3">
                            <ClassetInputText
                                icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                                height="48px"
                                width='368px'
                                value={field.label}
                                onChange={(e) => { this.handleFieldChange(index, 'label', e.target.value) }}
                                placeholder="Label"
                                //labelClassName="m-15"
                                label={`Label Name`}
                            />


                        </div>
                        {/* <div className="col-3">


                            {this.renderField(field, index)}
                        </div> */}
                        <div className="col-3">


                            <Button
                                className="cancelButtonForm"
                                onClick={() => { this.removeField(index) }}>
                                <p className='cancelButtonTextForm'>Remove</p>

                            </Button>

                        </div>
                    </div>
                ))}



                <div className="flex m-3" >
                    <Button icon={'pi pi-plus-circle px-2  py-2text-lg text-align-center '} onClick={this.addField} className='addButtonForm '>
                        <p className='addButtonTextForm'> Add</p>
                    </Button>

                    {/* <Button icon={'pi pi-plus-circle px-2  py-2text-lg text-align-center '} onClick={this.save} className='addButtonForm '>
                        <p className='addButtonTextForm'> save</p>
                    </Button> */}
                </div>



            </div>
        );
    }
}

export default DynamicForm;
