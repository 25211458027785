import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { AddIconInButtonFull, AddUserIcon, ClassetEditIcon, CodeLinkIcon, EditIcon, InputIcon, MailIcon, MoneyIcon, PlusBlackIcon } from '../svgIcons';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO, States, paymentTerms } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetTextArea from '../../classetComponents/classetTextArea';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../utile/formHelper';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import { getBoardsData } from '../../store/actions';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';





const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Vehicle Management' },
    { label: 'Route Management' },
    { label: 'Assign Management' },
    { label: 'Transport Fee ' },
];



const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]
const accountTypeOptions = [
    { value: 'CA', label: "Current" },
    { value: 'SA', label: "Savings" },
]
const countryOptions = [
    { value: 'I', label: "India" },
]

const createBankFields = require('./financeBank.json')

class Banking extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createBankFields);

        this.state = {
            bank: this.props?.editData?.id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            currentTab: 0,
            concessions: [],
            bankCreateDialog: false,
            bankFeeDialog: false,
            selectedMappingMethod: 'feeType',
            selectedFeeeType: null,
            mappingType: 'FET',
            isLoading: false,
            bankingDetails: [],
            edit: false,
            boardsInfo: [{ boardId: '', classId: [] }],
            feeTypeOptions: [],
            bankMappingDetails: {},
            academicYear: '',
            per: ''


        };
        this.service = new Service()
    }

    handleRadioChange = (value) => {
        this.setState({
            mappingType: value,
        });
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Transport Management</h4>
            </React.Fragment>
        )
    }


    componentDidMount() {
        this.props?.getBoardsData(true)
        this.getBankDetails()
        this.getFeeTypeOptions()
        this.getFeeCategoryOptions()
    }

    componentDidUpdate(prevProps, prevState) {

    }

    addNewConcession = () => {
        this.setState(prevState => ({
            concessions: [...prevState.concessions, { id: Date.now(), label: 'New Concession', description: 'Description here' }]
        }));
    }

    onMappingMethodChange = (e) => {
        this.setState({ selectedMappingMethod: e.value });
    };
    onInstallmentChange = (e) => {
        this.setState({ selectedInstallment: e.value });
    };

    onCreateBanking = () => {
        this.setState({ isLoading: true })
        let data = cloneDeep(this.state.bank)
        const formStatus = isFormValid(createBankFields, this.formFields.formValidations, data);
        let url = `${baseUrlAdmin}/bank`;
        const academicYear = localStorage.getItem('userAcademicYear')
        data.academicYear = data.academicYear || academicYear
        data.status = data.status || true
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            if (!this.state.edit && !data?._id?.length) {

                this.service.post(url, data, true).then(data => {
                    if (data?.status) {
                        this.setState({ bank: {}, bankCreateDialog: false, isLoading: false, })
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Bank created successfully', life: 3000 });
                        this.getBankDetails()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating Bank', life: 3000 });
                    }
                })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating Bank', life: 3000 });
                    });

            } else if (this.state.edit && data?._id?.length) {
                data["_id"] = data._id
                this.service.post(url, data, true).then(data => {
                    if (data?.status) {
                        this.setState({ bankCreateDialog: false, edit: false, bank: {}, isLoading: false })
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Bank updated successfully', life: 3000 });
                        this.getBankDetails()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating Bank', life: 3000 });
                    }
                })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating Bank', life: 3000 });
                    });

            }
        }

    }


    getBankDetails = () => {
        this.setState({
            isLoading: true,
        });
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/bank?academicYear=${AcademicYear}`
        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data) {

                    this.setState({
                        bankingDetails: data?.res?.data,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }
    onToggle = (e, bank) => {
        this.setState({
            isLoading: true,
        });
        delete bank.bankAssingedInfo
        bank["_id"] = bank._id
        bank.status = e.target.value
        let url = `${baseUrlAdmin}/bank`;
        this.service.post(url, bank, true).then(data => {
            if (data?.status) {
                this.setState({ bank: '', isLoading: false })

                this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Bank Status updated successfully', life: 3000 });
                this.getBankDetails()
            } else {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating Bank Status', life: 3000 });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error while updating Bank Status', life: 3000 });
            });



    }
    onEditBank = (rowData) => {
        this.setState({ bankCreateDialog: true, bank: rowData, edit: true })
    }
    handleCloseCreateBank = () => {
        this.setState({
            bankCreateDialog: false,
            bank: {}
        })

    }
    openCreateBank = () => {
        this.setState({ bankCreateDialog: true })
    }
    onHandleBankFeeMapping = (rowData, assignInfo) => {
        this.setState({
            bankFeeDialog: true, bankMappingDetails: rowData,
            mappingType: assignInfo?.mappingType || 'FET',
            feeTypes: assignInfo?.feeTypes,
            feeCategory: assignInfo?.feeCategory || this.state.feeCategory,
            per: assignInfo?.per,
            boardsInfo: assignInfo?.boardsInfo || [{ boardId: '', classId: [] }],
            installments: assignInfo?.installments,
            academicYear: assignInfo?.academicYear


        })
    }
    onCloseBankFeeMapping = () => {
        this.setState({
            bankFeeDialog: false,
            mappingType: 'FET',
            feeTypes: [{}],
            feeCategory: '',
            per: '',
            academicYear: ''

        })

    }
    addBoard = () => {
        this.setState((prevState) => ({
            boardsInfo: [...prevState.boardsInfo, { boardId: '', classId: [] }]

        }))

    }

    handleBoardDropDown = (index, field, value) => {
        this.setState((prevState) => {
            const boardsInfo = [...prevState.boardsInfo];
            boardsInfo[index][field] = value;
            return { boardsInfo };
        });


    }
    handleClassDropDown = (index, field, value) => {
        this.setState((prevState) => {
            const boardsInfo = [...prevState.boardsInfo];
            boardsInfo[index][field] = value;
            return { boardsInfo };
        });


    }
    getFeeTypeOptions = () => {
        let url = `${baseUrlAdmin}/fee-types`;
        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data?.response) {


                    this.setState({
                        feeTypeOptions: data?.res?.data?.response,
                    })
                }
            }
        });
    }
    getFeeCategoryOptions = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = {}
        payload.academicYear = academicYear

        let url = `${baseUrlAdmin}/fee-category/filtered`;
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each.categoryName,
                    value: each._id
                }
            })

            if (data?.status && data?.res?.data) {

                this.setState({ feeCategoriesOptions: options })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee category options', life: 3000 });

            }

        })
    }
    OnCreateBankMapping = () => {
        this.setState({
            isLoading: true,
        });
        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = {}
        let url = `${baseUrlAdmin}/bank/bank-fee`
        if (this.state.mappingType === 'FET') {
            payload.bankId = this.state?.bankMappingDetails?._id;
            payload.mappingType = this.state?.mappingType;
            payload.feeTypes = this.state?.feeTypes;
            payload.academicYear = this.state.academicYear || academicYear;

        } else if (this.state.mappingType === 'INS') {
            payload.bankId = this.state.bankMappingDetails._id;
            payload.mappingType = this.state.mappingType;
            payload.feeTypes = this.state.feeTypes;
            payload.installments = this.state.installments;
            payload.academicYear = this.state.academicYear || academicYear;

        } else if (this.state.mappingType === 'CLA') {
            payload.bankId = this.state.bankMappingDetails?._id;
            payload.mappingType = this.state.mappingType;
            payload.boardsInfo = this.state.boardsInfo;
            payload.academicYear = this.state.academicYear || academicYear;

        } else if (this.state.mappingType === 'PER') {
            payload.bankId = this.state.bankMappingDetails?._id;
            payload.mappingType = this.state.mappingType;
            payload.feeTypes = this.state.feeTypes;
            payload.feeCategory = this.state.feeCategory;
            payload.per = this.state.per;
            payload.academicYear = this.state.academicYear || academicYear;

        }

        let assignInfo = this.state?.bankMappingDetails?.bankAssingedInfo
        let flag1 = this.state?.mappingType === 'FET' || this.state?.mappingType === 'PER' ? (this.state?.feeTypes !== undefined && this.state?.feeTypes?.length) : true
        let flag2 = this.state?.mappingType === 'INS' ? (this.state?.installments !== undefined && this.state?.installments?.length) : true
        let flag3 = this.state?.mappingType === 'CLA' ? this.state?.boardsInfo?.every((each) => {
            if (each?.boardId !== "" && each?.boardId !== undefined && each?.classId?.length) {
                return true
            }
            return false

        }) : true
        let flag4 = this.state?.mappingType === 'PER' ? (this.state?.per !== "" && this.state?.per !== undefined && this.state?.feeCategory?.length) : true

        if (flag1 && flag2 && flag3 && flag4) {
            if (assignInfo._id) {
                this.service.post(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({ isLoading: false, bankMappingDetails: {}, bankFeeDialog: false })
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Bank Mapping Updated successfully', life: 3000 });
                        this.getBankDetails()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating Bank Mapping', life: 3000 });
                    }
                })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating Bank Mapping', life: 3000 });
                    });
            } else {
                this.service.post(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({ bankMappingDetails: {}, bankFeeDialog: false, isLoading: false, })
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Bank Mapping successfully Created', life: 3000 });
                        this.getBankDetails()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating Bank Mapping', life: 3000 });
                    }
                })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating Bank Mapping', life: 3000 });
                    });


            }
        } else {
            if (!flag1) {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Select Fee Types', life: 3000 });
            } else if (!flag2) {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Select Installments', life: 3000 });

            } else if (!flag3) {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Select Board & class', life: 3000 });

            } else if (!flag4) {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Validation Failed', detail: 'Please Enter Percentage and fee Categories', life: 3000 });
            }
        }


    }


    render() {

        const { bank, formValidations, bankingDetails, feeTypeOptions } = this.state


        return (
            <>
                <div>

                    <div className="admin-management">

                        <div className="grid mb-6">
                            <div className='col-11 flex justify-content-end align-items-center'>
                                <Authorizer permId={PERMISSIONS.CREATE_BANKING} >
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={this.openCreateBank}>
                                        <p className=' text-xl'>Add Bank Details</p>
                                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                                    </Button>
                                </Authorizer>
                            </div>

                        </div>
                        <div className='grid doubts-tabs'>
                            {
                                bankingDetails.map((bank, index) => {
                                    return <div
                                        className="mx-6 card cursor-pointer col-3 doubts-tab-w"
                                        style={this.state.currentTab === index ? { height: '170px', width: '320px' } : { height: '170px', width: '320px' }}
                                    >
                                        <div className="flex flex-column justify-content-center align-items-center ml-3">
                                            <Authorizer permId={PERMISSIONS.UPDATE_BANKING} >
                                                <div className='mt-1 w-full flex justify-content-end align-items-center'>
                                                    <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center'
                                                        onClick={() => this.onEditBank(bank)}
                                                    >
                                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                    </div>
                                                    <div className="">
                                                        <InputSwitch
                                                            className='mt-1'
                                                            onChange={(e) => { this.onToggle(e, bank) }}
                                                            checked={bank.status} />
                                                    </div>
                                                </div>
                                            </Authorizer>
                                            <div className="w-full flex mt-4  ml-3">
                                                <h3 className='Montserrat24'>{bank.name}</h3>
                                            </div>
                                            <Authorizer permId={PERMISSIONS.ASSIGN_BANKING} >
                                                <div style={{ marginLeft: 'auto' }} className='mt-4 d-flex  mr-3 justify-content-end align-items-center'
                                                    onClick={(e) => this.onHandleBankFeeMapping(bank, bank?.bankAssingedInfo)}
                                                >
                                                    <AddUserIcon width={32} height={32} color={'#000000'} />
                                                </div>
                                            </Authorizer>
                                        </div>
                                    </div>
                                })
                            }
                        </div>


                        {/* <div className='grid justify-content-between' style={{ marginTop: '120px' }}>
                            <div className='ml-8'>
                                <h5 className='font-bold'>Total Fee in all Active Bank Accounts</h5>
                                <p>90% is allocated, 10% is not</p>
                            </div>
                            <div className='flex justify-content-center mb-4 mr-6 gap-2'>
                                <div className=' flex justify-content-end align-items-center'>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => {
                                        // this.setState({ isDialogOpenForCreateConcession: true })
                                    }}>
                                        <p className=' '>Accept All</p>
                                    </Button>
                                </div>
                                <div className=' flex justify-content-end align-items-center'>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#E0E0E0', border: 'none' }} onClick={() => {
                                        // this.setState({ isDialogOpenForCreateConcession: true }) 
                                    }}>
                                        <p className='  ' style={{ color: 'black' }}>Reject All</p>
                                    </Button>
                                </div>

                            </div>
                        </div> */}
                        {this.state.isLoading && <LoadingComponent />}

                    </div>

                    <Dialog
                        visible={this.state.bankFeeDialog}
                        draggable={false}
                        open={this.state.bankFeeDialog}
                        closeOnEscape={false}
                        position={"right"}
                        className='ma-alert-box'
                        style={{
                            width: "90%",
                            maxWidth: "450px",
                            minHeight: "700px",
                            maxHeight: "90%",

                        }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="text-center text-black-alpha-90 font-bold">Bank-Fee Mappping</h4>
                            </div>)
                        }}
                        // footer={() => (
                        //     <div className="mt-3" style={{ display: 'flex' }}>
                        //         <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={this.OnCreateBankMapping}>
                        //             <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                        //             <p className='text-xl ml-2'>{this.state?.bankMappingDetails?.bankAssingedInfo?._id ? 'Update' : 'Add'}</p>
                        //         </Button>
                        //         <Button style={{ borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        //             onClick={this.onCloseBankFeeMapping}
                        //         >
                        //             <p className='text-xl'>Cancel</p>
                        //         </Button>
                        //     </div>
                        // )}
                        onHide={this.onCloseBankFeeMapping}
                    >

                        <div className='flex flex-column justify-content-center mx-3'>

                            <div className="col-12" >
                                <p className='ma-label-s2  font-bold  text-black  label ttext' style={{ fontSize: "16px" }} >Automatic Mapping Methods: </p>
                                <div className="flex">
                                    <div className="mt-3">
                                        <RadioButton
                                            name='mappingType'
                                            value="FET"
                                            id="FET" onChange={(e) => this.handleRadioChange(e.value)} checked={this.state.mappingType === 'FET'} />
                                        <label className='ml-3 -mt-4 text-black font-bold label' style={{ fontSize: '16px' }}>Fee Type Based</label>
                                    </div>
                                    <div className="mt-3" style={{ marginLeft: "24px" }}>
                                        <RadioButton id='INS' name='INS' value='INS' onChange={(e) => this.handleRadioChange(e.value)} checked={this.state?.mappingType === 'INS'} />
                                        <label className='ml-3 -mt-4 text-black font-bold label' style={{ fontSize: '16px' }}>Installment Based</label>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="mt-3">
                                        <RadioButton name='mappingType' id="CLA" value='CLA' onChange={(e) => this.handleRadioChange(e.value)} checked={this.state?.mappingType === 'CLA'} />
                                        <label className='ml-3 -mt-4 text-black font-bold label ' style={{ fontSize: '16px' }}>Class based</label>
                                    </div>

                                    <div className="mt-3" style={{ marginLeft: "48px" }} >
                                        <RadioButton id="PER" value='PER' name='mappingType' onChange={(e) => this.handleRadioChange(e.value)} checked={this.state?.mappingType === 'PER'} />
                                        <label className='ml-3 -mt-4 text-black font-bold label' style={{ fontSize: '16px' }}>Percentage Based</label>
                                    </div>
                                </div>
                            </div>

                            {this.state?.mappingType === 'FET' && (
                                <div className="col-12 md:col-12 lg:col-12 ">
                                    <label className='label'>Fee Types<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetMultiSelect
                                            // id="feeTypes"
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            className=' no-border text-xl'
                                            options={feeTypeOptions || []}
                                            value={this.state.feeTypes}
                                            //  value={(option) => feeTypeOptions?.find(feeTypeOptions._id === option._id)}
                                            onChange={(e) => this.setState({ feeTypes: e.value })}
                                            placeholder={<div className='text-xl'>
                                                <span>
                                                    Select Fee Types

                                                </span>
                                            </div>}
                                            optionValue='_id'
                                            optionLabel='feeType'
                                        />

                                    </div>


                                </div>
                            )}

                            {this.state?.mappingType === 'INS' && (

                                <div className="col-12 ">
                                    <label className='label'>Installments<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetMultiSelect
                                            // id='user_id'
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            className='no-border text-2xl'
                                            options={paymentTerms}
                                            value={this.state.installments}
                                            onChange={(e) => this.setState({ installments: e.value })}
                                            placeholder={<div className='text-xl'><span>Select Installments</span></div>}
                                        />

                                    </div>

                                </div>

                            )}


                            {this.state?.mappingType === 'CLA' ? (
                                <div>

                                    {this.state?.boardsInfo?.map((each, index) => {
                                        return (
                                            <>
                                                <div key={index}>
                                                    <div className="col-12 ">
                                                        <label className='label'>Board<span className='ma-required'>*</span></label>
                                                        <div className='mt-2'>
                                                            <ClassetDropdown
                                                                id='boardId'
                                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                                className=' no-border text-2xl'
                                                                // options={this.props?.boards.map((each) => {
                                                                //     return {
                                                                //         label: each?.boardName,
                                                                //         value: each?.boardId

                                                                //     }
                                                                // })}
                                                                options={this.props?.boards}
                                                                optionLabel="boardName"
                                                                optionValue="boardId"
                                                                value={each?.boardId}
                                                                onChange={(e) => this.handleBoardDropDown(index, 'boardId', e.value)}
                                                                placeholder={<div className='text-xl'><span>Select Board</span></div>}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-12 ">
                                                        <label className='label'>Grade<span className='ma-required'>*</span></label>
                                                        <div className='mt-2'>
                                                            <ClassetMultiSelect
                                                                id='classIds'
                                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                                className=' no-border text-2xl'
                                                                options={this.props?.boards?.find((board) => board?.boardId === each?.boardId)?.classes || []}
                                                                optionLabel="className"
                                                                optionValue="classId"
                                                                onChange={(e) => this.handleClassDropDown(index, 'classId', e.value)}
                                                                value={each?.classId}
                                                                placeholder={<div className='text-xl'><span>Select Grade</span></div>}
                                                            />

                                                        </div>

                                                    </div>
                                                </div>


                                            </>


                                        )




                                    })}

                                    <div className=' flex align-items-center mt-4 ml-2'>
                                        <Button icon={<PlusBlackIcon height={15} width={15} />} style={{ borderRadius: 12, backgroundColor: '#F4F5F6', border: 'none' }} onClick={this.addBoard}
                                            className=""
                                            disabled={!this.state?.boardsInfo?.every(each => each?.boardId && each?.classId?.length)}
                                        >
                                            <p className='addBoardButtonText ml-1' style={{ fontSize: '16px', color: '#171C1F' }}>Add Another Board</p>
                                        </Button>
                                    </div>
                                </div>



                            ) : <></>}


                            {this.state?.mappingType === 'PER' && (
                                <>
                                    <div className="col-12 ">
                                        <label className='label'>Fee Types<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetMultiSelect
                                                // id="feeTypes"
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                className=' no-border text-xl'
                                                options={feeTypeOptions}
                                                value={this.state.feeTypes}
                                                onChange={(e) => this.setState({ feeTypes: e.value })}
                                                placeholder={<div className='text-xl'><span>Select Fee Types</span></div>}
                                                optionValue='_id'
                                                optionLabel='feeType'
                                            />

                                        </div>

                                    </div>
                                    <div className="col-12 ">
                                        <label className='label'>Total Percentage<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                // id="percentage"
                                                className='border-round-md groupFormInputText  border-none'
                                                value={this.state.per}
                                                onChange={(e) => this.setState({ per: e.target.value })}
                                                placeholder=' Percentage'


                                            />
                                        </div>

                                    </div>
                                    <div className="col-12 ">
                                        <label className='label'>Fee Categories<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                icon={<MoneyIcon />}
                                                id='feeCategories'
                                                className="text-xl"
                                                height="48px"
                                                width="368px"
                                                options={this.state.feeCategoriesOptions}
                                                value={this.state.feeCategory}
                                                onChange={(e) => this.setState({ feeCategory: e.value })}
                                                // onChange={(e) => { onDropDownChange(e.target.value, 'feeCategories', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations) }}
                                                placeholder={(
                                                    <div>
                                                        <span className='text-xl'>Select Fee Category</span>
                                                    </div>
                                                )}

                                            />
                                        </div>
                                    </div>

                                </>



                            )}
                            <div className='flex justify-content-center mb-4 mt-4 p-2'>
                                <Button label={this.state?.bankMappingDetails?.bankAssingedInfo?._id ? 'Update' : 'Add'} className='p-button-primary submitButton mr-4 button px-6 text-lg w-4'
                                    onClick={this.OnCreateBankMapping}
                                />
                                <Button className='cancelButton w-4'
                                    onClick={this.onCloseBankFeeMapping} >
                                    <p className='cancelButtonText'>Cancel</p>
                                </Button>

                            </div>
                        </div>



                    </Dialog>
                    <Dialog
                        isOpen={this.state.bankCreateDialog}
                        visible={this.state.bankCreateDialog}
                        style={{ position: 'fixed', right: '0' }}
                        draggable={false}
                        onHide={this.handleCloseCreateBank}
                        closable={true}
                        header={() => {
                            return (<div className='mb-2 p-2'>
                                <h3 className="text-center formhead mb-5">{this.state?.bank?._id ? 'Update Bank' : "Add New Bank"}</h3>
                            </div>)
                        }}

                    >
                        <div className="mb-5 ml-5 mr-5 p-2">
                            <div className="" >
                                <div className="col-12 ">
                                    <label className='label'>Display Account Name<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="name"
                                            className='border-round-md   border-none '
                                            value={bank.name}
                                            onChange={(e) => { onTextChange(e.target.value, 'name', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Display Account Name" />
                                    </div>
                                    {formValidations && !formValidations.fields['name'].isValid && <p className="p-error text-lg ">{formValidations.fields['name'].errorMsg}</p>}
                                </div>

                                <div className="col-12 ">
                                    <label className='label'>Account Number<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="accNo"
                                            className='border-round-md   border-none '
                                            value={bank.accNo}
                                            onChange={(e) => { onTextChange(e.target.value, 'accNo', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Account Number" />
                                    </div>
                                    {formValidations && !formValidations.fields['accNo'].isValid && <p className="p-error text-lg ">{formValidations.fields['accNo'].errorMsg}</p>}

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>Confirm Account Number<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="confirmAccNo"
                                            className='border-round-md   border-none '
                                            value={bank.confirmAccNo}
                                            onChange={(e) => { onTextChange(e.target.value, 'confirmAccNo', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Reconfirm Account Number" />
                                    </div>
                                    {formValidations && !formValidations.fields['confirmAccNo'].isValid && <p className="p-error text-lg ">{formValidations.fields['confirmAccNo'].errorMsg}</p>}

                                </div>

                                <div className="col-12 ">
                                    <label className='label'>Account Holder Name<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="accHolderName"
                                            className='border-round-md   border-none '
                                            value={bank.accHolderName}
                                            onChange={(e) => { onTextChange(e.target.value, 'accHolderName', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Account Holder Name" />

                                    </div>
                                    {formValidations && !formValidations.fields['accHolderName'].isValid && <p className="p-error text-lg ">{formValidations.fields['accHolderName'].errorMsg}</p>}

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>Bank Name</label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="bankName"
                                            className='border-round-md   border-none '
                                            value={bank.bankName}
                                            onChange={(e) => { onTextChange(e.target.value, 'bankName', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Bank Name" />

                                    </div>
                                    {formValidations && !formValidations.fields['bankName'].isValid && <p className="p-error text-lg ">{formValidations.fields['bankName'].errorMsg}</p>}

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>Branch Address</label>
                                    <div className='mt-2'>
                                        <ClassetTextArea
                                            id="branchName"
                                            className='border-round-md   border-none '
                                            height={100}
                                            value={bank.branchName}
                                            onChange={(e) => { onTextChange(e.target.value, 'branchName', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Branch Address" />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['branchName'].isValid && <p className="p-error text-lg ">{formValidations.fields['branchName'].errorMsg}</p>} */}

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>Pincode<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="pincode"
                                            className='border-round-md   border-none '
                                            value={bank.pincode}
                                            onChange={(e) => { onTextChange(e.target.value, 'pincode', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="Pincode" />

                                    </div>
                                    {formValidations && !formValidations.fields['pincode'].isValid && <p className="p-error text-lg ">{formValidations.fields['pincode'].errorMsg}</p>}

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>IFSC Code<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            icon={<CodeLinkIcon height={24} />}
                                            id="ifsc"
                                            className='border-round-md   border-none '
                                            value={bank.ifsc}
                                            onChange={(e) => { onTextChange(e.target.value, 'ifsc', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder="IFSC Code" />

                                    </div>
                                    {formValidations && !formValidations.fields['ifsc'].isValid && <p className="p-error text-lg ">{formValidations.fields['ifsc'].errorMsg}</p>}

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>Account Type<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='accType'
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={accountTypeOptions}
                                            optionLabel="label"
                                            className=' no-border '
                                            value={bank.accType}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'accType', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder=
                                            {
                                                <div className=''>
                                                    <span>Select Account Type</span>
                                                </div>
                                            }
                                        />
                                        {formValidations && !formValidations.fields['accType'].isValid && <p className="p-error text-lg">{formValidations.fields['accType'].errorMsg}</p>}

                                    </div>

                                </div>
                                <div className="col-12 ">
                                    <label className='label'>Country<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='country'
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={countryOptions}
                                            optionLabel="label"
                                            className=' no-border '
                                            value={bank.country}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'country', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder={
                                                <div className=''>
                                                    <span>Select Country</span>
                                                </div>
                                            }
                                        />
                                        {formValidations && !formValidations.fields['country'].isValid && <p className="p-error text-lg">{formValidations.fields['country'].errorMsg}</p>}

                                    </div>

                                </div>
                                <div className="col-12">
                                    <label className='label'>State<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='state'
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={States.map((state) => {
                                                return {
                                                    label: state,
                                                    value: state
                                                }
                                            })}
                                            // optionLabel="label"
                                            className=' no-border '
                                            value={bank.state}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'state', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                            placeholder={
                                                <div className=''>
                                                    <span>Select State</span>
                                                </div>
                                            }


                                        />
                                        {formValidations && !formValidations.fields['state'].isValid && <p className="p-error text-lg">{formValidations.fields['state'].errorMsg}</p>}

                                    </div>

                                </div>
                                <div className='flex justify-content-center mb-4 mt-4 p-2'>
                                    <Button
                                        label={this.state?.bank?._id ? 'Update' : 'Add'}
                                        className='p-button-primary submitButton mr-4 button px-6 text-lg w-4'
                                        onClick={() => this.onCreateBanking()}
                                    />
                                    <Button className='cancelButton w-4'
                                        onClick={this.handleCloseCreateBank} >
                                        <p className='cancelButtonText'>Cancel</p>
                                    </Button>



                                </div>

                            </div>
                        </div>
                    </Dialog >


                </div >
                <Toast ref={(el) => this.toast = el} position="bottom-right" />


            </>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: state.boardsData.boards,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(Banking)));