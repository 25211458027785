import React, { Component } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Checkbox } from 'primereact/checkbox';
import { ClassetEditIcon, ClipIcon, MailIcon, MinusSquareIcon, PlusSquareIcon } from '../svgIcons';
import { AddUserIcon } from '../svgIcons';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import LoadingComponent from '../loadingComponent';
import { baseUrlAdmin } from '../../store/apiConstants';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import Service from '../../services';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ClassetTextArea from '../../classetComponents/classetTextArea';
import { getBoardsData } from '../../store/actions';

const sampleOptions = [
    { value: 1, label: 'sample 1' },
    { value: 1, label: 'sample 1' },
    { value: 1, label: 'sample 1' },
    { value: 1, label: 'sample 1' },
    { value: 1, label: 'sample 1' }
];

const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' }
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const CategoryTypes = [
    { id: 1, type: 'DayScholor', feeTypes: '12 Fee Types' },
    { id: 2, type: 'Residential', feeTypes: '8 Fee Types' },
    { id: 3, type: 'Weekend-Residential', feeTypes: '5 Fee Types' }
];

const home = { icon: 'pi pi-home' };

const tabs = [{ label: 'Vehicle Management' }, { label: 'Route Management' }, { label: 'Assign Management' }, { label: 'Transport Fee ' }];

class AdmissionCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            categories: CategoryTypes,
            categoryNameError: '',
            descriptionError: '',
            boardsListError: '',
            admissionStatusObj: {},
            classes: [],
            category: {
                description: '',
                categoryName: '',
                boardsList: [{ board: '', grade: [], classes: [] }]
            },

            editAdmissionCategory: {},
            isDialogOpenForCreateCategory: false,
            addBoardCardCount: 2,
            isLoading: false
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.getAdmissionTypesData();
    }

    getAdmissionTypesData = async () => {
        this.setState({ isLoading: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ categories: res?.res?.data, isLoading: false });
                console.log(res.res.data);
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };

    confirm1Change = (e, category) => {
        this.setState({ isStatusChangeDialog: true, admissionStatusObj: { ...category, isActiveStatus: e.target.value } });
    };

    handleChangeTheStatusAdmissionCategory = async () => {
        const { _id, isActiveStatus } = this.state.admissionStatusObj;
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        axios({
            method: 'patch',
            url: url,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
            },
            data: {
                _id: _id,
                isActive: isActiveStatus
            }
        })
            .then((res) => {
                if (res?.data?.status && res.status == 200) {
                    this.setState({ isStatusChangeDialog: false, admissionStatusObj: {} });
                    this.getAdmissionTypesData();
                    this.toast.show({ severity: 'success', summary: 'success', detail: res?.data?.message, life: 3000 });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
                }
            })
            .catch((e) => {
                console.log(e);
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
            });
    };

    onChangeBoard = (boardId, indexPosition) => {
        const _classes = [];
        const UpdatedBoardList = this.state.category.boardsList.map((each, index) => {
            if (indexPosition == index) {
                const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
                if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                    selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                }
                return { ...each, board: boardId, classes: _classes };
            } else {
                return each;
            }
        });

        this.setState((prevState) => ({
            classes: _classes,
            category: { ...prevState.category, boardsList: UpdatedBoardList }
        }));
    };

    handleRemoveBoard = () => {
        const { category } = this.state;
        if (this.state.category?.boardsList?.length > 1) {
            this.setState((prevState) => ({ category: { ...category, boardsList: prevState.category.boardsList.slice(0, -1) } }));
        }
    };

    onChangeClass = (classId, indexPosition) => {
        const UpdatedClassList = this.state?.category?.boardsList?.map((each, index) => {
            if (indexPosition == index) {
                return { ...each, grade: classId };
            } else {
                return each;
            }
        });

        this.setState((prevState) => ({
            category: { ...prevState.category, boardsList: UpdatedClassList }
        }));
    };

    handleDescriptionChange = (e) => {
        if (this.state.category.description?.length <= 499 || e.target.value?.length <= 499) {
            this.setState((prevState) => ({ category: { ...prevState.category, description: e.target.value } }));
        }
    };
    //should be upated
    onCreateAdmissionCategory = async () => {
        const { description, categoryName, boardsList } = this.state.category;
        const updatedList = boardsList.map((each) => {
            return { board: each.board, grade: each.grade };
        });
        let isCompleteFormValid = true;
        let academicYear = localStorage.getItem('userAcademicYear');

        if (description == '' || categoryName == '' || boardsList[0].board == '') {
            if (description == '') {
                this.setState({ descriptionError: 'Description is required' });
                isCompleteFormValid = false;
            }
            if (categoryName == '') {
                this.setState({ categoryNameError: 'Category name is required' });
                isCompleteFormValid = false;
            }
            let count = '';
            boardsList.map((each, index) => {
                if (each.board == '' || each.grade == []) {
                    count = count.toString() + ' ' + (index + 1).toString();
                    this.setState({ boardsListError: `Select atleast one Board && Grades for ${count} card` });
                    isCompleteFormValid = false;
                }
            });
        }
        if (isCompleteFormValid) {
            if (this.state.editAdmissionCategory._id) {
                const payload = {
                    _id: this.state.editAdmissionCategory._id,
                    categoryName: categoryName,
                    categoryDesc: description,
                    assign: updatedList,
                    academicYear
                };

                const academicYear = localStorage.getItem('userAcademicYear');
                let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`

                try {
                    const res = await this.service.put(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({
                            isDialogOpenForCreateCategory: false,
                            category: {
                                description: '',
                                categoryName: '',
                                boardsList: [{ board: '', grade: [], classes: [] }]
                            },
                            editAdmissionCategory: {}
                        });
                        this.getAdmissionTypesData();
                        this.toast.show({ severity: 'success', summary: 'Admission Category Updated successfully', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: 'Error', life: 3000 });
                }
            } else {
                const payload = {
                    categoryName: categoryName,
                    categoryDesc: description,
                    assign: updatedList,
                    academicYear
                };
                const academicYear = localStorage.getItem('userAcademicYear');
                let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({
                            isDialogOpenForCreateCategory: false,
                            category: {
                                description: '',
                                categoryName: '',
                                boardsList: [{ board: '', grade: [], classes: [] }]
                            }
                        });
                        this.getAdmissionTypesData();
                        this.toast.show({ severity: 'success', summary: 'Admission Category Created successfully', detail: res?.res?.message, life: 3000 });
                    } else {
                        const error = 'Some error occurred';
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }
        }
    };
    handleEditRequest = (category, rowIndex) => {
        let updatedList;
        if (category?.assign?.length) {
            updatedList = category.assign?.map((each, index) => {
                let filterGrades = [];
                const _classes = [];
                const selectedBoard = this.props.boards && this.props.boards?.find((board) => board.boardId === each.board);
                if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                    selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                }

                _classes?.filter((eachClass) => {
                    if (each?.grade?.includes(eachClass.value)) {
                        return filterGrades.push(eachClass.value);
                    }
                });

                return { board: each.board, grade: filterGrades, classes: _classes };
            });
        } else {
            updatedList = [{ board: '', grade: [], classes: [] }];
        }

        const filteredFields = {
            categoryName: category.categoryName,
            description: category.categoryDesc,
            boardsList: updatedList
        };
        this.setState({
            editAdmissionCategory: category,
            category: filteredFields,
            isDialogOpenForCreateCategory: true
        });
    };

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className="ma-tab-title">Transport Management</h4>
            </React.Fragment>
        );
    };

    // leftToolbarTemplate() {
    //     return (
    //         <React.Fragment>
    //             <BreadCrumb model={items} home={home} />
    //         </React.Fragment>
    //     )
    // }

    componentDidUpdate(prevProps, prevState) {}

    render() {
        const { category, categoryNameError, descriptionError, boardsListError } = this.state;
        return (
            <div>
                <div className="admin-management">
                    <div style={{ marginBottom: '110px' }}>
                        <div className=" grid">
                            <div className="mt-3 md:col-12 lg:col-12 xl:col-12">
                                <div className="grid mb-6 mt-4 -mr-6">
                                    <Authorizer permId={PERMISSIONS.CREATE_ADMISSION_CATEGORY}>
                                        <div className="col-11 flex justify-content-end align-items-center">
                                            <Button
                                                label="Add Admission Category"
                                                icon={<i className="pi pi-plus-circle text-xl ml-4 opacity-70"></i>}
                                                className="addAdmissionBtn"
                                                onClick={() => {
                                                    this.setState({ isDialogOpenForCreateCategory: true });
                                                }}
                                                // onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editVehicleData: null }) }}
                                            />
                                        </div>
                                    </Authorizer>
                                </div>
                                {this.state.categories?.length && !this.state.isLoading ? (
                                    <div className="mt-4 grid doubts-tabs flex lg:justify-content-start xl:justify-content-start sm:justify-content-center xs:justify-content-center align-items-Satrt">
                                        {this.state.categories?.map((category, index) => (
                                            // <Authorizer key={term.id} permId={PERMISSIONS.SHOW_ROLES_LIST}>
                                            <div className="mx-6 card admissionCategoryCardContainer cursor-pointer col-3 doubts-tab-w">
                                                <div className="flex flex-column justify-content-center align-items-center">
                                                    <div className="mt-1 w-full flex justify-content-end align-items-center">
                                                        <Authorizer permId={PERMISSIONS.UPDATE_ADMISSION_CATEGORY}>
                                                            <InputSwitch className="mt-1" onChange={(e) => this.confirm1Change(e, category)} checked={category?.isActive} />
                                                        </Authorizer>
                                                    </div>
                                                    <div className="flex flex-column justify-content-center align-items-center mt-5 ml-3">
                                                        <h3 className="categoryCardHeadText">{category?.categoryName}</h3>
                                                        <p className="surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis categoryCardPara w-18rem text-xl ml-4">{category?.categoryDesc}</p>
                                                    </div>
                                                    <div
                                                        className=" mr-5 w-full flex justify-content-end align-items-center"
                                                        onClick={() => {
                                                            this.handleEditRequest(category, index);
                                                        }}
                                                    >
                                                        <Authorizer permId={PERMISSIONS.UPDATE_ADMISSION_CATEGORY}>
                                                            <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                        </Authorizer>
                                                    </div>
                                                </div>
                                            </div>
                                            // </Authorizer>
                                        ))}
                                    </div>
                                ) : (
                                    !this.state.isLoading && (
                                        <div className="mt-8 card h-20rem w-full  flex flex-column justify-content-center align-items-center">
                                            <h3>No Admission categories Found</h3>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    visible={this.state.isDialogOpenForCreateCategory}
                    draggable={false}
                    closeOnEscape={false}
                    // position={"right"}
                    className="addAdmissionDilogBox mr-0"
                    width={'500px'}
                    height={'800px'}
                    position={'right'}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2">
                                <h3 className="text-center text-black-alpha-90 font-bold">Add Admission Category</h3>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-2 mt-2">
                            <Button label="Accept" icon={<i className="pi pi-plus-circle text-lg px-2 "></i>} className="addAdmissionDialogAceeptBtn border-radius-xs mr-4  px-8" onClick={() => this.onCreateAdmissionCategory()} />
                            <Button
                                label="Cancel"
                                className="border-none addAdmissionDialogCancelBtn border-radius-xs px-8"
                                onClick={() =>
                                    this.setState({
                                        isDialogOpenForCreateCategory: false,
                                        category: {
                                            description: '',
                                            categoryName: '',
                                            boardsList: [{ board: '', grade: [], classes: [] }]
                                        }
                                    })
                                }
                            />
                        </div>
                    )}
                    onHide={() =>
                        this.setState({
                            isDialogOpenForCreateCategory: false,
                            category: {
                                description: '',
                                categoryName: '',
                                boardsList: [{ board: '', grade: [], classes: [] }]
                            }
                        })
                    }
                >
                    <div className="flex flex-column justify-content-center ml-5">
                        <div className="grid col-12 flex justify-content-center align-content-center">
                            <div className="field col-12 px-4 ">
                                <label htmlFor="title" className="block groupFormLabelText">
                                    Category Name<span className="ma-required">*</span>
                                </label>
                                <div className="flex">
                                    <ClassetInputText
                                        icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                                        className="border-round-md no-border custom-field-focused border-none pl-3 body-medium"
                                        height="48px"
                                        width="343px"
                                        formHelperError={categoryNameError != '' && category.categoryName == ''}
                                        value={category.categoryName}
                                        onChange={(e) => {
                                            this.setState((prevState) => ({ category: { ...prevState.category, categoryName: e.target.value } }));
                                        }}
                                        placeholder="Name Of the Category"
                                    />
                                </div>
                                {category.categoryName == '' && <p className="p-error text-lg">{categoryNameError}</p>}
                                {/* {formValidations && !formValidations.fields['academicTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['academicTitle'].errorMsg}</p>} */}
                            </div>
                            <div className="field col-12 -mt-2 px-4">
                                <label htmlFor="description" className="block groupFormLabelText">
                                    Category Description<span className="ma-required">*</span>
                                </label>
                                <div className="p-inputgroup">
                                    <ClassetTextArea
                                        className="addAdmissionDilogBoxdesc custom-field-focused border-300 shadow-2 border-round-sm pl-3 body-medium"
                                        id="description"
                                        value={category.description}
                                        formHelperError={descriptionError != '' && category.description == ''}
                                        onChange={(e) => {
                                            this.handleDescriptionChange(e);
                                        }}
                                        placeholder="Write a Description"
                                    />
                                </div>
                                {category.description?.length > 499 && <p className="p-error -mt-2">Maximum characters limit reached</p>}
                                <p className="text-right">{category.description?.length}/500</p>
                                {category.description == '' && <p className="p-error text-lg">{descriptionError}</p>}
                                {/* {formValidations && !formValidations.fields['academicTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['academicTitle'].errorMsg}</p>} */}
                            </div>
                        </div>
                        <div className="">
                            {category.boardsList?.map((boardType, index) => (
                                <div className="addCardSize grid col-12 card -mt-3 flex justify-content-center align-content-center">
                                    <div className="col-12 px-6 flex justify-content-center align-content-center">
                                        <ClassetDropdown
                                            className="dialogCardInField border-round-md shadow-2 text-xl pl-2 pt-2"
                                            options={this.props.boards}
                                            icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                            optionLabel="boardName"
                                            optionValue="boardId"
                                            value={boardType.board}
                                            onChange={(e) => this.onChangeBoard(e.value, index)}
                                            placeholder={
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className="text-xl ml-4 body-medium">Select Board</span>
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className="col-12 px-6 flex justify-content-center align-content-center">
                                        <ClassetMultiSelect
                                            value={boardType.grade}
                                            icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                            onChange={(e) => this.onChangeClass(e.value, index)}
                                            options={boardType.classes}
                                            optionLabel="label"
                                            className="dialogCardInFiel text-xl shadow-2 pl-2"
                                            maxSelectedLabels={3}
                                            placeholder={
                                                <div className="flex justify-content-start  align-items-center mt-1">
                                                    <span className="text-xl ml-4 body-medium">Select Grades</span>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                            {(category.boardsList[0].board == '' || category.boardsList[0].grade?.length == 0) && <p className="p-error text-lg my-2">{boardsListError}</p>}
                            <div className="flex justify-content-between align-items-center">
                                <Button
                                    icon={<PlusSquareIcon width={24} height={24} color={'#000000'} />}
                                    label="Add Board"
                                    className="border-none addBoardBtn mr-4 w-auto px-4 pl-2"
                                    onClick={() => {
                                        this.setState((prevState) => ({ category: { ...category, boardsList: [...prevState.category.boardsList, { board: '', grade: [], classes: [] }] } }));
                                    }}
                                />
                                <Button
                                    icon={<MinusSquareIcon width={24} height={24} color={'#000000'} />}
                                    label="Remove Board"
                                    className="border-none addBoardBtn mr-4 w-auto px-4 pl-2"
                                    onClick={() => {
                                        this.handleRemoveBoard();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
                <Dialog
                    header={
                        <div className="flex justify-content-center align-items-center">
                            <i className="pi pi-exclamation-triangle text-center text-7xl text-red-400"></i>
                        </div>
                    }
                    visible={this.state.isStatusChangeDialog}
                    style={{ width: '400px', height: '350px' }}
                    onHide={() => {
                        this.setState({ isStatusChangeDialog: false });
                    }}
                >
                    <div className="flex flex-column align-items-center surface-overlay border-round">
                        <h4 className="font-bold text-2xl block mb-2 mt-4">Confirmation ?</h4>
                        <p className="mb-0 text-base">
                            {`Are you sure you want to Change The status `}
                            <span className="text-color text-base font-semibold"> {this.state.admissionStatusObj.isActiveStatus ? 'InActive to Active' : 'Active to InActive'} </span>For the
                            <span className="p-error text-xl mt-1 font-semibold">{` ${this.state.admissionStatusObj.categoryName}`}</span>?
                        </p>
                        <div className="flex align-items-center gap-2 mt-4">
                            <Button
                                label="Change"
                                onClick={() => {
                                    this.handleChangeTheStatusAdmissionCategory();
                                }}
                                className="confirmDialogAcceptBtn"
                            ></Button>
                            <Button
                                label="Cancel"
                                outlined
                                onClick={() => {
                                    this.setState({ isStatusChangeDialog: false, admissionStatusObj: {} });
                                }}
                                className="confirmDialogCancelBtn"
                            ></Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions) || [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(AdmissionCategory)));
