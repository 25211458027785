import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
// import './styles.scss'
import { Button } from 'primereact/button';
import { AddIcon2, DownloadIcon, MailIcon, TickBgIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BasicLazyParams } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';

const timeTableData = [
    {
        fee_type: "Monday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Tuesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Wednesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Thursday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Friday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Saturday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },


];

export class MyTimeTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            timeTableData: timeTableData,
            openCreateFeeDialog: false,
            searchType: 1,
            searchInput: ""
        }
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        },)
    }

    render() {
        return (
            <div className="m-2">
                <div className='grid m-2 mb-6'>
                    <BoardToSectionDD setValues={this.setDropdownValues} />
                </div>
                <DataTable ref={(el) => this.dt = el}
                    value={this.state.timeTableData}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesTextt"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts} 
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column field="fee_type" bodyClassName='text-center' header="Days / Periods" alignHeader={"center"} />
                    <Column field="approval" bodyClassName='text-center' header="Period 1" alignHeader={"center"} />
                    <Column field="total_fee" bodyClassName='text-center' header="Period 2" alignHeader={"center"} />
                    <Column field="concession" bodyClassName='text-center' header="Period 3" alignHeader={"center"} />
                    <Column field="assigned_fee" bodyClassName='text-center' header="Period 4" alignHeader={"center"} />
                    <Column field="paid_fee" bodyClassName='text-center' header="Period 5" alignHeader={"center"} />
                    <Column field="paid_fee" bodyClassName='text-center' header="Period 6" alignHeader={"center"} />
                    <Column field="paid_fee" bodyClassName='text-center' header="Period 7" alignHeader={"center"} />
                    <Column field="paid_fee" bodyClassName='text-center' header="Period 8" alignHeader={"center"} />
                </DataTable>
            </div>
        )
    }
}






export default MyTimeTable