import React, { Component } from 'react';
import { primaryColor } from '../../store/apiConstants';

import CreateTimeTable from './createTimeTable';
import TimeTableSettings from './timeTableSettings';
import TimeTableTemplate from './timeTableTemplate';
import TimeTables from './timeTable';

export default class SettingsTimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0
        };
    }
    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };
    render() {
        return (
            <div>
                <div>
                    <div className="grid ml-1 mr-1 h-4rem poppins24">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tabheadText"
                                        onClick={() => {
                                            this.onTabChange(0);
                                        }}
                                    >
                                        Timetable
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(1);
                                        }}
                                    >
                                        Timetable Template
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(2);
                                        }}
                                    >
                                        Timetable Settings
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(3);
                                        }}
                                    >
                                        Create Timetable
                                    </p>
                                </div>
                                {/* <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                            <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                        </div> */}
                            </div>
                        </div>
                    </div>
                    {this.state.currentTab == 0 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.BOARDS_MANAGEMENT_LIST} > */}
                            <TimeTables onTabChange={this.onTabChange} />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 1 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.GRADE_AND_SECTION_LIST} > */}
                            <TimeTableTemplate />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 2 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.SUBJECT_MANAGEMENT_LIST} > */}
                            <TimeTableSettings />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 3 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.CONTENT_MANAGEMENT_LIST} > */}
                            <CreateTimeTable />
                            {/* </Authorizer> */}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
