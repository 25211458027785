import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import Authentication from './../../session';
import { getBranchesLatest } from '../../../store/actions';
import { RadioButton } from 'primereact/radiobutton';
import ClassetStudentSearch from '../../../classetComponents/classetStudentSearch';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { CancelRed, CrossInBox, DownloadIcon, GreenCheck, MailIcon, RightMarkInCircle } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import moment from 'moment';
import { BasicLazyParams } from '../../../utile';
import { debounce } from 'lodash';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { Dialog } from 'primereact/dialog';
import '../styles.scss';
import ClassetTextArea from '../../../classetComponents/classetTextArea';

export default class AssignTransport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchLevel: 1,
            lazyParams: BasicLazyParams,
            studentInfo: []
        };
        this.debouncedApiCall = debounce(this.getStudentsInfo, 500);
        this.service = new Service();
    }
    getStudents = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        // let { lazyParams } = this.state;
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        if (payload?.sectionId) {
            this.service
                .post(url, payload, true)
                .then((data) => {
                    // console.log(data, "datastudent")
                    const studentsData = data?.res?.data.map((each) => {
                        return {
                            label: each?.name,
                            value: each?.user_id
                        };
                    });

                    if (data?.status) {
                        this.setState({
                            students: studentsData,
                            isLoading: false
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isLoading: false });
        }
    };
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                studentId: null
            },
            () => {
                this.getStudents();
            }
        );
    };
    handleSetSearchValue = (value) => {
        this.setState(
            {
                searchValue: value,
                dropdownsData: {
                    boardId: '',
                    branchId: '',
                    classId: '',
                    sectionId: ''
                }
            },
            () => {
                this.debouncedApiCall();
            }
        );
    };

    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value, studentId: '', searchValue: '', studentsData: [] });
    };
    onHandleAssignTransportApprove = async(rowData, isConfirmed) => {
        isConfirmed && await this.getFeeStructures(rowData?.existingFeeInfo?.feeStructure ? true :false)
        this.setState({
            isApproved: isConfirmed ? true : false,
            isRejected: !isConfirmed ? true : false,
            selectedStudent: rowData,
            selectedFeeStructure : rowData?.existingFeeInfo?.feeStructure

        });
        
    };

    getFeeStructures = (isTransportExists) => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fee-structure/transportfees?academicYear=${academicYear}&isNew=${false}`;
        const currentDate = new Date();
        this.service.get(url,  true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res?.data?.length) {
                    this.setState({
                        isLoading: false,
                        feeStructure: isTransportExists ?  res?.res?.data : res?.res?.data.filter(item => {
                          if (item.stepTwo && Array.isArray(item.stepTwo)) {
                            return !item.stepTwo.some(step => new Date(step.dueDate) < currentDate);
                          }
                          return true;
                        })
                      });
                      
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
            console.log(e);
        })
    }
    getStudentsInfo = () => {
        let { lazyParams, globalSearch, filterData } = this.state;
        this.setState({ isLoading: true, studentInfo: [] });
        const url = `${baseUrlAdmin}/transport-assign-approval/get-pending-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        let academicYear = localStorage.getItem('userAcademicYear');
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            gradeId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo
        };

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res?.records && res.res?.records?.length) {
                        this.setState({
                            isLoading: false,
                            studentInfo: res.res.records,
                            totalRecords: res.res.totalRecords
                        });
                    } else if (res.res?.records?.length == 0) {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'success', summary: 'No records found.', detail: '', life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };
    onStudentChange = (e) => {
        const name = this.state?.students
            ?.filter((each) => {
                return each.value === e.value;
            })
            .map((student) => student.label);
        this.setState({ studentId: e.value, studentName: name }, () =>e.value && this.getStudentsInfo());
    };

    onApproveAssignTransport = (status) => {
        this.setState({
            isLoading: true
        });
        let payload = {
            ...this.state.selectedStudent,
            feeStructure : this.state.selectedFeeStructure,
            isApproved: status,
            remarks: this.state.remarks
        };
        let url = `${baseUrlAdmin}/transport-assign-approval/approve-assigned-transport`;
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isRejected: false,
                            isApproved: false,
                            selectedStopInfo: null,
                            remarks: '',
                            selectedFeeStructure:""
                        },
                        () => this.getStudentsInfo()
                    );
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            });
    };

    render() {
        return (
            <div className="ma-main">
                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    <div style={{ marginLeft: '100px' }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BoardToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                        </div>
                    </div>

                    <div className="col-12 ml-5">
                        <label className="label">
                            Student<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id="student"
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                //     onChange={(e) => {this.setState({studentId:e.value})
                                //     this.getTermBasedData()

                                // }}
                                onChange={this.onStudentChange}
                                placeholder={
                                    <div>
                                        <span className="">Select Student</span>
                                    </div>
                                }
                            />
                        </div>
                        {/* {formValidations && !formValidations.fields['paymentMode'].isValid && <p className="p-error">{formValidations.fields['paymentMode'].errorMsg}</p>} */}
                    </div>
                </div>

                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.studentInfo}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column
                        alignHeader={'center'}
                        sortable
                        bodyClassName="text-center"
                        header="Student Name"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex justify-content-center">
                                    {rowData?.studentsInfo[0]?.user_info?.firstName} {rowData?.studentsInfo[0]?.user_info?.lastName}
                                </div>
                            );
                        }}
                    ></Column>
                    <Column
                        filterField="parentName"
                        field="parentName"
                        sortable
                        bodyClassName="text-center"
                        header="Parent Name"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <div className="flex justify-content-center">{rowData?.studentsInfo[0]?.user_info?.contactPersonName}</div>;
                        }}
                    />
                    <Column
                        filterField="contactDetails"
                        field="contactDetails"
                        sortable
                        bodyClassName="text-center"
                        header="Contact Details"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <div className="flex justify-content-center">{rowData?.studentsInfo[0]?.user_info?.parentContactNo}</div>;
                        }}
                    />
                    <Column
                        filterField="pickUpLocation"
                        field="pickUpLocation"
                        sortable
                        bodyClassName="text-center"
                        header="Pick Up Location"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <div className="flex justify-content-center">{rowData?.selectedPickUpStop?.stopName}</div>;
                        }}
                    />
                    <Column
                        filterField="dropLocation"
                        field="dropLocation"
                        sortable
                        bodyClassName="text-center"
                        header="Drop Location"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <div className="flex justify-content-center">{rowData?.selectedDropStop?.stopName}</div>;
                        }}
                    />
                    <Column
                        filterField="routeNo"
                        field="routeNo"
                        sortable
                        bodyClassName="text-center"
                        header="Route No"
                        alignHeader={'center'}
                        body={(rowData, { rowIndex }) => {
                            return <div className="flex justify-content-center">{rowData?.routeName}</div>;
                        }}
                    />
                    <Column
                        style={{ width: '15rem', height: '6rem' }}
                        alignHeader={'center'}
                        sortable
                        headerClassName="text-color-secondary"
                        bodyClassName="text-center"
                        className=" "
                        header="Approve / Reject"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    {rowData?.isRefundApproved == undefined && (
                                        <div className="flex justify-content-center gap-2">
                                            <div className="cursor-pointer" onClick={() => this.onHandleAssignTransportApprove(rowData, true)}>
                                                <GreenCheck />
                                            </div>

                                            <div className="cursor-pointer" onClick={() => this.onHandleAssignTransportApprove(rowData, false)}>
                                                <CancelRed />
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>

                <Dialog
                    visible={this.state.isRejected}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Pickup Location : {this.state.selectedStudent?.selectedPickUpStop?.stopName}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Drop Location : {this.state.selectedStudent?.selectedDropStop?.stopName}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Route Number : {this.state.selectedStudent?.routeName}</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#BF0031', border: 'none' }}
                                onClick={() => {
                                    this.onApproveAssignTransport(false);
                                }}
                            >
                                <p className="formSubmitButtonText">Reject</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejected: false, selectedFeeType: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRejected: false, selectedFeeType: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isApproved}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Assign Transport </p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Pickup Location : {this.state.selectedStudent?.selectedPickUpStop?.stopName}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Drop Location : {this.state.selectedStudent?.selectedDropStop?.stopName}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Route Number : {this.state.selectedStudent?.routeName}</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.onApproveAssignTransport(true);
                                }}
                            >
                                <p className="formSubmitButtonText">Approve</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isApproved: false, selectedFeeType: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isApproved: false, selectedFeeType: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Transport Fee Structure<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    options={this.state.feeStructure}
                                    optionLabel="scheduleName"
                                    optionValue="_id"
                                    width={250}
                                    value={this.state.selectedFeeStructure}
                                    onChange={(e) => {
                                        this.setState({ selectedFeeStructure: e.target.value });
                                    }}
                                    disabled={this.state.selectedStudent?.existingFeeInfo && Object.keys(this.state.selectedStudent?.existingFeeInfo).length } 
                                    placeholder="Enter Fee Structure"
                                />
                            </div>
                        </div>
                        <div className="mt-3">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                    {this.state.isLoading && <LoadingComponent />}
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}
