import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { AddIconInButtonFull, AddUserIcon, CancelIcon, ClassetEditIcon, CodeLinkIcon, EditIcon, InputIcon, MailIcon, PlusBlackIcon } from '../svgIcons';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import "./style.scss";
import ClassetInputText from '../../classetComponents/classetInputText';
import { getFormFields, isFormValid, onDropDownChange, onTextChange, onToggleChange } from '../../utile/formHelper';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { cloneDeep, debounce } from 'lodash';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import { getBoardsData } from '../../store/actions';
import ClassetCalendar from '../../classetComponents/classetCalender';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';
import LoadingComponent from '../loadingComponent';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD';
import { convertUtc } from '../../utile';



const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 2, label: "sample 2" },
    { value: 3, label: "sample 3" },
    { value: 4, label: "sample 4" },
    { value: 5, label: "sample 5" },
]



const applicableOptions = [
    { value: "ChequeBounce", label: "Cheque Bounce" },
    { value: "FeeInstallments", label: "Fee Installments" },

]
const LogicOptions1 = [
    { label: 'Fixed Amount', value: "FixedAmount" },
]

const home = { icon: 'pi pi-home' }

const LogicOptions = [
    { label: 'Fixed Amount', value: "FixedAmount" },
    { label: 'Fixed Percentage', value: 'FixedPercentage' },
    { label: 'Day Wise Amount', value: 'DayWiseAmount' },
    { label: 'Slab Wise Amount', value: 'SlabWiseAmount' },
    { label: 'Slab Percentage ', value: 'SlabPercentage' },

];



const createFineFields = require('./fine.json')
const createFineChequeBounceFields = require('./finechequebounce.json')



class Fine extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFineFields);
        this.formFieldsChequeBounce = getFormFields(createFineChequeBounceFields);

        this.state = {
            fineField: this.formFields.data,
            formValidations: this.formFields.formValidations,
            currentTab: 0,
            concessions: [],
            isDialogOpenCreateFine: false,
            logicOptions: LogicOptions,
            percentgeSwitch: false,
            studentSwitch: false,
            amount: '',
            feeCategory: '',
            feeType: '',
            board: '',
            grade: '',
            percentageValue: '',
            slabsWiseAmmount: [{ fromToDates: '', fineAmt: '' }],
            percentage: '',
            feeCategoryOptions: [],
            feeTypeOptions: [],
            fineTypeOptions: [],
            fines: [],
            fineEdit: false,
            isLoading: false,
            students: [],
            selectedStudents: [],
            categoryAssignInfo: [],
            admissionCategoryOptions: [],
            isDialogOpenForCreateConcession: false,
            feeconcessionStatusObj: {},
            feeTypetotalOptions: [],
            isStatusChangeDialog: false,
            selectedCategoryAssignInfo: [],
            selectAll: false,
            branchId: "",
            boardId: "",
            classId: "",
            sectionId: "",
            feeStructureDialog: false,
            newConcession: {}
        };

        this.service = new Service()
    }

    componentDidMount = () => {
        this.getFines()
        // this.getFineTypeOptions()
        this.props.getBoardsData(true)
        this.getFeeCategoryOptions()
        this.getFeeTypeOptions()
    }

    openCreateFine = () => {
        this.setState({ isDialogOpenCreateFine: true })

    }
    Editfine = (fine) => {
        const dropdownsData = {
            branchId: fine?.branchId,
            boardId: fine?.boardId,
            classId: fine?.classIds,
            sectionId: fine?.sectionId
        };
        let slabs = cloneDeep(fine?.slab_info);
        slabs?.forEach(element => {
            element.fromToDates = [new Date(element.startDate), new Date(element.endDate)]
        });
        let feeTypes = cloneDeep(this.state.allFeeTypes);
        feeTypes = feeTypes.filter((feeType) => feeType?.feeCategories?.includes(fine?.feeCategory));
        this.setState({
            isDialogOpenCreateFine: true,
            fineField: fine,
            fineEdit: true,
            selectedStudents: fine?.studentInfo,
            dropdownsData: { ...dropdownsData },
            amount: fine?.amount,
            percentage: fine?.percentage,
            slabsWiseAmmount: slabs,
            feeTypeOptions: feeTypes
        });

    }

    toggleEdit = (e, fine) => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let data = fine
        let slab_info = this.state?.slabsWiseAmmount
        if (data?.fineType === 'SlabWiseAmount' || data?.fineType === 'SlabPercentage') {
            slab_info = slab_info?.map((each) => {
                if (each.fineAmt) {
                    return {
                        startDate: each.fromToDates[0],
                        endDate: each.fromToDates[1],
                        fineAmt: each.fineAmt
                    }
                }
                return {
                    startDate: each.fromToDates[0],
                    endDate: each.fromToDates[1],
                    finePer: each.finePer
                }

            })
        }

        data.slab_info = slab_info
        data.academicYear = AcademicYear

        data.status = e.target.value
        let url = `${baseUrlAdmin}/fine`
        this.service.put(url, data, true).then(res => {
            if (res?.status) {
                this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Fine Status Updated Successfully', life: 3000 });
                this.getFines()

            } else {
                this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
            }
        }).catch(err => {
            this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
        })


    }
    handleCloseCreateFine = () => {
        this.setState({
            isDialogOpenCreateFine: false,
            fineField: {},
            fineEdit: false,
            selectedStudents: [],
            amount: '',
            percentage: '',
            slabsWiseAmmount: [{ fromToDates: '', fineAmt: '' }],
        })

    }
    findAndLogOverlaps = (data) => {

        const overlaps = [];

        for (let i = 0; i < data.length; i++) {
            for (let j = i + 1; j < data.length; j++) {
                const start1 = new Date(data[i].fromToDates[0]);
                const end1 = new Date(data[i].fromToDates[1]);
                const start2 = new Date(data[j].fromToDates[0]);
                const end2 = new Date(data[j].fromToDates[1]);
                if (start1 <= end2 && start2 <= end1) {
                    overlaps.push({
                        range1: data[i].fromToDates,
                        range2: data[j].fromToDates
                    });
                }
            }
        }
        overlaps.forEach(({ range1, range2 }) => {
            console.log(`Overlapping ranges: ${range1} and ${range2}`);
        });
        return overlaps
    }
    addSlab = () => {
        let overlaps = this.findAndLogOverlaps(this.state.slabsWiseAmmount);

        if (overlaps.length) {
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'Date ranges are overlapped please check once.', life: 3000 });
        } else {
            this.setState((prevState) => ({
                slabsWiseAmmount: [...prevState.slabsWiseAmmount, { fromToDates: '', fineAmt: '' }],
            }));
        }

    };
    handleSlabChange = (index, field, value, type) => {
        if (type === "SlabPercentage") {
            if (value <= 100) {
                this.setState((prevState) => {
                    const slabsWiseAmmount = [...prevState.slabsWiseAmmount];
                    slabsWiseAmmount[index][field] = value;
                    return { slabsWiseAmmount };
                });
            }

        } else {
            this.setState((prevState) => {
                const slabsWiseAmmount = [...prevState.slabsWiseAmmount];
                slabsWiseAmmount[index][field] = value;
                return { slabsWiseAmmount };
            });
        }
    };

    removeSlab = (index) => {
        let slabs = [...this.state.slabsWiseAmmount];
        slabs.splice(index, 1);
        this.setState({
            slabsWiseAmmount: slabs
        })
    }
    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let payload = {
            "branchId": branchId,
            "boardId": boardId,
            "classId": classId,
            "sectionId": sectionId

        }
        payload.academicYear = AcademicYear
        let url = `${baseUrlAdmin}/fee-structure/assignInfo`;
        this.service.post(url, payload, true).then(data => {
            if (data?.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data,
                    isLoading: false

                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });
            }
        });

    }







    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }





    getFeeCategoryOptions = () => {
        const academicYear = localStorage.getItem('userAcademicYear')
        let payload = {}
        payload.academicYear = academicYear
        let url = `${baseUrlAdmin}/fee-category/filtered`
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each?.categoryName,
                    value: each?._id
                }
            })

            if (data?.status && data?.res?.data) {
                this.setState({
                    feeCategoryOptions: options
                })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee category options', life: 3000 });

            }
        })
    }
    getFeeTypeOptions = () => {
        let url = `${baseUrlAdmin}/fee-types?academicYear=${localStorage.getItem('userAcademicYear')}`;
        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data?.response) {
                    this.setState({
                        feeTypeOptions: data?.res?.data?.response,
                        allFeeTypes: data?.res?.data?.response,
                    })
                }
            }
        });
    }

    getFineTypeOptions = () => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        // let url = `${baseUrlAdmin}/fine-types?academicYear=${AcademicYear}`;
        let url = ''
        this.service.post(url, {}, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data) {

                    this.setState({
                        fineTypeOptions: data?.res?.data,
                    })
                }
            }
        });


    }




    onCreateFine = () => {
        const academicYear = localStorage.getItem('userAcademicYear')
        let slab_info = []
        let data = this.state?.fineField
        if (data?.fineType === 'SlabWiseAmount' || data?.fineType === 'SlabPercentage') {
            slab_info = this.state?.slabsWiseAmmount
            slab_info = slab_info.map((each) => {
                if (each.fineAmt) {
                    return {
                        startDate: convertUtc(each?.fromToDates[0]),
                        endDate: convertUtc(each?.fromToDates[1]),
                        fineAmt: Number(each?.fineAmt)
                    }
                }
            })

        }
        let isCompleteFormValid = true

        let payload = {}
        payload.fineName = data?.fineName
        payload.applicableTo = data?.applicableTo
        payload.feeCategory = data?.feeCategory
        payload.feeTypes = data?.feeTypes
        payload.fineType = data?.fineType
        if (data.fineType === "FixedAmount") {
            payload.amount = Number(this.state?.amount)
            delete payload?.percentage;
            delete payload?.slab_info
        } else if (data.fineType === 'FixedPercentage') {
            payload.percentage = Number(this.state?.percentage)
            delete payload?.amount;
            delete payload?.slab_info


        }
        else if (data.fineType === 'DayWiseAmount') {
            payload.amount = Number(this.state?.amount)
            delete payload?.percentage;
            delete payload?.slab_info
        }

        else if (data.fineType === 'SlabWiseAmount' || data.fineType === 'SlabPercentage') {
            payload.slab_info = slab_info
            delete payload?.amount;
            delete payload?.percentage;
            this.state?.slabsWiseAmmount.map((e) => {
                if (e.fromToDates?.length != 2 || e.fineAmt == '') {
                    isCompleteFormValid = false
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: 'Date ranges and Fine are not to be empty.', life: 3000 });
                    return
                }
            })
            if (isCompleteFormValid) {
                let overlaps = this.findAndLogOverlaps(this.state?.slabsWiseAmmount);
                if (overlaps.length) {
                    isCompleteFormValid = false
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: 'Date ranges are overlapped please check once.', life: 3000 });
                }
            }
        }

        payload.academicYear = data.academicYear || academicYear
        payload.status = data.status || true

        if (data.applicableTo == "ChequeBounce") {
            const formStatusChequeBounce = isFormValid(createFineChequeBounceFields, this.formFieldsChequeBounce.formValidations, data);
            if (!formStatusChequeBounce.formValidations.isFormValid) {
                this.setState({ formValidations: formStatusChequeBounce.formValidations, });
                isCompleteFormValid = false;
            }
        } else {
            const formStatus = isFormValid(createFineFields, this.formFields.formValidations, data);
            if (!formStatus.formValidations.isFormValid) {
                this.setState({ formValidations: formStatus.formValidations, });
                isCompleteFormValid = false;
            }
        }
        let flag = (data.fineType === 'DayWiseAmount' || data.fineType === "FixedAmount") ? (this.state.amount !== undefined && this.state?.amount !== "") : true

        let flag2 = data.fineType === 'FixedPercentage' ? (this.state.percentage !== undefined && this.state?.percentage !== "") : true

        let url = `${baseUrlAdmin}/fine`
        if (isCompleteFormValid && flag && flag2) {
            this.setState({ isLoading: true })

            if (this.state?.fineEdit && data._id) {
                payload._id = data._id

                this.service.put(url, payload, true).then(res => {
                    if (res?.status) {
                        this.setState({
                            isDialogOpenCreateFine: false,
                            fineEdit: false,
                            fineField: {},
                            selectedStudents: [],
                            dropdownsData: {},
                            isLoading: false,
                            slabsWiseAmmount: [{ fromToDates: '', fineAmt: '' }]
                        },

                        )
                        this.getFines()
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });

                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })

            }
            else {
                this.setState({ isLoading: true })
                this.service.post(url, payload, true).then(res => {
                    if (res?.status) {
                        this.setState({
                            isDialogOpenCreateFine: false,
                            fineField: {},
                            selectedStudents: [],
                            dropdownsData: {},
                            isLoading: false,
                            slabsWiseAmmount: [{ fromToDates: '', fineAmt: '' }]

                        },

                        )
                        this.getFines()
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });

                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
                })
            }

        } else {
            if (!flag) {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter Amount value ', life: 3000 });
            } else if (!flag2) {
                this.setState({ isLoading: false })
                this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter Percentage value ', life: 3000 });

            }
        }


    }
    getFines = () => {
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/fine/get-fines?academicYear=${localStorage.getItem('userAcademicYear')}`
        this.service.post(url, {}, true).then((res) => {
            if (res.status) {
                this.setState({
                    fines: res?.res?.data?.response,
                    isLoading: false

                })


            }

        }).catch(e => {
            this.setState({ isLoading: false })
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting Fines', life: 3000 });

        })

    }


    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
        }, () => { this.getFeeCategoryAssignInfo(dropdownsData?.branchId, dropdownsData?.boardId, dropdownsData?.classId, dropdownsData?.sectionId) })


    }

    getStudentData = (branchId, boardId, classId, sectionId) => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            "branchId": branchId,
            "boardId": boardId,
            "classId": classId,
            "sectionId": sectionId,
            "academicYear": AcademicYear

        }
        this.service.post(url, payload, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data) {
                    this.setState({
                        students: data?.res?.data
                    })
                }
            }
        });



    }
    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = (value.length === this.state.categoryAssignInfo.length)
        this.setState({
            selectedCategoryAssignInfo: event.value,
            selectAll: selectedAll

        })
    }

    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedCategoryAssignInfo: this.state.categoryAssignInfo,
                selectAll: true
            })
        } else {
            this.setState({
                selectedCategoryAssignInfo: [],
                selectAll: false
            })
        }
    };

    onHideAssignDialog = () => {
        this.setState({
            isFineAssignDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false
        })
    }

    FineAssign = () => {

        let inputKey = this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId';


        if (this.state.selectedCategoryAssignInfo?.length) {
            let assignInfo = this.state.selectedCategoryAssignInfo?.map((each) => {
                return {
                    [inputKey]: each[inputKey]
                }
            });
            let payload = {
                "branchId": this.state.dropdownsData.branchId,
                "boardId": this.state.dropdownsData.boardId,
                "classId": this.state.dropdownsData.classId,
                "sectionId": this.state.dropdownsData.sectionId,
                "academicYear": localStorage.getItem("userAcademicYear"),
                "fineId": this.state.fineData?._id,
                "assignedInfo": assignInfo
            }

            let url = `${baseUrlAdmin}/fine/assign`

            this.service.post(url, payload, true).then(res => {
                if (res?.status && res?.res?.status) {

                    this.setState({
                        isFineAssignDialog: false,
                        feeStructureDetails: '',
                        categoryAssignInfo: [],
                        selectedCategoryAssignInfo: [],
                        selectAll: false

                    }, () => { this.getFines() });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                } else {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
                }
            }).catch(err => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
            })


        }
        else {
            this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: "Please select Atleast one record", life: 3000 });
        }


    }

    onChangeOfCategories = (value, feeCategory, thisObj, createFineFields, fineField, formValidations, fineFieldS, formValidationsS) => {
        let feeTypes = cloneDeep(this.state.allFeeTypes);
        feeTypes = feeTypes.filter((feeType) => feeType?.feeCategories?.includes(value));
        this.setState({ feeTypeOptions: feeTypes });
        onDropDownChange(value, feeCategory, thisObj, createFineFields, fineField, formValidations, fineFieldS, formValidationsS)
    }
    render() {

        const { fineField, formValidations, fines, fineEdit } = this.state
        return (
            <>
                <div>

                    <div className="">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                        </div>
                        {/* <div className="-mt-3">
                        <Toolbar className="border-none" 
                         left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}
                    </div>


                    <div className="admin-management">
                        {/* <div style={{ marginBottom: "110px" }}> */}

                        {/* <div className="ml-5 grid h-4rem"> */}
                        {/* <div className="mt-3 md:col-12 lg:col-12 xl:col-12"> */}
                        <div className="grid mb-6">
                            <div className='col-11 flex justify-content-end align-items-center'>
                                <Authorizer permId={PERMISSIONS.CREATE_FINE} >
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                                        // onClick={() => { this.setState({ isDialogOpenForCreateConcession: true }) }}
                                        onClick={this.openCreateFine}
                                    >
                                        <p className=' text-xl'>Add New Fine</p>
                                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                                    </Button>
                                </Authorizer>
                            </div>

                        </div>
                        <div className='grid doubts-tabs'>
                            {
                                fines?.map((fine, index) => {
                                    return <div
                                        className="mx-6 card cursor-pointer col-3 doubts-tab-w"
                                        style={this.state.currentTab === index ? { height: '170px', width: '320px' } : { height: '170px', width: '320px' }}
                                    >
                                        <div className="flex flex-column justify-content-center align-items-center ml-3">
                                            <Authorizer permId={PERMISSIONS.UPDATE_FINE} >
                                                <div className='mt-1 w-full flex justify-content-end align-items-center'>
                                                    <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center' onClick={() => this.Editfine(fine)} >
                                                        <ClassetEditIcon width={32} height={32} color={'#000000'}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <InputSwitch
                                                            className='mt-1'
                                                            onChange={(e) => { this.toggleEdit(e, fine) }}
                                                            checked={fine?.status} />
                                                    </div>
                                                </div>
                                            </Authorizer>
                                            <div className="w-full flex mt-4  ml-3">
                                                <h3 className='Montserrat24 surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis'>{fine?.fineName}</h3>
                                            </div>
                                            <div className="flex justify-content-center mr-5">
                                                {fine?.applicableTo == 'ChequeBounce' ? <p></p> : <p className='poppins24' style={{ fontSize: '24px' }}>{fine?.studentsCount || 0} Students</p>}
                                            </div>
                                            <Authorizer permId={PERMISSIONS.ASSIGN_FINE} >
                                                {fine?.applicableTo != 'ChequeBounce' && <div style={{ marginLeft: 'auto', top: 35 }} onClick={() => { this.setState({ isFineAssignDialog: true, fineData: fine }) }} className='-mt-5 d-flex  mr-3 justify-content-end align-items-center'>
                                                    <AddUserIcon width={32} height={32} color={'#000000'} />
                                                </div>}
                                            </Authorizer>

                                        </div>

                                    </div>
                                })
                            }
                        </div>
                        {this.state.isLoading && <LoadingComponent />}

                        {/* </div> */}
                        {/* </div>
                        </div> */}

                    </div>

                    <Dialog
                        visible={this.state.isFineAssignDialog}
                        draggable={false}
                        closeOnEscape={false}
                        style={{ width: "1400px", height: "800px" }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="text-center text-black-alpha-90 font-bold text-3xl">Assign Fine</h4>
                            </div>)
                        }}
                        onHide={() => this.setState({ isFineAssignDialog: false })}
                    >
                        <div className='ma-scroll-vr-smoke'>
                            <BranchToSectionDD setValues={this.setDropdownValues} />
                            <div className="p-5">
                                <DataTable ref={(el) => this.dt = el}
                                    value={this.state.categoryAssignInfo}
                                    dataKey={this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId'}
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    sortable
                                    selection={this.state?.selectedCategoryAssignInfo}
                                    onSelectionChange={this.onSelectionChange}
                                    selectAll={this.state.selectAll}
                                    onSelectAllChange={this.onSelectAllChange}
                                    selectionMode="checkbox">
                                    <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "5rem", height: "5rem" }} alignHeader={"center"} selectionMode="multiple" exportable={false} />
                                    <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="branchName" header="Branch" className="datatable-cel" sortable />
                                    <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="boardName" header="Board" className="datatable-cel" sortable />
                                    <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="className" header="Grade" className="datatable-cel" sortable />
                                    {this.state?.dropdownsData?.classId ? <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="sectionName" header="Section Name" className="datatable-cel" sortable /> : <></>}
                                    {!this.state?.dropdownsData?.sectionId ? <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="totalStudents" header="Total Students" className="datatable-cel" sortable /> : <></>}
                                    {/* {!this.state?.dropdownsData?.sectionId && <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "17rem", height: "5rem" }} alignHeader={"center"} field="totalStudents" header="Unassigned Students" className="datatable-cel" sortable />} */}
                                    {this.state?.dropdownsData?.sectionId ? <Column bodyClassName='text-center' headerClassName="text-color-secondary" style={{ width: "15rem", height: "5rem" }} alignHeader={"center"} field="username" header="Students" className="datatable-cel" sortable /> : <></>}

                                </DataTable>
                            </div >
                            <div className="text-center mt-4">
                                <Button label='Assign ' className='confirmDialogAcceptBtn  mr-5 w-22rem' onClick={this.FineAssign} />
                                <Button label="Cancel" className='confirmDialogCancelBtn px-7' onClick={this.onHideAssignDialog} />
                            </div>
                        </div>

                    </Dialog>


                    <Dialog
                        visible={this.state.isDialogOpenCreateFine}
                        className='ma-scroll-vr-smoke'
                        style={{ width: '70vw', height: '80vh', overflowX: 'clip', overflowY: 'visible' }}
                        breakpoints={{ '1024px': '90vw', '1366px': '90vw' }}
                        // style={{ width: '100vw'}}
                        draggable={false}
                        onHide={this.handleCloseCreateFine}
                        closable={true}
                        header={() => {
                            return (<div className='mb-2 p-2'>
                                <h3 className="text-center formhead mb-5">{fineEdit ? 'Update Fine' : 'Add New Fine'}</h3>
                            </div>)
                        }}


                    >
                        <div className="mb-5 ml-5 mr-5 p-2 " >
                            <div className="">
                                <div className="grid " >
                                    <div className="col-12 md:col-6 lg:col-4">
                                        <label className='label'>Fine Name<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetInputText
                                                icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                id="fineName"
                                                className='border-round-md groupFormInputText  border-none'
                                                value={fineField.fineName}
                                                onChange={(e) => { onTextChange(e.target.value, 'fineName', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                placeholder='Fine Name'
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['fineName'].isValid && <p className="p-error text-lg ">{formValidations.fields['fineName'].errorMsg}</p>}

                                    </div>
                                    <div className="col-12 md:col-6 lg:col-4">
                                        <label className='label'>Applicable to<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='applicableTo'
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                options={applicableOptions}
                                                optionLabel="label"
                                                className=' no-border text-2xl'
                                                value={fineField.applicableTo}
                                                onChange={(e) => {
                                                    let data = cloneDeep(fineField)
                                                    data.fineType = ""
                                                    data.applicableTo = null
                                                    this.setState({
                                                        fineField: { ...data },
                                                    })
                                                    onDropDownChange(e.target.value, 'applicableTo', this, createFineFields, data, formValidations, 'fineField', 'formValidations')
                                                }}
                                                placeholder={(
                                                    <div>
                                                        <span className='text-xl'>Select</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {formValidations && !formValidations.fields['applicableTo'].isValid && <p className="p-error text-lg ">{formValidations.fields['applicableTo'].errorMsg}</p>}

                                    </div>
                                    <div className="col-12 md:col-6 lg:col-4">
                                        <label className='label'>Logic<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='fineType'
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                options={fineField.applicableTo === "ChequeBounce" ? LogicOptions1 : this.state.logicOptions}
                                                className=' no-border text-2xl'
                                                value={fineField.fineType}
                                                disabled={fineField.applicableTo ? false : true}
                                                onChange={(e) => {
                                                    let data = cloneDeep(fineField)
                                                    data.isGrades = false
                                                    data.isStudents = false
                                                    this.setState({
                                                        fineField: { ...data },
                                                        amount: ''
                                                    })
                                                    onDropDownChange(e.target.value, 'fineType', this, createFineFields, data, formValidations, 'fineField', 'formValidations')
                                                }}
                                                placeholder=
                                                {(
                                                    <div>
                                                        <span className='text-xl'>Select Logic</span>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['fineType'].isValid && <p className="p-error text-lg">{formValidations.fields['fineType'].errorMsg}</p>}

                                    </div>

                                    {(fineField.fineType === 'FixedAmount') || (fineField.fineType === "DayWiseAmount") ? <div className="col-12 md:col-6 lg:col-4">
                                        {fineField.fineType === 'FixedAmount' && <label className='label'>Amount<span className='ma-required'>*</span></label>}
                                        {fineField.fineType === 'DayWiseAmount' && <label className='label'>Ammount per Day<span className='ma-required'>*</span></label>}
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                disabled={this.state.fineField?.studentsCount > 0 ? true : false}
                                                id="amount"
                                                className='border-round-md groupFormInputText  border-none'
                                                // value={fineField.amount}
                                                value={this.state?.amount}
                                                // onChange={(e) => { onTextChange(e.target.value, 'amount', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                onChange={(e) => this.setState({ amount: e.target.value })}
                                                placeholder="Amount"
                                                keyfilter="int"
                                            />

                                        </div>
                                        {/* {formValidations && !formValidations.fields['amount'].isValid && <p className="p-error text-lg">{formValidations.fields['amount'].errorMsg}</p>} */}


                                    </div> : <></>}
                                    {fineField.fineType === 'FixedPercentage' &&
                                        <div className="col-12 md:col-6 lg:col-4">
                                            <label className='label'>Percentage<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetInputText
                                                    icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                    // id="percentage"
                                                    className='border-round-md groupFormInputText  border-none'
                                                    // value={fineField.percentage}
                                                    value={this.state?.percentage}
                                                    onChange={(e) => e?.target?.value <= 100 ? this.setState({ percentage: e?.target?.value }) : null}
                                                    keyfilter='int'
                                                    // onChange={(e) => { onTextChange(e.target.value, 'percentage', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                    placeholder=' Percentage'


                                                />
                                            </div>
                                            {/* {formValidations && !formValidations.fields['percentage'].isValid && <p className="p-error text-lg">{formValidations.fields['percentage'].errorMsg}</p>} */}


                                        </div>}


                                    {fineField.applicableTo !== "ChequeBounce" && fineField.fineType &&

                                        <div className="col-12 md:col-6 lg:col-4">
                                            <label className='label'>Fee Categories<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetDropdown
                                                    id='feeCategory'
                                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                    options={this.state?.feeCategoryOptions}
                                                    // optionLabel="label"
                                                    className=' no-border text-2xl'
                                                    value={fineField.feeCategory}
                                                    onChange={(e) => {
                                                        this.onChangeOfCategories(e.target.value, 'feeCategory', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations')
                                                    }}
                                                    placeholder='Select Fee Category'
                                                />
                                                {formValidations && !formValidations.fields['feeCategory'].isValid && <p className="p-error text-lg">{formValidations.fields['feeCategory'].errorMsg}</p>}

                                            </div>

                                        </div>}
                                    {fineField.fineType && fineField.applicableTo !== "ChequeBounce" &&
                                        <div className="col-12 md:col-6 lg:col-4">
                                            <label className='label'>Fee Types<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetMultiSelect
                                                    id="feeTypes"
                                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                    className=' no-border text-xl'
                                                    options={this.state.feeTypeOptions}
                                                    disabled={fineField.feeCategory ? false : true}
                                                    value={fineField?.feeTypes}
                                                    onChange={(e) => { onDropDownChange(e.target.value, 'feeTypes', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                    placeholder={<p style={{ fontSize: '15px' }}>Select Fee Types</p>}
                                                    optionValue='_id'
                                                    optionLabel='feeType'
                                                />

                                            </div>
                                            {formValidations && !formValidations.fields['feeTypes'].isValid && <p className="p-error text-lg">{formValidations.fields['feeTypes'].errorMsg}</p>}

                                        </div>
                                    }


                                    <div style={{ width: 280 }}>

                                    </div>



                                    {(fineField.fineType === "SlabWiseAmount" || fineField.fineType === "SlabPercentage") && <>
                                        {this.state.slabsWiseAmmount?.map((slab, index) => (
                                            <div className='mr-6 mt-3'>
                                                <div className="boxinnershadow-card   ml-2 mr-2"
                                                >
                                                    <div className='flex'>
                                                        <div className='mr-2'>
                                                            <div key={index} className=' justify-content-center align-content-center ml-3'>
                                                                <div className=''>
                                                                    <ClassetCalendar
                                                                        width={280}
                                                                        calendarMode={'range'}
                                                                        required={false}
                                                                        // label={'Date'}
                                                                        icon={<i className="pi pi-calendar"></i>}
                                                                        value={slab?.fromToDates}
                                                                        onChange={(e) => this.handleSlabChange(index, 'fromToDates', e.target.value)}
                                                                        minDate={new Date()}
                                                                        placeholder={'From and To Dates'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div key={index} className='mt-3 justify-content-center align-content-center ml-3'>
                                                                <ClassetInputText
                                                                    icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                                    id="concessionName"
                                                                    className='border-round-md groupFormInputText  border-none'
                                                                    value={slab?.fineAmt}
                                                                    width={280}
                                                                    onChange={(e) => this.handleSlabChange(index, 'fineAmt', e.target.value, fineField.fineType)}
                                                                    placeholder="Fine Amount" type={'number'} />
                                                            </div>
                                                        </div>
                                                        <div className='cursor-pointer' onClick={() => this.removeSlab(index)}>
                                                            <CancelIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                        <div className='col-12 mt-2' >
                                            <Button
                                                icon={<PlusBlackIcon height={15} width={15} />}
                                                style={{ borderRadius: 10, backgroundColor: '#F2F0F0', border: 'none', }}
                                                disabled={!this.state?.slabsWiseAmmount?.every(each => each?.fromToDates && each?.fineAmt)}
                                                onClick={this.addSlab}
                                            >
                                                <p className=' addSlabTxt pl-2' style={{ color: 'black' }}>{'Add Slab'}</p>
                                            </Button>
                                        </div>
                                    </>}

                                </div>
                                <div className='flex justify-content-center mb-4 mt-4 p-2'>
                                    <Button label={fineEdit ? 'update' : 'Add'} className='p-button-primary submitButton mr-4 button px-6 text-lg w-4'
                                        onClick={() => this.onCreateFine()}
                                    />
                                    <Button className='cancelButtonn w-2'
                                        onClick={this.handleCloseCreateFine} >
                                        <p className='cancelButtonnText'>Cancel</p>
                                    </Button>
                                </div>
                            </div >

                        </div>


                    </Dialog>






                </div >
                <Toast ref={(el) => this.toast = el} position="bottom-right" />




            </>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: state.boardsData.boards,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(Fine)));
