import { BreadCrumb } from 'primereact/breadcrumb';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const AppBreadcrumb = (props) => {
    const location = useLocation();
    const currentComponent = props?.meta?.find(each => each.path == location.pathname)

    return <>{
        location.pathname === '/' ?
            <span>Dashboard</span> :
            <div className='flex justify-content-center align-items-center ml-2'>
                <span >{currentComponent?.parent} <span className='mx-4'>/</span>  </span>
                <span >{'         '} {currentComponent?.label}</span>
            </div>

    }

    </>
};

export default AppBreadcrumb;
