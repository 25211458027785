import React, { Component } from "react"
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';

import { BasicLazyParams, trimObj, warningDailogInit } from '../../utile';
import _ from 'lodash';
import { AcademicSessionCalIcon, AddIcon2, AssignFormIcon, CalenderIcon, ClassetEditIcon, CodeLinkIcon, CrossInBox, DeleteIconClasset, DeleteIconClassetFull, InfoIcon, MailIcon, MoneyIcon, } from '../svgIcons';
import { baseUrlAdmin, baseUrlForLongRunningTask, } from '../../store/apiConstants';
import ClassetDropdown from "../../classetComponents/classetDropDown";
import ClassetInputText from "../../classetComponents/classetInputText";
import ClassetMultiSelect from "../../classetComponents/classetMultiSelect";
import { paymentTerms, paymentModes } from "../../constants";
import { getFormFields, onTextChange, onDropDownChange, onToggleChange, isFormValid, onChoiceChange, isFieldValid } from "../../utile/formHelper";
import BranchToSectionDD from "../BaseDropdownComponents/BranchToSectionDD";
import ClassetCalendar from "../../classetComponents/classetCalender";
import ClassetTextArea from "../../classetComponents/classetTextArea";
import InputTextB from "../customComponents/inputTextB";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import FileUploadLambda from "../fileUpload/fileUploadLambda";
import AttachPreviewDelete from "../questionBank/common/attachPreviewDelete";
import reactStringReplace from "react-string-replace";
import CkEditorComponent from "../questionBank/common/ckEditor";
import notification from "../notification";
import moment from "moment/moment";
import LoadingComponent from '../loadingComponent';
import { getImageUrl } from "../../store/actions";

const NotificationTypes = [{ label: 'MockTest Report', value: 'MockTest Report' }, { label: 'Calendar', value: 'Calendar' }]

const NOTIFICATION_MODES = {
    SMS: 'sms',
    ALERT: 'alert',
    EMAIL: 'email',
    WHATS_APP: 'whatsapp'
}
const smstemps = [
    {
        "_id": "62cd0eacacff7519001a8c8b",
        "name": "sda tfyud dfsuda safua ftuaysdftuasyd fuydftasyudf",
        "content": "fduaoidsfo iasd foasidf osiday fhoasdyfasoidfyha sfyioofasoyif sodifyh aosdf yhsiod faosdf dsoaif oai foasd fyoasdyf hoi fasodi osiduf aodf oisd foasidf uaosid"
    },
    {
        "_id": "62cd08cb2eda2074e163a460",
        "name": "Assignment sms",
        "content": "Dear Parent,{#var#}has scored{#var#}in{#var#}Assignment,{#var#}conducted\non{#var#}.{#var#}"
    },
    {
        "_id": "62cd04702eda2074e163a43b",
        "name": "OTP for registration2",
        "content": "Dear User, The OTP for your request is {#var#}."
    },
    {
        "_id": "62cd0eadacff7519001a8c8e",
        "name": "sda tfyud dfsuda safua ftuaysdftuasyd fuydftasyudf",
        "content": "fduaoidsfo iasd foasidf"
    },
    {
        "_id": "62cd06c2ea4d201446baa5c1",
        "name": "app download msg",
        "content": "Dear Student, Please download the {#var#} from google play store ( Available\non Android only) App URL: {#var#} Your Login Details are UserName:{#var#}\nPassword:{#var#} An iOS version will be released soon Regards {#var#}"
    },
    {
        "_id": "62cd109591b3c1284ad8d5a5",
        "name": "notification",
        "content": "du iasouf asiof oaisfosi dyaosi ydaoisdy foid foasd foasidfy osia foasdi fyoasid fyoasidy oasiyd faosid fyasody foasid fyosiday fosidf oasidyf oasidfodsifu oisafu aosdfoasdu foasidu aoi aosifuasodifasidfju asdkoais udfoiadsoai"
    },
    {
        "_id": "62ccfbc3232e5298fd579254",
        "name": "absent msg",
        "content": "Dear Parent, This is to inform you that your ward {#var#}., is absent for\n{#var#}. out of {#var#}. conducted on {#var#}.. {#var#}."
    }
]
const whatsapptemps = [
    {
        "_id": "62d8f11abe808b2a08cc3e2a",
        "body": "Thanks for choosing {{1}} powered by *MAcademy*",
        "name": "welcome_msg"
    },
    {
        "_id": "62d8f177be808b2a08cc3e38",
        "body": "Your {{1}} is scheduled at {{2}}.\nThanks.",
        "name": "exam_schedule"
    },
    {
        "_id": "62d9213cecc111657d49fa58",
        "name": "hello_world",
        "body": "Welcome and congratulations!! This message demonstrates your ability to send a message notification from WhatsApp Business Platform’s Cloud API. Thank you for taking the time to test with us."
    }
]

var formFields = require('./createNotification.json');
class ComNotification extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields);
        this.state = {
            feeTypes: [],
            feeTypeFormData: {},
            lazyParams: BasicLazyParams,
            feeTypeDataTable: true,
            isDialogOpenForCreateNotification: false,
            feeStructureAssignDialog: false,
            editDialog: false,
            feeCategoriesOptions: [],
            admissionCategories: [],
            //--
            notification: this.props?.editData?._id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: '',
            currentStep: 0,
            filterData: null,
            textMsgs: {},
            smsTemplates: [],
            whatsAppTemplates: [],
            isTemplatesRequested: false,
            smsTemplateContent: "",
            whatsAppTemplateBody: "",
            columns: [
                { columnName: 'No of Users', isVisible: true, id: 'usersCount' },


            ],
            editNofication: null,
            notificationsData: [],
            dropdownsData: {},
            isRejectDialogOpen: false,
            status:"APPROVE"

        }

        this.service = new Service()
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getNotifications);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getNotifications);
    }
    getNotifications = () => {
        this.setState({
            isLoading: true
        });
        let { lazyParams, globalSearch ,status} = this.state;

        const url = `${baseUrlAdmin}/notification-manager?status=${status}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res.status) {
                this.setState({
                    notificationsData: res.res.data,
                    totalRecords: res.res.totalRecords,
                    isLoading: false
                });
                this.toast?.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            //  console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
        //this.getAdmissionCategoryOptions()
        //this.getFeeCategoryOptions();
        this.getNotifications();
        this.getTemplates();
        this.getWhatsAppTemplates();

    }

    getTemplates = () => {
        this.setState({
            isLoading: true,
            smsTemplates: [],
        });
        let { lazyParams, globalSearch } = this.state;


        let url = `${baseUrlAdmin}/smstemplate/all`;
        this.service.get(url, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        smsTemplates: res.res.data,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    getWhatsAppTemplates = () => {
        this.setState({
            isLoading: true,
            // whatsAppTemplates: [],
        });
        let { lazyParams, globalSearch } = this.state;


        let url = `${baseUrlAdmin}/whatsapptemplate/all`;
        this.service.get(url, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        whatsAppTemplates: res.res.data,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    handleOpenCreate = () => {
        this.setState({ isDialogOpenForCreateNotification: true, editDialog: false, notification: {} })
    }
    onHideCreateFeeDialog = () => {
        this.setState({
            isDialogOpenForCreateNotification: false,
            notification: {}

        })
    }
    _afterFilesUploaded = (files) => {

    }

    _onAttachSuccess = (fieldName) => (fileName, data) => {


        this.props.getImageUrl(data.imageStorageKey);
        this.addAttachment(fieldName, fileName, data);

    }
    addAttachment = (fieldName, fileName, data) => {

        let notification = {
            ...this.state.notification,
            [fieldName]: data.imageStorageKey
        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations
            }
        });
    }


    removeAttach = (fieldName) => {
        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    [fieldName]: ''
                }
            };
        });

    }
    onEdit = (rowData) => {
        this.setState({ isDialogOpenForCreateNotification: true, editDialog: true, notification: rowData, editNofication: rowData })
    }
    handleShare = (rowData) => {
        this.setState({
            feeStructureAssignDialog: true,
            editNofication: rowData

        })
    }
    onHideAssignDialog = () => {
        this.setState({
            feeStructureAssignDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false
        })
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
        }, () => { this.getFeeCategoryAssignInfo(dropdownsData.branchId, dropdownsData.boardId, dropdownsData.classId, dropdownsData.sectionId) })
    }
    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let payload = {
            "branchId": branchId,
            "boardId": boardId,
            "classId": classId,
            "sectionId": sectionId,
            "feeStructureId": this.state?.feeStructureDetails?._id

        }
        payload.academicYear = AcademicYear
        let url = `${baseUrlAdmin}/fee-structure/assignInfo`;
        this.service.post(url, payload, true).then(data => {
            if (data.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });

            }
        });

    }
    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedCategoryAssignInfo: this.state.categoryAssignInfo,
                selectAll: true
            })
        } else {
            this.setState({
                selectedCategoryAssignInfo: [],
                selectAll: false
            })
        }
    };
    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = (value.length === this.state.categoryAssignInfo.length)
        this.setState({
            selectedCategoryAssignInfo: event.value,
            selectAll: selectedAll

        })
    }


    combineDateAndTime = (scdate, sctime) => {

        const date = new Date(scdate);
        const time = new Date(sctime);


        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();


        const hours = time.getUTCHours();
        const minutes = time.getUTCMinutes();
        const seconds = time.getUTCSeconds();
        const milliseconds = time.getUTCMilliseconds();


        const combinedDateTime = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));


        return combinedDateTime.toISOString();
    }

    getSMSString = (str, txtEnteredMsgs) => {

        // var str = 'Dear Parent, This is to inform you that your ward {#var#}., is absent for {#var#}. out of {#var#}. conducted on {#var#}.. {#var#}.';


        let textMsgs = {
            // "50": "1st",
            // "74": "2nd",
            // "90": "3rd",
            // "112": "4th",
            // "122": "5th"
        }

        // for (var i = 0; i < str.length; i++) {
        //     if (str[i] === "{#var#}") {
        //         textMsgs[i] = ''
        //     }
        // }

        var regex = /{#var#}/gi, result, indices = [];
        while ((result = regex.exec(str))) {
            //  indices.push(result.index);
            textMsgs[result.index] = ''
        }


        textMsgs = { ...textMsgs, ...txtEnteredMsgs }


        Object.keys(textMsgs).forEach(a => {
            let result = str.lastIndexOf('{#var#}')
            str = str.slice(0, result) + str.slice(result + 7);
            str = this.addStr(str, result, textMsgs[`${result}`])
        });

        // console.log(str)

        return str;

    }


    getParamsForWhatsApp = (str, txtEnteredMsgs) => {

        // var str = 'Dear Parent, This is to inform you that your ward {#var#}., is absent for {#var#}. out of {#var#}. conducted on {#var#}.. {#var#}.';


        let textMsgs = {
            // "50": "1st",
            // "74": "2nd",
            // "90": "3rd",
            // "112": "4th",
            // "122": "5th"
        }

        // for (var i = 0; i < str.length; i++) {
        //     if (str[i] === "{#var#}") {
        //         textMsgs[i] = ''
        //     }
        // }

        var regex = /{#var#}/gi, result, indices = [];
        while ((result = regex.exec(str))) {
            //  indices.push(result.index);
            textMsgs[result.index] = ''
        }


        textMsgs = { ...textMsgs, ...txtEnteredMsgs }

        let params = [];
        Object.keys(textMsgs).forEach(a => {
            params.push(textMsgs[a]);
        });

        // console.log(str)

        return params;

    }
    addStr(str, index, stringToAdd) {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }

    onSubmitNotification = () => {
        

        const { smsTemplateContent, whatsAppTemplateBody, textMsgs ,notification} = this.state;

        const academicYear = localStorage.getItem('userAcademicYear')
        let payload = this.state.notification;
        let dateWithTime;
        if (this.state.notification.scdate && this.state.notification.sctime) {
            dateWithTime = this.combineDateAndTime(this.state.notification.scdate, this.state.notification.sctime)
        }

        if (notification.mode == NOTIFICATION_MODES.SMS) {
            if (smsTemplateContent && smsTemplateContent.length) {
                payload.message = this.getSMSString(smsTemplateContent, textMsgs);
            }
        }

        if (notification.mode == NOTIFICATION_MODES.WHATS_APP) {
            if (whatsAppTemplateBody && whatsAppTemplateBody.length) {
                payload.params = this.getParamsForWhatsApp(whatsAppTemplateBody, textMsgs);
            }
        }
        payload.scheduleTime = dateWithTime;
        payload.academicYear = academicYear

        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(payload));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, });

        } else {
            if (!this.state.notification?._id) {
                this.setState({ isLoading: true })
                let url = `${baseUrlForLongRunningTask}/notification-manager/create`;
                this.service.post(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({
                            isLoading: false,
                            editDialog: false, isDialogOpenForCreateNotification: false, notification: {}
                        })
                        this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        this.getNotifications()
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                    }
                })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Creating the fee type', life: 3000 });
                    });
            }
            else if (this.state?.notification?._id) {
                this.setState({ isLoading: true })
                payload._id = this.state?.notification?._id;
                let url = `${baseUrlForLongRunningTask}/notification-manager/update`;
                this.service.post(url, payload, true).then(data => {
                    if (data?.status) {
                        this.setState({
                            isLoading: false,
                            notification: {},
                            editDialog: false,
                            isDialogOpenForCreateNotification: false
                        })
                        this.getNotifications()
                        this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                    } else {
                        this.setState({ isLoading: false })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });

                    }

                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating the fee type', life: 3000 });
                });


            }

        }
    }
    getFeeCategoryOptions = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = {}
        payload.academicYear = academicYear
        let url = `${baseUrlAdmin}/fee-category/filtered`;
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each.categoryName,
                    value: each._id
                }
            })

            if (data?.status && data?.res?.data) {

                this.setState({ feeCategoriesOptions: options })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee category options', life: 3000 });

            }

        })
    }
    getAdmissionCategoryOptions = () => {
        // let url = `${baseUrlAdmin}/admission-types`
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        this.service.get(url, {}, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each?.categoryName,
                    value: each?._id
                }
            })

            if (data?.status && data?.res?.data) {

                this.setState({ admissionCategories: data?.res?.data })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the Admission category options', life: 3000 });

            }

        })
    }

    onPage = (event) => {
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);

    }

    onEditMultiDD = (selectedValue, key) => {
        if (this.state.editDialog) {
            let isCondition = this.state.backupEditData[key]?.every(item => selectedValue.includes(item));
            if (!isCondition && this.state.backupEditData.assignedStudents > 0) {
                this.toast?.show({ severity: 'warn', summary: `can't remove already assigned value`, life: 3000 });
                return false
            }
        }

        return true
    }

    nothing = () => {
        console.log('fsdfsfsdf')
    }
    handleInputChange = (index, field, value) => {
        const stops = [...this.state.stops];
        if (field === 'pickUpTime' || field === 'dropTime') {
            if (value instanceof Date && !isNaN(value)) {
                const hours = value.getHours().toString().padStart(2, '0');
                const minutes = value.getMinutes().toString().padStart(2, '0');
                value = `${hours}:${minutes}`;
            }
        }
        stops[index][field] = value;
        this.setState({ stops });
    };
    updateFields = (requiredFields, notRequiredFields) => {
        let { notification } = this.state;

        formFields = formFields.map(field => {
            if (requiredFields.includes(field.FieldName)) {

                field.Required = true;
            }

            if (notRequiredFields.includes(field.FieldName)) {
                notification[field.FieldName] = '';

                field.Required = undefined;
            }

            return field;
        });
        this.formFields = getFormFields(formFields, this.state.org);

        this.setState({
            notification,
            formValidations: this.formFields.formValidations,
            textMsgs: {},
            smsTemplateContent: "",
            whatsAppTemplateBody: ""
        })
    }
    onMessageTemplateChange = (fieldName, data) => {

        let smsTemplateContent = this.state.smsTemplates.find(a => a._id == data).content

        let notification = {
            ...this.state.notification,
            [fieldName]: data,

        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations,
                smsTemplateContent
            }
        });
    }
    onTxtMsgFieldChange = (position, value) => {
        this.setState((prevState) => {
            return {
                textMsgs: {
                    ...prevState.textMsgs,
                    [position]: value
                }
            }
        });
    }
    formatWhatsAppTemplateTxt = (str) => {
        let result;
        let i = 100;
        do {
            result = str.lastIndexOf(`{{${i}}}`);
            if (result >= 0) {
                str = str.slice(0, result) + '{#var#}' + str.slice(result + 5);
            }
            i--;
        } while (i > 0);
        return str;
    }
    onWhatsAppTemplateChange = (fieldName, data) => {

        let template = this.state.whatsAppTemplates.find(a => a._id == data);
        let whatsAppTemplateBody = template.body

        whatsAppTemplateBody = this.formatWhatsAppTemplateTxt(whatsAppTemplateBody)

        let notification = {
            ...this.state.notification,
            [fieldName]: data,
            whatsAppTemplateName: template.name

        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations,
                whatsAppTemplateBody
            }
        });
    }
    onSubmitClick = () => {

        const { notification, smsTemplateContent, whatsAppTemplateBody, textMsgs } = this.state;

        let _notification = notification;

        if (_notification.mode == NOTIFICATION_MODES.SMS) {
            if (smsTemplateContent && smsTemplateContent.length) {
                _notification.message = this.getSMSString(smsTemplateContent, textMsgs);
            }
        }

        if (_notification.mode == NOTIFICATION_MODES.WHATS_APP) {
            if (whatsAppTemplateBody && whatsAppTemplateBody.length) {
                _notification.params = this.getParamsForWhatsApp(whatsAppTemplateBody, textMsgs);
            }
        }

        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(_notification));

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            if (_notification.mode == NOTIFICATION_MODES.WHATS_APP) {
                if (_notification.params && _notification.params.length) {
                    let isValid = true;
                    _notification.params.forEach(param => {
                        if (!(param || param.length)) {
                            isValid = false
                        }
                    });

                    if (!isValid) {
                        return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please complete the message', life: 3000 });
                    }

                }
            }
        }


    }



    deleteNotification = () => {
        
   

        if (this.state?.notification!=null) {

            this.setState({
                isLoading: true
            });
            const url = `${baseUrlForLongRunningTask}/notification-manager/${this.state?.notification?._id}`;
            this.service.delete(url, {}, true).then((data) => {

                if (data && data.res && data.res.status) {
                    this.setState({
                        isLoading: false,
                        isRejectDialogOpen: false
                    });
                    this.getNotifications()
                    this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unkown error', life: 3000 });
                }

            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });
        }




    }

    assignStudents = (users) => {
        
        let { editNofication } = this.state;

        if (this.state?.dropdownsData?.sectionId != undefined && this.state?.dropdownsData?.sectionId != null && this.state?.dropdownsData?.sectionId && this.state?.editNofication && users.length > 0) {
            let payload = {
                ...editNofication, assignTo: 'section', assignIds: users,
                usersCount: users?.length
            }
            this.setState({
                isLoading: true
            });
            const url = `${baseUrlForLongRunningTask}/notification-manager/send`;
            this.service.post(url, payload, true).then((data) => {

                if (data && data.res && data.res.status) {
                    console.log(data, "datadatadatadata")
                    this.setState({
                        isLoading: false,
                        feeStructureAssignDialog: false
                    },);
                    this.toast?.show({ severity: 'success', summary: 'Success', detail: data?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unkown error', life: 3000 });
                }

            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });
        }




    }
    render() {
        const { currentStep, notification, formValidations, textMsgs } = this.state;
        return (
            <div>
                <div className='admin-management'>
                    <div className="grid mb-6 mt-2">
                        <div className='col-12 flex justify-content-end align-items-center'>
                            <Button onClick={this.handleOpenCreate} className='addButtonn'>
                                <AddIcon2 />
                                <p className='' >Add New Notification</p>
                            </Button>
                        </div>
                    </div>

                    <div className="ma-m30">
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.notificationsData}
                            dataKey="id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Notifications"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"

                        >

                            <Column header="S.No." style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{index.rowIndex + 1}</p>
                                    </>
                                )
                            }} />
                            <Column field="name" header="Name of the Event" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />


                            <Column header="Date and Time" sortField='user_info.name' field="name" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center'
                                body={(rowData) => {
                                    return <>
                                        {moment(rowData.scheduleTime).format('MMM Do YYYY, h:mm:ss a')}</>
                                }} />
                            <Column header="Notification Type" field="type" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />


                            <Column field="usersCount" header="No of Users" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />
                            <Column field="mode" header="Notification Mode" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Share" body={(rowData, { rowIndex }) => {
                                return (<>
                                    <div className="flex">

                                        <span data-pr-tooltip="" data-pr-position="" className={`ma-ml10 awl${rowIndex}`}

                                        >
                                            <Button className='formShowButton' onClick={() => this.handleShare(rowData)}>
                                                <p className='formShowButtonText' >Send</p>
                                            </Button>


                                        </span>

                                    </div>
                                </>
                                );
                            }} ></Column>
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary"  header="Edit" body={(rowData, { rowIndex }) => {
                                return (<>
                                    <div className="flex justify-content-center">

                                        <span data-pr-tooltip="Edit Notification" data-pr-position="" className={`ma-ml10 awl${rowIndex}`}
                                            onClick={() => { this.onEdit(rowData) }}
                                        >
                                            <ClassetEditIcon width={32} height={32} color={'#000000'} />


                                        </span>

                                    </div>
                                </>
                                );
                            }} ></Column>

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Delete" body={(rowData, { rowIndex }) => {
                                return (<>
                                    <div className="flex  justify-content-center">

                                        <span data-pr-tooltip="Delete Notification" data-pr-position="" className={`ma-ml10 awl${rowIndex}`}
                                          
                                            onClick={() => this.setState({ isRejectDialogOpen: true, notification:rowData}) }
                                        >
                                            <DeleteIconClasset width={32} height={32} color={'#000000'} />
                                        </span>

                                    </div>
                                </>
                                );
                            }} ></Column>

                        </DataTable>

                        <Toast ref={(el) => this.toast = el} position="bottom-right" />

                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </div >
                <Dialog
                    visible={this.state.isDialogOpenForCreateNotification}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box w-8'
                    style={{ width: "500px", height: "700px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="formHeadingInter">Create Notification</h4>
                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button className='formSubmitButton px-6' style={{ borderRadius: '10px', marginRight: 10 }} onClick={this.onSubmitNotification} >
                                <p className='formSubmitButtonText'> {this.state.editDialog ? 'Update' : 'Create'}</p>
                            </Button>
                            <Button className='formCancelButton px-6' style={{ borderRadius: '10px' }} onClick={() => this.setState({ dateRange: null, isDialogOpenForCreateNotification: false, editDialog: false, notification: {} })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ dateRange: null, isDialogOpenForCreateNotification: false, editDialog: false, notification: {} })}
                >
                    <div className='flex flex-column justify-content-center mx-6 align-items-center' >
                        <div className='grid  justify-content-center'>
                            <div className={notification.mode ? 'col-6 justify-content-center' : 'col-6 '}>
                                <div>
                                    <ClassetInputText
                                        required={true}
                                        label={'Notification Title'}
                                        icon={<MailIcon />}
                                        width={'470px'}
                                        placeholder={'Title'}
                                        value={notification.name}
                                        onChange={(e) => { onTextChange(e.target.value, 'name', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                        title="Name"
                                    />
                                    {formValidations && !formValidations.fields['name'].isValid && <p className="p-error font-semibold">{formValidations.fields['name'].errorMsg}</p>}
                                </div>
                                <div className="mt-4">
                                    <ClassetDropdown
                                        required={true}
                                        width={'470px'}
                                        label={'Notification Type'}
                                        icon={<AcademicSessionCalIcon />}
                                        placeholder={'Select Notification Type'}
                                        options={NotificationTypes}
                                        value={notification.type}
                                        onChange={(e) => {
                                            onDropDownChange(e.value, 'type', this, formFields, notification, formValidations, 'notification', 'formValidations')
                                        }}
                                    />

                                    {formValidations && !formValidations.fields['type'].isValid && <p className="p-error font-semibold">{formValidations.fields['type'].errorMsg}</p>}

                                </div>
                                <div className="flex flex-row col-12 -m-5 mt-1">
                                    <div className="col-6">
                                        <ClassetCalendar
                                            icon={<CalenderIcon height={20} />}
                                            className=""
                                            label="Schedule"
                                            calendarMode="single"
                                            name="scdate"
                                            //value={notification.scheduleDate}
                                            value={notification.scdate ? new Date(notification.scdate) : null}
                                            // onChange={(e) => this.setState({ scheduleDate: e.value })}
                                            onChange={(e) => onTextChange(e.value, 'scdate', this, formFields, notification, formValidations, 'notification', 'formValidations')}
                                            minDate={new Date()}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <ClassetCalendar
                                            icon={<CalenderIcon height={20} />}
                                            required={true}
                                            className=""
                                            label="Time"
                                            calendarMode="single"
                                            name="sctime"
                                            value={notification.sctime ? new Date(notification.sctime) : null}
                                            onChange={(e) => onTextChange(e.value, 'sctime', this, formFields, notification, formValidations, 'notification', 'formValidations')}

                                            //onChange={(e) => this.setState({ time: e.value })}
                                            minDate={new Date()} timeOnly
                                        />
                                    </div>

                                </div>
                                <div className="grid ma-w100p mt-5" >
                                    <div className="col-12 p-md-3 ">
                                        <label className={`ma-label-s2 font-serif font-semibold text-900 text-lg `}>{'Notification Mode'}
                                            {<span className="ma-required">*</span>}
                                        </label>
                                        <div >
                                            <div className='col-4 flex gap-3 -ml-3'>
                                                <div className="flex  align-items-center  " style={{ padding: '2px' }}>
                                                    <RadioButton className="radio-inline mr-2" value={NOTIFICATION_MODES.ALERT} inputid="coyes" name="mode"
                                                        onChange={(e) => {
                                                            onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {
                                                                this.updateFields(['title', 'description'], ['whatsAppTemplateId', 'message', 'subject', 'body', 'smsTemplateId'])

                                                            });
                                                        }}
                                                        checked={notification.mode === NOTIFICATION_MODES.ALERT} />
                                                    <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.ALERT}>Alert</label>
                                                </div>
                                                <div className="flex  align-items-center  " style={{ padding: '2px' }}>
                                                    <RadioButton className="radio-inline ma-ml10 mr-2" value={NOTIFICATION_MODES.SMS} inputid="cono" name="mode"
                                                        onChange={(e) => {
                                                            onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {
                                                                this.updateFields(['message', 'smsTemplateId'], ['whatsAppTemplateId', 'title', 'description', 'subject', 'body'])
                                                            })

                                                        }}
                                                        checked={notification.mode === NOTIFICATION_MODES.SMS} />
                                                    <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.SMS}>SMS</label>
                                                </div>
                                                <div className="flex  align-items-center  " style={{ padding: '2px' }}>
                                                    <RadioButton className="radio-inline ma-ml10 mr-2" value={NOTIFICATION_MODES.EMAIL} inputid="cono" name="mode"
                                                        onChange={(e) => {
                                                            onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {
                                                                this.updateFields(['subject', 'body',], ['whatsAppTemplateId', 'title', 'description', 'message', 'smsTemplateId'])
                                                            })

                                                        }}
                                                        checked={notification.mode === NOTIFICATION_MODES.EMAIL} />
                                                    <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.EMAIL}>Email</label>
                                                </div>
                                                <div className="flex  align-items-center  " style={{ padding: '2px' }}>
                                                    <RadioButton className="radio-inline ma-ml10 mr-2" value={NOTIFICATION_MODES.WHATS_APP} inputid="cono" name="mode"
                                                        onChange={(e) => {
                                                            onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {

                                                                this.updateFields(['whatsAppTemplateId'], ['body', 'title', 'description', 'message', 'smsTemplateId', 'subject'])
                                                            });
                                                        }}

                                                        checked={notification.mode === NOTIFICATION_MODES.WHATS_APP} />
                                                    <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.WHATS_APP}>WhatsApp</label>
                                                </div>
                                            </div>
                                        </div>
                                        {formValidations && !formValidations.fields['mode'].isValid && <p className="p-error">{formValidations.fields['mode'].errorMsg}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                {notification.mode == NOTIFICATION_MODES.ALERT && <>
                                    <div className=''>
                                        <div className=''>
                                            <div className=''>
                                                <ClassetInputText value={notification.title} label={'Title'}
                                                    onChange={(e) => { onTextChange(e.target.value, 'title', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                    className='p-inputtext-style1 w-full' />
                                                {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                                            </div>
                                            <div className='mt-3'>
                                                <ClassetTextArea
                                                    rows={8}
                                                    label={'Description'}
                                                    height={'209px'}
                                                    value={notification.description}
                                                    onChange={(e) => { onTextChange(e.target.value, 'description', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                    className='p-inputtext-style1 w-full' />
                                                {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                                            </div>
                                            <div className='mt-3'>
                                                <FileUploadLambda id={'notifatt'}
                                                    multiple={false}
                                                    onProgress={this._onProgress}
                                                    onSucess={(fileName, data) => this._onAttachSuccess('attachment')(fileName, data)}
                                                    maxFileSize={307200}
                                                    onError={this._onFilsUploadError}
                                                    accept={'images'}
                                                    title={'Images'}
                                                    dirName=''
                                                    afterFilesUploaded={this._afterFilesUploaded}
                                                >
                                                    <label className='ma-label-s2 font-serif font-semibold text-900 text-lg' htmlFor={'notifatt'}>
                                                        Attachment <i className='pi pi-file' />
                                                    </label>
                                                </FileUploadLambda>

                                                {this.props.getAllImagesUrlInfo[notification['attachment']] &&
                                                    <AttachPreviewDelete
                                                        imagePath={this.props.getAllImagesUrlInfo[notification['attachment']]}
                                                        removeAttach={() => { this.removeAttach('attachment') }}

                                                    />}
                                            </div>
                                        </div>
                                    </div>
                                </>}

                                {notification.mode == NOTIFICATION_MODES.SMS && <>
                                    {
                                        <>
                                            <div className=' '>
                                                <div className=''>
                                                    <div className=''>
                                                        <ClassetDropdown value={notification.smsTemplateId}
                                                            className='w-full'
                                                            label={'Message Template'}
                                                            options={this.state.smsTemplates}
                                                            onChange={(e) => {
                                                                this.onMessageTemplateChange('smsTemplateId', e.value)
                                                            }}
                                                            optionLabel='name'
                                                            optionValue='_id'
                                                            placeholder="Select Template" />
                                                        {formValidations && !formValidations.fields['smsTemplateId'].isValid && <p className="p-error">{formValidations.fields['smsTemplateId'].errorMsg}</p>}
                                                    </div>


                                                </div>
                                            </div>
                                            {
                                                notification.smsTemplateId && 
                                                <div className='flex flex-row -m-3 mt-1'>
                                                    <div className='col-12 p-md-6 p-lg-6'>
                                                        <div className=''>
                                                            <p className='`ma-label-s2 font-serif font-semibold text-900 text-lg'>Message<span className='ma-required'></span></p>
                                                            <div className='sms-template-txt card '>
                                                                {reactStringReplace(this.state.smsTemplateContent, '{#var#}', (match, i, offset) => (
                                                                    <ClassetInputText value={textMsgs[offset] ? textMsgs[offset] : ''} onChange={(e) => { this.onTxtMsgFieldChange(offset, e.target.value) }}  />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {/* {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>} */}
                                        </>}
                                </>}

                                {notification.mode == NOTIFICATION_MODES.EMAIL && <>
                                    {
                                        <>
                                            <div className=''>
                                                <div className=''>
                                                    <ClassetInputText value={notification.subject}
                                                        label={'Subject'}
                                                        onChange={(e) => { onTextChange(e.target.value, 'subject', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                        className='p-inputtext-style1 w-full' />
                                                    {formValidations && !formValidations.fields['subject'].isValid && <p className="p-error">{formValidations.fields['subject'].errorMsg}</p>}
                                                    <div className=''>
                                                        <ClassetTextArea
                                                            rows={100}
                                                            label={'Message Body'}
                                                            height={'209px'}
                                                            value={notification.body}
                                                            onChange={(e) => { onTextChange(e.target.value, 'body', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                            className='p-inputtext-style1 w-full' />
                                                        {formValidations && !formValidations.fields['body'].isValid && <p className="p-error">{formValidations.fields['body'].errorMsg}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>}

                                {notification.mode == NOTIFICATION_MODES.WHATS_APP && <>
                                    {
                                        <>

                                            <div className=''>
                                                <div className=''>
                                                    <div className=''>
                                                        <ClassetDropdown value={notification.whatsAppTemplateId}
                                                            className='w-full'
                                                            label={'Message Template'}
                                                            options={this.state.whatsAppTemplates}
                                                            onChange={(e) => {
                                                                this.onWhatsAppTemplateChange('whatsAppTemplateId', e.value)
                                                            }}
                                                            optionLabel='name'
                                                            optionValue='_id'
                                                            placeholder="Select Template" />
                                                        {formValidations && !formValidations.fields['whatsAppTemplateId'].isValid && <p className="p-error">{formValidations.fields['whatsAppTemplateId'].errorMsg}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                notification.whatsAppTemplateId && <div className='flex flex-row -m-3 mt-2'>
                                                    <div className='col-12 p-md-6 p-lg-6'>
                                                        <div className=''>
                                                            <p className='`ma-label-s2 font-serif font-semibold text-900 text-lg'>Message<span className='ma-required'></span></p>
                                                            <div className='sms-template-txt card'>
                                                                {reactStringReplace(this.state.whatsAppTemplateBody, '{#var#}', (match, i, offset) => (
                                                                    <ClassetInputText value={textMsgs[offset] ? textMsgs[offset] : ''} onChange={(e) => { this.onTxtMsgFieldChange(offset, e.target.value) }} className='p-inputtext-style1' />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>}
                                        </>}
                                </>}

                            </div>
                        </div>

                    </div>
                </Dialog >

                <Dialog
                    isOpen={this.state.feeStructureAssignDialog}
                    visible={this.state.feeStructureAssignDialog}
                    style={{ width: '80vw', height: '80vh', }}
                    draggable={false}
                    onHide={this.onHideAssignDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className='mt-4'>
                                <h3 className="text-center formhead">Notification Assign</h3>
                            </div>
                        )
                    }}
                >
                    <div className="p-5">
                        <div className=''>
                            <div className="grid mb-2 " >

                                <BranchToSectionDD setValues={this.setDropdownValues} />

                                {/* <div className="col-3 ml-1">
                                    <ClassetInputText

                                        icon={<CodeLinkIcon />}
                                        id="categoryName"
                                        className="text-xl "
                                        // height="48px"
                                        // width="262px"
                                        placeholder="Structure Name"
                                        value={this.state?.feeStructureName}
                                        infoIcon={<InfoIcon />}
                                        disabled={true}
                                    />
                                </div> */}
                            </div>

                        </div>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.categoryAssignInfo}
                            dataKey={this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId'}
                            paginator
                            rows={10}
                            // showGridlines
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            // globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            // header={header}
                            sortable
                            selection={this.state?.selectedCategoryAssignInfo}
                            onSelectionChange={this.onSelectionChange}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.onSelectAllChange}
                            selectionMode="checkbox"

                        >

                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                            <Column field="branchName" header="Brach Name" className="datatable-cel" sortable />
                            <Column field="boardName" header="Board Name" className="datatable-cel" sortable />
                            <Column field="className" header="Grade Name" className="datatable-cel" sortable />
                            {this.state?.dropdownsData?.classId ? <Column field="sectionName" header="Section Name" className="datatable-cel" sortable /> : <></>}
                            {this.state?.dropdownsData?.sectionId ? <></> : <Column field="totalStudents" header="Total Students" className="datatable-cel" sortable />}

                            {this.state?.dropdownsData?.sectionId ? <Column field="username" header="Students" className="datatable-cel" body={(rowData) => {
                                return (

                                    <p>{(rowData?.username)?.slice(3)}</p>
                                )
                            }} /> : <></>}

                        </DataTable>
                        <div className='flex justify-content-center mb-4 mt-4'>
                            <Button className='submitButtonn w-2 '

                                onClick={() => {
                                    if (Array.isArray(this.state?.selectedCategoryAssignInfo)) {
                                        this.assignStudents(this.state.selectedCategoryAssignInfo.map((i) => i._id));
                                    }
                                }}
                            >
                                <p className='submitButtonnText'>Assign</p>
                            </Button>
                            <Button className='cancelButtonn w-2 ml-3'
                                onClick={this.onHideAssignDialog} >
                                <p className='cancelButtonnText'>Cancel</p>
                            </Button>
                        </div>

                    </div >
                </Dialog>


                <Dialog
                    visible={this.state.isRejectDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className='w-3'
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (<div className='formHeadingInter' style={{}}>
                           
                             <CrossInBox bgcolor={'#BF0031'} color={'white'} /> 
                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center '>
                            <Button className='formSubmitButton px-6' style={{ border: 0 }} onClick={this.deleteNotification} >
                                <p className='formSubmitButtonText'>Delete</p>
                            </Button>
                            <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejectDialogOpen: false })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRejectDialogOpen: false })}
                >
                    <div>
                        <p className='sideHeading' style={{ fontSize: 20 }}>{"Are you sure you want to delete?"}</p>

                    </div>

                </Dialog>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }

    
}


const mapStateToProps = (state) => ({
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
    emailConfig: state.orgData.emailConfig,
    smsConfig: state.orgData.smsConfig,
    whatsAppConfig: state.orgData.whatsAppConfig,
    orgData: state.orgData
});

const mapDispatchToProps = (dispatch) => ({
    getImageUrl: (url) => dispatch(getImageUrl(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComNotification);

