import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import Authentication from './../session';
import { connect } from 'react-redux';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';


class FeeReciept extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reciept: {

                "receiptNo": "AS00027",
                "receiptDate": "2024-09-06T06:12:41.210Z",
                "studentName": "aaa adasd",
                "academicYear": "2024 - 2025",
                "className": "Grade 1",
                "paymentMode": "Cash",
                "feePaidDetails": [
                    {
                        "feeType": "Application fee",
                        "amountPaying": "500"
                    }
                ],
                "amountPaid": "500"

            }
        }
    }
    render() {
        const { receiptData } = this.props;

        const { logoRectangle, orgName, orgAddress, mobileOperational } = this.props;
        console.log(this.props, 'recieptnew')
        // let receiptData = this.state.reciept
        return (
            <>
                <Dialog
                    style={{ width: '80%' }}
                    header='Fee Reciept'
                    modal
                    footer={() => {
                        return <>
                            <ReactToPrint
                                pageStyle="padding:'10px'"
                                content={() => this.componentRef}>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <>  <Button data-pr-position="bottom"
                                            data-pr-tooltip="Download "
                                            onClick={handlePrint}
                                            className='print-button ma-mr20 p-ml-10 p-mb-4'
                                            label='Print'
                                        ></Button>
                                            <Tooltip className="table-li-tooltip" target=".egraph" />
                                        </>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </>
                    }}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.props.hideFeeReciept}
                >
                    <div className='flex justify-between ma-m20 ' ref={(el) => this.componentRef = el}>
                        <div style={{ width: '48%', border: '1px dashed' }}>
                            <div className='flex'>
                                <div className='w-30 ma-mt10' >
                                    <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{ marginLeft: '10%' }} />
                                </div>
                                <div className='flex justify-center w-70'>
                                    <div>
                                        <div className='flex justify-center'>
                                            <h2 className='p-mb-2 orgHeader' >{orgName}</h2>
                                        </div>
                                        <h4 className='heading-font'>{orgAddress}</h4>
                                        <div className=''>
                                            <h4 className='heading-font '>Contact Number :{mobileOperational}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100'>
                                <hr className='dashed' />
                            </div>
                            <div className='flex justify-end mr-3' >
                                <h5>Office Copy</h5>
                            </div>
                            <div className='flex justify-center mb-3'>
                                <h3>{receiptData?.receiptName || "Fee Receipt"}</h3>
                            </div>
                            <div className='ml-3' >
                                <h6 className='payinfo'>RECEIPT NUMBER : {receiptData?.receiptNo}</h6>
                                <h6 className='payinfo'>RECEIPT DATE : {moment(receiptData?.date).format('DD-MM-YYYY')}</h6>
                                <h6 className='payinfo'>NAME OF THE STUDENT : {receiptData?.studentName}</h6>

                                <h6 className='payinfo'>ACADAMIC YEAR : {receiptData?.academicYear}</h6>
                                <h6 className='payinfo'>CLASSNAME : {receiptData?.className}</h6>
                                <h6 className='payinfo'>PAYMENT MODE : {receiptData?.paymentMode}</h6>
                                <div className='mr-3'>
                                    <table className='table-border1 w-100 '>
                                        <tr>
                                            <th className='table-border heading-font w-1'>S.no</th>
                                            <th className='table-border heading-font'>Particulars</th>
                                            <th className='table-border heading-font'>Amount</th>
                                        </tr>
                                        {receiptData?.feePaidDetails.map((e, i) => {
                                            let amount = Number(e.amountPaying) || 0
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='table-border heading-font'>{i + 1}</td>
                                                        <td className='table-border heading-font'> {e.feeType}</td>
                                                        <td className='table-border heading-font'>{amount}</td>

                                                    </tr>
                                                </>
                                            )
                                        })}

                                        {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                        <tr>
                                            <td className='table-border heading-font totalAmt' colSpan='5'>Total Amount Paid - {Number(receiptData?.amountPaid).toFixed(2) || 0}</td>
                                            {/* <td className='table-border heading-font'></td> */}
                                        </tr>
                                    </table>
                                    <div>
                                        <h6>Note : <span></span></h6>
                                    </div>
                                    <div className='flex justify-end mt-7'>
                                        <h6 >Cashier/Manager</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: '48%', border: '1px dashed' }}>
                            <div className='flex'>
                                <div className='w-30 ma-mt10' >
                                    <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{ marginLeft: '10%' }} />
                                </div>
                                <div className='flex justify-center w-70'>
                                    <div>
                                        <div className='flex justify-center'>
                                            <h2 className='p-mb-2 orgHeader' >{orgName}</h2>
                                        </div>
                                        <h4 className='heading-font'>{orgAddress}</h4>
                                        <div className=''>
                                            <h4 className='heading-font '>Contact Number :{mobileOperational}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100'>
                                <hr className='dashed' />
                            </div>
                            <div className='flex justify-end mr-3'>
                                <h5>Duplicate Copy</h5>
                            </div>
                            <div className='flex justify-center mb-3'>
                                <h3 >{receiptData?.receiptName || "Fee Receipt"}</h3>
                            </div>
                            <div className='ml-3' >
                                <h6 className='payinfo'>RECEIPT NUMBER : {receiptData?.receiptNo}</h6>
                                <h6 className='payinfo'>RECEIPT DATE : {moment(receiptData?.date).format('DD-MM-YYYY')}</h6>
                                <h6 className='payinfo'>NAME OF THE STUDENT : {receiptData?.studentName}</h6>

                                <h6 className='payinfo'>ACADAMIC YEAR : {receiptData?.academicYear}</h6>
                                <h6 className='payinfo'>CLASSNAME : {receiptData?.className}</h6>
                                <h6 className='payinfo'>PAYMENT MODE : {receiptData?.paymentMode}</h6>

                                <div className='mr-3'>
                                    <table className='table-border1 w-100 '>
                                        <tr>
                                            <th className='table-border heading-font w-1'>S.no</th>
                                            <th className='table-border heading-font'>Particulars</th>
                                            <th className='table-border heading-font'>Amount</th>
                                        </tr>
                                        {receiptData?.feePaidDetails.map((e, i) => {
                                            let amount = Number(e.amountPaying) || 0
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='table-border heading-font'>{i + 1}</td>
                                                        <td className='table-border heading-font'> {e.feeType}</td>
                                                        <td className='table-border heading-font'>{amount}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}

                                        {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                        <tr>
                                            <td className='table-border heading-font totalAmt' colSpan='5'>Total Amount Paid - {Number(receiptData?.amountPaid).toFixed(2) || 0}</td>
                                            {/* <td className='table-border heading-font'>{receiptData.amountPaid}</td> */}
                                        </tr>
                                    </table>
                                    <div>
                                        <h6>Note : <span></span></h6>
                                    </div>
                                    <div className='flex justify-end mt-7'>
                                        <h6>Cashier/Manager</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgAddress: state.orgData.address,
    mobileOperational: state.orgData.mobileOperational,
});

export default connect(mapStateToProps, {})(
    Authentication(FeeReciept)
);