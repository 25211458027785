import React, { Component } from 'react'

import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import moment from 'moment';
import axios from 'axios';
//import Navbar from './../navbar';
import _, { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { getFormFields, isFormValid, onDropDownChange, onEmailChange, onNumberChange, onTextChange, onToggleChange } from '../../utile/formHelper'
import { v4 as uuidv4 } from 'uuid';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import './styles.scss'
// import UserInfo from './userInfo';
// import CreateUser from './createUser';
import Authentication from '../session';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { BasicLazyParams, StatesAndDirstricts, cities, countries, states, warningDailogInit } from '../../utile';
import IndividualUsageAnalytics from '../analytics/usageAnalytics/individualUsageAnalytics';
import withRouter from '../lib/withRouter';
import InputTextB from '../customComponents/inputTextB';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect'
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetCalendar from '../../classetComponents/classetCalender';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';

import { getBranchesLatest, getRequestUpdatedBrnachData, saveBranchesLatest, saveNewOrupdateBranchesLatest } from '../../store/actions';
import { CallingIcon, MailIcon, PersonStandingIcon, PlainCalenderIcon } from '../svgIcons';



const sampleOptions = [
    { value: "Kavya", label: "Kavya" },
    { value: "Navven", label: "Navven" },
    { value: "cummins", label: "cummins" },
    { value: "Bhuvi", label: "Bhuvi" },
    { value: "Sharma", label: "Sharma" },
]

const createGroupFields = require('./createGroup.json');
const createBranchFields = require('./createBranch.json');




class BranchMaster extends Component {

    constructor(props) {

        super(props);
        this.formFields = getFormFields(createGroupFields);
        this.formFieldsBranch = getFormFields(createBranchFields);

        this.state = {
            isLoading: false,
            group: this.formFields.data,
            branch: this.formFieldsBranch.data,
            branchData: [],
            groupData: [],
            formValidations: this.formFields.formValidations,
            formValidationsBranch: this.formFieldsBranch.formValidations,
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCalenderDialogOpen: false,
            estabilishDateError: "",
            estabilishDate: "",
            updateDateType: "",
            selectedProducts: null,
            countriesOptions: countries,
            stateOptions: states,
            isShowAction: null,
            citiesOptions: cities,
            updatedToRow: {},
            isCreateUser: false,
            isBranchView: true,
            isOpenDialogBoxToCreateGroup: false,
            isOpenDialogBoxToCreateBranch: false,
            groupName: "",
            branchName: "",
            groupHead: "",
            contactForGroup: "",
            descForGroup: "",
            //filterData: {},
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
        }
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.service = new Service();
        this.op = React.createRef();
    }


    
    

    getGroupsData = async () => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        this.setState({ isLoading: true });

        let url = `${baseUrlAdmin}/group?academicYear=${AcademicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status && res?.res?.data?.length) {
                this.setState({ groupData: res?.res?.data, isLoading: false });
            } else {
                const error = 'Some error occurred';
                this.setState({ isLoading: false });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
        }

    };





    componentDidMount() {
        this.props.getBranchesLatest(true);
        this.getGroupsData();


        if (this.props.branchData?.length) {
            const branchTableData = this.props.branchData?.filter((each => {
                if (each.level == 1) {
                    return { ...each }
                }
            }));
            this.setState({ branchData: branchTableData })
        }


    }

    

    


    onPage = (event) => {
        this.setState({ lazyParams: event });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.isOpenDialogBoxToCreateBranch != this.state.isOpenDialogBoxToCreateBranch) || (prevState.isBranchView != this.state.isBranchView)) {
            this.props.getBranchesLatest(true);
            if (this.props.branchData?.length) {
                const branchTableData = this.props.branchData?.filter((each => {
                    if (each.level == 1) {
                        return { ...each }
                    }
                }));
                this.setState({ branchData: branchTableData })
            }

        }
    }



    handleEditBranch = (rowData, index) => {

        const formatData = {
            ...rowData,
            branchName: rowData.name,
            branchHead: rowData.head,
            contactNumber: rowData.contactNo,
            creationDate: new Date(rowData.creationDate),
            estabilishDate: new Date(rowData.estabilishDate),
            branchStatus: rowData.isActive,
        }

        this.setState({ isOpenDialogBoxToCreateBranch: true, branch: formatData, estabilishDate: new Date(rowData.estabilishDate) })

    }

    handleEditGroup = (rowData, index) => {
        let filterBranches = [];
        this.state.branchData?.filter(each => {
            if (rowData?.branches.includes(each?.key)) {
                return filterBranches.push(each.key)
            }
        })
        const formatData = {
            ...rowData,
            groupName: rowData.name,
            groupHead: rowData.head,
            branch: filterBranches,
            contactNumber: rowData.contactNo,
            description: rowData.description

        }

        this.setState({ isOpenDialogBoxToCreateGroup: true, group: formatData })

    }



   

    handleGetStates = (value) => {
        const filterdStates = states.filter(each => { if (each.countryId == value) { return each; } })
        this.setState({ stateOptions: filterdStates })

    }

    handleGetCities = (value) => {
        const filterdCities = cities.filter(each => { if (each.stateId == value) { return each; } })
        this.setState({ citiesOptions: filterdCities })

    }

    handleSwitchChange(e) {

        if (e.value) {
            this.props.getBranchesLatest();
        }
        else {
            this.getGroupsData();
        }
        this.setState({ isBranchView: e.value });
    }


    onCreateGroup = async () => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let body = cloneDeep(this.state.group)
        const formStatus = isFormValid(createGroupFields, this.formFields.formValidations, body);
        let isCompleteFormValid = true

        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, dateError: true });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            const obj = {
                'academicYear': AcademicYear,
                "name": body.groupName,
                "head": body.groupHead,
                "branches": body.branch,
                "contactNo": body.contactNumber,
                "description": body.description
            }

            const payload = this.state.group._id ? { ...obj, _id: this.state.group._id } : obj
            let url = `${baseUrlAdmin}/group/`;

            try {
                const res = await this.service.post(url, payload, true);
                if (res?.res?.status && res.status) {
                    this.setState({ isOpenDialogBoxToCreateGroup: false, isShowAction: null, isLoading: false })
                    this.getGroupsData();
                    this.toast.show({ severity: 'success', summary: `Group has been ${this.state.group?._id ? "updated" : "Created"} successfully`, detail: "", life: 3000 });
                } else {
                    this.setState({ isLoading: false })
                    const error = 'Some error occurred';
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                }
            } catch (e) {
                this.setState({ isLoading: false })
                console.log(e);
                this.toast.show({ severity: 'error', summary: "Error", detail: 'Some error occured', life: 3000 });
            }

        }
    }






    onCreateBranch = async () => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let body = cloneDeep(this.state.branch)
        body.estabilishDate = this.state.estabilishDate
        const formStatus = isFormValid(createBranchFields, this.formFieldsBranch.formValidations, body);
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidationsBranch: formStatus.formValidations, dateError: true });
            isCompleteFormValid = false;
        }
        if (this.state.estabilishDate == 0) {

            this.setState({ estabilishDateError: "Establishment Date Is Required" })
        }
        if (isCompleteFormValid) {
            const filterBranchDataForEdit = this.props.branchData && this.props.branchData?.filter(each => each.key != this.state.branch.key)

            const obj = {
                "key": this.state.branch.key ? this.state.branch.key : uuidv4(),
                "creationDate": this.state.branch.key ? body.creationDate : new Date(),
                "name": body.branchName,
                "isActive": body.branchStatus,
                "head": body.branchHead,
                "contactNo": body.contactNumber,
                'branchCode': body.branchCode,
                'emailId': body.emailId,
                'estabilishDate': body.estabilishDate,
                'boardAffiliation': body.boardAffiliation,
                'branchAddress': body.branchAddress,
                'country': body.country,
                'state': body.state,
                'city': body.city,
                'pincode': body.pincode,
                'url': body.url,
                "level": 1,
                "children": [],
                "academicYear": AcademicYear
            }

            const payload = {
                "_id": this.props.getBranchesId,
                "levels": this.props.branchLevels,
                "data": this.state.branch.key ? [...filterBranchDataForEdit, obj] : [...this.props.branchData, obj]

            }
            this.getSaveBranchesLatest(payload);
        }
    }


    getSaveBranchesLatest = (data) => {

        this.setState({ isLoading: true })
        const AcademicYear = localStorage.getItem('userAcademicYear')
        // const store = getState();
        let etag = this.props.etag;
        console.log(etag, "beforecreatebranch")
        this.props.getRequestUpdatedBrnachData()
        axios({
            method: 'put',
            url: `${baseUrlAdmin}/branch`,
            headers: {
                "If-Match": etag,
                'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
            },
            data: { ...data, academicYear: AcademicYear }
        }).then((res) => {
            // const etag = res.headers && res.headers.etag;
            if (res && res?.data && res.data?.data) {
                this.setState({ isOpenDialogBoxToCreateBranch: false, branch: {}, estabilishDate: "", isShowAction: null, isLoading: false });
                this.props.saveNewOrupdateBranchesLatest(res?.data?.data);
                this.props.getBranchesLatest();
                this.toast.show({ severity: 'success', summary: 'success', detail: `Branch has been ${this.state.branch?.key ? "updated" : "Created"} Successfully`, life: 3000 });
            }
            else {
                this.setState({ isShowAction: null, isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Error', detail: `Error in ${this.state.branch?.key ? "update" : "Create"} Request`, life: 3000 });
            }
        }).catch(e => {
            this.setState({ isShowAction: null, isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Error', detail: `Error in ${this.state.branch?.key ? "update" : "Create"} Request`, life: 3000 });
        });
    }

    getPagedData = () => {
        const { branchData, lazyParams } = this.state;
        const { first, rows } = lazyParams;
    
        return branchData.slice(first, first + rows);
    }


   
    render() {
        const { selectedUser, isShowUserInfo, isShowAnalytics, editUser, group, branch, formValidations, formValidationsBranch } = this.state
        return (
            <div className='mt-4'>
                {/* <div className="ask-a-doubt ma-main">
                            <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} />
                        </div> */}
                <div className='flex justify-content-between'>
                    <div className="grid flex justify-content-between ml-2 mt-2 md:col-6 lg:col-12 xl:col-12">
                        <div className='flex justify-content-center align-items-center'>
                            <p className='branchMasterViewText'>
                                Group View
                            </p>
                            <InputSwitch className='mx-3 custom-inputswitch' checked={this.state.isBranchView} onChange={this.handleSwitchChange} />

                            <p className='branchMasterViewText'>
                                Branch View
                            </p>
                        </div>
                        <div className='mr-3'>
                            <Authorizer permId={PERMISSIONS.CREATE_GROUP} >
                                {!this.state.isBranchView && <Button icon={'pi pi-plus-circle text-lg'} style={{ border: '1px solid #E6E7EA', padding: '9px 18px', backgroundColor: '#076eff', boxShadow: '0px 4px 4px 0px #00000040', borderRadius: "15px", color: "white" }} label="Create Group" className='p-button-outlined text-xl button' onClick={() => {
                                    this.setState({ isOpenDialogBoxToCreateGroup: true });
                                }} />}
                            </Authorizer>
                            {this.state.isBranchView &&
                                <>
                                    {/* <Button icon={'pi pi-plus-circle text-lg'} style={{ border: '1px solid #E6E7EA', padding: '9px 18px', backgroundColor: '#076eff', boxShadow: '0px 4px 4px 0px #00000040', borderRadius: "15px", color: "white" }} label="Create Group" className='p-button-outlined text-xl button mr-3' onClick={() => {
                                        this.setState({ isOpenDialogBoxToCreateGroup: true });
                                    }} /> */}
                                    <Authorizer permId={PERMISSIONS.CREATE_BRANCH} >
                                        <Button icon={'pi pi-plus-circle text-lg'} style={{ border: '1px solid #E6E7EA', marginLeft: "20px", backgroundColor: '#076eff', padding: '9px 18px', boxShadow: '0px 4px 4px 0px #00000040', borderRadius: "15px", color: "white" }} label="Create Branch" className='p-button-outlined text-xl button' onClick={() => {
                                            this.setState({ isOpenDialogBoxToCreateBranch: true });
                                        }} />
                                    </Authorizer>
                                </>
                            }

                        </div>
                    </div>

                </div>
                <div className="card datatable-crud-demo mt-5 mx-4">
                    {this.state.isBranchView ?
                        <DataTable ref={(el) => this.dt = el}
                            value={this.getPagedData()} 
                            dataKey="key"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            isLoading={this.props.isLoading}
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.branchData?.length}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.branchData?.length}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        >


                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="name" field="name" header="Branch Name" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="head" field="head" header="Branch Head" />
                            <Column style={{ width: "17rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="contactNo" field="contactNo" header="Contact Number" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="creationDate" body={(rowData, { rowIndex }) => {
                                return (
                                    <p>{rowData.creationDate ? new Date(rowData.creationDate).toLocaleDateString('en-GB') : "--"}</p>
                                )
                            }} ></Column>
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStaff" field="totalStaff" header="Total Staff" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="maxStrength" field="maxStrength" header="Total Students" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                                return (
                                    <>
                                        <Authorizer permId={PERMISSIONS.UPDATE_BRANCH} >
                                            <Button id={rowData?.key} onClick={(event) => this[rowData?.key].toggle(event)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary ma-ml10' />
                                            <Menu model={[
                                                {
                                                    label: 'InActive',
                                                    command: () => { }
                                                },
                                                {
                                                    label: 'Edit',
                                                    command: () => { this.handleEditBranch(rowData, rowIndex) }
                                                },
                                                {
                                                    label: 'Delete',
                                                    command: () => { }
                                                }
                                            ]} style={{ width: "85px" }} className="h-7rem card shadow-4 flex flex-column justify-content-center align-items-center" popup={true} ref={el => this[rowData?.key] = el} />
                                        </Authorizer>

                                    </>
                                )
                            }} ></Column>


                        </DataTable>
                        :
                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={this.state.groupData}
                            dataKey="id"
                            paginator
                            lazy
                            responsiveLayout="scroll"
                            className="dataTableValuesText"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            isLoading={!this.state.groupData?.length ? this.state.isLoading : false}
                            // onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.groupData?.length}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.groupData?.length}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        // header={header}
                        >

                            <Column style={{ width: "15rem", height: "6rem" }} filterField="name" field="name" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Group Name" alignHeader={"center"} />
                            <Column style={{ width: "15rem", height: "6rem" }} filterField="head" field="head" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Group Head" alignHeader={"center"} />
                            <Column style={{ width: "17rem", height: "6rem" }} filterField="contactNo" field="contactNo" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Contact Number" alignHeader={"center"} />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="creationDate" body={(rowData, { rowIndex }) => {
                                return (
                                    <p>{new Date(rowData.createdAt).toLocaleDateString('en-GB')}</p>
                                )
                            }} ></Column>
                            <Column style={{ width: "15rem", height: "6rem" }} filterField="totalStaff" field="totalStaff" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Total Staff" alignHeader={"center"} />
                            <Column style={{ width: "15rem", height: "6rem" }} filterField="totalStudents" field="totalStudents" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Total Students" alignHeader={"center"} />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                                return (
                                    <>
                                        <Authorizer permId={PERMISSIONS.UPDATE_BRANCH} >
                                            <Button id={rowData?._id} onClick={(event) => this[rowData?._id].toggle(event)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary' />

                                            <Menu model={[
                                                {
                                                    label: 'InActive',
                                                    command: () => { }
                                                },
                                                {
                                                    label: 'Edit',
                                                    command: () => { this.handleEditGroup(rowData, rowIndex) }
                                                },
                                                {
                                                    label: 'Delete',
                                                    command: () => { }
                                                }
                                            ]} style={{ width: "85px" }} className="h-7rem card shadow-4 flex flex-column justify-content-center align-items-center" popup={true} ref={el => this[rowData?._id] = el} />
                                        </Authorizer>
                                    </>
                                )
                            }} ></Column >


                        </DataTable>}
                </div>
                {/* {
                        isShowUserInfo && <UserInfo isShowUserInfo={isShowUserInfo} selectedUser={selectedUser} onHide={() => { this.setState({ isShowUserInfo: false }) }} />
                    } */}
                {
                    isShowAnalytics && <IndividualUsageAnalytics
                        userInfo={selectedUser}
                        onHide={() => { this.setState({ isShowAnalytics: false }) }} />
                }

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {/* </Navbar> */}
                <Dialog
                    blockScroll={true}
                    visible={this.state.isCalenderDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box w-4'
                    style={{ height: "250px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='ma-mt20'>
                            <h4 className="text-center text-black-alpha-90 font-semibold">Update {this.state.updateDateType} Date Of {this.state.updatedToRow?.grades} Grade</h4>

                        </div>)
                    }}
                    footer={() => {
                        return (<div className='ma-mt20'>
                            <Button label="Cancel" onClick={() => {
                                this.setState({
                                    isCalenderDialogOpen: false,
                                })

                            }} />
                            <Button label='Update' className='ma-m-lr10' />

                        </div>)
                    }}
                    onHide={() => {
                        this.setState({
                            isCalenderDialogOpen: false,
                        })
                    }}>
                    <div className='flex flex-column justify-content-center'>


                        <p className='mx-8'>{this.state.updateDateType}</p>
                        <Calendar
                            //  value={dates} 
                            //  onChange={(e) => setDates(e.value)} 
                            className='mx-8'
                            placeholder={`--Select ${this.state.updateDateType}--`}
                            selectionMode="range"
                            readOnlyInput hideOnRangeSelection showIcon />


                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isOpenDialogBoxToCreateGroup}
                    draggable={false}
                    closeOnEscape={false}
                    // position={"right"}
                    className='lg:w-11 lg:mx-2 sm:w-12 sm:m-0'
                    style={{
                        maxWidth: "1000px",
                        width: "75%",
                        maxHeight: "90%",
                        height: "auto"
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h3 className="text-center text-black-alpha-90 font-bold">{this.state.group?._id ? "Edit " : "Create "} Group</h3>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button label='Accept' className='confirmDialogAcceptBtn mr-4  px-6' onClick={() => this.onCreateGroup()} />
                            <Button label="Cancel" className='confirmDialogCancelBtn px-6' onClick={() => this.setState({ group: {}, isOpenDialogBoxToCreateGroup: false, isShowAction: null })} />

                        </div>
                    )}
                    onHide={() => this.setState({ group: {}, isOpenDialogBoxToCreateGroup: false, isShowAction: null })}

                >

                    <div className='flex justify-content-center align-items-center align-content-center mt-4'>
                        <div className='grid'>
                            <div className="col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="groupName" className="block font-serif text-lg font-bold text-800">Group Name<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={24} height={24} color={"black"} />}
                                            id="groupName"
                                            className='border-round-md   border-none '
                                            value={group.groupName}
                                            onChange={(e) => { onTextChange(e.target.value, 'groupName', this, createGroupFields, group, formValidations, 'group', 'formValidations') }}
                                            placeholder="Group Name" />
                                        <div className="">
                                            <Tooltip target=".custom-target-title ">
                                                <div className='flex justify-content-center align-items-center'>
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className='font-semibold text-sm'>Title of the Group
                                                        </p>
                                                        <p className="-mt-1">Group name reflecting the branches will improve the understanding
                                                        </p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i className="custom-target-title  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                            </i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['groupName'].isValid && <p className="p-error text-lg ">{formValidations.fields['groupName'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="groupHead" className="block font-serif text-lg font-bold text-800">Group Head <span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<PersonStandingIcon width={32} height={32} color={""} />}
                                            id="title"
                                            className='border-round-md   border-none '
                                            value={group.groupHead}
                                            onChange={(e) => { onTextChange(e.target.value, 'groupHead', this, createGroupFields, group, formValidations, 'group', 'formValidations') }}
                                            placeholder="Group Head Name" />
                                        <div className="">
                                            <Tooltip target=".custom-target-head ">
                                                <div className='flex justify-content-center align-items-center'>
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className='font-semibold text-sm'>Name of the Group Head


                                                        </p>
                                                        <p className="-mt-1">Group Head reflecting the branches will improve the understanding</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i className="custom-target-head  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                            </i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['groupHead'].isValid && <p className="p-error text-lg ">{formValidations.fields['groupHead'].errorMsg}</p>}
                                </div>
                            </div>


                            <div className="col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Select Branches<span className='ma-required'>*</span></p>

                                    <div className='flex'>
                                        <ClassetMultiSelect
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            optionLabel="name"
                                            optionValue="key"
                                            height={"48px"}
                                            options={this.state.branchData}
                                            className='no-border'
                                            value={group.branch}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'branch', this, createGroupFields, group, formValidations, 'group', 'formValidations') }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=''>Select Branches</span>
                                                </div>
                                            )}
                                        />
                                        <div className="">
                                            <Tooltip target=".custom-target-branch ">
                                                <div className='flex justify-content-center align-items-center'>
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className='font-semibold text-sm'>Selected Branches
                                                        </p>
                                                        <p className="-mt-1">This is not editable and to add more, please go back to previous screen</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i className="custom-target-branch  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                            </i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['branch'].isValid && <p className="p-error text-lg">{formValidations.fields['branch'].errorMsg}</p>}

                                </div>

                            </div>
                            <div className="col-12 md:col-12 lg:col-6 xl:col-6 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="contactNumber" className="block font-serif text-lg font-bold text-800">Contact Number<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<CallingIcon width={32} height={32} color={""} />}
                                            id="contactNumber"
                                            className=' border-round-md  border-none '
                                            value={group.contactNumber}
                                            onChange={(e) => { onNumberChange(e.target.value, 'contactNumber', this, createGroupFields, group, formValidations, 'group', 'formValidations') }}
                                            placeholder="Group Head Mobile No" />
                                        <div className="">
                                            <Tooltip target=".custom-target-contact ">
                                                <div className='flex justify-content-center align-items-center'>
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className='font-semibold text-sm'>Contact Number
                                                        </p>
                                                        <p className="-mt-1">Provide Group Head Contact Number</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i className="custom-target-contact  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                            </i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['contactNumber'].isValid && <p className="p-error text-lg ">{formValidations.fields['contactNumber'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="px-6 col-12 md:col-12 lg:col-12 xl:col-12 flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="description" className="block font-serif font-semibold text-900 text-lg">Description</label>
                                    <div className="flex">
                                        <InputTextarea
                                            className='border-300 shadow-2 border-radius-md custom-desc-focused pt-2'
                                            style={{ width: "850px", height: "119px" }}
                                            id="description"
                                            value={group.description}
                                            onChange={(e) => { onTextChange(e.target.value, 'description', this, createGroupFields, group, formValidations, 'group', 'formValidations') }}
                                            placeholder="Write A Description" rows={3} />
                                        <div className="">
                                            <Tooltip target=".custom-target-desc ">
                                                <div className='flex justify-content-center align-items-center'>
                                                    <i className="pi pi-info-circle  mr-3"></i>
                                                    <div>
                                                        <p className='font-semibold text-sm'>Description
                                                        </p>
                                                        <p className="-mt-1">Describe about the Group and branches allocated.</p>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            <i className="custom-target-desc  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                                                data-pr-position="right"
                                                data-pr-at="right+5 center"
                                                data-pr-my="left center-2"
                                                style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                            </i>
                                        </div>
                                    </div>
                                    {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>}
                                </div>
                            </div>


                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.isOpenDialogBoxToCreateBranch}
                    className='ma-scroll-vr-smoke'
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    style={{
                        maxWidth: "1300px",
                        width: "100%",
                        maxHeight: "90%",
                        height: "auto"
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h3 className="text-center text-black-alpha-90 font-bold">{this.state.branch?.key ? "Edit " : "Create "} Branch</h3>
                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button label={this.state.branch?.key ? "Edit Branch" : "Create Branch"} className='confirmDialogAcceptBtn mr-4  px-6' onClick={() => this.onCreateBranch()} />
                            <Button label="Cancel" className='confirmDialogCancelBtn px-6' onClick={() => this.setState({ branch: {}, isOpenDialogBoxToCreateBranch: false, estabilishDate: "" })} />
                        </div>
                    )}
                    onHide={() => this.setState({ branch: {}, isOpenDialogBoxToCreateBranch: false, estabilishDate: "" })}
                >

                    <div className='flex flex-column justify-content-center align-items-center align-content-center mt-4'>
                        <div className='grid'>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center ">
                                <div>
                                    <label htmlFor="branchName" className="block font-serif text-lg font-bold text-800">Branch Name<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={24} height={24} color={"black"} />}
                                            id="branchName"
                                            className='border-round-md  border-none '
                                            value={branch.branchName}
                                            onChange={(e) => { onTextChange(e.target.value, 'branchName', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Branch Name" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['branchName'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['branchName'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center ">
                                <div>
                                    <label htmlFor="branchCode" className="block font-serif text-lg font-bold text-800">Branch Code<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={24} height={24} color={""} />}
                                            id="branchCode"
                                            className=' border-round-md   border-none '
                                            value={branch.branchCode}
                                            onChange={(e) => { onTextChange(e.target.value, 'branchCode', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Branch Code" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['branchCode'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['branchCode'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <p className='block font-serif text-lg font-bold text-800'>Branch Head<span className='ma-required'>*</span></p>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={sampleOptions}
                                            optionLabel="label"
                                            className=' no-border l'
                                            value={branch.branchHead}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'branchHead', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=' '>Branch Head</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['branchHead'].isValid && <p className="p-error text-lg">{formValidationsBranch.fields['branchHead'].errorMsg}</p>}

                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="contactNumber" className="block font-serif text-lg font-bold text-800">Branch contact no.<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={32} height={32} color={""} />}
                                            id="contactNumber"
                                            className=' border-round-md   border-none '
                                            value={branch.contactNumber}
                                            onChange={(e) => { onNumberChange(e.target.value, 'contactNumber', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Contact No" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['contactNumber'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['contactNumber'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="emailId" className="block font-serif text-lg font-bold text-800">Email id<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={32} height={32} color={""} />}
                                            id="emailId"
                                            className=' border-round-md   border-none '
                                            value={branch.emailId}
                                            onChange={(e) => { onEmailChange(e.target.value, 'emailId', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Email id" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['emailId'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['emailId'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <p className=' font-serif text-lg font-bold text-800'>Establishment Date<span className='ma-required'>*</span></p>
                                    <div className='flex'>
                                        <ClassetCalendar
                                            icon={<PlainCalenderIcon width={24} height={24} color={"grey"} />}
                                            className=' border-round-md  border-none'
                                            calendarMode={"single"}
                                            value={this.state.estabilishDate}
                                            onChange={(e) => this.setState({ estabilishDate: e.value })}
                                            // onChange={(e) => { onTextChange(e.target.value, 'estabilishDate', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Establishment Date" />
                                    </div>
                                    {/* {formValidationsBranch && !formValidationsBranch.fields['estabilishDate'].isValid && <p className="p-error text-lg">{formValidationsBranch.fields['estabilishDate'].errorMsg}</p>} */}
                                    {this.state.estabilishDate == "" && <p className='p-error'>{this.state.estabilishDateError}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="boardAffiliation" className="block font-serif text-lg font-bold text-800">Board/Affiliation</label>
                                    <div className='flex'>
                                        <ClassetMultiSelect
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            optionLabel="boardName"
                                            optionValue="boardId"
                                            options={this.props.boardsDropDownOptions}
                                            className=' no-border '
                                            // className=' w-full md:w-14rem border-round-md pt-2 border-none groupFormInputText pl-3'
                                            value={branch.boardAffiliation}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'boardAffiliation', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=' '>Board/Affiliation</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['boardAffiliation'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['boardAffiliation'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-start align-items-start align-content-start lg:ml-5 lg:mt-5">
                                <div>
                                    <div className='flex'>
                                        <InputSwitch
                                            checked={branch.branchStatus}
                                            onChange={(e) => { onToggleChange(e.target.value, 'branchStatus', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                        />
                                        <label className='ml-3 font-bold'>Branch Status</label>
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['branchStatus'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['branchStatus'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='grid mt-1'>
                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="branchAddress" className="block font-serif text-lg font-bold text-800">Branch Address<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            // icon={<MailIcon width={32} height={32} color={""} />}
                                            style={{ boxSizing: 'border-box' }}
                                            id="branchAddress"
                                            height={70}
                                            className=' border-round-md   border-none pb-6'
                                            value={branch.branchAddress}
                                            onChange={(e) => { onTextChange(e.target.value, 'branchAddress', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Input Value" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['branchAddress'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['branchAddress'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="country" className="block font-serif text-lg font-bold text-800">Country<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={this.state.countriesOptions}
                                            optionLabel="name"
                                            optionValue="id"
                                            className=' no-border'
                                            // className=' w-full md:w-14rem border-round-md pt-2 border-none groupFormInputText pl-3'
                                            value={branch.country}
                                            onChange={(e) => {
                                                onDropDownChange(e.target.value, 'country', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch'); this.handleGetStates(e.target.value);
                                            }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=' '>Select Country</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['country'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['country'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="state" className="block font-serif text-lg font-bold text-800">State<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={this.state.stateOptions}
                                            optionLabel="name"
                                            optionValue="id"
                                            className=' no-border '
                                            // className=' w-full md:w-14rem border-round-md pt-2 border-none groupFormInputText pl-3'
                                            value={branch.state}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'state', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch'); this.handleGetCities(e.target.value); }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=''>Select State</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['state'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['state'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="city" className="block font-serif text-lg font-bold text-800">City<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={this.state.citiesOptions}
                                            optionLabel="name"
                                            optionValue="id"
                                            className=' no-border '
                                            // className=' w-full md:w-14rem border-round-md pt-2 border-none groupFormInputText pl-3'
                                            value={branch.city}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'city', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=''>Select City</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['city'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['city'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="pincode" className="block font-serif text-lg font-bold text-800">Pincode<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={32} height={32} color={""} />}
                                            id="pincode"
                                            className=' border-round-md   border-none '
                                            value={branch.pincode}
                                            onChange={(e) => { onTextChange(e.target.value, 'pincode', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Pincode" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['pincode'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['pincode'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="col-12 md:col-5 lg:col-5 xl:col-4 flex-wrap flex flex-column justify-content-center align-items-center align-content-center">
                                <div>
                                    <label htmlFor="url" className="block font-serif text-lg font-bold text-800">Google Location Tag<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<MailIcon width={32} height={32} color={""} />}
                                            id="url"
                                            className=' border-round-md border-none '
                                            value={branch.url}
                                            onChange={(e) => { onTextChange(e.target.value, 'url', this, createBranchFields, branch, formValidationsBranch, 'branch', 'formValidationsBranch') }}
                                            placeholder="Location URL" />
                                    </div>
                                    {formValidationsBranch && !formValidationsBranch.fields['url'].isValid && <p className="p-error text-lg ">{formValidationsBranch.fields['url'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>

                    </div>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    etag: state.branchDataLatest && state.branchDataLatest.etag,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    boardsDropDownOptions: state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions || [],
    getBranchesId: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
    status: state.branchDataLatest && state.branchDataLatest.status,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data || [],
});

export default connect(mapStateToProps, { getBranchesLatest, getRequestUpdatedBrnachData, saveNewOrupdateBranchesLatest, saveBranchesLatest })(Authentication(withRouter(BranchMaster)));