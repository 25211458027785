import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { MailIcon, TickBgIcon, ArrowClockWise, InputIcon, CodeLinkIcon, AddIcon2 } from '../../svgIcons';
import { BasicLazyParams, trimObj } from '../../../utile';
import { MultiSelect } from 'primereact/multiselect';
import './styles.scss';
import ClassetTeacherSearch from '../../../classetComponents/classetTeacherSearch';
import { RadioButton } from 'primereact/radiobutton';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import Service from '../../../services';
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import ClassetInputText from "../../../classetComponents/classetInputText";
import ClassetMultiSelect from "../../../classetComponents/classetMultiSelect";
import { getFormFields, onTextChange, onDropDownChange, onToggleChange, isFormValid, isFieldValid } from "../../../utile/formHelper";
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { getUserData } from '../../../store/actions';
import withRouter from '../../lib/withRouter';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { debounce, template, uniqueId } from 'lodash';
import FileUpload from '../../discussionBoard/common/fileUpload';
import { StaticConstants } from '../../../constants';
import _ from "lodash";

const createFields = require('./teacherDairy.json');
const searchTypeOptions = [
    { label: "Teacher Name", value: "teacherName" },
    { label: "Teacher Number", value: "teacherNumber" }
]
let formFields = createFields;
export class TeacherDairy extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(createFields);
        this.state = {
            dairy: this.formFields.data,
            formValidations: this.formFields.formValidations,
            lazyParams: BasicLazyParams,
            openCreateDairyDialog: false,
            searchType: searchTypeOptions[0].value,
            searchInput: "",
            isLoading: false,
            searchLevel: 1,
            searchValue: "",
            dropdownsData: {},
            columns: [],
            data: [],
            newRowData: [],
            selectedRow: null,
            editDialog: false,
            selectedItem: null,
            orgId: this.props?.userInfo?._id,
            globalSearch: '',
            uniqueCell: null,
            selectedRowIndex: null,
            teacherId: null,
            templateId: null,
            uploadedFileName: null,
            uploadedFilesInfo: []
        }
        this.debouncedApiCall = debounce(this.getDairy, 700)
        this.service = new Service();


    }

    componentDidMount() {

    }

    openCreateDairyDialog = () => {
        this.setState({
            openCreateDairyDialog: true,
            editDialog: false,
            dairy: {}
        })
    }

    closeCreateDairyDialog = () => {
        this.setState({
            openCreateDairyDialog: false,
            editDialog: false,
            dairy: {}
        })
    }

    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {

            this.debouncedApiCall(this.state.searchValue);

        })
    }



    handleSearchLevel = (value) => {
        console.log("NAVAYA")
        this.setState({ searchLevel: value }, () => {
            // this.filterUsersData()
        })
    }


    getDairy = async () => {
        this.setState({ isLoading: true });

        const academicYear = localStorage.getItem("userAcademicYear");
        let lazyParams = this.state.lazyParams;
        let globalSearch = this.state.globalSearch;
        let url = `${baseUrlAdmin}/teacher-dairy?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}&academicYear=${academicYear}&name=${this.state?.searchValue}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                console.log(res.res.data, "res.res.data")


                const records = res.res.data.records || [];
                const dairyrecords = res.res.data.dairyrecords || [];

                if (records.length > 0 || dairyrecords.length > 0) {


                    const firstRecord = records[0];
                    const tctemplateFields = firstRecord?.tctemplate || [];
                    const columns = tctemplateFields.map((templateField, cellIndex) => ({
                        field: templateField.title,
                        header: templateField.title.charAt(0).toUpperCase() + templateField.title.slice(1),
                        cellIndex: cellIndex,
                        templateId: firstRecord?._id,
                        teacherId: res?.res?.data?.teacherId,
                        isEmpty: true,


                    }));


                    // const processedDairyObjs = dairyrecords
                    //     .filter(record => Array.isArray(record.dairyObj) && record.dairyObj.length > 0)
                    //     .map(record =>
                    //         record.dairyObj.map((item, index) => ({
                    //             ...item,
                    //             cellIndex: index,
                    //             slNo: index + 1
                    //         }))
                    //     );

                    const processedData = dairyrecords.map((record, recordIndex) => {
                        if (Array.isArray(record.dairyObj) && record.dairyObj.length > 0) {
                            const processedDairyObj = record.dairyObj.map((item, itemIndex) => ({
                                ...item,
                                cellIndex: itemIndex,
                                row: record?.row,
                                _id: record?._id,

                            }));

                            return {
                                ...record,
                                dairyObj: processedDairyObj,
                            };
                        }

                        // Return record as is if dairyObj is not an array or is empty
                        return record;
                    });

                    //console.log(processedData, "processedData")
                    //console.log(processedDairyObjs, "processedDairyObjs")

                    this.setState({
                        columns,
                        teacherId: res?.res?.data?.teacherId,
                        templateId: firstRecord?._id,
                        data: processedData,
                        totalRecords: res?.res?.data?.dairytotalRecords,
                        isLoading: false
                    }, () => {

                    });

                    this.toast?.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                }
            } else {
                //console.log(res, "res.res");
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Getting the Template', life: 3000 });
        }
    };

    onPage = (event) => {

        console.log("CALLPAGE")
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getDairy);


    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getDairy);
    }




    onAddRow = () => {
        let newRow = {};

        // Iterate over columns and initialize newRow with empty values for each field
        this.state?.columns?.forEach(col => {
            newRow[col.field] = '';
        });

        this.setState((prevState) => {
            // Ensure prevState.data is an array
            const currentData = Array.isArray(prevState.data) ? prevState.data : [];

            console.log('Previous State Data:', currentData);

            return {
                data: [
                    ...currentData, this.state?.columns
                ],
            };
        }, () => {
            console.log(this.state.data, "daaaaaaaaaa", this.state?.columns)
            this.onAddClick()
        });
    };


    onAddClick = () => {
        let { orgId, selectedItem, uniqueCell, selectedRowIndex, data, teacherId, templateId } = this.state


        const academicYear = localStorage.getItem("userAcademicYear");
        const lastItem = data?.[data.length - 1] || {};
        const rowIndex = data?.length - 1;
        const eachRow = lastItem;



        let payload = {

            dairyObj: eachRow,
            academicYear,
            orgId,
            uniqueCell,
            row: rowIndex,
            templateId: templateId,
            teacherId: teacherId,



        }
        console.log("datadatadatadata", payload)

        if (data && data.length > 0) {
            this.setState({
                isLoading: true
            })
            let url = `${baseUrlAdmin}/teacher-dairy/save`

            this.service.post(url, payload, true).then(res => {

                if (res?.status && res?.res?.status) {

                    this.toast.show({ severity: 'success', summary: 'Dairy', detail: res?.res?.message, life: 3000 });
                    this.setState({
                        isLoading: false,
                        openCreateDairyDialog: false,
                        editDialog: false,
                        //dairy: {}

                    }, () => { this.getDairy() })
                } else {
                    this.setState({
                        isLoading: false,
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
                }
            }).catch(err => {
                console.log(err, 'err')
                this.setState({
                    isLoading: false,
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
            })
        }




    }



    onRemoveRow = (rowIndex) => {
        this.setState(prevState => {
            const newRowData = [...prevState.newRowData];
            newRowData.splice(rowIndex, 1); // Remove the row at the given index
            return { newRowData };
        });
    };

    generateCellId = (rowIndex, columnField) => {
        return `${rowIndex}-${columnField}`;
    };
    renderAttachmentContainer = (rowData, col, rowIndex, index, options) => {

        const cellId = this.generateCellId(rowIndex, col.field);
        const selectedItem = {
            ...col,
            _id: rowData?._id,
            row: rowData?.row
        };


        const matchingItem = rowData?.dairyObj?.find(item => item.cellIndex === selectedItem?.cellIndex);
        let fileUrl = `https://classet-profiles-old.s3.ap-south-1.amazonaws.com/${matchingItem?.resorceKey}`;

        const handleCellClick = () => {
            if (matchingItem?.isEmpty !== undefined && !matchingItem?.isEmpty) {
                this.onEditClick(matchingItem);
                this.setState({ selectedItem: matchingItem });
                this.setState({ uniqueCell: cellId });

            } else {
                this.openCreateDairyDialog();
                this.setState({ selectedItem: matchingItem, });
                this.setState({ uniqueCell: cellId });
            }
        };


        const handleDownloadClick = () => {
            if (matchingItem?.resorceKey) {
               window.open(fileUrl, '_blank');
            } else {
                console.log("No storage location found for this item.");
            }
        };
        

        let displayText = rowIndex === rowData?.row ? matchingItem?.descriptionValue : 'New Entry';
        let attachmentFile = rowIndex === rowData?.row ? matchingItem?.fileName : 'document.pdf';
        const iconStyle = {
            opacity: attachmentFile ? 1 : 0,
        };


        if (rowIndex === rowData?.row && !matchingItem.isEmpty) {
            return (


                <div
                    key={col.uniqueCell}
                    data-cell-id={col.uniqueCell}
                    className="attachment-container"
                    onClick={handleCellClick}
                >
                    <div className="text-on-top">{displayText}</div>
                    <div className="attachment-div" onClick={handleDownloadClick}>
                    <span className="attachment-icon" style={iconStyle}>📎</span>
                        <span className="attachment-file">{attachmentFile}</span>
                    </div>

                </div>
            );
        } else {
          
            return (


                <div
                    key={col.cellIndex}
                    data-cell-id={col.cellIndex}
                    className="attachment-container"
                    onClick={handleCellClick}
                >
                    <div className="text-on-top" style={{ opacity: 0 }} >{"New Entry"}</div>
                    <div className="attachment-div">
                        <span className="attachment-icon" style={{ opacity: 0 }}></span>
                        <span className="attachment-file" style={{ opacity: 0 }}>{"document.pdf"}</span>
                    </div>

                </div>
            );
        }


    }

    onRowClick = (rowData) => {
        this.setState({ selectedRow: rowData });
    }

    onEditClick = (rowData) => {
        this.setState({ openCreateDairyDialog: true, editDialog: true, dairy: rowData, selectedItem: rowData })

    }

    onSubmitClick = () => {
        let { selectedItem, } = this.state
        let payload = {
            ...selectedItem,
            isEmpty: false,
            descriptionValue: this.state.dairy.descriptionValue,
            resorceTitle: this.state.dairy.resorceTitle,
            resorceKey: this.state.dairy.resorceKey,
            fileName:this.state.uploadedFileName
        };

        const formStatus = isFormValid(createFields, this.formFields.formValidations, trimObj(this.state?.dairy));

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            this.setState({
                isLoading: true
            })
            let url = `${baseUrlAdmin}/teacher-dairy/update/${selectedItem?._id}`
            this.service.put(url, payload, true).then(res => {

                if (res?.status && res?.res?.status) {
                    this.toast.show({ severity: 'success', summary: 'Dairy', detail: res?.res?.message, life: 3000 })
                    this.setState({
                        isLoading: false,
                        openCreateDairyDialog: false,
                        editDialog: false,
                        dairy: {},
                        uploadedFileName:null

                    }, () => { this.getDairy() })
                } else {
                    this.setState({
                        isLoading: false,
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
                }
            }).catch(err => {
                console.log(err, 'err')
                this.setState({
                    isLoading: false,
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', life: 3000 });
            })
        }
    }
    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "teacherName") {
                this.setState({ isUserNameSearch: true, })
            } else {
                this.setState({ isUserEnrollSearch: true, })
            }

        })
    }



    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {

            this.debouncedApiCall(this.state.searchValue);

        })
    }


    handleSearchLevel = (value) => {

        this.setState({ searchLevel: value }, () => {
            // this.filterUsersData()

        })
    }
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }



    addAttachmentRR = (fieldName, fileName, data) => {
        debugger
        const field = this.formFields.data[fieldName]; // Ensure this exists
        if (!field) {
            console.error(`Field ${fieldName} is not found in formFields.`);
            return;
        }

        let dairy = {
            ...this.state.dairy,
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,
            }
        };

        let formValidations = isFieldValid(field, this.state.formValidations, dairy);

        this.setState((prevState) => ({
            dairy,
            formValidations,
            uploadedFileName: fileName,
        }));
    };


    // addAttachment1 = (fieldName, fileName, data) => {
    //     debugger
    //     let dairy = {
    //         ...this.state.dairy,
    //         [fieldName]: {
    //             fileName,
    //             storageBucket: data.Bucket,
    //             storageKey: data.Key,
    //             storageLocation: data.Location,
    //         }
    //     }
    //     let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, dairy);
    //     this.setState((prevState) => {
    //         return { dairy, uploadedFileName:fieldName,formValidations }
    //     });
    // }

    // Method to handle attachment
    addAttachment = (fieldName, fileName, data) => {
        debugger
        let dairy = {
            ...this.state.dairy,
            [fieldName]: data.Key,

        }
        //let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, dairy);
        this.setState((prevState) => {
            return { dairy, uploadedFileName: fileName }
        });
    }
    _onUploadPdf = (fileName, data) => {

        this.addAttachment('resorceKey', fileName, data);


    }


    _afterFilesUploadedArr = (filess) => {
        let files = _.cloneDeep(Array.from(filess));
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                openAddFiles: false,
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]],
                showFileUploadDialog: true,
            }));
        }
    };

    _onSuccessArr = (fileName, data) => {
        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == fileName) {
                file.s3Info = data;
                file.uri = data.Location;
            }
            return file;
        });
        let attachment = files[0];
        console.log(attachment, "attachment")
        this.setState(
            (prevState) => {
                return {
                    uploadedFilesInfo: [],
                    showFileUploadDialog: false,
                };
            },
            () => {
                console.log(this.state.uploadedFilesInfo, "uploadedFilesInfo")
            }
        );
    };



    _onProgress = (filesInfo) => {

    }

    _afterFilesUploaded = (files) => {

    }
    render() {
        const { columns, data, dairy, formValidations, selectedItem, newRowData } = this.state
        console.log(selectedItem, "columns",dairy)


        return (

            <div className="m-2">
                <div className='grid '>



                    <div className="datatable-crud-demo ma-m30 mx-2 mb-5">
                        <div className="flex mt-4">
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                            </div>

                            <div className="field ml-4">
                                <ClassetTeacherSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                            </div>
                        </div>

                        {/* <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                                <span className="">OR</span>
                            </div>
                            <div className="flex">
                                <div className="flex flex-column justify-content-center align-items-center mt-3">
                                    <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                                </div>

                                <div>
                                    <BoardToSectionDD setValues={this.setDropdownValues} />

                                </div>


                            </div> */}

                    </div>

                </div>
                <div className="text-right mb-2">

                    {/* <Authorizer permId={PERMISSIONS.USER_ADDNEWUSER} > */}
                    <Button
                        icon={'pi pi-plus-circle text-lg px-1 mt-1'}
                        className='addButtonTeacher'
                        onClick={this.onAddRow}>
                        Add Dairy
                    </Button>
                    {/* </Authorizer> */}
                </div>

                <div className="card datatable-crud-demo ma-m30">

                    <DataTable ref={(el) => this.dt = el}
                        value={data.length > 0 ? data : []}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        // loading={this.state.isLoading}
                        className="dataTableValuesText"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}

                    >

                        {columns && columns?.length > 0 && columns.map((col, index) => (
                            <Column key={col.uniqueCell} style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField={col.field} field={col.field} header={col.header} body={(rowData, options) => this.renderAttachmentContainer(rowData, col, options.rowIndex, index, options)} // Pass rowIndex here
                            />
                        ))}

                    </DataTable>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>


                <Dialog
                    visible={this.state.openCreateDairyDialog}
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    className='ma-alert-box w-3 mr-0'
                    style={{ width: "300px", height: "600px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2 mb-4'>
                            <h3 className="formhead text-center ">{selectedItem?.isEmpty ? 'Add Diary ' : 'Edit Diary '}</h3>

                        </div>)
                    }}
                    onHide={() => this.setState({ openCreateDairyDialog: false, editDialog: false, dairy: {} })}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div className="" >
                            <div className="col-12">
                                <label className='label mb-2 '>Column Name<span className='ma-required'>*</span></label>


                                <div className="mt-2">
                                    <ClassetInputText
                                        id='field'
                                        className='border-round-md add-diary-field-input h-3rem  w-full'
                                        icon={<CodeLinkIcon height={22} className=" p-inputgroup-addon pl-3" />}
                                        // disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        height="48px"
                                        width="368px"
                                        placeholder="Column Name"
                                        disabled={true}
                                        value={selectedItem?.field}
                                    //onChange={(e) => onTextChange(e.target.value, 'colName', this, createFields, dairy, formValidations, 'dairy', formValidations)}

                                    />
                                </div>
                                {/* {formValidations && !formValidations.fields['colName'].isValid && <p className="p-error">{formValidations.fields['colName'].errorMsg}</p>} */}
                            </div>

                            <div className="col-12 ">
                                <label className='label'>Enter Value</label>
                                <div className='mt-2'>
                                    <ClassetTextArea
                                        id="descriptionValue"
                                        className='border-round-md  border-none '
                                        height={100}
                                        value={dairy?.descriptionValue}
                                        onChange={(e) => { onTextChange(e.target.value, 'descriptionValue', this, createFields, dairy, formValidations, 'dairy', 'formValidations') }}
                                        placeholder="Enter Value" />

                                </div>
                                {formValidations && !formValidations.fields['descriptionValue'].isValid && <p className="p-error text-lg ">{formValidations.fields['descriptionValue'].errorMsg}</p>}

                            </div>

                            <div className="col-12 ">
                                <label className='label'>Resource Title</label>
                                <ClassetInputText
                                    id='feeType'
                                    className='border-round-md add-diary-field-input h-3rem  w-full'
                                    icon={<CodeLinkIcon height={22} className=" p-inputgroup-addon pl-3" />}
                                    height="48px"
                                    width="368px"
                                    placeholder="Resource Title"
                                    value={dairy?.resorceTitle}
                                    onChange={(e) => onTextChange(e.target.value, 'resorceTitle', this, createFields, dairy, formValidations, 'dairy', formValidations)}

                                />
                                {formValidations && !formValidations.fields['resorceTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['resorceTitle'].errorMsg}</p>}

                            </div>

                            <div className="col-12 ">

                                <FileUpload
                                    id={"resorceKey"}
                                    //multiple={false}
                                    onProgress={this._onProgress}
                                    onSucess={this._onUploadPdf}
                                    // Reset={this.state.Reset}
                                    //maxFileSize={307200}
                                    //onError={this._onFilsUploadError}
                                    maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                    onError={this._onFilsUploadError}
                                    accept={'pdfs'}
                                    title={'Pdfs and Docs'}
                                    afterFilesUploaded={this._afterFilesUploaded} >
                                    <span className="button-style">Uplaod</span>

                                </FileUpload>

                                {(this.state?.dairy?.resorceKey) && (
                                    <div className="uploaded-file-name">
                                        Uploaded File: {this.state?.uploadedFileName || dairy?.fileName}
                                    </div>
                                )}
                                
                            </div>

                            <div className='flex justify-content-center mb-4 mt-4'>


                                <Button className='submitButtonDairy w-7 mr-2' onClick={this.onSubmitClick} >
                                    {selectedItem?.isEmpty && <AddIcon2 />}
                                    <p className='submitButtonTextDairy'>{selectedItem?.isEmpty ? 'Add ' : 'Edit'}</p>
                                </Button>
                                <Button className='cancelButtonnDairy w-4'
                                    onClick={this.closeCreateDairyDialog} >
                                    <p className='cancelButtonnTextDairy'>Cancel</p>
                                </Button>
                            </div>


                        </div >


                    </div>
                </Dialog>


                {this.state.isLoading && <LoadingComponent />}

            </div>




        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
    orgName: state.orgData.name,
    orgCode: state.orgData.orgCode,
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(TeacherDairy)));



//getUserData
