import React, { useState } from 'react';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

const TicketmangementTable = () => {

    const [state, setState] = useState({
        showSearch: false,
        globalSearch: ''
    })
    const { showSearch, globalSearch } = state
    const header = (
        <div className="table-header ma-tbl-header">
            <div className="ma-tbl-left">
                <h2 >Ticket Management</h2>
            </div>
            <div className="p-input-icon-left ma-tbl-filter">
                <ul className="ma-tbl-li">
                    <li>
                    <Button label={`New Ticket`} className='rol-add-btn' />
                    </li>
                </ul>
            </div>
        </div>
    );

    return (<div className="p-grid white-bg tab-active-body">
        <div className="card datatable-crud-demo ma-m30 fetch-q-tabled">
            <DataTable 
                scrollable
                responsiveLayout="scroll"
                value={[]}
                dataKey="id"
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                header={header}
            >
                <Column headerClassName='difficulty' field="name" header="S.No." />
                <Column headerClassName='difficulty' field="roles" header="Ticket No" />
                <Column headerClassName='difficulty' field="userType" header="URL" />
                <Column headerClassName='difficulty' style={{ flex: '0 0 4rem' }} field="Decription" header="Decription" />
                <Column headerClassName='difficulty' field="userType" header="Raised on" />
                <Column headerClassName='difficulty' field="userType" header="Expected" />
                <Column headerClassName='difficulty' field="userType" header="Level of the issue" />
                <Column headerClassName='difficulty' field="userType" header="Status" />
                <Column headerClassName='difficulty' field="userType" header="Chat" />
                <Column field="action" header="Actions" />
            </DataTable>

        </div>

    </div>)
}

export default TicketmangementTable