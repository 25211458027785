import React, { Component } from 'react'
import InputTextB from '../../customComponents/inputTextB'
import { InputText } from 'primereact/inputtext'
import { FIELDS_INFO } from '../../../constants'
import _, { cloneDeep, constant } from 'lodash';
import Service from './../../services';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { connect } from 'react-redux';
import { getPermissionList, getSelectedModules } from '../../../store/actions/getPermissionsAction';
import { addRole, updateAddRolesRequest } from '../../../store/actions/rolesAction';
import { selectPermissions } from '../../../store/selectors/authorize';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { trimObj } from '../../../utile';
import { AcademicSessionCalIcon, AssignFormIcon, MailIcon } from '../../svgIcons';
import ClassetMultiSelect from "../../../classetComponents/classetMultiSelect";
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { Toast } from 'primereact/toast';
import { CookieStorage } from 'amazon-cognito-identity-js';
import { CodeStarNotifications } from 'aws-sdk';
import LoadingComponent from '../../loadingComponent';
import ClassetDropdown from '../../../classetComponents/classetDropDown';

const createRoleFields = require('./createRole.json');
const TemplateTypes = [{ label: 'Admin', value: 'admin' }, { label: 'Teacher', value: 'teacher' },{ label: 'Counsellor', value: 'counsellor' }]

let formFields = createRoleFields;
const parsePermissionsData = (permissions) => {

    let selectedPermissions = {};

    Object.keys(permissions).forEach((permissionGroupId) => {
        selectedPermissions[permissionGroupId] = [];
        permissions[permissionGroupId].permissions.forEach(selectedPermissionObj => {
            selectedPermissions[permissionGroupId].push(selectedPermissionObj.id);
        })
    });

    return selectedPermissions;
};

class CreateRoleManagement extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editRoleData || {});

        this.state = {
            currentTab: this.props.currentTab,
            selectedModule: this.props.permissionsMasterList && this.props.permissionsMasterList.length > 0
                ? this.props.permissionsMasterList[0]._id
                : '',
            role: this.formFields.data,
            formValidations: this.formFields.formValidations,
            permissions: parsePermissionsData((this.props.editRoleData && this.props.editRoleData.permissions) ? this.props.editRoleData.permissions : {}),
            errors: {},
            permissionsMasterList1: [],
            summary: null,
            isShowCreateOrUpdateSuccessDlg: false,
            permissionIds: [],
            editModules: this.props.editModules || [],
            isAdd: this.props.isAdd,
            filterData: [],
            labelOptions: [],
            isLoading: false
          

        }

        this.service = new Service();
    }



    getPermissionListByGroup = (groupId) => {
        const { permissionsMasterList } = this.props;
        const groupedPermissions = permissionsMasterList.find((item) => item.id === groupId);
        return groupedPermissions;
    };
    onSubmitClick = () => {
        // const academicYear = localStorage.getItem("userAcademicYear")
        let { permissions } = this.state;

        let role = this.state.role;


        if (permissions && Object.keys(permissions).length) {
            Object.keys(permissions).forEach(key => {
                if (!(permissions[key] && permissions[key].length)) {
                    delete permissions[key]
                }
            })
        }

        role.permissions = permissions;

        role = trimObj(role);
        const formStatus = isFormValid(formFields, this.formFields.formValidations, role);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            const permissionOrderedByGroups = Object.keys(permissions)?.reduce((acc, curr) => {
                const groupedPermissions = this.getPermissionListByGroup(curr);

                if (groupedPermissions) {
                    const perms = permissions[curr].map((permId) => {
                        return groupedPermissions.permissions.find((permObject) => {
                            if (permObject.id === permId) {
                                return permObject;
                            }
                        });
                    });

                    acc[curr] = {
                        groupName: groupedPermissions.groupName,
                        permissions: perms.filter((item) => item !== undefined)
                    };
                }
                return acc;
            }, {});

            role = trimObj(role);
            const payload = {
                ...role,
                // academicYear: academicYear,
                permissions: permissionOrderedByGroups
            };


            this.setState({
                isLoading: true
            })


            if (!this.props.isAdd) {


                const url = `${baseUrlAdmin}/authorize/addRole`;
                this.service.post(url, payload, true).then(res => {
                    if (res && res.status) {


                        let summary = this.getSummaryReport(this.state.permissionsMasterList1, payload)

                        this.toast.show({ severity: 'success', summary: 'Role Created Successfully', detail: `${summary.roleName}`, life: 3000 });
                        this.setState({
                            isLoading: false,
                            summary,
                            isShowCreateOrUpdateSuccessDlg: true,
                            isCreated: true

                        }, () => {
                            //this.props.getPermissions()
                            this.props.getApiCall(true)
                            this.props.getRoles()
                            this.props.onTabChange(0);

                        })

                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        });
                    }
                }).catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    console.log(e);
                })
            } else {

                const url = `${baseUrlAdmin}/authorize/updateRole`;
                console.log(this.state.permissionIds, "this.state.permissionIds", payload)

                this.service.put(url, { ...payload }, true).then((res) => {
                    if (res && res.status) {
                        let summary = this.getSummaryReport(this.state.permissionsMasterList1, payload)

                        this.toast.show({ severity: 'success', summary: 'Role Updated Successfully', detail: `${summary.roleName}`, life: 3000 });

                        this.setState({
                            isLoading: false,
                            summary,
                            isShowCreateOrUpdateSuccessDlg: true,
                            isCreated: true
                        }, () => {
                            this.props.getApiCall(true)
                            this.props.getRoles()
                            this.props.onTabChange(0);
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                });

            }


        }

    };

    componentDidMount() {

        if (this.props.permissionsMasterList && Array.isArray(this.props.permissionsMasterList) && this.props.permissionsMasterList.length > 0) {
            console.log("navyacallleddddddd", this.props.isAdd, this.props)
            this.setState({
                permissionsMasterList1: this.props.permissionsMasterList
            }, () => {
                console.log(this.state.permissionsMasterList1, "navyacallledddddddnavyacallleddddddd",this.props.permissionsMasterList);

                this.getLabelOptions()
                this.getSelectedModulesSet()




            });
        }



    }


    getSelectedModulesSet = () => {

        if (this.state.permissionsMasterList1 && Array.isArray(this.state.permissionsMasterList1) && this.state.permissionsMasterList1.length > 0) {

            let result = this.extractPermissionIds(this.state.isAdd, this.state.editModules, this.state.permissionsMasterList1, this.state.permissionIds)
            if (result) {
                this.getSelectedModulesData()
            }


        }


    }

    getSelectedModulesData = () => {

        const transformedData = Array.isArray(this.state.permissionsMasterList1) && this.state.permissionsMasterList1.length > 0
            ? this.state.permissionsMasterList1.flatMap(group =>
                group.permissions.map(permission => ({
                    groupName: group.groupName,
                    subFeature: permission.name,
                    subModule: permission.category,
                    id: permission.id,
                    groupId: group.id,
                    _id: group._id
                }))
            )
            : [];


        const filterData = Array.isArray(transformedData) && transformedData.length > 0 && Array.isArray(this.state.permissionIds) && this.state.permissionIds.length > 0
            ? transformedData.filter(item => {

                const isInSelectedValue = this.state.permissionIds.includes(item.groupId);
                return isInSelectedValue;
            })
            : [];


        this.setState({
            filterData: filterData
        }, () => {
            if (this.state.permissionIds && this.state.filterData) {

                this.filterObjectByKeys(this.state.permissionIds, this.state.permissions)


            }

        });
    }


    filterObjectByKeys(keys, obj) {
        let filteredObj = {};

        keys.forEach(key => {
            if (obj[key]) {
                filteredObj[key] = obj[key];
            }
        });
        this.setState({
            permissions: filteredObj
        }, () => {
        });
        return filteredObj;
    }

    isChecked = (id, permissions, permissionIds) => {


        return Object.keys(permissions)?.reduce((acc, curr) => {
            acc = acc.concat(permissions[curr]);
            return acc;
        }, []).includes(id);


    };

    handleCheckBoxClick = (groupId, permissionId, checked) => {

        const permissionList = this.state.permissions;
        if (Object.keys(permissionList).includes(groupId)) {
            if (checked) {
                permissionList[groupId].push(permissionId);
            } else {
                permissionList[groupId] = permissionList[groupId].filter((item) => item !== permissionId);
                if (permissionList[groupId].length === 0) {
                    delete permissionList[groupId];
                }
            }
        } else {
            permissionList[groupId] = [permissionId];
        }

        this.setState((prevState) => {

            return {
                permissions: permissionList,
                errors: {
                    ...prevState.errors,
                    permissions: Object.keys(permissionList).length === 0
                }
            };
        });
    };
    handleCheckBoxChange = (groupId, permissionId, permissionIds) => (event) => {

        this.handleCheckBoxClick(groupId, permissionId, event.target.checked);
    };


    getSummaryReport = (pList, roleInfo) => {
        let summary = {
            roleName: roleInfo.roleName,
            description: roleInfo.description,
            roleType: roleInfo.roleType,
            permissions: [
            ]
        };
        pList.forEach(permission => {
            let perm = {
                groupName: permission.groupName,
                totalPermissions: permission.permissions.length,
                assignedPermissions: 0
            };

            if (roleInfo && roleInfo.permissions && roleInfo.permissions[permission.id] && roleInfo.permissions[permission.id].permissions) {
                perm.assignedPermissions = roleInfo.permissions[permission.id].permissions.length
            }

            summary.permissions.push(perm);

        }
        );
        return summary;
    }
    onMultiChange = (e, permission, permissionIds) => {
        this.setState((prevState) => {
            return {
                permissionIds: e.value,
            };
        }, () => {
            this.getSelectedModulesData()

        });
        ;
    }


    getLabelOptions = () => {
        const options = this.props.permissionsMasterList && this.props.permissionsMasterList?.map(obj => ({
            value: obj.id,
            label: obj.groupName.toUpperCase()
        })) || [];

        this.setState((prevState) => {

            return {
                labelOptions: options,
            };
        });

    }

    extractPermissionIds = (isAdd, editModules, permissionsMasterList1, permissionIdsSelected) => {

        let permissionIds = permissionIdsSelected;

        if (isAdd !== undefined && isAdd) {
            permissionsMasterList1.forEach((data, index) => {

                const matchedData = editModules.find(edit => edit.id === data.id);
                if (matchedData) {


                    permissionIds.push(matchedData.id);
                }
            });


        } else {
            let ids = permissionsMasterList1[0]?.id;
            if (!permissionIds.includes(ids)) {

                permissionIds.push(ids);
            }
        }

        return permissionIds;
    }


    // handleSelectAllChange = (e) => {
    //     const checked = e.target.checked;
    //     const allPermissionIds = this.props.filterData.map(item => item.id);

    //     this.setState({
    //         selectAll: checked,
    //         selectedPermissions: checked ? allPermissionIds : [] // Select all or clear selection
    //     });
    // }

    // handleCheckBoxChange = (groupId, id) => (e) => {
    //     const checked = e.target.checked;
    //     let selectedPermissions = [...this.state.selectedPermissions];

    //     if (checked) {
    //         selectedPermissions.push(id);
    //     } else {
    //         selectedPermissions = selectedPermissions.filter(item => item !== id);
    //     }

    //     this.setState({ selectedPermissions });
    // }

    render() {


        const { role, formValidations, permissions, permissionIds, filterData, labelOptions } = this.state;
        const groupIds = Array.from(new Set(filterData.map(item => item.groupId)));

        return (
            <>
                <div className=' grid col-12  mt-4'>

                    <div className='grid col-12'>
                        <div className="col-3">
                            <p className='ma-label-s2 formLabelText'>Role Name<span className='ma-required'>*</span></p>

                            <ClassetInputText icon={<MailIcon />} placeholder={'Role Name'} value={role.roleName} onChange={(e) => { onTextChange(e.target.value, 'roleName', this, createRoleFields, role, formValidations, 'role', 'formValidations') }} />



                            {formValidations && !formValidations.fields['roleName'].isValid && <p className="p-error font-semibold">{formValidations.fields['roleName'].errorMsg}</p>}

                        </div>

                        <div className="col-9">
                            <p className='ma-label-s2 formLabelText'>Role Description<span className='ma-required'>*</span></p>

                            <ClassetInputText width={'100%'} height={"49px"} value={role.description} onChange={(e) => { onTextChange(e.target.value, 'description', this, createRoleFields, role, formValidations, 'role', 'formValidations') }} placeholder="Role Description" />
                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}

                        </div>
                    </div>

                    <p className='mainHead text-3xl'>Permissions</p>
                    <div className='grid col-12'>

                        <div className='col-3 '>
                            <p className='ma-label-s2 formLabelText'>Select Modules<span className='ma-required'>*</span></p>
                            <ClassetMultiSelect
                                icon={<MailIcon />}
                                placeholder='Select Modules'
                                value={permissionIds.length > 0 ? permissionIds : []}
                                onChange={(e) => this.onMultiChange(e, permissions, permissionIds)}
                                options={labelOptions}
                            />
                            {/* {formValidations && !formValidations.fields['groupName'].isValid && <p className="p-error">{formValidations.fields['groupName'].errorMsg}</p>}  */}


                        </div>

                        <div className="col-3">
                            <p className='ma-label-s2 formLabelText'>Type<span className='ma-required'>*</span></p>

                            <ClassetDropdown


                                icon={<MailIcon />}
                                placeholder={'Type'}
                                options={TemplateTypes}
                                value={role?.type}
                                onChange={(e) => {
                                    onDropDownChange(e.value, 'type', this, createRoleFields, role, formValidations, 'role', 'formValidations')
                                }}
                            />
                            {formValidations && !formValidations.fields['type'].isValid && <p className="p-error">{formValidations.fields['type'].errorMsg}</p>}

                        </div>
                    </div>

                    <div className=' col-12 card ml-3 mt-3 '>
                        <DataTable ref={(el) => this.dt = el}
                            responsiveLayout="scroll"
                            className="datatable-cell"

                            value={filterData.length > 0 && filterData} >


                            <Column header='Module' headerClassName="datatable-header" sortable filterField="groupName" field="groupName" className='col-4'
                            />

                            <Column header="Sub Module" headerClassName="datatable-header" sortable className='col-4' filterField="subModule" field="subModule" />

                            <Column header="Feature" headerClassName="datatable-header" sortable filterField="subFeature" field="subFeature" />
                            <Column header="Enabled" headerClassName="datatable-header" sortable filterField="id" field="id" className='col-1' body={(rowData, index) => {
                                return (
                                    <Checkbox inputId={rowData.id}
                                        checked={this.isChecked(rowData.id, permissions, permissionIds)}
                                        value={rowData.id}
                                        onChange={this.handleCheckBoxChange(rowData.groupId, rowData.id)}
                                    ></Checkbox>
                                )
                            }} />

                            

                        </DataTable>
                    </div>



                    <div className="text-center mt-4 ml-3">
                        <div className="button-wrapper">

                            <Button className='submitButton'
                                onClick={this.onSubmitClick}

                            >
                                <p className='submitButtonText'>{`${this.props.isAdd ? 'Update' : 'Save'}`}</p>
                            </Button>

                            <Button
                                className="cancelButton"

                                onClick={() => { this.props.showEditRoleDialog(null, null, false); this.props.onTabChange(0); }}>
                                <p className='cancelButtonText'>Cancel</p>

                            </Button>

                        </div>
                    </div>


                </div>

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(CreateRoleManagement);
