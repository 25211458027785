import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import './styles.scss';
import { MultiSelect } from "primereact/multiselect";
import ClassetDropdown from "../../../../classetComponents/classetDropDown";
import { MailIcon } from "../../../svgIcons";
import ClassetInputText from "../../../../classetComponents/classetInputText";
import ClassetTextArea from "../../../../classetComponents/classetTextArea";
import ClassetMultiSelect from "../../../../classetComponents/classetMultiSelect";
import { getFormFields, isFormValid, onNumberChange, onTextChange } from "../../../../utile/formHelper";
import { baseUrlAdmin, questionType } from "../../../../store/apiConstants";
import Service from "../../../services";
import { Toast } from "primereact/toast";
import { isDigit } from "../../../../utile";
import uuidv4 from 'uuid/v4';

// import Service from './../../services';
const getSubjectsDrpDwn = () => {
    let subjectsCount = [];

    for (let i = 1; i <= 10; i++) {
        subjectsCount.push({ label: i + ' Subject' + (i > 1 ? 's' : ''), value: i })

    }

    return subjectsCount

}

const getSectionDrpDwn = () => {
    let sectionCount = [];

    for (let i = 1; i <= 10; i++) {
        sectionCount.push({ label: i + ' Section' + (i > 1 ? 's' : ''), value: i })

    }

    return sectionCount

}
const totalSections = [
    { value: 1, label: 'One(1)' },
    { value: 2, label: 'Two(2)' },
    { value: 3, label: 'Three(3)' },
    { value: 4, label: 'Four(4)' }
]


const totalSubjects = [
    { value: 1, label: 'Subject(1)' },
    { value: 2, label: 'Subject(2)' },
    { value: 3, label: 'Subject(3)' },
    { value: 4, label: 'Subject(4)' }
]

const QuestionTypesrr = [
    { value: 1, label: 'SCCT' },
    { value: 2, label: 'MCCT' },
    { value: 3, label: 'Integer' },
    { value: 4, label: 'Numerical' },
    { value: 5, label: 'Fill in the Balnk' },
    { value: 6, label: 'Passage SCCT' },
    { value: 7, label: 'Passage MCCT' },
    { value: 8, label: 'Matrix Matching' },
    { value: 9, label: 'True/False' },
    { value: 10, label: 'Audio Based' },
    { value: 11, label: 'Video Based' },
    { value: 12, label: 'Subjective - VSAQ' },
    { value: 13, label: 'Subjective - SAQ' },
    { value: 14, label: 'Subjective - LAQ' },
]
const QuestionTypes = [
    { value: 'SCCT', label: 'SCCT' },
    { value: 'MCCT', label: 'MCCT' },
    { value: 'Integer', label: 'Integer' },
    { value: 'Numerical', label: 'Numerical' },
    { value: 'Fill in the Blank', label: 'Fill in the Blank' },
    { value: 'Passage SCCT', label: 'Passage SCCT' },
    { value: 'Passage MCCT', label: 'Passage MCCT' },
    { value: 'Matrix Matching', label: 'Matrix Matching' },
    { value: 'True/False', label: 'True/False' },
    { value: 'Audio Based', label: 'Audio Based' },
    { value: 'Video Based', label: 'Video Based' },
    { value: 'Subjective - VSAQ', label: 'Subjective - VSAQ' },
    { value: 'Subjective - SAQ', label: 'Subjective - SAQ' },
    { value: 'Subjective - LAQ', label: 'Subjective - LAQ' },
];

const tempType = [
    { label: 'Subjective', value: 'Subjective' },
    { label: 'Objective', value: 'Objective' },
]


const createExamFields = require('./addExam.json');
let formFields = createExamFields;
export class AddExam extends Component {
    constructor(props) {
        super(props)

        this.formFields = getFormFields(formFields, this.props.editExamData || {});

        this.state = {
            exam: this.props?.editData?._id ? this.props?.editData : this.formFields?.data,
            formValidations: this.formFields.formValidations,
            tab: '1',
            totalSections: totalSections,
            totalSubjects: totalSubjects,
            sections: [],
            selectedSubject: 0,
            QuestionTypes: QuestionTypes,
            selectedQuestionType: [],
            subjects: [],
            selectedSections: null,
            questionTypes: []
        }
        this.service = new Service();

    }
    handleSelect = (item) => {
        this.setState({ selectedQuestionType: [...item] });
    }
    validateSectionFields = (section) => {
        const errors = {};

        if (!section.sectionName || section.sectionName.trim() === '') {
            errors.sectionName = "Section Name is required.";
        }

        if (!section.totalQuestions || isNaN(section.totalQuestions) || parseInt(section.totalQuestions) <= 0) {
            errors.totalQuestions = "Total Questions must be a valid number greater than 0.";
        }

        if (!section.totalMarkedQuestions || isNaN(section.totalMarkedQuestions) || parseInt(section.totalMarkedQuestions) < 0) {
            errors.totalMarkedQuestions = "Marked Questions must be a valid number.";
        }


        return errors;
    };
    onSubmitClick = () => {
        const academicYear = localStorage.getItem("userAcademicYear");
        let exam = {
            ...this.state.exam,
            totalSubjects: this.state.exam?.subjects?.length || 0,
            status: "Active",
            subjects: this.state.exam?.subjects?.map(subject => ({
                ...subject,
                totalSections: subject?.sections?.length || 0,
                id: uuidv4(),
                subjectId: uuidv4(),
                sections: subject?.sections?.map(section => ({
                    ...section,
                    errors: this.validateSectionFields(section)
                }))
            })) || [],
            academicYear: academicYear
        };

        const totalQuestionsAllowed = this.state.exam.totalNoOfQuestions || 0;
        const totalQuestionsInSubjects = exam.subjects.reduce((sum, subject) => sum + (Number(subject.totalQuestions) || 0), 0);

        if (totalQuestionsInSubjects > totalQuestionsAllowed) {
            this.toast.show({
                severity: 'error',
                summary: 'Total Questions Exceeded',
                detail: `Total questions in subjects (${totalQuestionsInSubjects}) cannot exceed Exam Total Questions (${totalQuestionsAllowed})`,
                life: 3000
            });
            return;
        }

        const totalMarkedQuestionsAllowed = this.state.exam.totalMarkedQuestions || 0;
        const totalMarkedQuestionsInSubjects = exam.subjects.reduce((sum, subject) => sum + (Number(subject.totalMarkedQuestions) || 0), 0);

        if (totalMarkedQuestionsInSubjects > totalMarkedQuestionsAllowed) {
            this.toast.show({
                severity: 'error',
                summary: 'Total Marked Questions Exceeded',
                detail: `Total marked questions in subjects (${totalMarkedQuestionsInSubjects}) cannot exceed Exam Total Marked Questions (${totalMarkedQuestionsAllowed})`,
                life: 3000
            });
            return;
        }

        const subjectValidationErrors = exam.subjects.some((subject, index) => {
            const totalQuestionsInSections = subject.sections?.reduce((sum, section) => sum + (Number(section.totalQuestions) || 0), 0);
            const totalMarkedQuestionsInSections = subject.sections?.reduce((sum, section) => sum + (Number(section.totalMarkedQuestions) || 0), 0);

            if (totalQuestionsInSections > Number(subject.totalQuestions)) {
                this.toast.show({
                    severity: 'error',
                    summary: `Total questions in sections ${index + 1}`,
                    detail: `Total questions in sections (${totalQuestionsInSections}) do not match subject total questions (${subject.totalQuestions})`,
                    life: 3000
                });
                return true;
            }

            if (totalMarkedQuestionsInSections > Number(subject.totalMarkedQuestions)) {
                this.toast.show({
                    severity: 'error',
                    summary: `Total marked questions in sections ${index + 1}`,
                    detail: `Total marked questions in sections (${totalMarkedQuestionsInSections}) do not match subject total marked questions (${subject.totalMarkedQuestions})`,
                    life: 3000
                });
                return true;
            }

            return false;
        });


        if (subjectValidationErrors) {
            return;
        }


        const hasErrors = exam.subjects?.some(subject =>
            subject.sections?.some(section => Object.keys(section.errors)?.length > 0)
        );

        if (hasErrors) {
            this.setState({ exam });
            this.toast.show({ severity: 'error', summary: 'Please fill the required fields', life: 3000 });
            return;
        }


        const formStatus = isFormValid(formFields, this.formFields.formValidations, exam);
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editData?._id) {
                const payload = exam;
                let url = `${baseUrlAdmin}/examtemplate/updatetemplate`;
                this.service.put(url, payload, true).then(res => {
                    if (res.status && res?.res?.success) {
                        this.toast.show({ severity: 'success', summary: ' updated succesfully', detail: "", life: 3000 });
                        // this.props?.switchTab('2')
                        setTimeout(() => {
                            this.props?.switchTab('2');
                        }, 1000);
                        this.setState({
                            exam: {}
                        });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                    }
                }).catch((e) => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                });
            }
            else {
                const payload = exam;
                let url = `${baseUrlAdmin}/examtemplate/savetemplate`;
                this.service.post(url, payload, true).then(res => {
                    if (res.status && res?.res?.success) {
                        this.toast.show({ severity: 'success', summary: ' created succesfully', detail: "", life: 3000 });
                        // this.props?.switchTab('2')
                        setTimeout(() => {
                            this.props?.switchTab('2');
                        }, 1000);
                        this.setState({
                            exam: {}
                        });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch((e) => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                });
            }
        }
    }
    handleTotalQuestionsChange = (index, value) => {


        this.setState((prevState) => {
            const newSubjects = [...prevState.exam.subjects];
            newSubjects[index].totalQuestions = value;
            newSubjects[index].subjectName = "Subject" + (index + 1);
            newSubjects[index].priroity = index + 1;
            //newSubjects[index].subjectId = uuidv4();
            return { exam: { ...prevState.exam, subjects: newSubjects } };
        }, () => {

        });
    };

    handleMarkedTotalQuestionsChange = (index, value) => {
        this.setState((prevState) => {
            const newSubjects = [...prevState.exam.subjects];
            newSubjects[index].totalMarkedQuestions = value;
            newSubjects[index].subjectName = "Subject" + (index + 1);
            newSubjects[index].priroity = index + 1;
            //newSubjects[index].subjectId = uuidv4();
            return { exam: { ...prevState.exam, subjects: newSubjects } };
        });
    };




    handleSubjectsChange = (value) => {
        const numberOfSubjects = parseInt(value, 10);
        const newSubjects = Array.from({ length: numberOfSubjects }, () => ({
            totalQuestions: '',
            totalMarkedQuestions: '',
        }));

        this.setState((prevState) => ({
            exam: {
                ...prevState.exam,
                subjects: newSubjects,
            },
        }));
    };

    handleSubjectLevelSectionChange = (index, value) => {
        const numberOfSections = parseInt(value, 10);
        const newSections = Array.from({ length: numberOfSections }, () => ({
            sectionName: '',
            totalQuestions: '',
            totalMarkedQuestions: '',
            sectionInstruction: '',
            questionTypes: []
        }));

        this.setState((prevState) => {
            const newSubjects = [...prevState.exam.subjects];
            newSubjects[index].sections = newSections;

            return {
                exam: {
                    ...prevState.exam,
                    subjects: newSubjects,
                },
                sections: {
                    ...prevState.sections,
                    [index]: numberOfSections,
                }
            };
        });
    };


    render() {
        const { exam, formValidations } = this.state


        return (
            <>
                <div className="px-6 py-3 grid">
                    <div className="grid" >
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Name of the Template<span className="ma-required">*</span></label>
                            <div className=''>
                                <div >

                                    <ClassetInputText
                                        icon={<MailIcon width={24} height={24} color={"black"} />}
                                        // width={'320px'}
                                        className=' border-round-md groupFormInputText  border-none '
                                        value={this.props?.editData?._id ? exam?.name : exam?.templateName}
                                        onChange={(e) => { onTextChange(e.target.value, 'name', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                        placeholder="Template Name" />
                                    {formValidations && !formValidations.fields['name'].isValid && <p className="p-error text-lg ">{formValidations.fields['name'].errorMsg}</p>}

                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                        </div>

                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800"> Template Type<span className="ma-required">*</span></label>
                            <div className=''>
                                <div >

                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={"black"} />}
                                        options={tempType}
                                        value={exam?.examType}
                                        // required={true}
                                        className=' border-round-md groupFormInputText  border-none '
                                        onChange={(e) => { onTextChange(e.target.value, 'examType', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                        placeholder="Template Type" />
                                </div>
                            </div>
                            {formValidations && !formValidations.fields['examType'].isValid && <p className="p-error text-lg ">{formValidations.fields['examType'].errorMsg}</p>}
                        </div>
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Questions<span className="ma-required">*</span></label>
                            <div className=''>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md groupFormInputText  border-none '
                                    value={this.props?.editData?._id ? exam?.totalNoOfQuestions : exam?.totalQuestions}
                                    onChange={(e) => { onNumberChange(e.target.value, 'totalNoOfQuestions', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                    placeholder="Total Questions" />
                            </div>
                            {formValidations && !formValidations.fields['totalNoOfQuestions'].isValid && <p className="p-error text-lg ">{formValidations.fields['totalNoOfQuestions'].errorMsg}</p>}
                        </div>
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Marked Questions<span className="ma-required">*</span></label>
                            <div className=''>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md groupFormInputText  border-none '
                                    value={exam?.totalMarkedQuestions}
                                    onChange={(e) => { onNumberChange(e.target.value, 'totalMarkedQuestions', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                    placeholder="Marked Questions" />
                            </div>
                            {formValidations && !formValidations.fields['totalMarkedQuestions'].isValid && <p className="p-error text-lg ">{formValidations.fields['totalMarkedQuestions'].errorMsg}</p>}
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Template Description<span className="ma-required">*</span></label>
                            <ClassetTextArea
                                title="description"
                                width="656px"
                                height="119px"
                                labelClassName="mb-2"
                                className="text-lg"
                                value={exam?.description}
                                onChange={(e) => { onTextChange(e.target.value, 'description', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                placeholder="Description"
                                rows={3}
                            />

                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>}
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Exam Instructions<span className="ma-required">*</span></label>

                            <ClassetTextArea
                                title="description"
                                width="656px"
                                height="119px"
                                labelClassName="mb-2"
                                className="text-lg"
                                value={exam?.instructions}
                                onChange={(e) => { onTextChange(e.target.value, 'instructions', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                placeholder="Exam Instructions"
                                rows={3}
                            />
                            {formValidations && !formValidations.fields['instructions'].isValid && <p className="p-error text-lg">{formValidations.fields['instructions'].errorMsg}</p>}
                        </div>
                        <div className="col-3 mt-2">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Subjects<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div>

                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={getSubjectsDrpDwn()}

                                        placeholder="Total Subjects"
                                        value={exam?.subjects?.length}
                                        onChange={(e) => this.handleSubjectsChange(e.target.value)}
                                    />
                                    {formValidations && !formValidations.fields['subjects'].isValid && <p className="p-error text-lg">{formValidations.fields['subjects'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {exam?.subjects?.length > 0 && (
                    <div className="ml-5">
                        {exam?.subjects?.map((subject, subjectIndex) => (
                            <div key={subjectIndex} id={subjectIndex}>
                                <div className="grid mb-0 col-10 gap-1">
                                    <div style={{ alignContent: 'center', justifyContent: 'center', height: '47px' }} className="p-inputgroup border-300 shadow-2 border-round-md col-1">
                                        <label className="block font-semibold truncate">Subject {subjectIndex + 1}</label>
                                    </div>
                                    <div className="col-3 p-0">
                                        <ClassetInputText
                                            className='h-3rem border-round-md border-none text-lg pl-3 w-8'
                                            placeholder="Total Questions"
                                            value={subject?.totalQuestions}
                                            onChange={(e) => { this.handleTotalQuestionsChange(subjectIndex, e.target.value) }}
                                        />
                                    </div>
                                    <div className="col-3 p-0">
                                        <ClassetInputText
                                            className='h-3rem border-round-md border-none text-lg pl-3 w-8'
                                            placeholder="Total Marked Questions"
                                            value={subject?.totalMarkedQuestions}
                                            onChange={(e) => { this.handleMarkedTotalQuestionsChange(subjectIndex, e.target.value) }}
                                        />
                                    </div>
                                    <div className="col-2 p-0">
                                        <ClassetDropdown
                                            options={getSectionDrpDwn()}
                                            value={this.state.sections[subjectIndex] || ''}

                                            onChange={(e) => {
                                                this.handleSubjectLevelSectionChange(subjectIndex, e.value);
                                            }}
                                            placeholder="Total Sections"
                                        />
                                    </div>
                                </div>
                                <br />
                                {subject.sections && subject.sections?.length > 0 && (
                                    <Accordion className="section-accordion -ml-3 -mt-4 mb-3" activeIndex={0}>
                                        {subject?.sections?.map((section, sectionIndex) => (
                                            <AccordionTab key={sectionIndex} header={<span>Section {sectionIndex + 1}</span>}>
                                                <div className="grid">
                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Section Name<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetInputText
                                                            icon={<MailIcon width={24} height={"black"} />}
                                                            className='border-round-md groupFormInputText border-none'
                                                            placeholder="Section Name"
                                                            value={section.sectionName}
                                                            onChange={(e) => {
                                                                const newSections = [...subject.sections];
                                                                // const errors = this.validateSectionFields(newSections[sectionIndex]);
                                                                // newSections[sectionIndex].errors = errors;
                                                                newSections[sectionIndex].sectionName = e.target.value;
                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                        />
                                                        {/* {section.errors?.sectionName && (
                                                            <span className="error-message" style={{ color: 'red' }}>{section.errors.sectionName}</span>
                                                        )} */}
                                                    </div>

                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Total Questions<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetInputText
                                                            icon={<MailIcon width={24} height={"black"} />}
                                                            className='border-round-md groupFormInputText border-none'
                                                            placeholder="Total Questions"
                                                            value={section.totalQuestions}
                                                            onChange={(e) => {
                                                                const newSections = [...subject.sections];
                                                                newSections[sectionIndex].totalQuestions = e.target.value;
                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Marked Questions<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetInputText
                                                            icon={<MailIcon width={24} height={"black"} />}
                                                            className='border-round-md groupFormInputText border-none'
                                                            placeholder="Marked Questions"
                                                            value={section.totalMarkedQuestions}
                                                            onChange={(e) => {
                                                                const newSections = [...subject.sections];
                                                                newSections[sectionIndex].totalMarkedQuestions = e.target.value;
                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Question Types
                                                        </label>
                                                        <ClassetMultiSelect
                                                            icon={<MailIcon />}
                                                            placeholder={<span className='text-lg ml-4'>Select Question Types</span>}
                                                            value={section.questionTypes}
                                                            onChange={(e) => {
                                                                // const newSections = [...subject.sections];
                                                                // newSections[sectionIndex].questionTypes = e.value;
                                                                // this.setState((prevState) => {
                                                                //     const newSubjects = [...prevState.exam.subjects];
                                                                //     newSubjects[subjectIndex].sections = newSections;
                                                                //     return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                // });

                                                                const newQuestionTypes = e.value;

                                                                // Initialize marking scheme if question types are selected
                                                                const markingScheme = newQuestionTypes.map(type => ({
                                                                    questionType: type,
                                                                    mandatoryQuestions: '',
                                                                    optionalQuestions: '',
                                                                    positiveMarking: '',
                                                                    negativeMarking: '',
                                                                    partialMarking: ''
                                                                }));

                                                                const newSections = [...subject.sections];
                                                                newSections[sectionIndex].questionTypes = newQuestionTypes;
                                                                newSections[sectionIndex].markingScheme = markingScheme;

                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                            options={QuestionTypes}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="block font-serif font-bold text-800">
                                                            Section Instructions
                                                        </label>
                                                        <InputTextarea
                                                            className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3'
                                                            placeholder="Section Instructions"
                                                            rows={3}
                                                            value={section.sectionInstruction}
                                                            onChange={(e) => {
                                                                const newSections = [...subject.sections];
                                                                newSections[sectionIndex].sectionInstruction = e.target.value;
                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </div>


                                                <div className="mt-3">
                                                    {section.questionTypes?.length > 0 && section.markingScheme && section.markingScheme.map(mark => (
                                                        <div key={mark.questionType} className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">{mark.questionType}</div>
                                                                <div className="col-3">
                                                                    <ClassetInputText
                                                                        placeholder="Mandatory Questions"
                                                                        value={mark.mandatoryQuestions}
                                                                        onChange={(e) => {
                                                                            mark.mandatoryQuestions = e.target.value;
                                                                            this.setState({ exam: { ...this.state.exam } });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Optional Questions"
                                                                        value={mark.optionalQuestions}
                                                                        onChange={(e) => {
                                                                            mark.optionalQuestions = e.target.value;
                                                                            this.setState({ exam: { ...this.state.exam } });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Right Mark"
                                                                        value={mark.positiveMarking}
                                                                        onChange={(e) => {
                                                                            mark.positiveMarking = e.target.value;
                                                                            this.setState({ exam: { ...this.state.exam } });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Negative Mark"
                                                                        value={mark.negativeMarking}
                                                                        onChange={(e) => {
                                                                            mark.negativeMarking = e.target.value;
                                                                            this.setState({ exam: { ...this.state.exam } });
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Partial Mark"
                                                                        value={mark.partialMarking}
                                                                        onChange={(e) => {
                                                                            mark.partialMarking = e.target.value;
                                                                            this.setState({ exam: { ...this.state.exam } });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* {section.questionTypes.includes('SCCT') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">SCCT</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('MCCT') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">MCCT</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Integer') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Integer</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Numerical') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Numerical</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Fill in the Blank') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Fill in the Blank</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Passage SCCT') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Passage SCCT</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Passage MCCT') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Passage MCCT</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Matrix Matching') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Matrix Matching</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('True/False') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">True/False</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Audio Based') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Audio Based</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Video Based') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Video Based</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Subjective - VSAQ') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Subjective - VSAQ</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Subjective - SAQ') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Subjective - SAQ</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {section.questionTypes.includes('Subjective - LAQ') && (
                                                        <div className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">Subjective - LAQ</div>
                                                                <div className="col-3"><ClassetInputText placeholder="Mandatory Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Optional Questions" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Right Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Negative Mark" /></div>
                                                                <div className="col-2"><ClassetInputText placeholder="Partial Mark" /></div>
                                                            </div>
                                                        </div>
                                                    )} */}
                                                </div>

                                                {/* <div className=" mt-3"> */}
                                                {/* {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes('SCCT') &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', overflow: 'hidden', justifyContent: 'center', alignContent: 'center' }} className="question-type-label col-1 mt-3 border-round-md truncate text-lg ">SCCT</div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    } */}
                                                {/* {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(2) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', overflow: 'hidden', justifyContent: 'center', alignContent: 'center' }} className="question-type-label col-1 mt-3 border-round-md truncate text-lg ">MCCT</div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }{this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(3) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', overflow: 'hidden', justifyContent: 'center', alignContent: 'center' }} className="question-type-label col-1 mt-3 border-round-md truncate text-lg ">Integer</div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(4) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', overflow: 'hidden', justifyContent: 'center', alignContent: 'center' }} className="question-type-label col-1 mt-3 border-round-md truncate text-lg ">Numerical</div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(5) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Fill in the Blank
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(6) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Passage SCCT
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(7) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Passage MCCT
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(8) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Matrix Matching
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(9) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                True/False
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(10) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Audio Based
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(11) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Video Based
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(12) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Subjective - VSAQ
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(13) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Subjective - SAQ
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(14) &&
                                                        <div className="col-12 grid my-0 py-1 ">
                                                            <div
                                                                style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: '0 0 auto',
                                                                }}
                                                                className="question-type-label col-1 mt-3 border-round-md truncate text-lg"
                                                            >
                                                                Subjective - LAQ
                                                            </div>
                                                            <div className="col-3 -ml-3 " >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Mandatory Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2" >
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg  '
                                                                    placeholder="Optional Questions"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Right Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Negetive Mark"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <ClassetInputText id="title" className='question-type-input  border-round-md  text-lg '
                                                                    placeholder="Partial Mark"
                                                                />
                                                            </div>
                                                        </div>
                                                    } */}
                                                {/* </div> */}


                                            </AccordionTab>


                                        ))}
                                    </Accordion>
                                )}


                            </div>
                        ))}
                    </div>
                )}



                <div style={{ marginTop: '70px' }} className="text-center ml-3">
                    <div className="button-wrapper">

                        <Button className='submitButton'
                            onClick={this.onSubmitClick}

                        >
                            <p className='submitButtonText'>{`${this.props.editData?._id ? 'Update' : 'Save'}`}</p>
                        </Button>

                        <Button
                            className="cancelButton"

                        // onClick={() => { this.props.showEditRoleDialog(null, null, false); this.props.onTabChange(0); }}
                        >
                            <p className='cancelButtonText'>Cancel</p>

                        </Button>

                    </div>
                </div>


                <Toast ref={(el) => this.toast = el} position="bottom-right" />


            </>
        )
    }
}

export default AddExam