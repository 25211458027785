import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';
import { primaryColor } from '../../../store/apiConstants';
import './styles.scss'
import StudentStatusChangeRequests from './StudentStatusChangeRequests';
import StudentAdmissionChangeRequests from './StudentAdmissionChangeRequests';





class StudentStatusChangeApprovalTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    
   

    componentDidUpdate(prevProps, prevState) {

    }

    render() {

        return (
            <div>

                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-6 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tabheadText" onClick={() => { this.onTabChange(0) }}>Status Change</p>
                                </div>
                                <div className=" cursor-pointer col-6 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(1) }}>Admission Category</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.currentTab == 0 && <>
                            <StudentStatusChangeRequests />
                        </>
                    }
                    {
                        this.state.currentTab == 1 && <>
                            <StudentAdmissionChangeRequests />
                        </>
                    }
                </div>

            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(StudentStatusChangeApprovalTabs)));