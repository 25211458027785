import { RadioButton } from 'primereact/radiobutton'
import React, { Component } from 'react'
import ClassetStudentSearch from '../../../classetComponents/classetStudentSearch'
import { MailIcon } from '../../svgIcons'
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD'
import ClassetDropdown from '../../../classetComponents/classetDropDown'
import Service from '../../services'
import { debounce } from 'lodash'
import Authentication from './../../session';
import { baseUrlAdmin } from '../../../store/apiConstants'
import withRouter from '../../lib/withRouter'
import { BasicLazyParams } from '../../../utile'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import moment from 'moment'

const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]
class ClosedConcessionApprovals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lazyParams: BasicLazyParams,
            searchType: 1,
            searchInput: "",
            searchLevel: 1,
            searchType: searchTypeOptions[0].value,
        }
        this.debouncedApiCall = debounce(this.getStudentsInfo, 500)
        this.service = new Service();
    }
    handleSetSearchValue = (value) => {
        this.setState({
            searchValue: value,
            dropdownsData: {
                boardId: '',
                branchId: "",
                classId: '',
                sectionId: '',
            }
        }, () => {
            this.debouncedApiCall()

        })
    }
    handleSearchLevel = (value) => {

        this.setState({ searchLevel: value, studentId: "", searchValue: "", studentsData: [] })
    }

    onStudentChange = (e) => {
        this.setState({ studentId: e.value }, () => {
            this.getStudentsInfo()
        })

    }
    getStudentsInfo = () => {
        let { lazyParams, globalSearch, filterData } = this.state;
        this.setState({ isLoading: true, studentInfo: [] })

        const url = `${baseUrlAdmin}/concession-approval/get-closed-concession-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        let academicYear = localStorage.getItem('userAcademicYear')
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : ""
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : ""

        let payload = {
            "branchId": this.state?.dropdownsData?.branchId,
            "boardId": this.state?.dropdownsData?.boardId,
            "gradeId": this.state?.dropdownsData?.classId,
            "sectionId": this.state?.dropdownsData?.sectionId,
            "studentId": this.state?.studentId,
            "academicYear": academicYear,
            "username": this.state.searchLevel == 2 ? username : '',
            "enrollmentNo": enrollmentNo
        }

        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res?.data && res.res?.data?.length) {
                    this.setState({
                        isLoading: false,
                        studentInfo: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
            studentId: null
        }, () => { this.getStudents(); })
    }
    getStudents = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        // let { lazyParams } = this.state;
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/fine/student`
        let payload = {
            "branchId": this.state.dropdownsData?.branchId,
            "boardId": this.state.dropdownsData?.boardId,
            "classId": this.state.dropdownsData?.classId,
            "sectionId": this.state.dropdownsData?.sectionId,
            "academicYear": academicYear
        }
        if (payload?.sectionId) {
            this.service.post(url, payload, true).then(data => {
                // console.log(data, "datastudent")
                const studentsData = data?.res?.data.map(each => {
                    return {
                        label: each?.name,
                        value: each?.user_id
                    }
                })

                if (data?.status) {
                    this.setState({
                        students: studentsData,
                        isLoading: false
                    })

                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }

            }).catch(error => {
                this.setState({ isLoading: false })

            })
        } else {
            this.setState({ isLoading: false });
        }
    }
    handleSetSearchTypeValue = (value) => {

        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true, })
            } else {
                this.setState({ isUserEnrollSearch: true, })
            }

        })
    }


    render() {
        return (
            <div className="ma-main">

                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">

                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BoardToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                        </div>
                    </div>

                    <div className="col-12 ml-5">
                        <label className='label'>Student<span className='ma-required'>*</span></label>
                        <div className='mt-2'>
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id='student'
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                //     onChange={(e) => {this.setState({studentId:e.value}) 
                                //     this.getTermBasedData()

                                // }}
                                onChange={this.onStudentChange}
                                placeholder={(
                                    <div>
                                        <span className=''>Select Student</span>
                                    </div>
                                )}
                            />

                        </div>
                        {/* {formValidations && !formValidations.fields['paymentMode'].isValid && <p className="p-error">{formValidations.fields['paymentMode'].errorMsg}</p>} */}
                    </div>

                </div>
                <DataTable ref={(el) => this.dt = el}
                    value={this.state.studentInfo}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts} 
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >

                    <Column alignHeader={"center"} sortable bodyClassName='text-center' header="Student Name" body={(rowData, { rowIndex }) => {
                        return (
                            <div className="flex justify-content-center">
                                {rowData?.firstName} {rowData?.lastName}
                            </div>
                        )
                    }} ></Column>
                    <Column filterField="enrollmentId" field="enrollmentId" sortable bodyClassName='text-center' header="Enrollment No" alignHeader={"center"} />
                    <Column filterField="contactPersonName" field="contactPersonName" sortable bodyClassName='text-center' header="Contact Person" alignHeader={"center"} />
                    <Column filterField="parentContactNo" field="parentContactNo" sortable bodyClassName='text-center' header="Grade-Section" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                        return (

                            <p>{rowData ? `${rowData?.gradeName} - ${rowData?.sectionName}` : null}</p>
                        )
                    }} />
                    <Column filterField="boardName" field="boardName" sortable bodyClassName='text-center' header="Board" alignHeader={"center"} />
                    <Column filterField="Date" field="" sortable bodyClassName='text-center' header="Date" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                        return (

                            <p>{rowData?.approvedDate ? moment(rowData?.approvedDate).format('DD-MM-YYYY') : null}</p>
                        )
                    }} />
                    <Column alignHeader={"center"} sortable bodyClassName='text-center' header="Concession" body={(rowData, { rowIndex }) => {
                        return (
                            <>
                                {rowData?.isConcessionApproved == true && <div className="cursor-pointer">
                                    <Button className='approve-btn' style={{ backgroundColor: "#7CDD56" }}>Approved</Button>
                                </div>}
                                {rowData?.isConcessionApproved == false &&
                                    <div className="cursor-pointer" >
                                        <Button className='approve-btn' style={{ backgroundColor: "#BF0031" }}>Rejected</Button>
                                    </div>

                                }
                            </>
                        )
                    }} ></Column>
                    <Column alignHeader={"center"} sortable bodyClassName='text-center' header="Details" body={(rowData, { rowIndex }) => {
                        return (
                            <Button id={rowData?._id} onClick={() => this.onHandleViewFeeConcessions(rowData)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary ma-ml10' />
                        )
                    }} ></Column>

                </DataTable>
            </div>
        )
    }
}

export default withRouter(Authentication(ClosedConcessionApprovals))
