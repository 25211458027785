import React, { useState, useEffect, useRef, useMemo, Children } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation, useMatch } from 'react-router-dom';
import Authentication from './session';
import { connect } from 'react-redux';
import Notification from './components/notification';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AssignFeeScreen from './components/financeManagement/asignFee';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import BulkUploadc from './components/contentMaster/bulkUpload';
import FormLayoutDemo from './components/FormLayoutDemo';
import InputDemo from './components/InputDemo';
import FloatLabelDemo from './components/FloatLabelDemo';
import ButtonDemo from './components/ButtonDemo';
import TableDemo from './components/TableDemo';
import ListDemo from './components/ListDemo';
import TreeDemo from './components/TreeDemo';
import PanelDemo from './components/PanelDemo';
import OverlayDemo from './components/OverlayDemo';
import MediaDemo from './components/MediaDemo';
import MenuDemo from './components/MenuDemo';
import MessagesDemo from './components/MessagesDemo';
import FileDemo from './components/FileDemo';
import ChartDemo from './components/ChartDemo';
import MiscDemo from './components/MiscDemo';
import Documentation from './components/Documentation';
import BlocksDemo from './components/BlocksDemo';
import IconsDemo from './utilities/IconsDemo';
import CrudDemo from './pages/CrudDemo';
import CalendarDemo from './pages/CalendarDemo';
import Invoice from './pages/Invoice';
import Help from './pages/Help';
import EmptyPage from './pages/EmptyPage';
import InvalidStateDemo from './components/InvalidStateDemo';
import TimelineDemo from './pages/TimelineDemo';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import './fonts/fonts.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import withRouter from './components/lib/withRouter';
import Service from './components/services';
import Dashboard from './components/dashboard/index';
import Boards from './components/boardsMaster/index';
import Branch from './components/branchMaster/index';
import OrganizationMaster from './components/orgnizationMaster';
import ChequeOrDDStatus from './components/financeManagement/chequeStatus';
import CurriculumMaster from './components/curriculumMaster';
import AssignContent from './components/curriculumMaster/assignContent';
import CalendarMaster from './components/calendarMaster';
import EnquiryFrom from './components/enqiryForm';
import AcadamicYear from './components/admissionsOrganization/academicYears';
import AdmissionType from './components/admissionsOrganization/admissionType';
import ApplicationFee from './components/applicationFee';
import Course from './components/course';
import BusFeePlans from './components/finance/busFeePlans';
import Finance from './components/finance';
import FeeDaySheet from './components/organizationManage/feeDaySheet';
import FeeTypes from './components/organizationManage/feeTypes';
import FeePlan from './components/organizationManage/feePlan';
import VehicleManagement from './components/transport/vehicleManagement';
import TransportStaffManagement from './components/transport/transportStaffManagement';
import RouteManagement from './components/transport/routeManagement';
import TransportAttendance from './components/transport/attendance';
import TransportAttendanceReport from './components/transport/attendanceReport';
import BulkUploadYearlyPlanner from './components/calendarMaster/bulkUpload';
import TimeTable from './components/timeTable';
import QuestionBank from './components/questionBank';
import VirtualClass from './components/virtualClass';
import AssignQuestions from './components/assignQuestions';
import Examinations from './components/examination';
import DiscussionBoard from './components/discussionBoard';
import AskADoubt from './components/askADoubt';
import ContentMaster from './components/contentMaster';
import AdminManagement from './components/adminManagement';
import BulkUploadA from './components/adminManagement/bulkUpload';
import ClassRoom from './components/classroom';
import Attendance from './components/attendance';
import BulkAttendance from './components/attendance/bulkAttendance';
import AttendaceReport from './components/attendance/attendaceReport';
import UsageAnalytics from './components/analytics/usageAnalytics/index';
import ExamAnalytics from './components/analytics/examAnalytics';
import TeacherAnalytics from './components/analytics/teacherAnalytics';
import BranchWiseAnalytics from './components/analytics/usageAnalytics/branchWiseAnalytics';
import UserManagement from './components/userManagement';
import TicketManagement from './components/ticketManagement';
import BulkUpload from './components/userManagement/bulkUpload';

import TransportIndex from './components/TransportManagement/index';
import AdminManagementIndex from './components/admissionNewManagement/index';
import SchoolSetupIndex from './components/schoolSetup/index';
import Acadamics from './components/academics';
import FinanceNewIndex from './components/financeNew/index';
import UnAssignFee from './components/financeManagement/unAssignFee';
import TimeTableNew from './components/TimeTableNew/timeTable';
import RequestSubstitution from './components/TimeTableNew/Request Substitution/requestSubstitutionTable';
import Enquiry from './components/Admissions/Enquiry/index';
import AdmissionApplication from './components/Admissions/Application';

import AddEnquiry from './components/Admissions/Enquiry/enquiryAdd';
import ApplicationCreate from './components/Admissions/Application/application';

import AdmissionExam from './components/Admissions/Exam';
import ExamCreate from './components/Admissions/Exam/exam';

import RolesAndUsers from './components/Roles&Users/index';
import AcademicYears from './components/admissionsOrganization/academicYears';
import FeeSetUpIndex from './components/feeSetUp';
import StudentInfo from './components/studentManagement/studentInformation';
import StudentAttendance from './components/studentManagement/attendance';
import StudentTransfer from './components/studentManagement/studentTransfer';
import TransferPendingStudents from './components/studentManagement/transferPendingStudents';
import CollectFee from './components/financeManagement/collectFee';
import TeacherDairyTemplate from './components/ClassetAcademicsModule/Teacher';
import TeacherDairyFormat from './components/ClassetAcademicsModule/TeacherDairy';

import AcademicCalendar from './components/ClassetAcademicsModule/AcademicCalendar';
import FeeConcession from './components/financeManagement/feeConcession';
import CreateTest from './components/ClassetAcademicsModule/Examination/createTest/index';
import CreateTestType from './components/ClassetAcademicsModule/Examination/createTestType/index';
import DigitalLibrary from './components/ClassetAcademicsModule/DigitalLibrary';
import CreateTemplate from './components/ClassetAcademicsModule/Examination/createTemplate/index';
import TestsandExam from './components/ClassetAcademicsModule/Examination/testsExam/index';
import NewQuestionBank from './components/ClassetAcademicsModule/Examination/questionBank/index';

import UsersBulkUpload from './components/studentManagement/bulkUpload';
import AdmissionRegistration from './components/Admissions/Registration';
import CreateRegistration from './components/Admissions/Registration/registration';
import Axios from 'axios';
import { baseUrlAdmin } from './store/apiConstants';
import { icon } from '@fortawesome/fontawesome-svg-core';
import ComNotification from './components/classetCommunication';
import SettingsApprovals from './components/settingsApprovals/statusChange';
import SettingsApprovalsStudentTransfer from './components/settingsApprovals/studentTransfer';
import SettingsApprovalsFeeConcession from './components/settingsApprovals/feeConcession';
import SettingsApprovalsFeeRefund from './components/settingsApprovals/feeRefund';
import AdmissionEnrollment from './components/Admissions/Enrollment';
import ConfirmEnrollment from './components/Admissions/Enrollment/enrollment';
import SettingsApprovalsFeeUnassign from './components/settingsApprovals/feeUnassign';
import StudentStatusChange from './components/studentManagement/studentStatusChange';
import StudentStatusChangeApprovalTabs from './components/classetApprovals/studentStatucChange';
import { menuItems } from './menuItems';
import StaffAllocationIndex from './components/staffMnagement/index';
import FeeConcessionApproval from './components/approvalManagement/feeConcessionApproval';
import ClosedConcessionApprovals from './components/approvalManagement/feeConcessionApproval/closedConcessionApprovals';
import FeeRefundApproval from './components/approvalManagement/feeRefundApproval';
import ClosedRefundApprovals from './components/approvalManagement/feeRefundApproval/closedRefundApprovals';
import FeeUnassignApprovals from './components/approvalManagement/feeUnassignApproval';
import ClosedUnassignApprovals from './components/approvalManagement/feeUnassignApproval/closedUnassignApprovals';
import AssignTransportTable from './components/menuTransport/AssignTransport/assignTransportTable';
import FeeReceivables from './components/menuTransport/FeeReceivables/feeReceivables';
import MenuRouteManagement from './components/menuTransport/menuRouteManagement/menuRouteManagement';
import SettingsApprovalsAssignTransport from './components/settingsApprovals/assignTransport';
import SettingsApprovalsTransportRoute from './components/settingsApprovals/transportRoute';
import AssignTransport from './components/approvalManagement/assignTransport/assignTransport';
import TransportRouteTabs from './components/approvalManagement/TransportRoute';
import SettingsTimeTable from './components/settingsTimeTable';
import OrganizationSetupIndex from './components/organizationSetup';
import CreateOrg from './components/organizationSetup/createOrganization';
import GeneralSettings from './components/feeSetUp/GeneralSettings.js/generalSettings';
import FeeRefund from './components/financeManagement/feeRefund';
const academicYearsOptions = [
    { finYear: '2022-2023', value: '2022-2023' },
    { finYear: '2021-2022', value: '2021-2022' },
    { finYear: '2020-2021', value: '2020-2021' }
];

let timer1;
const App = (props) => {
    //console.log(props)
    //console.log(loc.state.level, 'name')

    const [menuActive, setMenuActive] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [academicYearOptions, setAcademicYearOptions] = useState([]);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState('white');
    const [menuMode, setMenuMode] = useState('slim');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-white');
    const [componentTheme, setComponentTheme] = useState('blue');
    const [logoUrl, setLogoUrl] = useState('./macademy-logo2.jpg');
    const [admissionSettingsInfo, setadmissionSettingsInfo] = useState(null);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [logged, setLogged] = useState(location.state?.level);
    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;
    const breadcrumb = [
        { path: '/', parent: 'Dashboard', label: 'Dashboard' },
        { path: '/organization', parent: 'Organization', label: 'Organization Master' },
        { path: '/boards', parent: 'Boards', label: 'Boards List' },
        { path: '/branch', parent: 'Branch Master', label: 'Branch Master' },
        { path: '/admission-create', parent: 'Admission', label: 'Admission Create' },
        { path: '/academic-years', parent: 'Admission', label: 'Academic Years' },
        { path: '/admission-types', parent: 'Admission', label: 'Admission Types' },
        { path: '/application-fees', parent: 'Admission', label: 'Application Fees' },
        { path: '/admission-courses', parent: 'Admission', label: 'Admission Courses' },
        { path: '/bus-fee-plans', parent: 'Finance', label: 'Bus Fee plans' },
        { path: '/fee-collect', parent: 'Finance', label: 'Fee Collect' },
        { path: '/fee-day-sheet', parent: 'Finance', label: 'Fee Day Sheet' },
        { path: '/fee-types', parent: 'Finance', label: 'Fee Types' },
        { path: '/fee-plans', parent: 'Finance', label: 'Fee Plans' },
        { path: '/vehicle-management', parent: 'Transport', label: 'Vehicle Management' },
        { path: '/transport-staff-management', parent: 'Transport', label: 'Transport Staff Management' },
        { path: '/route-management', parent: 'Transport', label: 'Route Management' },
        { path: '/transport-attendance', parent: 'Transport', label: 'Transport Attendence' },
        { path: '/transport-attendance-report', parent: 'Transport', label: 'Transport Attendence Report' },
        { path: '/approvals-assigntransport', parent: 'Approvals', label: 'Assign Transports' },
        { path: '/approvals-transportroute', parent: 'Approvals', label: 'Transport Route' },

        { path: '/curriculum', parent: 'CurriculumMaster', label: 'Curriculum Master' },
        { path: '/assign-content', parent: 'Assign Content', label: 'Assign Content' },
        { path: '/assign-content', parent: 'Assign Content', label: 'Assign Content' },
        { path: '/content', parent: 'Contetn master', label: 'Content Master' },
        { path: '/question-bank', parent: 'Examination', label: 'Question Bank' },
        { path: '/assign-question', parent: 'Examination', label: 'Assign Questions' },
        { path: '/examination', parent: 'Examination', label: 'Exams' },
        { path: '/discussion-board', parent: 'Discussion Board', label: 'Discussion Board' },
        { path: '/askadoubt', parent: 'Ask a Doubt', label: 'Ask a Doubt' },
        { path: '/rolesAndUsers', parent: 'RolesAndUsers', label: 'RolesAndUsers' },
        { path: '/transportManagement', parent: 'Transport Management', label: 'Transport Management' },
        { path: '/admin-manage', parent: 'Admin Management', label: 'Admin Management' },
        { path: '/admins-bulkupload', parent: 'Admin Management', label: 'Bulk Upload' },
        { path: '/finance', parent: 'Settings', label: 'Finance' },
        { path: '/UnAssignFee', parent: 'Finance', label: 'UnAssignFee' },
        { path: '/staff', parent: 'Settings', label: 'Staff Management' },

        { path: '/formlayout', parent: 'UI Kit', label: 'Form Layout' },
        { path: '/input', parent: 'UI Kit', label: 'Input' },
        { path: '/floatlabel', parent: 'UI Kit', label: 'Float Label' },
        { path: '/invalidstate', parent: 'UI Kit', label: 'Invalid State' },
        { path: '/button', parent: 'UI Kit', label: 'Button' },
        { path: '/table', parent: 'UI Kit', label: 'Table' },
        { path: '/list', parent: 'UI Kit', label: 'List' },
        { path: '/tree', parent: 'UI Kit', label: 'Tree' },
        { path: '/panel', parent: 'UI Kit', label: 'Panel' },
        { path: '/overlay', parent: 'UI Kit', label: 'Overlay' },
        { path: '/media', parent: 'UI Kit', label: 'Media' },
        { path: '/menu', parent: 'UI Kit', label: 'Menu' },
        { path: '/menu/seat', parent: 'UI Kit', label: 'Menu' },
        { path: '/menu/payment', parent: 'UI Kit', label: 'Menu' },
        { path: '/menu/confirmation', parent: 'UI Kit', label: 'Menu' },
        { path: '/messages', parent: 'UI Kit', label: 'Messages' },
        { path: '/file', parent: 'UI Kit', label: 'File' },
        { path: '/chart', parent: 'UI Kit', label: 'Charts' },
        { path: '/misc', parent: 'UI Kit', label: 'Misc' },
        { path: '/icons', parent: 'Utilities', label: 'Icons' },
        { path: '/blocks', parent: 'PrimeBlocks', label: 'Blocks' },
        { path: '/crud', parent: 'Utilities', label: 'Crud' },
        { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
        { path: '/timeline', parent: 'Pages', label: 'Timeline' },
        { path: '/invoice', parent: 'Pages', label: 'Invoice' },
        { path: '/help', parent: 'Pages', label: 'Help' },
        { path: '/empty', parent: 'Pages', label: 'Empty Page' },
        { path: '/documentation', parent: 'Pages', label: 'Documentation' },

        { path: '/', parent: 'PrimeBlocks', label: 'Dashboard' },
        { path: '/newusers', parent: 'PrimeBlocks', label: 'New users' },
        { path: '/users', parent: 'PrimeBlocks', label: 'Users' },
        { path: '/parents', parent: 'PrimeBlocks', label: 'Parents' },
        { path: '/leaves', parent: 'PrimeBlocks', label: 'Leaves' },
        { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
        { path: '/user-management', parent: 'PrimeBlocks', label: 'User ManageMent' },
        { path: '/user-bulkupload', parent: 'PrimeBlocks', label: 'Bulk Upload' },
        { path: '/ticket-management', parent: 'PrimeBlocks', label: 'Ticket Management' },
        { path: '/admissionManagement', parent: 'Home', label: 'Admission' },
        { path: '/schoolSetup', parent: 'Home', label: 'School Setup' },
        { path: '/notifications', parent: 'PrimeBlocks', label: 'Notification Management' },
        { path: '/feesetup', parent: 'Home', label: 'Finance' },
        { path: '/financeGeneralSettings', parent: 'Home', label: 'Finance General Settings' },

        { path: '/acadamics', parent: 'Home', label: 'Academics' },

        { path: '/studentInfo', parent: 'Students', label: 'Student Information' },
        { path: '/userBulkupload', parent: 'Students', label: 'Student BulkUpload' },

        { path: '/attendance', parent: 'Students', label: 'Attendance' },
        { path: '/studentTransfer', parent: 'Students', label: 'Student Transfer' },
        { path: '/transferPendingStudents', parent: 'Students', label: 'Transfer Pending Students' },

        { path: '/TeacherDairyTemplate', parent: 'Academics', label: 'Teacher Dairy Template' },
        { path: '/AcademicCalendar', parent: 'Academics', label: 'AcademicCalendar' },
        { path: '/TeacherDairy', parent: 'Academics', label: 'Teacher Dairy' },
        { path: '/DigitalLibrary', parent: 'Academics', label: 'Digital Library' },
        { path: '/CreateTest', parent: 'Examination', label: 'Create Test' },
        { path: '/QuestionBank', parent: 'Examination', label: 'Question Bank' },
        { path: '/CreateTestType', parent: 'Examination', label: 'Create Test Type' },
        { path: '/CreateTemplate', parent: 'Examination', label: 'Create Template' },
        { path: '/TestsandExams', parent: 'Examination', label: 'Tests and Exams' },

        { path: '/GradingSystem', parent: 'Prograss Reports', label: 'Grading System' },
        { path: '/PerformanceAnalytics', parent: 'Prograss Reports', label: 'Performance Analytics' },
        { path: '/GradeBookTemplate', parent: 'Prograss Reports', label: 'Grade Book Template' },
        { path: '/GradeBook', parent: 'Prograss Reports', label: 'Grade Book' },
        { path: '/ApprovalHierarchy', parent: 'Academics', label: 'Approval Hierarchy' },
        { path: '/Reports', parent: 'Academics', label: 'Reports' },
        { path: '/myTimeTable', parent: 'Timetable', label: 'My Timetable' },
        { path: '/requestSubstitution', parent: 'Timetable', label: 'Request Substitution' },
        { path: '/lagReport', parent: 'Timetable', label: 'Lag Report' },
        { path: '/timeTableReports', parent: 'Timetable', label: 'TimeTable Reports' },
        { path: '/enquiry', parent: 'Admissions', label: 'New Enquiry Form' },
        { path: '/addEnquiry', parent: 'Enquiry', label: 'Add Enquiry' },
        { path: '/application', parent: 'Enquiry', label: 'Application' },
        { path: '/collectFee', parent: 'Finance', label: 'CollectFee' },
        { path: '/assignFee', parent: 'Finance', label: 'AssignFee' },
        { path: '/feeConcession', parent: 'Finance', label: 'Concession' },
        { path: '/feeRefund', parent: 'Finance', label: 'Refund' },

        { path: '/admission-application', parent: 'Admissions', label: 'Application' },
        { path: '/communicate-notification', parent: 'Communicate', label: 'Notification' },
        { path: '/approval-hierarchy-status-change', parent: 'Approval Hierarchy', label: 'Status Change' },
        { path: '/approval-hierarchy-student-transfer', parent: 'Approval Hierarchy', label: 'Student Transfer' },
        { path: '/approval-hierarchy-fee-concession', parent: 'Approval Hierarchy', label: 'Fee Concession' },
        { path: '/approval-hierarchy-assign-transport', parent: 'Approval Hierarchy', label: 'Assign Transport' },
        { path: '/approval-hierarchy-transport-route', parent: 'Approval Hierarchy', label: 'Transport Route' },
        { path: '/approval-hierarchy-fee-unassign', parent: 'Approval Hierarchy', label: 'Fee Unassign' },
        { path: '/approval-hierarchy-fee-refund', parent: 'Approval Hierarchy', label: 'Fee Refund' },
        { path: '/admission-exam', parent: 'Admissions', label: 'Exam' },
        { path: '/addExam', parent: 'Exam', label: 'Add Exam' },
        { path: '/admission-registration', parent: 'registration', label: 'Registration Form' },
        { path: '/addRegistration', parent: 'registration', label: 'Add Registration' },
        { path: '/admission-enrollment', parent: 'enrollment', label: 'Enrollment' },
        { path: '/confirm-enrollment', parent: 'enrollment', label: 'Confirm Enrollment' },
        { path: '/approvals-fee-concession', parent: 'Approvals', label: 'Fee concession' },
        { path: '/approvals-fee-refund', parent: 'Approvals', label: 'Fee Refund' },
        { path: '/approvals-Fee-unassign', parent: 'Approvals', label: 'Fee Unassign' },
        { path: '/approvals-studentTransfer', parent: 'Approvals', label: 'Student Transfer' },
        { path: '/approvals-studentstatuschange', parent: 'Approvals', label: 'Student Status Change' },
        { path: '/studentStatusChange', parent: 'Student', label: 'Student Status Change' },
        { path: '/closedConcessionApprovals', parent: 'Approvals', label: 'Fee Concession Report' },
        { path: '/closedRefundApprovals', parent: 'Approvals', label: 'Fee Refund Report' },
        { path: '/closedUnassignApprovals', parent: 'Approvals', label: 'Fee Unassign Report' },
        { path: '/assigntransport', parent: 'Transport', label: 'Assign Transport' },
        { path: '/transportfeereceivables', parent: 'Transport', label: 'Transport Fee Receivables' },
        { path: '/routemanagement', parent: 'Transport', label: 'Route Management' },
        { path: '/settimetable', parent: 'Settings', label: 'Time Table' },
        { path: '/organisation-setup', parent: 'Organization', label: 'Organization Master' },
        { path: '/create-organization', parent: 'Organization', label: 'Create Organization' }
    ];

    let menu = [];

    let meta = breadcrumb.find((obj) => {
        return obj.path === location.pathname;
    });

    const handleAcadamicsYearsData = async () => {
        const year = localStorage.getItem('userAcademicYear');
        let isAllAcademicYears = localStorage.getItem('isAllAcademicYears');
        const p = localStorage.getItem('assignedAcademicYears') || '';
        const assignedAcademicYears = p?.split(',') || [];
        console.log(assignedAcademicYears);
        let url = `${baseUrlAdmin}/academic-years/`;
        return await new Service()
            .get(url, true)
            .then((res) => {
                if (res?.res?.status && res.status) {
                    let aa;
                    if (isAllAcademicYears == 'false' && assignedAcademicYears.length > 0) {
                        aa = res?.res?.data?.filter((item) => assignedAcademicYears.includes(item._id));
                    } else {
                        aa = res?.res?.data;
                    }
                    if (isAllAcademicYears == null) {
                        handleAcadamicsYearsData();
                    }
                    setAcademicYearOptions(aa);
                    // only of empty key in localstorage
                    setSelectedOption(aa[0]._id);
                    if (year !== null && year !== '' && year != undefined) {
                        localStorage.setItem('userAcademicYear', year);
                    } else {
                        localStorage.setItem('userAcademicYear', aa[0]._id);
                    }
                } else {
                    setAcademicYearOptions([]);
                }
            })
            .catch((e) => {
                console.log(e);
                setAcademicYearOptions([]);
            });
    };

    const getAdmissionSettingsInfo = async () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        await new Service()
            .get(url, true)
            .then((res) => {
                if (res?.res?.status && res.status) {
                    setadmissionSettingsInfo(res?.res?.data);
                } else {
                    setadmissionSettingsInfo(null);
                }
            })
            .catch((e) => {
                console.log(e);
                setadmissionSettingsInfo(null);
            });
    };

    const handleMenuItems = () => {
        let p = localStorage.getItem('perms');
        let perms = JSON.parse(p)?.flat();
        if (perms?.length) {
            for (let item of menuItems) {
                let flag = perms?.some((obj) => obj?.groupName?.toLowerCase() === item?.label?.toLowerCase());
                if (flag) {
                    let onePerm = perms.find((obj) => obj?.groupName?.toLowerCase() === item?.label?.toLowerCase());
                    let IItems = [];
                    if (item?.items?.length) {
                        for (let internalItem of item?.items) {
                            let itemsInInternalItem = [];
                            let flag2 = onePerm?.permissions?.some((obj) => obj.category.toLowerCase() === internalItem?.label?.toLowerCase());
                            if (flag2) {
                                if (internalItem?.items?.length) {
                                    let IItemOnePerm = onePerm?.permissions?.filter((obj) => obj.category.toLowerCase() === internalItem?.label?.toLowerCase());
                                    internalItem?.items?.filter((ii) => {
                                        let flag3 = IItemOnePerm?.some((obj) => obj.name.includes(ii?.label?.replace('#', '')?.trim()));
                                        if (flag3) {
                                            itemsInInternalItem.push(ii);
                                        }
                                    });
                                    internalItem.items = itemsInInternalItem;
                                    IItems.push(internalItem);
                                } else {
                                    IItems.push(internalItem);
                                }
                            }
                        }
                    }
                    if (IItems.length) {
                        item.items = IItems;
                    } else {
                        delete item.items;
                    }
                    if (admissionSettingsInfo && item?.label === 'Admissions') {
                        item.items = item?.items?.filter((item) => {
                            const keyMap = {
                                Enquiry: 'enquiry',
                                Application: 'application',
                                'Exam/Test': 'examTest',
                                Registration: 'registration',
                                Enrollment: 'enrollment'
                            };
                            const key = keyMap[item.label];
                            let date = new Date();
                            if (date >= new Date(admissionSettingsInfo?.fromDate) && date <= new Date(admissionSettingsInfo?.toDate)) {
                                return admissionSettingsInfo[key] === true;
                            }
                        });
                    }

                    menu.push(item);
                    menu.push({ separator: true });
                }
            }
        }
        setMenuData(menu);
        // setMenuData(menuItems);
    };

   
    useEffect(() => {
        const fetchData = async () => {
            await handleAcadamicsYearsData();
        };

        fetchData();
    }, []);
    useEffect(() => {
        getAdmissionSettingsInfo();
    }, [academicYearOptions]);

    useEffect(() => {
        const perms = localStorage.getItem('perms');

        if (perms || (admissionSettingsInfo && admissionSettingsInfo.someProperty)) {
            handleMenuItems();
        }
    }, [admissionSettingsInfo, localStorage.getItem('perms')]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
        // if (location.state.level == 2) {
        //     setLogged(true)
        // }
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    // const admin = () => {
    //     setMenuActive1(true);
    //     props?.user?.user.username == 'superadmin' && menuActive1 == true ? { label: 'CREATE USER', icon: 'pi pi-fw pi-user-plus', to: '/create-user' } : null

    // };

    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme);

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `macademy-logo2.jpg`;
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        const horizontalLogoUrl = `macademy-logo2.jpg`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        if (horizontalLogoLink) {
            horizontalLogoLink.src = horizontalLogoUrl;
        }
        setLogoColor(logoColor);
    };

    const changeComponentTheme = (theme) => {
        setComponentTheme(theme);
        changeStyleSheetUrl('theme-css', theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);

        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById('app-logo');
        const mobileLogoLink = document.getElementById('logo-mobile');
        const invoiceLogoLink = document.getElementById('invoice-logo');
        const footerLogoLink = document.getElementById('footer-logo');
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        setLogoUrl(`macademy-logo2.jpg`);

        if (appLogoLink) {
            appLogoLink.src = `macademy-logo.jpg`;
        }

        if (horizontalLogoLink) {
            horizontalLogoLink.src = `macademy-logo.jpg`;
        }

        if (mobileLogoLink) {
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
        } else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false);
        }
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive((prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive((prevSearchActive) => !prevSearchActive);
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive((prevRightMenuActive) => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const onChangeDropDownOption = (value) => {
        localStorage.setItem('userAcademicYear', value);
        setSelectedOption(value);
        window.location.reload();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 1091;
    };

    const containerClassName = classNames(
        'layout-wrapper',
        {
            'layout-overlay': menuMode === 'overlay',
            'layout-static': menuMode === 'static',
            'layout-slim': menuMode === 'slim',
            'layout-horizontal': menuMode === 'horizontal',
            'layout-sidebar-dim': colorScheme === 'dim',
            'layout-sidebar-dark': colorScheme === 'dark',
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        colorScheme === 'light' ? menuTheme : ''
    );

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            {/* <InitialComponent /> */}
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-content-wrapper">
                <AppTopbar
                    meta={breadcrumb}
                    topbarNotificationMenuActive={topbarNotificationMenuActive}
                    topbarUserMenuActive={topbarUserMenuActive}
                    onMenuButtonClick={onMenuButtonClick}
                    academicYearOptions={academicYearOptions}
                    selectedOption={selectedOption}
                    onChangeDropOption={onChangeDropDownOption}
                    onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick}
                    onTopbarUserMenu={onTopbarUserMenuButtonClick}
                    onRightMenuClick={onRightMenuButtonClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    menuMode={menuMode}
                    menu={menuData}
                    menuActive={menuActive}
                    staticMenuMobileActive={staticMenuMobileActive}
                    onMenuClick={onMenuClick}
                    onMenuitemClick={onMenuitemClick}
                    onRootMenuitemClick={onRootMenuitemClick}
                ></AppTopbar>

                <div style={{ backgroundColor: 'white' }} className="layout-content overflow-auto">
                    <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                        <AppBreadcrumb meta={breadcrumb} />
                    </div>
                    <Routes>
                        {/* <Route path="/" element={<Dashboard/>} /> */}
                        <Route path="/boards" element={<Boards />} />
                        {/* <Route path="/" element={<TableDemo />} /> */}
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/branch" element={<Branch />} />
                        <Route exact path="/organization" element={<OrganizationMaster />} />
                        <Route exact path="/admission-create" element={<EnquiryFrom />} />
                        <Route exact path="/academic-years" element={<AcadamicYear />} />
                        <Route exact path="/admission-types" element={<AdmissionType />} />
                        <Route exact path="/application-fees" element={<ApplicationFee />} />
                        <Route exact path="/admission-courses" element={<Course />} />
                        <Route exact path="/bus-fee-plans" element={<BusFeePlans />} />
                        <Route exact path="/fee-collect" element={<Finance />} />
                        <Route exact path="/fee-day-sheet" element={<FeeDaySheet />} />
                        <Route exact path="/fee-types" element={<FeeTypes />} />
                        <Route exact path="/fee-plans" element={<FeePlan />} />
                        <Route exact path="/vehicle-management" element={<VehicleManagement />} />
                        <Route exact path="/transport-staff-management" element={<TransportStaffManagement />} />
                        <Route exact path="/route-management" element={<RouteManagement />} />
                        <Route exact path="/transport-attendance" element={<TransportAttendance />} />
                        <Route exact path="/transport-attendance-report" element={<TransportAttendanceReport />} />
                        <Route path="/curriculum" element={<CurriculumMaster />} />
                        <Route path="/assign-content" element={<AssignContent />} />
                        <Route path="/yearlyplanner" element={<CalendarMaster />} />
                        <Route exact path="/curriculum" element={<CurriculumMaster />} />
                        <Route exact path="/assign-content" element={<AssignContent />} />
                        <Route exact path="/content" element={<ContentMaster />} />
                        <Route exact path="/content-bulkupload" element={<BulkUploadc />} />
                        <Route exact path="/yearlyplanner" element={<CalendarMaster />} />
                        <Route exact path="/yearlyplanner-bulk" element={<BulkUploadYearlyPlanner />} />
                        <Route exact path="/timetable" element={<TimeTable />} />
                        <Route exact path="/question-bank" element={<QuestionBank />} />
                        <Route exact path="/examination" element={<Examinations />} />
                        <Route exact path="/assign-question" element={<AssignQuestions />} />
                        <Route exact path="/virtualclass" element={<VirtualClass />} />
                        <Route exact path="/discussion-board" element={<DiscussionBoard />} />
                        <Route exact path="/askadoubt" element={<AskADoubt />} />
                        <Route exact path="/admin-manage" element={<AdminManagement />} />
                        <Route exact path="/admins-bulkupload" element={<BulkUploadA />} />
                        <Route exact path="/classroom" element={<ClassRoom />} />
                        <Route exact path="/give-attendance" element={<Attendance />} />
                        <Route exact path="/upload-attendance" element={<BulkAttendance />} />
                        <Route exact path="/attendance-report" element={<AttendaceReport />} />
                        <Route exact path="/usage-analytics" element={<UsageAnalytics />} />
                        <Route exact path="/exam-analytics" element={<ExamAnalytics />} />
                        <Route exact path="/teacher-analytics" element={<TeacherAnalytics />} />
                        <Route exact path="/branch-analytics" element={<BranchWiseAnalytics />} />
                        <Route exact path="/user-management" element={<UserManagement />} />
                        <Route exact path="/user-bulkupload" element={<BulkUpload />} />
                        <Route exact path="/ticket-management" element={<TicketManagement />} />
                        <Route exact path="/notifications" element={<Notification />} />
                        <Route exact path="/transportManagement" element={<TransportIndex />} />
                        <Route exact path="/admissionManagement" element={<AdminManagementIndex />} />
                        <Route exact path="/schoolSetup" element={<SchoolSetupIndex />} />
                        <Route exact path="/rolesAndUsers" element={<RolesAndUsers />} />
                        <Route exact path="/chequeOrDDstatus" element={<ChequeOrDDStatus />} />
                        <Route exact path="/feeSetUp" element={<FeeSetUpIndex />} />
                        <Route exact path="/financeGeneralSettings" element={<GeneralSettings />} />

                        <Route exact path="/acadamics" element={<Acadamics />} />
                        <Route exact path="/finance" element={<FinanceNewIndex />} />
                        <Route exact path="/UnAssignFee" element={<UnAssignFee />} />
                        <Route exact path="/myTimeTable" element={<TimeTableNew />} />
                        <Route exact path="/requestSubstitution" element={<RequestSubstitution />} />

                        <Route exact path="/studentInfo" element={<StudentInfo />} />
                        <Route exact path="/attendance" element={<StudentAttendance />} />
                        <Route exact path="/studentTransfer" element={<StudentTransfer />} />
                        <Route exact path="/transferPendingStudents" element={<TransferPendingStudents />} />
                        <Route exact path="/collectFee" element={<CollectFee />} />
                        <Route exact path="/assignFee" element={<AssignFeeScreen />} />

                        <Route exact path="/TeacherDairyTemplate" element={<TeacherDairyTemplate />} />

                        <Route exact path="/TeacherDairy" element={<TeacherDairyFormat />} />

                        <Route exact path="/AcademicCalendar" element={<AcademicCalendar />} />
                        <Route exact path="/DigitalLibrary" element={<DigitalLibrary />} />
                        <Route exact path="/feeConcession" element={<FeeConcession />} />
                        <Route exact path="/feeRefund" element={<FeeRefund />} />

                        <Route exact path="/CreateTest" element={<CreateTest />} />
                        <Route exact path="/CreateTestType" element={<CreateTestType />} />
                        <Route exact path="/CreateTemplate" element={<CreateTemplate />} />
                        <Route exact path="/TestsandExams" element={<TestsandExam />} />
                        <Route exact path="/QuestionBank" element={<NewQuestionBank />} />
                        <Route exact path="/enquiry" element={<Enquiry />} />
                        <Route exact path="/admission-application" element={<AdmissionApplication />} />
                        <Route exact path="/admission-exam" element={<AdmissionExam />} />
                        <Route exact path="/addEnquiry" element={<AddEnquiry />} />
                        <Route exact path="/application" element={<ApplicationCreate />} />
                        <Route exact path="/addExam" element={<ExamCreate />} />
                        <Route exact path="/userBulkupload" element={<UsersBulkUpload />} />

                        <Route exact path="/communicate-notification" element={<ComNotification />} />
                        <Route exact path="/approval-hierarchy-status-change" element={<SettingsApprovals />} />
                        <Route exact path="/approval-hierarchy-student-transfer" element={<SettingsApprovalsStudentTransfer />} />
                        <Route exact path="/approval-hierarchy-fee-concession" element={<SettingsApprovalsFeeConcession />} />
                        <Route exact path="/approval-hierarchy-assign-transport" element={<SettingsApprovalsAssignTransport />} />
                        <Route exact path="/approval-hierarchy-transport-route" element={<SettingsApprovalsTransportRoute />} />
                        <Route exact path="/approval-hierarchy-fee-refund" element={<SettingsApprovalsFeeRefund />} />
                        <Route exact path="/approval-hierarchy-fee-unassign" element={<SettingsApprovalsFeeUnassign />} />
                        <Route exact path="/admission-registration" element={<AdmissionRegistration />} />
                        <Route exact path="/addRegistration" element={<CreateRegistration />} />
                        <Route exact path="/admission-enrollment" element={<AdmissionEnrollment />} />
                        <Route exact path="/confirm-enrollment" element={<ConfirmEnrollment />} />
                        <Route exact path="/studentStatusChange" element={<StudentStatusChange />} />
                        <Route exact path="/approvals-studentstatuschange" element={<StudentStatusChangeApprovalTabs />} />
                        <Route exact path="/staff" element={<StaffAllocationIndex />} />
                        <Route exact path="/closedConcessionApprovals" element={<ClosedConcessionApprovals />} />
                        <Route exact path="/approvals-fee-concession" element={<FeeConcessionApproval />} />
                        <Route exact path="/approvals-fee-refund" element={<FeeRefundApproval />} />
                        <Route path="/approvals-assigntransport" element={<AssignTransport />} />
                        <Route path="/approvals-transportroute" element={<TransportRouteTabs />} />
                        <Route exact path="/closedRefundApprovals" element={<ClosedRefundApprovals />} />
                        <Route exact path="/approvals-Fee-unassign" element={<FeeUnassignApprovals />} />
                        <Route exact path="/closedUnassignApprovals" element={<ClosedUnassignApprovals />} />
                        <Route path="/assigntransport" element={<AssignTransportTable />} />
                        <Route path="/transportfeereceivables" element={<FeeReceivables />} />
                        <Route path="/routemanagement" element={<MenuRouteManagement />} />
                        <Route path="/settimetable" element={<SettingsTimeTable />} />
                        <Route path="/organisation-setup" element={<OrganizationSetupIndex />} />
                        <Route path="/create-organization" element={<CreateOrg />} />
                        {/* <Route path="/formlayout" element={<FormLayoutDemo />} /> */}
                        {/* <Route path="/formlayout" element={<FormLayoutDemo />} />
                        <Route path="/input" element={<InputDemo />} />
                        <Route path="/floatlabel" element={<FloatLabelDemo />} />
                        <Route path="/invalidstate" element={<InvalidStateDemo />} />
                        <Route path="/button" element={<ButtonDemo />} />
                        <Route path="/table" element={<TableDemo />} />
                        <Route path="/list" element={<ListDemo />} />
                        <Route path="/tree" element={<TreeDemo />} />
                        <Route path="/panel" element={<PanelDemo />} />
                        <Route path="/overlay" element={<OverlayDemo />} />
                        <Route path="/media" element={<MediaDemo />} />
                        <Route path="/menu/*" element={<MenuDemo />} />
                        <Route path="/messages" element={<MessagesDemo />} />
                        <Route path="/file" element={<FileDemo />} />
                        <Route path="/chart" element={<ChartDemo colorMode={colorScheme} location={location} />} />
                        <Route path="/misc" element={<MiscDemo />} />
                        <Route path="/icons" element={<IconsDemo />} />
                        <Route path="/crud" element={<CrudDemo />} />
                        <Route path="/blocks" element={<BlocksDemo />} />
                        <Route path="/calendar" element={<CalendarDemo />} />
                        <Route path="/timeline" element={<TimelineDemo />} />
                        <Route path="/invoice" element={<Invoice logoUrl={logoUrl} location={location} />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/empty" element={<EmptyPage />} />
                        <Route path="/documentation" element={<Documentation />} /> */}
                    </Routes>
                </div>

                <AppFooter />
            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            <AppConfig
                configActive={configActive}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                colorScheme={colorScheme}
                changeColorScheme={changeColorScheme}
                menuTheme={menuTheme}
                changeMenuTheme={changeMenuTheme}
                componentTheme={componentTheme}
                changeComponentTheme={changeComponentTheme}
                onConfigClick={onConfigClick}
                onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
            ></AppConfig>

            {/* <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} /> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    authenticated: state?.user?.authenticated,
    isLoading: state?.orgData?.isLoading,
    isSuperOrg: state.orgData.isSuper
});

export default connect(mapStateToProps, {})(Authentication(withRouter(App)));
