import React, { Component } from "react"
import { connect } from 'react-redux';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import cloneDeep from 'lodash/cloneDeep';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import _ from 'lodash';
import { AddIcon2, MailIcon, WarningIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask } from './../../../store/apiConstants';
import { BreadCrumb } from 'primereact/breadcrumb';
import CreateFeeType from "../../organizationManage/feeTypes/createFeeType";
import { MultiSelect } from "primereact/multiselect";
import withRouter from '../../lib/withRouter';
import { AnalyticsIcon2 } from '../../svgIcons';
import moment from 'moment';
import InputTextB from '../../customComponents/inputTextB';
import { userAssignedBoards } from './../../../store/selectors/userAssignedBoards';
import SearchBar from "./searchBar";
import './styles.scss'
import BranchToSectionMultiDD from "../../BaseDropdownComponents/BranchToSectionMultiDD";
import BranchToSectionMultiDDPreFill from "../../BaseDropdownComponents/BranchToSectionMultiDDPreFill";

class StudentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: [
            ],
            filterData: null,

            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],

            isUserNameSearch: false,
            username: '',
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: [],
            boards: [],
            classes: [],
            groups: [],
            sections: [],
            branchLevels: {},
            lazyParams: BasicLazyParams,
            users: [],
            selectedUser: [],
            globalSearch: '',
            totalRecords: 0,
            dropdownsData: {}
        };
        this.service = new Service();

    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }



    filterUsersData = (dropdownsData) => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const payload = {
            sectionId: this.state?.dropdownsData?.sectionId
        }

        const url = `${baseUrlAdmin}/user/filter/allusers?academicYear=${localStorage.getItem('userAcademicYear')}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords
                });
                // this.toast.show({ severity: 'success', summary: 'Fetch User Details Successfully', detail: res.errMessage, life: 3000 });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    componentDidMount() {
        if (this.state?.dropdownsData?.sectionId) {
            this.filterUsersData();

        }
    }



    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }
    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.filterUsersData);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }
    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        }, () => this.filterUsersData())
    }
    gradeSectionBodyTemplate = (rowData) => {
        return `${rowData["custom:grade"]}-${rowData.sectionName}`;
    }
    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className='body-small-semibold'>Students List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>


            </div>
        );

        return (
            <div >


                <BranchToSectionMultiDDPreFill setValues={this.setDropdownValues} />



                <div className="flex-container">
                    <Authorizer permId={PERMISSIONS.STUDENTS_BULK_UPLOAD} >
                        <Button onClick={() => { this.props.navigate('/userBulkupload') }} className="right-button">
                            <p>Bulk Upload</p>
                            <AddIcon2 />
                        </Button>
                    </Authorizer>
                </div>



                <div className="card datatable-crud-demo mt-5 mx-4">

                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesText"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    // header={header}
                    >

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="given_name" field="given_name" header="Student Name" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="username" field="username" header="User Name" />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchId" field="branchName" header="Branch" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="custom:board" field="custom:board" header="Board" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="custom:grade" field="custom:grade" body={this.gradeSectionBodyTemplate} header="Grade-Section" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="phone_number" field="phone_number" header="Phone Number" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="email" field="email" header="Email" />



                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                            return (
                                <Button className='formCancelButton ' style={{ backgroundColor: rowData.status == "Active" ? '#E1F7D8' : '#FFECF1', borderColor: 'lightgray', borderWidth: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 15, padding: 0, height: '32px', width: "75px" }} onClick={() => this.setState({ isTransferDialogOpen: true })} >
                                    <p className='sideHeading' style={{ fontSize: 12, lineHeight: '16px', color: rowData.status == "Active" ? '##1E6D00' : '#BF0031' }}>{rowData.status == "Active" ? "Active" : "InActive"}</p>
                                </Button>
                            )
                        }} ></Column>


                    </DataTable>
                    {
                        this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                </div>

            </div>
        );
    }
}

const mapStatesToProps = (state) => ({


});
export default connect(null, {

})(Authentication(withRouter(StudentInfo)));