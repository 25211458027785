import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import Service from '../../services';
import Authentication from '../../session';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import { AddIcon2, FileAttachmentIcon, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { Dialog } from 'primereact/dialog';
import './styles.scss';
import { paymentModes } from '../../../constants';
import LoadingComponent from '../../loadingComponent';
import { trimObj } from '../../../utile';
import FileUpload from '../../fileUpload';
import FeeReciept from '../receipt';
const createFields = require('./collectFee.json');
const createApplicationFields = require('./applicationCreate.json');

const items = [{ label: 'Management' }, { label: 'User Management' }, { label: 'Users List', className: 'ma-active-item' }];

const home = { icon: 'pi pi-home' };
const formFields = createApplicationFields;
class ApplicationCreate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createApplicationFields);
        this.payformFields = getFormFields(createFields);
        this.state = {
            isLoading: false,
            feePlans: [],
            admissionType: [],
            payFeeFormData: this.payformFields.data,
            gender: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Other', value: 'other' }
            ],
            parentTypes: [
                { label: 'Father', value: 'Father' },
                { label: 'Mother', value: 'Mother' },
                { label: 'Guardian', value: 'Guardian' }
            ],
            application: this.props?.editApplication?._id ? this.props?.editApplication : this.formFields.data,
            payFee: this.payformFields.data,
            formValidations: this.formFields.formValidations,
            payFormValidations: this.payformFields.formValidations,
            addDifferentialFeeAllow: false,
            selectedEnquiry: {},
            isAddApplication: false,
            academicCategory: [],
            isEdit: false,
            boards: [],
            isExam: false,
            createdId: null,
            isShowReceipt: false,
            isApplicationFeePaid: false,
            receiptInfo: {},
            isShowPayFeeButton: false
        };
        this.service = new Service();
    }

    async componentDidMount() {
        const enquiryId = await localStorage.getItem('enquiryId');
        await this.getAdmissionSettingsInfo();
        await this.props.getBranchesLatest();
        await this.props.getBoardsData(true);
        await this.getAdmissionTypeDropdown();
        await this.getAcademicSessions();

        if (enquiryId) {
            await this.getEnquiryInfo(enquiryId);
        }
        if (this.props?.editApplication?._id) {
            this.setState(
                (prevState) => ({ application: { ...prevState.application, ...prevState?.application?.application }, isEdit: true }),
                async () => {
                    await this.handlePayFee(false);
                }
            );
        }
    }
    getAdmissionSettingsInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ admissionSettingsInfo: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    handlePayFee = async (isContinue) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/application-fee/filter`;
        try {
            let payload = {
                isActive: true,
                academicYear: this.state.application?.academicSession,
                orgId: this.state.application?.orgId,
                board: this.state.application?.board,
                grade: this.state.application?.grade,
                admissionStep: 'Application'
            };
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ feePayInfo: res?.res?.data, isShowPayFeeButton: res?.res?.data && Object.keys(res?.res?.data).length ? true : false, isLoading: false }, () => this.onCheckFeePaidInfo(isContinue));
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    getEnquiryInfo = async (enquiryId) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/admissions/form?id=${enquiryId}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res.data.admissionRecords.length) {
                        this.setState(
                            {
                                isLoading: false,
                                application: res.res.data.admissionRecords[0]
                            },
                            async () => {
                                this.onChangeBranch(this.state?.application?.branch);
                                this.onChangeBoard(this.state?.application?.board);
                                await this.handlePayFee(false);
                                localStorage.removeItem('enquiryId');
                            }
                        );
                    }
                } else {
                    this.setState({
                        isLoading: true
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
                console.log(e);
            });
    };

    getAcademicSessions = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/academic-years/filter`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map((admision) => ({
                    label: admision.academicCode,
                    value: admision._id
                }));
                this.setState({ academicSession: result, isLoading: false });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    getAdmissionTypeDropdown = async () => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map((admision) => ({
                    label: admision.categoryName,
                    value: admision._id
                }));

                this.setState({ academicCategory: result, isLoading: false });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    onHandleNavigate = (isContinue) => {
        const { examTest, registration } = this.state?.admissionSettingsInfo;
        let createdId = this.state.createdId;
        if (isContinue && createdId) {
            localStorage.setItem('applicationId', createdId);
            if (examTest && (this.state.application?.examTest ?? false)) {
                this.props.navigate('/addExam');
            } else if (registration) {
                this.props.navigate('/addRegistration');
            } else {
                this.props.navigate('/confirm-enrollment');
            }
            // this.state.application?.examTest ? this.props.navigate('/addExam') : this.props.navigate('/addRegistration')
        } else {
            !this.state.isEdit ? this.props.navigate('/admission-application') : this.setState({ isEdit: false }, () => this.props.onHandleClose());
        }
    };

    onSubmitApplication = async (isContinue) => {
        this.setState({
            isLoading: true
        });
        if (!this.state.isApplicationFeePaid) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please Pay Application Fee', detail: 'Please Pay Application Fee', life: 3000 });
            return;
        }
        if (!this.state?.application?.studentPhoto) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please upload student photo', detail: 'Please upload student photo', life: 3000 });
            return;
        }
        const formStatus = isFormValid(createApplicationFields, this.formFields.formValidations, trimObj(this.state.application));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            isCompleteFormValid = false;
        }
        const { examTest, registration, enrollment } = this.state?.admissionSettingsInfo;
        if (isCompleteFormValid) {
            let payload = {};
            payload._id = this.state.application?._id;
            payload.orgId = this.state.application?.orgId;
            payload.academicSession = this.state.application?.academicSession;
            payload.applicationId = this.state.application?.applicationId;
            payload.currentLocation = this.state.application?.currentLocation;
            payload.stage = 'Application';
            payload.address = this.state.application?.address;
            payload.studentPhoto = this.state.application?.studentPhoto;
            payload.board = this.state.application?.board;
            payload.grade = this.state.application?.grade;
            payload.examTest = this.state.application?.examTest || false;
            if (this.state.isEdit == true) {
                payload.isEdit = true;
            }
            if (examTest && payload.examTest && isContinue) {
                payload.stage = 'Exam';
            } else if (registration && isContinue) {
                payload.stage = 'Registration';
                payload.registration = {
                    siblings: [{ name: '', gender: '', classStudying: '', schoolStudying: '' }],
                    prevAcadamicDetails: [{ name: '', classStudied: '', schoolStudied: '', percentageOrGpa: '' }]
                };
            } else if (enrollment && isContinue) {
                payload.stage = 'Enrollment';
            }

            const url = `${baseUrlAdmin}/admissions/add-application`;
            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                                createdId: res?.res?.data
                            },
                            () => {
                                this.onHandleNavigate(isContinue);
                            }
                        );
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                });
        }
    };

    deleteDifferentialFee = (index) => {
        const updatedFeeTypes = this.state.transportFeeTypes.filter((_, i) => i !== index);
        this.setState({ transportFeeTypes: updatedFeeTypes });
    };

    handleInputChange = (index, field, value) => {
        const feeType = [...this.state.transportFeeTypes];
        feeType[index][field] = value;
        this.setState({ feeType });
    };

    handleRadioButtonChange = (option) => {
        this.setState({ pickupFee: option });
    };
    openDailogPayAppFee = () => {
        let paymentMode = paymentModes.filter((e) => e.value !== '1c98862e-46dc-4454-a120-7d15e2e56e2f' && e.value !== '9d08ebab-2d3e-4a30-8460-4e4092fe30a1');
        this.setState({ isOpenPayFeeDailog: true, paymentModes: paymentMode });
    };

    onChangeBranch = (branchId) => {
        this.setState({
            boards: [],
            classes: []
        });

        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId)?.name;
        let filteredBoards = this.props.boards.filter((board) => board.assignedBranches.includes(branchId));
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            boards: filteredBoards,
            classes: [],
            boardId: '',
            selectedBoardName: '',
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: ''
        });
    };

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [],
            sections: []
        });
        const selectedBoard = this.state.boards && this.props.boards.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard?.classes,
            boardId,
            selectedBoardName: selectedBoard?.boardName,
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: ''
        });
    };

    onHandleCancelPayFee = () => {
        this.setState({
            isOpenPayFeeDailog: false,
            payFeeFormData: {},
            transctionId: null
        });
    };

    onCheckFeePaidInfo = async (isContinue) => {
        const url = `${baseUrlAdmin}/admissions/feePaidInfo?_id=${this.state.application?._id}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            receiptInfo: res?.res?.data
                        },
                        () => this.handleShowReceipt(isContinue)
                    );
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            });
    };

    handleShowReceipt = (isContinue) => {
        const { receiptInfo } = this.state;
        if (!isContinue) {
            this.setState({
                isApplicationFeePaid: receiptInfo?.isApplicationFeePaid || false
            });
        } else {
            let receiptData = {
                receiptName: this.state.feePayInfo?.label,
                receiptNo: receiptInfo?.application?.receiptNo,
                receiptDate: receiptInfo?.application?.date,
                studentName: receiptInfo?.studentName,
                academicYear: receiptInfo?.academicYearCode,
                className: receiptInfo?.application?.gradeName,
                paymentMode: receiptInfo?.application?.paymentMode,
                feePaidDetails: [
                    {
                        feeType: receiptInfo?.application?.FeeTypeName,
                        amountPaying: receiptInfo?.application?.feeAmount
                    }
                ],
                amountPaid: receiptInfo?.application?.feeAmount
            };
            this.setState({
                isShowReceipt: true,
                receiptInfo: receiptData,
                isApplicationFeePaid: receiptInfo?.isApplicationFeePaid || false
            });
        }
        if (!this.state.isShowPayFeeButton) {
            this.setState({
                isApplicationFeePaid: true
            });
        }
    };

    OnPayFee = () => {
        this.setState({ isLoading: false });
        let academicYear = localStorage.getItem('userAcademicYear');
        let data = this.state.payFeeFormData;
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/admissions/payFee`;
        let paymentMode = this.state.paymentModes.find((el) => el.value == data?.paymentType)?.label;
        data.paymentMode = paymentMode;
        data.FeeTypeName = this.state.feePayInfo?.feeName;
        data.feeAmount = Number(this.state.feePayInfo?.amount);
        data.transctionId = this.state?.transctionId;
        if (
            data?.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' ||
            data?.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' ||
            data?.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' ||
            data?.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489'
        ) {
            if (!data?.transctionId) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Please enter transaction id.', detail: 'Please enter transaction id.', life: 3000 });
                return;
            }
        } else {
            data.transctionId = null;
        }
        const formStatus = isFormValid(createFields, this.payformFields.formValidations, trimObj(data));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                payFormValidations: formStatus.formValidations
            });
            isCompleteFormValid = false;
        }
        let payload = { ...data };
        if (this.state?.application?._id) {
            payload.academicYearCode = this.state.application?.enquiry?.academicYearCode;
            payload.admissionCategoryName = this.state.application?.enquiry?.admissionCategoryName;
            payload.studentName = `${this.state?.application?.firstName} ${this.state?.application?.lastName}`;
            payload.applicationNo = this.state?.application?.applicationId;
            payload.admissionId = this.state?.application?._id;
            payload.academicYear = this.state?.application?.academicSession;
            payload.gradeName = this.state.application?.gradeName;
            payload.stage = 'Application';
        }
        if (isCompleteFormValid) {
            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.data) {
                            this.setState(
                                {
                                    isOpenPayFeeDailog: false,
                                    isLoading: false,
                                    payFeeFormData: {},
                                    transctionId: null
                                },
                                () => {
                                    this.onCheckFeePaidInfo(true);
                                }
                            );
                            this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                        } else {
                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };
    _onProgress = (filesInfo) => {};

    _afterFilesUploaded = (files) => {
        console.log(files);
    };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };

    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => ({
            application: {
                ...prevState.application,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };

    _onStudenPhotoSuccess = (fileName, data) => {
        this.addAttachment('studentPhoto', fileName, data);
    };

    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.application));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = createApplicationFields.filter((f) => f.FieldName == field);
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` };
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` };
            }
        }

        this.setState({
            application: data,
            formValidations
        });
    };

    render() {
        const { application, formValidations, payFeeFormData, payFormValidations } = this.state;
        return (
            <>
                <div className="">
                    <div className="text-right mt-4 mb-2">
                        {this.state.isShowPayFeeButton && (
                            <Button disabled={this.state?.isApplicationFeePaid ? true : false} className="add-vehicle-btn" style={{ paddingLeft: 20, width: 200 }} onClick={() => this.openDailogPayAppFee()}>
                                Pay Application Fee
                            </Button>
                        )}
                    </div>
                    <div className="grid  md:col-12 lg:rid-col-12 mx-5 column-gap-8">
                        {/*   <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Application ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    value={application.applicationId}
                                    onChange={(e) => onTextChange(e.target.value, 'applicationId', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Application ID"
                                    disabled={this.state.isEdit ? true : false} />
                            </div>
                            {formValidations && !formValidations.fields['applicationId'].isValid && <p className="p-error text-lg">{formValidations.fields['applicationId'].errorMsg}</p>}

                        </div> */}
                        <div className="col-span-1">
                            <p className="transport-fee-form-label">
                                Academic Session<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl add-vehicle-field-input w-full"
                                    value={application.academicSession}
                                    options={this.state.academicSession}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'academicSession', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    placeholder="Select Academic Session"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1">
                            <p className="transport-fee-form-label">
                                Admission Category<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl add-vehicle-field-input w-full"
                                    value={application.academicCategory}
                                    options={this.state.academicCategory}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'academicCategory', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    placeholder="Admission Category"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicCategory'].isValid && <p className="p-error text-lg">{formValidations.fields['academicCategory'].errorMsg}</p>}
                        </div>
                    </div>

                    <div className="grid mt-2 md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5">
                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Student First Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    // id="title"
                                    className="  add-vehicle-field-input w-full "
                                    value={application.firstName}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'firstName', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Student First Name"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Student Last Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    // id="title"
                                    className="  add-vehicle-field-input w-full "
                                    width={250}
                                    value={application.lastName}
                                    onChange={(e) => onTextChange(e.target.value, 'lastName', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Student Last Name"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error text-lg">{formValidations.fields['lastName'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Contact no<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className="  add-vehicle-field-input w-full "
                                    value={application.contactNo}
                                    width={250}
                                    keyfilter="int"
                                    onChange={(e) => onTextChange(e.target.value, 'contactNo', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Contact no"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['contactNo'].isValid && <p className="p-error text-lg">{formValidations.fields['contactNo'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1  ">
                            <p className="transport-fee-form-label">
                                Email ID<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className="  add-vehicle-field-input w-full "
                                    value={application.emailId}
                                    onChange={(e) => onTextChange(e.target.value, 'emailId', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    width={250}
                                    placeholder="Email ID"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['emailId'].isValid && <p className="p-error text-lg">{formValidations.fields['emailId'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Gender<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    width={250}
                                    optionLabel="label"
                                    optionValue="value"
                                    className="text-xl add-vehicle-field-input w-full"
                                    value={application.gender}
                                    options={this.state.gender}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'gender', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    placeholder="Select Gender"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error text-lg">{formValidations.fields['gender'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Date of Birth<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetCalendar
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" border-round-md add-vehicle-field-input text-xl w-full"
                                    width={250}
                                    calendarMode={'single'}
                                    value={application.dob ? new Date(application.dob) : new Date()}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'dob', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    maxDate={new Date()}
                                    placeholder="DOB"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['dob'].isValid && <p className="p-error text-lg">{formValidations.fields['dob'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Adhaar Card<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" add-vehicle-field-input w-full "
                                    width={250}
                                    keyfilter="int"
                                    value={application.adhaarNo}
                                    onChange={(e) => onTextChange(e.target.value, 'adhaarNo', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Adhaar Card No."
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['adhaarNo'].isValid && <p className="p-error text-lg">{formValidations.fields['adhaarNo'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Branch<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="name"
                                    optionValue="key"
                                    className="text-xl add-vehicle-field-input w-full"
                                    value={application.branch}
                                    options={this.props.branchData}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'branch', this, formFields, application, formValidations, 'application', 'formValidations');
                                        this.onChangeBranch(e.target.value);
                                    }}
                                    placeholder="Select Branch"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['board'].isValid && <p className="p-error text-lg">{formValidations.fields['board'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Board<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    className="text-xl add-vehicle-field-input w-full"
                                    width={250}
                                    value={application.board}
                                    options={this.props.boards}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'board', this, formFields, application, formValidations, 'application', 'formValidations');
                                        this.onChangeBoard(e.target.value);
                                    }}
                                    placeholder="Select Board"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['board'].isValid && <p className="p-error text-lg">{formValidations.fields['board'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Grade<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="className"
                                    optionValue="classId"
                                    className="text-xl add-vehicle-field-input w-full"
                                    width={250}
                                    value={application.grade}
                                    options={this.state.classes}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'grade', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    placeholder="Select Grade"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['grade'].isValid && <p className="p-error text-lg">{formValidations.fields['grade'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Previous School<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" add-vehicle-field-input w-full "
                                    width={250}
                                    value={application.previousSchool}
                                    onChange={(e) => onTextChange(e.target.value, 'previousSchool', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Previous School"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['previousSchool'].isValid && <p className="p-error text-lg">{formValidations.fields['previousSchool'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Previous Board<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    className="text-xl add-vehicle-field-input w-full"
                                    value={application.previousBoard}
                                    options={this.props.boards}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'previousBoard', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    placeholder="Select Previous Board"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['previousBoard'].isValid && <p className="p-error text-lg">{formValidations.fields['previousBoard'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Parent Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" add-vehicle-field-input w-full "
                                    width={250}
                                    value={application.parentName}
                                    onChange={(e) => onTextChange(e.target.value, 'parentName', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Parent Name"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentName'].isValid && <p className="p-error text-lg">{formValidations.fields['parentName'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1  ">
                            <p className="transport-fee-form-label">
                                Parent Type<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="label"
                                    width={250}
                                    optionValue="value"
                                    className="text-xl add-vehicle-field-input w-full"
                                    value={application.parentType}
                                    options={this.state.parentTypes}
                                    onChange={(e) => {
                                        onDropDownChange(e.target.value, 'parentType', this, formFields, application, formValidations, 'application', 'formValidations');
                                    }}
                                    placeholder="Parent Type"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentType'].isValid && <p className="p-error text-lg">{formValidations.fields['parentType'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className="transport-fee-form-label">
                                Parent Contact No.<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" add-vehicle-field-input w-full "
                                    width={250}
                                    keyfilter="int"
                                    value={application.parentContact}
                                    onChange={(e) => onTextChange(e.target.value, 'parentContact', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Contact No."
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentContact'].isValid && <p className="p-error text-lg">{formValidations.fields['parentContact'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1">
                            <p className="transport-fee-form-label">
                                Parent Email ID<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className="  add-vehicle-field-input w-full "
                                    value={application.parentEmailId}
                                    onChange={(e) => onTextChange(e.target.value, 'parentEmailId', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Parent Email Id"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentEmailId'].isValid && <p className="p-error text-lg">{formValidations.fields['parentEmailId'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1">
                            <p className="transport-fee-form-label">
                                Occupation<span className="ma-required"></span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className=" add-vehicle-field-input w-full "
                                    width={250}
                                    value={application.occupation}
                                    onChange={(e) => onTextChange(e.target.value, 'occupation', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Select Occupation"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['occupation'].isValid && <p className="p-error text-lg">{formValidations.fields['occupation'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1">
                            <p className="transport-fee-form-label">
                                Current Location<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    className="  add-vehicle-field-input w-full "
                                    value={application.currentLocation}
                                    onChange={(e) => onTextChange(e.target.value, 'currentLocation', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Current Location"
                                />
                            </div>
                            {formValidations && !formValidations.fields['currentLocation'].isValid && <p className="p-error text-lg">{formValidations.fields['currentLocation'].errorMsg}</p>}
                        </div>

                        <div className="">
                            <p className="transport-fee-form-label">
                                Address<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetTextArea
                                    placeholder="address"
                                    className=""
                                    width={550}
                                    value={application.address}
                                    onChange={(e) => onTextChange(e.target.value, 'address', this, formFields, application, formValidations, 'application', 'formValidations')}
                                />
                            </div>
                            {formValidations && !formValidations.fields['address'].isValid && <p className="p-error text-lg">{formValidations.fields['address'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1">
                            <p className="transport-fee-form-label">
                                Upload Photo<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <FileUpload
                                    id={'student'}
                                    multiple={false}
                                    onProgress={this._onProgress}
                                    onSucess={this._onStudenPhotoSuccess}
                                    //  Reset={this.state.Reset}
                                    maxFileSize={307200}
                                    onError={this._onFilsUploadError}
                                    accept={'images'}
                                    title={'Images'}
                                    afterFilesUploaded={this._afterFilesUploaded}
                                    className="border-solid"
                                >
                                    {' '}
                                    <ClassetInputText
                                        width={250}
                                        icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                        className="add-vehicle-field-input w-full "
                                        value={application.studentPhoto?.fileName}
                                        // onChange={(e) => onTextChange(e.target.value, 'studentPhoto', this, formFields, application, formValidations, 'application', 'formValidations')}
                                        placeholder="Upload Photo"
                                        disabled={true}
                                    />
                                </FileUpload>
                                {/*   */}
                            </div>
                        </div>

                        <div className="col-span-1 flex mt-5">
                            <p className="transport-fee-form-label">
                                Exam / Test<span className="ma-required">*</span>
                            </p>
                            <InputSwitch className="mx-3 custom-inputswitch" checked={application?.examTest} onChange={(e) => this.onToggleChange(e, 'examTest')} />
                        </div>
                    </div>

                    <div className="text-center mt-8">
                        {/* <Button icon={'pi pi-plus-circle text-lg '} label='Save and Proceed to Next Step' onClick={this.onSubmitApplication} className='confirmDialogAcceptBtn  mr-5 w-24rem ' /> */}
                        <Button icon={'pi pi-plus-circle text-lg '} label={!this.state.isEdit ? 'Save Application' : 'Update Application'} onClick={() => this.onSubmitApplication(false)} className="confirmDialogAcceptBtn  mr-5 w-18rem p-5" />
                        {!this.state.isEdit && <Button icon={'pi pi-plus-circle text-lg '} label="Save and Proceed to Next Step" onClick={() => this.onSubmitApplication(true)} className="confirmDialogAcceptBtn  mr-5 w-24rem p-5 " />}
                        <Button label="Cancel" className="confirmDialogAcceptBtn p-5" onClick={() => (!this.state.isEdit ? this.props.navigate('/admission-application') : this.setState({ isEdit: false }, () => this.props.onHandleClose()))} />
                    </div>
                </div>

                <Dialog
                    isopen={this.state.isOpenPayFeeDailog}
                    visible={this.state.isOpenPayFeeDailog}
                    className="pay-fee"
                    style={{ position: 'fixed', right: '0' }}
                    draggable={false}
                    onHide={this.onHandleCancelPayFee}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 mb-4">
                                <h3 className="formhead text-center ">Pay Fee</h3>
                            </div>
                        );
                    }}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="term"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type name"
                                        value={`${this.state.feePayInfo?.feeName || ''}`}
                                        disabled={true}
                                        onChange={(e) => onTextChange(e.target.value, 'FeeTypeName', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['FeeTypeName'].isValid && <p className="p-error">{payFormValidations.fields['FeeTypeName'].errorMsg}</p>}
                            </div>

                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="feeAmount"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Amount"
                                        disabled={true}
                                        value={`${Number(this.state.feePayInfo?.amount) || 0}`}
                                        onChange={(e) => onTextChange(e.target.value, 'feeAmount', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['feeAmount'].isValid && <p className="p-error">{payFormValidations.fields['feeAmount'].errorMsg}</p>}
                            </div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id="paymentType"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state.paymentModes}
                                        value={payFeeFormData.paymentType}
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'paymentType', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations);
                                        }}
                                        placeholder={
                                            <div>
                                                <span className="">Payment Type</span>
                                            </div>
                                        }
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['paymentType'].isValid && <p className="p-error">{payFormValidations.fields['paymentType'].errorMsg}</p>}
                            </div>
                            {(payFeeFormData.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' ||
                                payFeeFormData.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' ||
                                payFeeFormData.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' ||
                                payFeeFormData.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') && (
                                <div className="col-12">
                                    <label className="label mb-2 ">
                                        Transaction Id<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetInputText className="" height="48px" width="368px" placeholder="Enter Transaction Id" value={this.state.transctionId} onChange={(e) => this.setState({ transctionId: e.target.value })} />
                                    </div>
                                </div>
                            )}
                            {payFeeFormData.paymentType && (
                                <div className="col-12">
                                    <label className="label mb-2 ">
                                        Remarks<span className="ma-required"></span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetTextArea
                                            id="feeType"
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Enter Remarks"
                                            value={payFeeFormData.remarks}
                                            onChange={(e) => onTextChange(e.target.value, 'remarks', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-content-center mb-4 mt-4">
                                <Button className="formSubmitButtonn mr-2" style={{ borderRadius: '12px', fontSize: '16px', backgroundColor: '#076EFF' }} onClick={this.OnPayFee}>
                                    <AddIcon2 />
                                    <p className="formSubmitButtonnText">Pay Now</p>
                                </Button>
                                <Button className="cancelButtonn w-4" onClick={this.onHandleCancelPayFee}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
                {this.state.isShowReceipt && (
                    <FeeReciept
                        receiptData={this.state?.receiptInfo}
                        hideFeeReciept={() => {
                            this.setState({
                                isShowReceipt: false,
                                receiptInfo: {}
                            });
                        }}
                    />
                )}
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(ApplicationCreate)));
