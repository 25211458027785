import { Component } from 'react';
import Service from '../../services';
import withRouter from '../../lib/withRouter';
import Authentication from '../../session';
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { paymentModes } from '../../../constants';
import { Button } from 'primereact/button';
import { baseUrlAdmin } from '../../../store/apiConstants';
import moment from 'moment';
import { AddIcon2, CrossInBox, RightMarkInCircle, StarInfoBadge } from '../../svgIcons';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { Dialog } from 'primereact/dialog';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import FeeReciept from '../receipt';
import { BasicLazyParams, trimObj } from '../../../utile';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
const createFields = require('./collectFee.json');
class ConfirmEnrollment extends Component {
    constructor(props) {
        super(props);
        this.payformFields = getFormFields(createFields);
        this.state = {
            isLoading: false,
            lazyParams: BasicLazyParams,
            isOpenPayFeeDailog: false,
            enrollment: {},
            viewEnrollment: false,
            payFeeFormData: this.payformFields.data,
            payFee: this.payformFields.data,
            payFormValidations: this.payformFields.formValidations,
            isEnrollmentFeePaid: false,
            isShowPayFeeButton: false,
            isShowFeeStructure: false,
            isOptedForTransport: false,
            isCredeintalsDisplay: false,
            studentDetails: false,
            userCreatedInfo: {},
            isStudentEnrolledConfirmed: false,
            isCloseOfFeeStructure: false
        };
        this.service = new Service();
    }
    async componentDidMount() {
        await this.getFeeInstallments();

        const applicationId = await localStorage.getItem('applicationId');
        if (applicationId) {
            await this.getAdmissionInfo(applicationId);
        }
    }

    getFeeInstallments = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/feeInstallments?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ paymentTerms: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };

    onToggleChange = (e, field) => {
        this.setState(
            {
                isOptedForTransport: e.value,
                selectedFeeStructure: null,
                feeStructure: []
            },
            () => {
                if (e.value) {
                    this.getTransportInfo();
                    this.getTransportFeeStructure(e.value);
                }
            }
        );
    };

    getAdmissionInfo = async (applicationId) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/admissions/form?id=${applicationId}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res.data.admissionRecords.length) {
                        this.setState(
                            {
                                isLoading: false,
                                enrollment: res.res.data.admissionRecords[0],
                                viewEnrollment: true,
                                selectedFeeStructure: res.res.data.admissionRecords[0]?.registration?.feeStructure ?? null,
                                isOptedForTransport: res.res.data.admissionRecords[0]?.registration?.transport ?? false,
                                requestedStop: res.res.data.admissionRecords[0]?.registration?.requestedStop ?? null,
                                canShowTransport : res.res.data.admissionRecords[0]?.registration?.transport ?? false,
                            },
                            async () => {
                                await this.handlePayFee(false);
                                this.state.isOptedForTransport && (await this.getTransportInfo());
                                this.state.isOptedForTransport && (await this.getTransportFeeStructure());
                                this.state.isOptedForTransport && (await this.getSelectedFeeStructure(this.state.selectedFeeStructure, false));
                                await this.getFeeStructures();
                            }
                        );
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
                console.log(e);
            });
    };

    handlePayFee = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/application-fee/filter`;
        try {
            let payload = {
                isActive: true,
                academicYear: this.state.enrollment?.academicSession,
                orgId: this.state.enrollment?.orgId,
                board: this.state.enrollment?.board,
                grade: this.state.enrollment?.grade,
                admissionStep: 'Enrollment'
            };
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ feePayInfo: res?.res?.data, isShowPayFeeButton: res?.res?.data && Object.keys(res?.res?.data).length ? true : false, isLoading: false }, () => this.onCheckFeePaidInfo(false));
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    openDailogPayAppFee = () => {
        if (this.state?.enrollment?.applicationId) {
            let paymentMode = paymentModes.filter((e) => e.value !== '1c98862e-46dc-4454-a120-7d15e2e56e2f' && e.value !== '9d08ebab-2d3e-4a30-8460-4e4092fe30a1');
            this.setState({ isOpenPayFeeDailog: true, paymentModes: paymentMode });
        }
    };

    onHandleCancelPayFee = () => {
        this.setState({
            isOpenPayFeeDailog: false,
            payFeeFormData: {},
            transctionId: null
        });
    };

    OnPayFee = () => {
        const formStatus = isFormValid(createFields, this.payformFields.formValidations, trimObj(this.state.payFeeFormData));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            isCompleteFormValid = false;
        }

        this.setState({ isLoading: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let data = this.state.payFeeFormData;
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/admissions/payFee`;
        let paymentMode = this.state.paymentModes.find((el) => el.value == data?.paymentType)?.label;
        data.paymentMode = paymentMode;
        data.FeeTypeName = this.state.feePayInfo?.feeName;
        data.transctionId = this.state?.transctionId;
        data.feeAmount = Number(this.state.feePayInfo?.amount);
        if (
            data?.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' ||
            data?.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' ||
            data?.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' ||
            data?.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489'
        ) {
            if (!data?.transctionId) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Please enter transaction id.', detail: 'Please enter transaction id.', life: 3000 });
                return;
            }
        } else {
            data.transctionId = null;
        }
        let payload = { ...data };
        if (this.state?.enrollment?._id) {
            payload.academicYearCode = this.state.enrollment?.enquiry?.academicYearCode;
            payload.admissionCategoryName = this.state.enrollment?.enquiry?.admissionCategoryName;
            payload.studentName = `${this.state?.enrollment?.firstName} ${this.state?.enrollment?.lastName}`;
            payload.applicationNo = this.state?.enrollment?.applicationId;
            payload.admissionId = this.state?.enrollment?._id;
            payload.academicYear = this.state?.enrollment?.academicSession;
            payload.gradeName = this.state.enrollment?.gradeName;
            payload.stage = 'Enrollment';
        }
        if (isCompleteFormValid) {
            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.data) {
                            this.setState(
                                {
                                    isOpenPayFeeDailog: false,
                                    isLoading: false,
                                    payFeeFormData: {},
                                    transctionId: null
                                },
                                () => {
                                    this.onCheckFeePaidInfo(true);
                                }
                            );
                            this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                        } else {
                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };

    onCheckFeePaidInfo = async (isContinue) => {
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/admissions/feePaidInfo?_id=${this.state.enrollment?._id}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            receiptInfo: res?.res?.data
                        },
                        () => this.handleShowReceipt(isContinue)
                    );
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };

    handleShowReceipt = (isContinue) => {
        const { receiptInfo } = this.state;
        if (!isContinue) {
            this.setState({
                isEnrollmentFeePaid: receiptInfo?.isEnrollmentFeePaid || false
            });
        } else {
            let receiptData = {
                receiptName: this.state.feePayInfo?.label,
                receiptNo: receiptInfo?.enrollment?.receiptNo,
                receiptDate: receiptInfo?.enrollment?.date,
                studentName: receiptInfo?.studentName,
                academicYear: receiptInfo?.academicYearCode,
                className: receiptInfo?.enrollment?.gradeName,
                paymentMode: receiptInfo?.enrollment?.paymentMode,
                feePaidDetails: [
                    {
                        feeType: receiptInfo?.enrollment?.FeeTypeName,
                        amountPaying: receiptInfo?.enrollment?.feeAmount
                    }
                ],
                amountPaid: receiptInfo?.enrollment?.feeAmount
            };
            this.setState({
                isShowReceipt: true,
                receiptData: receiptData,
                isEnrollmentFeePaid: receiptInfo?.isEnrollmentFeePaid || false
            });
        }
        if (!this.state.isShowPayFeeButton) {
            this.setState({
                isEnrollmentFeePaid: true
            });
        }
    };
    getTransportInfo = () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admissions/transportInfo?academicYear=${academicYear}`;
        let payload = {};
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res?.data?.routesList?.length) {
                        this.setState({
                            isLoading: false,
                            routesList: res.res.data.routesList
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
                console.log(e);
            });
    };

    onConfrimEnrollment = () => {
        this.setState({
            isLoading: true
        });
        if (!this.state.isEnrollmentFeePaid) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please Pay Enrollment Fee', detail: 'Please Pay Enrollment Fee', life: 3000 });
            return;
        }

        const { enrollment } = this.state;
        let payload = {
            admissionId: enrollment?._id,
            firstName: enrollment?.firstName,
            lastName: enrollment?.lastName,
            phone_number: enrollment?.contactNo,
            email: enrollment?.emailId,
            boardId: enrollment?.board,
            board: enrollment?.boardName,
            gradeId: enrollment?.grade,
            grade: enrollment?.gradeName,
            branchId: enrollment?.branch,
            branchName: enrollment?.branchName,
            groupId: enrollment?.groupId,
            group: 'NO_GROUP',
            academicYear: enrollment?.academicSession,
            academicYearCode: enrollment?.enquiry?.academicYearCode,
            academicCategory: enrollment?.enquiry?.academicCategory,
            categoryName: enrollment?.enquiry?.admissionCategoryName,
            dateOfBirth: enrollment?.dob,
            religion: enrollment?.registration?.religion,
            caste: enrollment?.registration?.caste,
            gender: enrollment?.gender,
            aadharNo: enrollment?.adhaarNo,
            motherTounge: enrollment?.registration?.motherTounge,
            fatherName: enrollment?.registration?.fatherName,
            fatherOccupation: enrollment?.registration?.fatherOccupation,
            fatherQualification: enrollment?.registration?.fatherQualification,
            fatherMobileNum: enrollment?.registration?.fatherMobileNum,
            fatherEmailId: enrollment?.registration?.fatherEmailId,
            motherName: enrollment?.registration?.motherName,
            motherOccupation: enrollment?.registration?.motherOccupation,
            motherQualification: enrollment?.registration?.motherQualification,
            motherMobileNum: enrollment?.registration?.motherMobileNum,
            motherEmailId: enrollment?.registration?.motherEmailId,
            guardianName: enrollment?.registration?.guardianName,
            guardianOccupation: enrollment?.registration?.guardianOccupation,
            guardianQualification: enrollment?.registration?.guardianQualification,
            guardianMobileNum: enrollment?.registration?.guardianMobileNum,
            guardianEmailId: enrollment?.registration?.guardianEmailId,
            houseNum: enrollment?.registration?.houseNum,
            streetOrVillage: enrollment?.registration?.streetOrVillage,
            mandal: enrollment?.registration?.mandal,
            cityOrDist: enrollment?.registration?.cityOrDist,
            state: enrollment?.registration?.state,
            pinCode: enrollment?.registration?.pinCode,
            siblings: enrollment?.registration?.siblings,
            prevAcadamicDetails: enrollment?.registration?.prevAcadamicDetails,
            studentPhoto: enrollment?.registration?.studentPhoto ?? enrollment?.application?.studentPhoto,
            contactPersonName: enrollment?.parentName || '',
            parentContactNo: enrollment?.parentContact || ''
        };
        const url = `${baseUrlAdmin}/user/addUser`;

        let userToSave = {
            ...payload,
            phone_number: `+91${payload.phone_number}`
        };

        this.service
            .post(url, userToSave, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            viewEnrollment: false,
                            userCreatedInfo: res?.res?.data,
                            isStudentEnrolledConfirmed: true
                        },
                        () => this.onShowFeeStructureDetails()
                    );
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });
    };
    onHandleReject = () => {
        this.setState({
            isRaiseDialogOpen: true
        });
    };
    onShowFeeStructureDetails = async () => {
        this.state.isOptedForTransport && (await this.getTransportFeeStructure());
        await this.getFeeStructures();
        this.setState({ isShowFeeStructure: true });
    };
    onRejectUser = () => {
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/admissions/rejectEnrollment`;
        let payload = {
            remarks: this.state.remarks,
            _id: this.state.enrollment?._id
        };
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isRaiseDialogOpen: false,
                            viewEnrollment: false
                        },
                        () => {
                            this.props.onHandleClose();
                        }
                    );
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };
    getTransportFeeStructure = () => {
        this.setState({
            isLoading: true
        });
        const { enrollment } = this.state;
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fee-structure/transportfees?academicYear=${academicYear}&isNew=${true}`;
        this.service
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res?.data?.length) {
                        this.setState({
                            isLoading: false,
                            feeStructure: res.res.data || []
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'success', summary: 'success', detail: 'No records found.', life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            });
    };
    getFeeStructures = () => {
        this.setState({
            isLoading: true
        });
        const { enrollment } = this.state;
        let url = `${baseUrlAdmin}/fee-structure/fsgrades?isTransport=${false}`;
        let payload = {
            boardId: enrollment?.board,
            gradeId: enrollment?.grade,
            academicYear: enrollment?.academicSession
        };
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res?.data?.length) {
                        this.setState({
                            isLoading: false,
                            feeStructureR: res.res.data
                        });
                    }
                } else {
                    this.setState({
                        isLoading: true
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
                console.log(e);
            });
    };

    getSelectedFeeStructure = (selectedFeeStructure, isRegularStructure) => {
        if (selectedFeeStructure) {
        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/fee-structure/selectedFee/${selectedFeeStructure}?academicYear=${this.state?.enrollment?.academicSession}`;
        
            this.service
                .get(url, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.data && res.res?.status) {
                            this.setState({
                                isLoading: false,
                                ...(isRegularStructure && { regularFeeStructureDetails: res.res.data }),
                                ...(!isRegularStructure && { feeStructureDetails: res.res.data })
                            });
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
                    console.log(e);
                });
        }
    };

    feeStructureAssign = (isRegularFee) => {
        const { userCreatedInfo } = this.state;
        this.setState({ isLoading: true });
        let inputKey = userCreatedInfo?.user_info?.sectionId ? 'user_id' : '';

        if (inputKey == '') {
            // Error Msg Section is not assigned to user
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: `Error`, detail: 'something went wrong', life: 3000 });
            return;
        }
        let feeTypes = [];
        let feeStructureDetails = [];
        let selectedFeeStructure;
        if (!isRegularFee) {
            feeStructureDetails = this.state.feeStructureDetails;
            this.state.feeStructureDetails?.stepOne?.map((each) => feeTypes.push(each?.feeTypeId));
            selectedFeeStructure = this.state?.selectedFeeStructure;
        } else {
            feeStructureDetails = this.state.regularFeeStructureDetails;
            feeStructureDetails = this.state.regularFeeStructureDetails?.stepOne?.map((each) => feeTypes.push(each?.feeTypeId));

            selectedFeeStructure = this.state?.selectedRFeeStructure;
        }
        if (feeTypes?.length) {
            let payload = {
                branchId: userCreatedInfo?.user_info?.branchId,
                boardId: userCreatedInfo?.user_info?.boardId,
                classId: userCreatedInfo?.user_info?.classId,
                sectionId: userCreatedInfo?.user_info?.sectionId,
                academicYear: userCreatedInfo?.user_info?.academicYear,
                feeCategoryId: feeStructureDetails?.feeCategory,
                feeStructureId: selectedFeeStructure,
                feeTypes: feeTypes,
                assignedInfo: [
                    {
                        [inputKey]: userCreatedInfo?.user_id
                    }
                ]
            };
            let url = `${baseUrlAdmin}/fee-structure/assign`;

            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                            // feeStructureAssignDialog: false,
                            // isShowFeeStructure: false,
                            ...(isRegularFee && { isRegularFeeStructureAssigned: true ,regularFeeStructureDetails:""}),
                            ...(!isRegularFee && { isFeeStructureAssigned: true ,feeStructureDetails : "" })
                        });
                        this.toast.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                });
        } else {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: 'Please select Atleast one record', life: 3000 });
        }
        // this.setState({ isShowFeeStructure: false }, () => this.showConfirmDialog())
    };

    onAssignTransportFee = () => {
        const { userCreatedInfo } = this.state;
        let stopInfo = this.state.routesList.filter((el) => el.stopId === this.state.requestedStop);
        let payload = {
            stopInfo,
            user_id: userCreatedInfo?.user_id,
            academicYear: userCreatedInfo?.user_info?.academicYear,
            requestedStop: this.state.requestedStop
        };

        let url = `${baseUrlAdmin}/admissions/assign/transport`;

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false
                        },
                        () => {
                            this.feeStructureAssign(false);
                        }
                    );
                    this.toast.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
            });
    };

    onCloseOfFeeStructure = () => {
        this.setState({ isCloseOfFeeStructure: true });
    };
    showConfirmDialog = () => {
        this.setState({ isCredeintalsDisplay: true, isShowFeeStructure: false });
    };

    render() {
        const { enrollment, payFeeFormData, payFormValidations, receiptInfo } = this.state;
        console.log(this.state.routesList, 'routesList', this.state.userCreatedInfo);
        return (
            <>
                <div>
                    {this.state.isShowPayFeeButton && (
                        <div className="text-right mt-4 mb-2">
                            <Button disabled={this.state.isEnrollmentFeePaid ? true : false} className="add-vehicle-btn" style={{ width: '120px', paddingLeft: '25px' }} onClick={() => this.openDailogPayAppFee()}>
                                Pay Fee
                            </Button>
                        </div>
                    )}
                    <div className="">
                        <div className="grid col-12">
                            <div className="sm:col-12 md:col-10">
                                <h3 className="text-left md:ml-3 my-5">Basic Information</h3>
                                <div className="grid md:col-12 row-gap-0 column-gap-0	">
                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Student Name </h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {`${enrollment?.firstName} ${enrollment?.lastName}`}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Father's Name </h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.fatherName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Mother's Name </h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.motherName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>DOB</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {moment(enrollment?.dob).format('DD/MM/YYYY')}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Contact No</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.contactNo}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Email ID</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.emailId}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Aadhaar Card</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.adhaarNo}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Mother Tounge</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.motherTounge}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Nationality</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.nationality}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Religion</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.religion}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Caste</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.caste}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Identification Marks</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.identificationMarks}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Guardian's Details</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.registration?.guardianName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Address</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>
                                            :{' '}
                                            {`${enrollment?.registration?.houseNum || ''} ${enrollment?.registration?.streetOrVillage || ''} ${enrollment?.registration?.mandal || ''} ${enrollment?.registration?.cityOrDist || ''} ${
                                                enrollment?.registration?.state || ''
                                            } ${enrollment?.registration?.pinCode || ''}`}
                                        </h5>
                                    </div>
                                </div>

                                <h3 className="text-left md:ml-3 mr-5 my-5">Admission Fee Detatils</h3>
                                <div className="grid flex md:col-12 row-gap-0 column-gap-0">
                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Application Fee</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {receiptInfo?.application?.feeAmount || 0}</h5>
                                    </div>
                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Entrance Exam Fee</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {receiptInfo?.exam?.feeAmount || 0}</h5>
                                    </div>
                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Registration Fee</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {receiptInfo?.registration?.feeAmount || 0}</h5>
                                    </div>
                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Enrollment Fee</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {receiptInfo?.enrollment?.feeAmount || 0}</h5>
                                    </div>
                                </div>
                                <h3 className="text-left md:ml-3 mr-5 my-5">Academic Details</h3>
                                <div className="grid flex md:col-12 row-gap-0 column-gap-0	">
                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Application ID</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.applicationId}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header">
                                        <h5>Academic Session</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.enquiry?.academicYearCode}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Admission Category</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.enquiry?.admissionCategoryName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Admission Date</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {moment(enrollment?.application?.applicationDate).format('DD/MM/YYYY')}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Branch</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.branchName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Board</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.boardName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Grade</h5>
                                    </div>
                                    <div className="col-8 my-0  py-1">
                                        <h5>: {enrollment?.gradeName}</h5>
                                    </div>

                                    <div className="col-4 my-0 py-1 view-header ">
                                        <h5>Exam/Test</h5>
                                    </div>
                                    <div className="col-8 my-0 py-1">
                                        <h5>: {enrollment?.exam?.score}</h5>
                                    </div>
                                </div>
                            </div>
                            {(enrollment?.registration?.studentPhoto?.storageLocation || enrollment?.application?.studentPhoto?.storageLocation) && (
                                <div className="md:col-2 sm:col-12 text-right ">
                                    <img
                                        src={`${enrollment?.registration?.studentPhoto?.storageLocation ?? enrollment?.application?.studentPhoto?.storageLocation}`}
                                        className="logo-img"
                                        style={{ marginLeft: '10%', width: '201px', height: '207px' }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="text-center mt-8">
                        <Button
                            label="Confirm Enrollment"
                            onClick={() => {
                                this.onConfrimEnrollment();
                            }}
                            className="confirmDialogAcceptBtn  mr-5 w-18rem p-5"
                        />

                        <Button
                            label="Reject"
                            onClick={() => {
                                this.onHandleReject();
                            }}
                            className="rejectBtn  mr-5 p-5"
                            style={{ backgroundColor: '#BF0031', border: 'none' }}
                        />

                        <Button
                            label="Cancel"
                            className="confirmDialogAcceptBtn p-5"
                            onClick={() => (!this.state?.viewEnrollment ? this.props.navigate('/admission-enrollment') : this.setState({ viewEnrollment: false }, () => this.props.navigate('/admission-enrollment')))}
                        />
                    </div>
                </div>

                <Dialog
                    isopen={this.state.isOpenPayFeeDailog}
                    visible={this.state.isOpenPayFeeDailog}
                    className="pay-fee"
                    style={{ position: 'fixed', right: '0' }}
                    draggable={false}
                    onHide={this.onHandleCancelPayFee}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 mb-4">
                                <h3 className="formhead text-center ">Pay Fee</h3>
                            </div>
                        );
                    }}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="term"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type name"
                                        disabled={true}
                                        value={`${this.state.feePayInfo?.feeName || ''}`}
                                        onChange={(e) => onTextChange(e.target.value, 'FeeTypeName', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['FeeTypeName'].isValid && <p className="p-error">{payFormValidations.fields['FeeTypeName'].errorMsg}</p>}
                            </div>

                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="feeAmount"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Amount"
                                        disabled={true}
                                        value={`${Number(this.state.feePayInfo?.amount) || 0}`}
                                        onChange={(e) => onTextChange(e.target.value, 'feeAmount', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['feeAmount'].isValid && <p className="p-error">{payFormValidations.fields['feeAmount'].errorMsg}</p>}
                            </div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id="paymentType"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state?.paymentModes}
                                        value={payFeeFormData?.paymentType}
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'paymentType', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations);
                                        }}
                                        placeholder={
                                            <div>
                                                <span className="">Payment Type</span>
                                            </div>
                                        }
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['paymentType'].isValid && <p className="p-error">{payFormValidations.fields['paymentType'].errorMsg}</p>}
                            </div>
                            {(payFeeFormData.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' ||
                                payFeeFormData.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' ||
                                payFeeFormData.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' ||
                                payFeeFormData.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') && (
                                <div className="col-12">
                                    <label className="label mb-2 ">
                                        Transaction Id<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetInputText className="" height="48px" width="368px" placeholder="Enter Transaction Id" value={this.state?.transctionId} onChange={(e) => this.setState({ transctionId: e.target.value })} />
                                    </div>
                                </div>
                            )}
                            {payFeeFormData.paymentType && (
                                <div className="col-12">
                                    <label className="label mb-2 ">
                                        Remarks<span className="ma-required"></span>
                                    </label>
                                    <div className="mt-2">
                                        <ClassetTextArea
                                            id="feeType"
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Enter Remarks"
                                            value={payFeeFormData.remarks}
                                            onChange={(e) => onTextChange(e.target.value, 'remarks', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-content-center mb-4 mt-4">
                                <Button className="formSubmitButtonn mr-2" style={{ borderRadius: '12px', fontSize: '16px', backgroundColor: '#076EFF' }} onClick={this.OnPayFee}>
                                    <AddIcon2 />
                                    <p className="formSubmitButtonnText">Pay Now</p>
                                </Button>
                                <Button className="cancelButtonn w-4" onClick={this.onHandleCancelPayFee}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isRaiseDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Reject Student Enrollment </p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#BF0031', border: 'none' }}
                                onClick={() => {
                                    this.onRejectUser();
                                }}
                            >
                                <p className="formSubmitButtonText">Reject</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRaiseDialogOpen: false, remarks: '' })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRaiseDialogOpen: false })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.isCloseOfFeeStructure}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Canceling this will leave the fee assignment incomplete for the student. Do you want to proceed?</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button className="formSubmitButton px-4" style={{ borderRadius: '10px', backgroundColor: '#BF0031', border: 'none' }} onClick={() => this.showConfirmDialog()}>
                                <p className="formSubmitButtonText">Confirm</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isCloseOfFeeStructure: false, remarks: '' })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isCloseOfFeeStructure: false })}
                ></Dialog>

                <Dialog
                    isOpen={this.state.isShowFeeStructure}
                    visible={this.state.isShowFeeStructure}
                    style={{ width: '75vw', height: '80vh' }}
                    draggable={false}
                    onHide={() => this.onCloseOfFeeStructure()}
                    closable={true}
                    header={() => {
                        return (
                            <div className=" p-2">
                                <h3 className="text-center formhead ">Confirm Enrollment</h3>
                                {this.state?.userCreatedInfo && (
                                    <>
                                        <div className="flex flex-column align-items-center justify-content-center">
                                            <p>
                                                <span className="enrolledDataHeaders">Enrollment Id :</span>
                                                <span className="enrolledData">{this.state?.userCreatedInfo?.enrollmentId}</span>
                                            </p>
                                            <p>
                                                <span className="enrolledDataHeaders">Branch :</span>
                                                <span className="enrolledData">{this.state?.userCreatedInfo?.user_info?.branchName}</span>
                                            </p>
                                            <p>
                                                <span className="enrolledDataHeaders">Grade :</span>
                                                <span className="enrolledData">{this.state?.userCreatedInfo?.user_info?.['custom:grade']}</span>
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    }}
                    footer={() => <div className="flex justify-content-center mb-4 mt-4 p-2"></div>}
                >
                    <div className="mb-5 ml-5 mr-5 p-2 ">
                        <div className="flex ">
                            <div className="col-4 ">
                                <label className="label">
                                    Fee Structure<span className="ma-required">*</span>
                                </label>
                                <div className="mt-3">
                                    <ClassetDropdown
                                        options={this.state.feeStructureR}
                                        optionLabel="feeStructureName"
                                        optionValue="id"
                                        width={250}
                                        value={this.state.selectedRFeeStructure}
                                        onChange={(e) => this.setState({ selectedRFeeStructure: e.target.value }, () => this.getSelectedFeeStructure(this.state.selectedRFeeStructure, true))}
                                        placeholder="Enter Fee Structure"
                                        disabled={this.state.isRegularFeeStructureAssigned}
                                    />
                                </div>
                            </div>
                            <div className="col-4 mt-5 ">
                                <label className="label"></label>
                                <Button label="Assign" className="p-button-primary mt-2 button-style mr-4 button text-lg " onClick={() => this.feeStructureAssign(true)} disabled={this.state.isRegularFeeStructureAssigned}/>
                            </div>
                        </div>

                        {this.state.canShowTransport && (
                            <div className="grid mb-3 ml-1 mt-2">
                                <div className="col-4">
                                    <div className="flex column-gap-8 ">
                                        <label className="label">
                                            Transport<span className="ma-required">*</span>
                                        </label>
                                        <InputSwitch className="mx-3 custom-inputswitch" checked={this.state.isOptedForTransport} onChange={(e) => this.onToggleChange(e, 'transport')} />
                                    </div>
                                    <div className="col-span-1 ">
                                        {/* <p className='transport-fee-form-label'>Requested Stop<span className='ma-required'>*</span></p> */}
                                        <div className="flex">
                                            <ClassetDropdown
                                                filter
                                                showClear
                                                filterBy="label"
                                                options={this.state.routesList}
                                                optionLabel="label"
                                                optionValue="stopId"
                                                width={250}
                                                value={this.state.requestedStop}
                                                onChange={(e) => this.setState({ requestedStop: e.target.value })}
                                                placeholder="Enter Requested Stop"
                                                disabled={!this.state.isOptedForTransport ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 ">
                                    <label className="label">
                                        Transport Fee Structure<span className="ma-required">*</span>
                                    </label>
                                    <div className="mt-4">
                                        <ClassetDropdown
                                            options={this.state.feeStructure}
                                            optionLabel="scheduleName"
                                            optionValue="_id"
                                            width={250}
                                            value={this.state.selectedFeeStructure}
                                            onChange={(e) => this.setState({ selectedFeeStructure: e.target.value }, () => this.getSelectedFeeStructure(this.state.selectedFeeStructure, false))}
                                            placeholder="Enter Fee Structure"
                                        />
                                    </div>
                                </div>
                                <div className="col-4 mt-5 ">
                                    <label className="label"></label>
                                    <Button label="Assign" className="p-button-primary mt-3 button-style mr-4 button text-lg "
                                    disabled={!this.state?.isRegularFeeStructureAssigned || (!this.state?.isOptedForTransport && !this.state?.isFeeStructureAssigned)}
                                    onClick={this.onAssignTransportFee} />
                                </div>
                            </div>
                        )}

                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state?.regularFeeStructureDetails?.stepOne}
                            dataKey="feeTypeId"
                            paginator
                            rows={10}
                            // showGridlines
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            // header={header}
                            reorderableRows
                            onRowReorder={this.onRowReorder}
                        >
                            {/* <Column rowReorder style={{ width: '3em' }} /> */}
                            <Column field="feeType" header="Fee Type" className="datatable-cel" />
                            <Column
                                style={{ width: '3em' }}
                                header="Priority"
                                field="priority"
                                className="datatable-cel"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                header="Admission Category"
                                className="datatable-cel"
                                field="admissionType"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <div>
                                                {rowData?.admissionTypes?.map((label, index) => (
                                                    <div key={index}>{label}</div>
                                                ))}
                                            </div>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                header="No .of Payment Terms"
                                className="datatable-cel"
                                field=""
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <ClassetDropdown
                                                className="text-xl"
                                                height="48px"
                                                width="227px"
                                                optionLabel="label"
                                                optionValue="value"
                                                options={this.state.paymentTerms.map((term, index) => ({
                                                    label: `Term ${index + 1}`,
                                                    value: term?._id
                                                }))}
                                                value={rowData?.paymentTerms}
                                                disabled={true}
                                                placeholder={
                                                    <div>
                                                        <span className="text-xl">Payment Terms</span>
                                                    </div>
                                                }
                                            />
                                        </>
                                    );
                                }}
                            />
                            <Column
                                header="Total Fee"
                                className="datatable-cel"
                                field=""
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <ClassetInputText
                                                className="text-xl"
                                                height="48px"
                                                width="227px"
                                                value={rowData.totalFee}
                                                // value={feeTypes.totalFee}
                                                onChange={(e) => this.handleTotalFeeChange(e, rowData)}
                                                disabled={true}
                                                placeholder="Total Fee"
                                                keyfilter="int"
                                            />
                                        </>
                                    );
                                }}
                            />
                        </DataTable>

                        <div className="flex justify-content-center mb-4 mt-4 p-2">
                            <Button
                                label="Proceed to Fee Schedule"
                                className="p-button-primary submitButtonn mr-4 button text-lg "
                                disabled = {!this.state?.isRegularFeeStructureAssigned || (this.state?.isOptedForTransport && !this.state?.isFeeStructureAssigned)}
                                onClick={() => {
                                    if (this.state?.isRegularFeeStructureAssigned || (this.state?.isOptedForTransport && this.state?.isFeeStructureAssigned)) {
                                        this.showConfirmDialog();
                                    }
                                }}
                            />
                            {/* Need to check and change the code */}
                            <Button className="cancelButtonn w-2" onClick={() => this.onCloseOfFeeStructure()}>
                                <p className="cancelButtonnText">Cancel</p>
                            </Button>
                        </div>

                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isCredeintalsDisplay}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Confirmed Enrollment </p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-8"
                                style={{ borderRadius: '10px', backgroundColor: '#076EFF', border: 'none' }}
                                onClick={() => {
                                    this.setState({ isCredeintalsDisplay: false, studentDetails: true });
                                }}
                            >
                                <p className="formSubmitButtonText">Student Details</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isCredeintalsDisplay: false })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        {this.state?.userCreatedInfo && (
                            <>
                                <div className="flex flex-column align-items-center justify-content-start">
                                    <p>
                                        <span className="enrolledDataHeaders">Enrollment Id : </span>
                                        <span className="enrolledData">{this.state?.userCreatedInfo?.enrollmentId}</span>
                                    </p>
                                    <p>
                                        <span className="enrolledDataHeaders">Branch : </span>
                                        <span className="enrolledData">{this.state?.userCreatedInfo?.user_info?.branchName}</span>
                                    </p>
                                    <p>
                                        <span className="enrolledDataHeaders">Board : </span>
                                        <span className="enrolledData">{this.state?.userCreatedInfo?.user_info?.['custom:board']}</span>
                                    </p>
                                    <p>
                                        <span className="enrolledDataHeaders">Grade : </span>
                                        <span className="enrolledData">{this.state?.userCreatedInfo?.user_info?.['custom:grade']}</span>
                                    </p>
                                    <p>
                                        <span className="enrolledDataHeaders">Section : </span>
                                        <span className="enrolledData">{this.state?.userCreatedInfo?.user_info?.sectionName}</span>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.studentDetails}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 600 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>LMS & ERP</p>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Login Credentials</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#076EFF', border: 'none' }}
                                onClick={() =>
                                    !this.state?.viewEnrollment
                                        ? this.props.navigate('/admission-enrollment')
                                        : this.setState({ viewEnrollment: false, isCredeintalsDisplay: false, studentDetails: false }, () => this.props.navigate('/admission-enrollment'))
                                }
                            >
                                <p className="formSubmitButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ studentDetails: false })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <p>
                            <span className="enrolledDataHeaders">Student Login Credentials</span>
                        </p>
                        {this.state?.userCreatedInfo && (
                            <>
                                <div className="flex flex-column align-items-start justify-content-start">
                                    <p>
                                        <span className="enrolledDataHeaders">Username : </span>
                                        <span className="enrolledData"> {this.state?.userCreatedInfo?.user_info?.username ? this.state.userCreatedInfo.user_info.username.slice(3) : ''}</span>
                                    </p>
                                    <p>
                                        <span className="enrolledDataHeaders">Passwrod : </span>
                                        <span className="enrolledData">Last name+@+DDMMYYYY(Xyz@25122024)</span>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </Dialog>
                {this.state.isShowReceipt && (
                    <FeeReciept
                        receiptData={this.state?.receiptData}
                        hideFeeReciept={() => {
                            this.setState({
                                isShowReceipt: false,
                                receiptData: {}
                            });
                        }}
                    />
                )}
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(ConfirmEnrollment)));
