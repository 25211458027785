import React from 'react';
import './login.scss';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { userSignIn, clearSignInData, getUserData } from '../../store/actions';
import { Link } from 'react-router-dom';
import LoadingComponent from '../loadingComponent';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import HandLoader from '../loadingComponent/HandLoader';
const INITIAL_STATE = {
    number: 'ALLINONE',
    password: 'Classet@123',
    error: null,
    passwordErr: null
};

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }

    isVaildNumber = (mobile) => {
        if (/^[0-9\b]+$/.test(mobile)) {
            return true;
        } else {
            return false;
        }
    };

    componentDidMount() {
        if (this.props.authenticated) {
            this.props.navigate('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
            if (this.props.authenticated) {
                this.props.getUserData();
                setTimeout(() => {
                    this.props.navigate('/');
                }, 10);
            }
        }
    }

    userSignIn = () => {
        // const number = "+919052565771"  //'shashi2puppy'// `+91${this.state.number}`;
        // const pw ="Lodu@9052565771"       /// 'Dinesh@123'  // this.state.password;
        // const number = `+91${this.state.number}`;
        const pw = this.state.password;
        this.props.userSignIn((this.props.orgCode ? this.props.orgCode : '') + this.state.number, pw);
    };

    render() {
        const { userSignIn, number, password, numberErr, passwordErr } = this.state;
        // console.log(this.props.orgCode)
        const showBtn = number === '' || password === '';
        //|| !this.isVaildNumber(number)
        const { logoRectangle, isSuperOrg, orgName } = this.props;
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8%' }}>
                <div className="login-wrapper">
                    <div className="flex flex-column align-items-center">
                        {logoRectangle?.storageLocation ? (
                            <>
                                <img src={`${logoRectangle?.storageLocation ? logoRectangle?.storageLocation : ''}`} alt="" className="img-fluid" />
                                <h3 className="mt-3">
                                    <b>{orgName}</b>
                                </h3>
                            </>
                        ) : (
                            <div className="flex">
                                <h4 style={{ marginRight: '10px' }}>Fetching Organisation Details...</h4>
                                {/* <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <div style={{ width: '30px', height: '30px' }}>
                                    {/* <LoadingComponent /> */}

                                    {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}

                                    {/* <CustomisedLoader /> */}

                                    {/* <StepsLoader /> */}
                                    <HandLoader />
                                </div>
                            </div>
                        )}

                        {isSuperOrg && <h3>Superadmin</h3>}
                        <div className="w-100 mt-3">
                            <h4 className="text-center">Login</h4>
                            <div className="input-wrapper">
                                <InputText onChange={(e) => this.setState({ number: e.target.value })} value={number} ref="number" type="text" placeholder="Username" />
                                <div className="ma-danger">{numberErr ? 'Please enter mobile number' : ''}</div>
                            </div>
                            <div className="input-wrapper">
                                <Password
                                    feedback={false}
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    onBlur={() => (password === '' ? this.setState({ passwordErr: 'please enter the password' }) : this.setState({ passwordErr: '' }))}
                                    ref="password"
                                    value={password}
                                    type="password"
                                    placeholder="Password"
                                />
                                <div className="p-error">{passwordErr ? 'Please enter password' : ''}</div>
                            </div>
                            <div className="text-center text-warning">{this.props?.errMsg?.message}</div>
                            <div className="button-wrapper">
                                <Button disabled={logoRectangle?.storageLocation ? false : true} label="Log In" type="button" onClick={() => this.userSignIn()} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.login && state.login,
    authenticated: state.auth && state.auth.authenticated,
    loading: state.signInData && state.signInData.loading && state.signInData.loading,
    error: state.signInData && state.signInData.error && state.signInData.error,
    errMsg: state.signInData && state.signInData.errMsg && state.signInData.errMsg.message,
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgCode: state.orgData.orgCode,
    isLoading: state.orgData.isLoading,
    isSuperOrg: state.orgData.isSuper
});

export default connect(mapStateToProps, { userSignIn, clearSignInData, getUserData })(Authentication(withRouter(LoginPage)));
