import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";
import { Checkbox } from 'primereact/checkbox';
import _ from 'lodash';
import './styles.scss';
import { ClassetEditIcon, CodeLinkIcon, MailIcon, QuestionMarkIcon, RefreshIcon } from "../../../svgIcons";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Tooltip } from "chart.js";
import { DataTable } from "primereact/datatable";
import SvgViewer from "../../../customComponents/svgViewer";
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from "../../../../utile/formHelper";
import { Calendar } from 'primereact/calendar';
import ClassetInputText from "../../../../classetComponents/classetInputText";
import ClassetMultiSelect from "../../../../classetComponents/classetMultiSelect";
import ClassetDropdown from "../../../../classetComponents/classetDropDown";
import Service from "../../../../services";
import { baseUrlAdmin } from "../../../../store/apiConstants";
import { getBoardsData } from "../../../../store/actions";
import Authentication from '../../../session';
import { connect } from 'react-redux';
import withRouter from '../../../lib/withRouter';
import { TestTypes } from '../../Examination/constants';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import { Toast } from "primereact/toast";
import Authorizer, { PERMISSIONS, isAuthorized, getPermIdOfAction } from '../../../session/authorizer';
import QuestionBank from './../../../questionBank/index';
import QuestionInTable from "../../../questionBank/common/questionInTable";

const creatTestFields = require('./createTest.json');
let formFields = creatTestFields;
const testFormat = [{ value: 'openTest', label: "Open Test" }, { value: 'closedTest', label: "Closed Test" }]

const testTemplates = [
    { value: 'neet2024', label: "NEET 2024" },
    { value: 'cbse', label: "CBSE" },
    { value: 'custom', label: "Custom" },
    { value: 'jEEMains2024', label: "JEE Mains 2024" },
    { value: 'jEEMains2023', label: "JEE Mains 2023" }


]
const testType = [{ label: 'Assignment', value: 'assignment' }, { label: 'Quiz', value: 'quiz' }, { label: 'Assessment', value: 'assessment' }, { label: 'Mock Test', value: 'mocktest' }]

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const paperLevel = [
    { label: 'Easy', value: 'EASY' },
    { label: 'Medium', value: 'Medium' },
    { label: 'Hard', value: 'Hard' },
]

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'title': { value: '', matchMode: 'contains' },
        'subDomain': { value: '', matchMode: 'contains' },
        // 'tenureEndDate': { value: '', matchMode: 'contains' },
        // 'isActive': { value: '', matchMode: 'contains' },
    }
}
class CreateTest extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(creatTestFields);
        this.state = {
            formValidations: this.formFields.formValidations,
            testData: this.props?.location?.state?.editdata?._id ? this.props?.location?.state?.editdata : this.formFields.data,
            selfSelection: false,
            autoSelection: false,
            onEdit: false,
            // onhide: false,
            testFormats: testFormat,
            testFormatType: null,
            startDate: null,
            testTemplates: testTemplates,
            selectedTestTemplate: null,
            addMore: true,
            generateQuestions: false,
            examTemplates: [],
            testTypes: [],
            examType: null,
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            boardId: this.props?.location?.state?.editData?._id ? this.props?.location?.state?.editData?.boardId : null,
            classId: this.props?.location?.state?.editData?._id ? this.props?.location?.state?.editData?.classId : null,
            groupId: this.props?.location?.state?.editData?._id ? this.props?.location?.state?.editData?.groupId : null,
            subjectId: this.props?.location?.state?.editData?._id ? this.props?.location?.state?.editData?.boardId : null,
            chapterId: this.props?.location?.state?.editData?._id ? this.props?.location?.state?.editData?.boardId : null,
            topicId: this.props?.location?.state?.editData?._id ? this.props?.location?.state?.editData?.boardId : null,
            questions: [],
            selectedQIds: [],
            subjects: [],
            chapters: [],
            totalQuestions: null,
            paperLevel: []

        }
        this.service = new Service();
    }

    question = [
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" }
    ]

    handleSelfSelection = () => {
        this.setState({
            selectedButton: 'Self'
        })
        const formStatus = isFormValid(formFields, this.formFields.formValidations, this.state?.testData);
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            this.setState(prevState => ({
                onEdit: true,
                autoSelection: false,
                selfSelection: true,

            }))
        }
    }
    handleGenerateQuestions = () => {
        this.setState(prevState => ({
            onEdit: true,
            generateQuestions: true,
            // autoSelection: false,

        }))


    }
    handleAutoPaperGenerate = () => {
        this.setState(prevState => ({
            onEdit: true,
            autoSelection: true,
            selfSelection: false,
            selectedButton: 'Auto'
        }))


    }
    // handleOnEdit = () => {
    //     console.log(this.state.selfSelection, "selfSelection", this.state.autoSelection, "autoSelection", this.state.onEdit);

    //     this.setState(prevState => ({
    //         selfSelection: !prevState.selfSelection,  // Toggle selfSelection
    //         autoSelection: prevState.selfSelection,   // autoSelection will be opposite of selfSelection
    //         onEdit: false,  // Keep onEdit false as per your original logic
    //         // selectedQuestions: ''
    //     }));
    // }


    handleOnEdit = () => {
        console.log(this.state.selfSelection, "selfSelection", this.state.autoSelection, "autoSelection", this.state.onEdit);

        this.setState(prevState => {

            if (prevState.onEdit) {
                return {
                    selfSelection: false,
                    autoSelection: false,
                    onEdit: false
                };
            }


            const newSelfSelection = !prevState.selfSelection;
            const newAutoSelection = prevState.selfSelection;

            return {
                selfSelection: newSelfSelection,
                autoSelection: newAutoSelection,
                onEdit: !(newSelfSelection || newAutoSelection),
            };
        });
    }

    onCancel = () => {
        this.setState(prevState => ({
            selfSelection: false,
            autoSelection: true,
            onEdit: false,
            testData: '',
            selectedQuestions: []
        }));
    }

    handleAddMore = () => {
        this.setState(prevState => ({
            onEdit: prevState.autoSelection,
            selfSelection: true,
            onhide: true,
            addMore: false
        }))

    }

    onChangeBoard = (boardId, classID, subjectId, chapterId, topicId) => {
        this.setState({
            classes: [], groups: [],
            classId: classID || null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: [],
            questions: [],
            totalRecords: 0,
            // selectedQuestions: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard?.classes && selectedBoard?.classes?.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard?.boardName,
            resourseOrigin: selectedBoard?.isBase ? 'upload' : null

        }
            , () => {
                // If pre-filled classId exists, call onChangeClass after setting the board
                if (classID) {
                    this.onChangeClass(classID, subjectId, chapterId, topicId);
                }
            }
        );

    };

    onChangeClass = (classId, subjectId, chapterId, topicId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: chapterId || null,
            topics: [], topicId: topicId || null,
            content: null,
            filteredContentDetails: [],
            questions: [],
            totalRecords: 0,
            // selectedQuestions: [],
            subjectId: subjectId || null
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props?.boards && this.props?.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard?.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass?.groups && selectedClass?.groups?.length > 0) {
            selectedClass?.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass?.className,
                groupId: _groups[0].value,
                groupName: _groups[0].label,
            }, () => {
                // this.onChangeSubject(subjectId, chapterId, topicId);
                this.getCurriculumData();
            });
        }
    };

    onChangeSubject = (subjectIds, chapterId, topicId) => {
        ;

        const subjectIdArray = Array.isArray(subjectIds) ? subjectIds : [subjectIds];

        let selectedSubjects = this.state?.subjects?.filter((subject) => subjectIdArray.includes(subject.subjectId));
        let allChapters = selectedSubjects.flatMap((subject) => subject?.chapters || []);

        allChapters = allChapters.sort((a, b) => a.chapterNumber - b.chapterNumber);

        this.setState((prevState) => {
            return {
                subjectId: subjectIds || null,
                subjectName: selectedSubjects.map(sub => sub.subjectName).join(', ') || '',
                chapterId: chapterId || null,
                topicId: topicId || null,
                chapters: allChapters,  // Set chapters to all sorted chapters
                topics: [],
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                // selectedQuestions: []
            };
        },
            () => {
                if (chapterId) {
                    this.onChangeChapter(chapterId, topicId);
                }
            }
        );
    };


    onChangeChapter = (chapterIds, topicId) => {
        ;

        const chapterIdArray = Array.isArray(chapterIds) ? chapterIds : [chapterIds];

        let selectedChapters = this.state?.chapters?.filter((chapter) => chapterIdArray.includes(chapter.chapterId));

        let allTopics = selectedChapters.flatMap((chapter) => chapter?.topics || []);

        selectedChapters = selectedChapters.sort((a, b) => a.chapterNumber - b.chapterNumber);

        let chapterNames = selectedChapters.map(chap => chap.chapterName).join(', ');

        this.setState(
            {
                topics: allTopics,  // Set topics to all collected topics
                chapterId: chapterIds || null,
                chapterName: chapterNames || '',  // Join chapter names if multiple are selected
                topicId: topicId || null,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                // selectedQuestions: []
            },
            () => {
                this.onChangeTopic(topicId);
            }
        );
    };


    onChangeTopic = (topicId) => {





        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic?.topicName,
                questions: [],
                totalRecords: 0,
                isLoading: true,
                // selectedQuestions: []
            });
        // let topicID = this.state?.topicId

        let filters = {};
        let { skillType, totalQuestions, selectedButton } = this.state;

        if (topicId) {
            filters = { ...filters, topicId: topicId }
        }

        if (this.state?.selectedButton == 'Auto' && this.state?.totalQuestions) {
            if (skillType && skillType.length) {
                filters = { ...filters, skillType: skillType }
            }

            if (totalQuestions && totalQuestions.length) {
                filters = { ...filters, totalQuestions: totalQuestions }
            }
        }


        if (selectedButton) {
            filters = { ...filters, selectedButton: selectedButton };
        }



        if (this.state?.selectedButton == 'Auto' && this.state?.totalQuestions && skillType) {
            this.service.post(`${baseUrlAdmin}/question/getTopicLinkedQuestions`, filters, true).then((data) => {

                if (data && data?.status && data?.res?.questions) {

                    const editData = this.props?.location?.state?.editdata;

                    console.log(editData, "editDataFoorrr")

                    let selectedQuestions = [];

                    if (editData && editData.questions) {
                        selectedQuestions = editData.questions;
                        // this.setState({ selectedQuestions });
                    }
                    this.setState({
                        questions: data?.res?.questions,
                        selectedQuestions: selectedQuestions,
                        isLoading: false
                    },
                        () => {
                            console.log("Updated selectedQuestions:", this.state.selectedQuestions);
                        });

                } else {
                    this.setState({
                        isLoading: false
                    })
                }


            }).catch(e => {
                this.setState({
                    isLoading: false
                })
                console.log(e);

            });
        }
        this.service.post(`${baseUrlAdmin}/question/getTopicLinkedQuestions`, filters, true).then((data) => {

            if (data && data?.status && data?.res?.questions) {

                const editData = this.props?.location?.state?.editdata;

                console.log(editData, "editDataFoorrr")

                let selectedQuestions = [];

                if (editData && editData.questions) {
                    selectedQuestions = editData.questions;
                    // this.setState({ selectedQuestions });
                }
                this.setState({
                    questions: data?.res?.questions,
                    selectedQuestions: selectedQuestions,
                    isLoading: false
                },
                    () => {
                        console.log("Updated selectedQuestions:", this.state.selectedQuestions);
                    });

            } else {
                this.setState({
                    isLoading: false
                })
            }


        }).catch(e => {
            this.setState({
                isLoading: false
            })
            console.log(e);

        });



        console.log("first", this.state.selectedQuestions)



    };


    checkIsChecked = (question) => {
        let qIds = this.state.selectedQIds
        if (question.questionType == 'PSGS' || question.questionType == 'PSGM') {
            return question.questions.some((q) => qIds.includes(q.questionId))
        } else {
            return this.state.selectedQIds.includes(question.questionId);
        }


    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {



                if (data && data.res && data.res.Item) {
                    let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}

                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,

                        ...selected
                    }, () => {

                        const selectedSubjectId = this.state.subjectId; // Pass the subjectId you want to select
                        const selectedChapterId = this.state.chapterId; // Pass the chapterId you want to select
                        const selectedTopicId = this.state.topicId;

                        this.onChangeSubject(selectedSubjectId, selectedChapterId, selectedTopicId);
                        if (isEdit) {
                            this.onSearch()

                        }
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };

    onSelectQuestion = (question, e, psgQ) => { }

    componentDidMount() {


        const editData = this.props?.location?.state?.editdata;

        //console.log(editData,"this.props?.location?.state?.editData",editData)

        if (editData) {
            const selectedQuestions = editData.questions
            this.setState({
                selectedQuestions: selectedQuestions,
            });
        }


        if (editData && editData.boardId) {
            this.onChangeBoard(editData.boardId, editData.classId, editData?.subjectId, editData.chapterId, editData.topicId);
        }

        if (this.props?.location?.state?.editdata?._id) {
            this.setState(prevState => ({
                onEdit: true,
                autoSelection: false,
                selfSelection: true,
                boardId: this.props.location.state.editdata.boardId
            }))
        }
        console.log(this.props, "ppppppp", this.props.userInfo)

        if (this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-list'), this.props.userInfo))) {
                        console.log("navya", t)
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }

        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
        this.getTemplates()
    }


    componentDidUpdate(prevProps, prevState) {
        console.log("first2", this.state.selectedQuestions)

        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-list'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }
    getTemplates = () => {

        this.setState({
            isLoading: true,
            examTemplates: [],
            totalRecords: 0
        });

        const academicYear = localStorage.getItem("userAcademicYear");

        let { lazyParams, globalSearch } = this.state;

        let url = `${baseUrlAdmin}/examtemplate/gettemplatesNames?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&academicYear=${academicYear}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;


        //let url = `${baseUrlAdmin}/examtemplate/gettemplatesNames`
        this.service.post(url, {}, true).then((data) => {
            if (data && data.res && data.res.data && data.res.data.length) {
                this.setState({
                    examTemplates: data.res.data,
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }



    handleSubmit = () => {

        const dataForPayload = this.state?.testData
        if (Number(dataForPayload?.noOfQuestions) !== this.state.selectedQuestions.length) {
            this.toast.show({ severity: 'error', summary: 'Please add the required number of questions.', detail: "", life: 3000 });
            return;
        }

        else {

            if (this.props.editData?._id) {
                //     const payload = exam;
                //     let url = `${baseUrlAdmin}/examtemplate/updatetemplate`;
                //     this.service.put(url, payload, true).then(res => {
                //         if (res.status && res?.res?.success) {
                //             this.toast.show({ severity: 'success', summary: ' updated succesfully', detail: "", life: 3000 });
                //             this.setState({
                //                 exam: {}
                //             });
                //         } else {
                //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                //         }
                //     }).catch((e) => {
                //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                //     });
            }
            else {
                const payload = {
                    "testType": dataForPayload?.testType,
                    "examName": dataForPayload?.examName,
                    "templateId": dataForPayload?.templateId,
                    "type": this.state?.selectedButton === 'Self' ? 'Self' : 'Auto',
                    "examType": dataForPayload?.examType,
                    "noOfQuestions": Number(dataForPayload?.noOfQuestions),
                    "totalTime": dataForPayload?.totalTime,
                    "totalMarks": dataForPayload?.totalMarks,
                    "difficulty": [
                        "Easy"
                    ],
                    "skillType": this.state?.paperLevel || [],
                    "questionType": [
                        "MCCT"
                    ],
                    "status": [],
                    "questions": this.state?.selectedQuestions,
                    "psgQuestions": [],
                    "boardId": this.state.boardId,
                    "classId": this.state.classId,
                    "groupId": this.state.groupId,
                    "subjectId": this.state.subjectId,
                    "chapterId": this.state.chapterId,
                    "topicId": this.state.topicId,
                    "boardName": this.state?.boardName,
                    "className": this.state?.className,
                    "groupName": "this.state?.groupName",
                    "subjectName": this.state?.subjectName,
                    "chapterName": this.state?.chapterName,
                    "topicName": this.state?.topicName,
                    "totalQuestions": this.state?.totalQuestions || ''

                };
                let url = `${baseUrlAdmin}/examination/add-exam-test`;
                this.service.post(url, payload, true).then(res => {
                    if (res.status && res?.res?.success) {
                        this.toast.show({ severity: 'success', summary: this.props.location.state?.editdata?._id ? ' Updated succesfully' : 'created succesfully', detail: "", life: 3000 });
                        this.setState({
                            exam: {}
                        });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch((e) => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                });
            }
        }


        // if (this.props.editData?._id) {
        //     const payload = exam;
        //     let url = `${baseUrlAdmin}/examtemplate/updatetemplate`;
        //     this.service.put(url, payload, true).then(res => {
        //         if (res.status && res?.res?.success) {
        //             this.toast.show({ severity: 'success', summary: ' updated succesfully', detail: "", life: 3000 });
        //             this.setState({
        //                 exam: {}
        //             });
        //         } else {
        //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
        //         }
        //     }).catch((e) => {
        //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
        //     });
        // }
        // else {
        // const payload = exam;
        // let url = `${baseUrlAdmin}/examtemplate/savetemplate`;
        // this.service.post(url, payload, true).then(res => {
        //     if (res.status && res?.res?.success) {
        //         this.toast.show({ severity: 'success', summary: ' created succesfully', detail: "", life: 3000 });
        //         this.setState({
        //             exam: {}
        //         });
        //     } else {
        //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        //     }
        // }).catch((e) => {
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        // });
        // }
    }


    onSelectQuestions = (e) => {

        this.setState({ selectedQuestions: e.value })
    }


    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {

        if (tableItems && tableItems.length) {
            let d = tableItems?.every(a => selectedItems?.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {

        return selectedItems?.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {

        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        })





    }

    render() {
        const { formValidations, testData } = this.state
        console.log(this.props?.location?.state?.editData?.type === 'Auto', "this.props?.location?.state?.editData?.type")
        return (
            <>
                <div className="">
                    {!this.state.generateQuestions &&

                        <div className="createtest-card " >
                            <div className="grid">

                                <div className="col-4">
                                    <label className='label'>Title of the Test<span className='ma-required'>*</span></label>
                                    <div className='mt-2 '   >
                                        <ClassetInputText
                                            id="title"
                                            className={this.state.onEdit ? "text-xl   " : ""}
                                            icon={<MailIcon height={22} />}
                                            height="48px"
                                            width="368px"
                                            placeholder="Name of the Test"
                                            value={testData.examName}
                                            onChange={(e) => { onTextChange(e.target.value, 'examName', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}

                                            disabled={this.state.onEdit}
                                        />

                                    </div>
                                    {formValidations && !formValidations.fields['examName'].isValid && <p className="p-error">{formValidations.fields['examName'].errorMsg}</p>}

                                </div>

                                <div className="col-4">
                                    <label className='label'>Test Type<span className='ma-required'>*</span></label>
                                    <div className="mt-2" >
                                        <ClassetDropdown
                                            icon={<MailIcon />}
                                            id='examType'

                                            height="48px"
                                            width="368px"
                                            className={this.state.onEdit ? "text-xl  " : ""}
                                            value={testData?.examType}
                                            // onChange={(e) => this.setState({ examType: e.value })}
                                            onChange={(e) => { onTextChange(e.target.value, 'examType', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            options={this.state.testTypes}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Type of Test</span>
                                                </div>
                                            )}
                                            disabled={this.state.onEdit}

                                        />
                                        {formValidations && !formValidations.fields['examType'].isValid && <p className="p-error">{formValidations.fields['examType'].errorMsg}</p>}


                                    </div>
                                </div>


                                <div className="col-4">
                                    <label className='label'>No. Of Questions<span className='ma-required'>*</span></label>
                                    <div className='mt-2' >
                                        <ClassetInputText
                                            id="noOfQuestions"
                                            icon={<MailIcon height={22} />}
                                            className={this.state.onEdit ? "text-xl  " : ""}
                                            height="48px"
                                            width="368px"
                                            placeholder="No. Of Questions"
                                            value={testData.noOfQuestions}

                                            onChange={(e) => { onTextChange(e.target.value, 'noOfQuestions', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            disabled={this.state.onEdit}
                                        // style={this.state.onhide ? { backgroundColor: '#C6CED2' } : {}}
                                        />

                                    </div>
                                    {formValidations && !formValidations.fields['noOfQuestions'].isValid && <p className="p-error">{formValidations.fields['noOfQuestions'].errorMsg}</p>}

                                </div>

                                {!this.state.onEdit &&

                                    <div className="col-4">
                                        <label className='label'>Total Time (in minutes)<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                id="totalTime"
                                                icon={<MailIcon height={22} />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                placeholder="00"
                                                value={testData.totalTime}
                                                onChange={(e) => { onTextChange(e.target.value, 'totalTime', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['totalTime'].isValid && <p className="p-error">{formValidations.fields['totalTime'].errorMsg}</p>}
                                    </div>

                                }


                                {!this.state.onEdit &&
                                    <div className="col-4">
                                        <label className='label'>Total Marks<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                id="totalMarks"
                                                icon={<MailIcon height={22} />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                placeholder="Total Marks"
                                                value={testData.totalMarks}
                                                onChange={(e) => { onTextChange(e.target.value, 'totalMarks', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['totalMarks'].isValid && <p className="p-error">{formValidations.fields['totalMarks'].errorMsg}</p>}
                                    </div>

                                }
                                {!this.state.onEdit &&
                                    <div className="col-4">
                                        <label className='label'>Test Format<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='testType'
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                options={testFormat}
                                                value={testData.testType}
                                                onChange={(e) => {
                                                    onDropDownChange(e.target.value, 'testType', this, creatTestFields, testData, formValidations, 'testData', formValidations)



                                                }}
                                                placeholder={(
                                                    <div>
                                                        <span className=''>Test Format</span>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['testType'].isValid && <p className="p-error">{formValidations.fields['testType'].errorMsg}</p>}
                                    </div>



                                }
                                {!this.state.onEdit && testData.testType === 'closedTest' &&
                                    <>

                                        <div className=" col-4">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Start Date & Time<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Calendar id="time24" className='h-3rem text-lg  border-round-md  border-none' placeholder="Start Date & Time" showTime showSeconds width='48px' />
                                            </div>
                                        </div>

                                        <div className=" col-4">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>End Date & Time<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>

                                                    <MailIcon />
                                                </div>
                                                <Calendar id="time24" className='h-3rem text-lg  border-round-md  border-none' placeholder="End Date & Time" showTime showSeconds />
                                            </div>
                                        </div>

                                    </>}



                                {!this.state.onEdit &&
                                    <div className="col-4">
                                        <label className='label'>Test Template<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='templateId'
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                optionValue='_id'
                                                optionLabel='name'
                                                options={this.state?.examTemplates}
                                                value={testData?.templateId}
                                                onChange={(e) => {
                                                    onDropDownChange(e.target.value, 'templateId', this, creatTestFields, testData, formValidations, 'testData', formValidations)
                                                }}
                                                placeholder={(
                                                    <div>
                                                        <span className=''>Select Test Template</span>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['templateId'].isValid && <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
                                    </div>

                                }
                            </div>
                            <div className="">
                                <div className="flex justify-content-center ">


                                    {(!this.state.onEdit || this.state.selfSelection) &&
                                        <Button className='formCancelButton'
                                            onClick={this.handleSelfSelection}
                                            // style={this.state.onhide ? { backgroundColor: '#076EFF', borderRadius: '12px', marginRight: '17px', borderWidth: 'none' } : {borderRadius: '12px'}}
                                            style={this.state.selfSelection ? { backgroundColor: '#076EFF', borderRadius: '12px', marginRight: '17px', borderWidth: 'none' } : { borderRadius: '12px' }}

                                        >
                                            <p className='formCancelButtonText' style={this.state.selfSelection ? { color: '#FFFFFF' } : {}}>Self selection</p>
                                        </Button>
                                    }
                                    {(!this.state.onEdit || this.state.autoSelection) &&
                                        <Button className="addButton ml-4 w-2 " onClick={this.handleAutoPaperGenerate}
                                            style={this.state.onEdit ? { marginRight: '10px' } : {}}>
                                            <p >Auto Paper Generate</p>
                                        </Button>
                                    }
                                    {this.state.onEdit &&
                                        <div onClick={this.handleOnEdit} className="cursor-pointer mt-2 ml-2" >
                                            <ClassetEditIcon width={32} height={32} color={'#000000'} onClick={this.onEdit} className="mt-2 " />
                                        </div>}
                                </div>
                            </div>

                        </div>}


                    {(this.state.selfSelection == true || this.state.autoSelection == true) && <div className={`createtest-card `} >
                        <div className='grid mt-2'>

                            <div className="col-3 ">
                                {/* <p>{this.state.selfSelection +"dsjfj" }</p> */}
                                <label className='label'>Board<span className='ma-required'>*</span></label>
                                <ClassetDropdown
                                    icon={<MailIcon />}
                                    value={this.state.boardId}
                                    className='w-2 mx-4 ma-w200 ma-mr20 ma-mt10'
                                    options={this.props?.boards}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board" />
                            </div>

                            <div className="col-3 ">
                                <label className='label'>Class<span className='ma-required'>*</span></label>
                                <ClassetDropdown
                                    icon={<MailIcon />}
                                    value={this.state.classId}
                                    options={this.state.classes}
                                    className='w-2 mx-4ma-mr20 ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            </div>
                            <div className="col-3 ">
                                <label className='label'>Subject<span className='ma-required'>*</span></label>
                                <ClassetMultiSelect
                                    icon={<MailIcon />}
                                    value={this.state.subjectId}
                                    options={this.state.subjects}
                                    optionLabel='subjectName'
                                    optionValue='subjectId'
                                    className='w-2 mx-4 ma-mr20 ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                            </div>
                            <div className="col-3">
                                <label className='label'>Chapter<span className='ma-required'>*</span></label>
                                <ClassetMultiSelect value={this.state.chapterId}
                                    icon={<MailIcon />}
                                    options={this.state.chapters}
                                    optionLabel='chapterName'
                                    optionValue='chapterId'
                                    className='w-2 mx-4 ma-mr20 ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                            </div>
                            <div className="col-3 ">
                                <label className='label'>Topic<span className='ma-required'>*</span></label>
                                <ClassetMultiSelect value={this.state.topicId}
                                    icon={<MailIcon />}
                                    options={this.state.topics}
                                    optionLabel='topicName'
                                    optionValue='topicId'
                                    className='w-2 mx-4 ma-mr20 ma-w200 ma-mt10'
                                    onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                            </div>


                            {(this.state.autoSelection || this.props?.location?.state?.editData?._id === 'Auto') &&
                                <div className="col-3">
                                    <label className='label'>Total Questions<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetInputText
                                            id="totalMarks"
                                            icon={<MailIcon height={22} />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Total Questions"
                                            value={this.state?.totalQuestions}
                                            onChange={(e) => this.setState({ totalQuestions: e.target.value })}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['totalMarks'].isValid && <p className="p-error">{formValidations.fields['totalMarks'].errorMsg}</p>} */}
                                </div>

                            }

                            {(this.state.autoSelection || this.props?.location?.state?.editData?._id === 'Auto') &&
                                <div className="col-3">
                                    <label className='label'>Paper Level<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetMultiSelect
                                            id='templateId'
                                            icon={<MailIcon />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={paperLevel}
                                            value={this.state?.paperLevel}
                                            onChange={(e) => this.setState({ paperLevel: e.value })}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Select Paper Level</span>
                                                </div>
                                            )}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['totalMarks'].isValid && <p className="p-error">{formValidations.fields['totalMarks'].errorMsg}</p>} */}
                                </div>

                            }

                        </div>
                    </div>}

                    {/* {(testData.selectedTestTemplate === 'custom' && testData.testFormats && !this.state.generateQuestions && !this.state.onEdit) && <div className="mt-7 flex justify-content-center">
                        <Button className="addButton ml-4 " onClick={this.handleGenerateQuestions}
                        >
                            <p >Generate Questions</p>
                        </Button>
                    </div>} */}




                    <div>
                        <div className="">
                            {this.state.onEdit &&
                                <div className="mt-5 card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt = el}
                                        className="dataTableValuesText"
                                        value={this.state?.questions || []}
                                        dataKey="_id"
                                        paginator
                                        rows={10}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        globalFilter={this.state.globalFilter}
                                        responsiveLayout="scroll"
                                        selectionMode={'checkbox'}
                                    // selection={this.state.selectedQuestions}  // Set the selected questions
                                    // onSelectionChange={(e) => this.setState({ selectedQuestions: e.value })}
                                    >
                                        <Column alignHeader={"center"} bodyClassName='text-center' className='' field='_id' header={<Checkbox inputId={'item.id'}
                                            checked={this.isSelectedAllItems(this.state.questions, this.state.selectedQuestions, '_id')}
                                            onChange={(e) => { this.selectOrdeselect('list', this.state.selectedQuestions, this.state.questions, e.checked, 'selectedQuestions', '_id') }}
                                        ></Checkbox>}
                                            body={(rowData) => {

                                                return <Checkbox inputId={'item.id'}
                                                    checked={this.isSelected(rowData, this.state.selectedQuestions, '_id')}
                                                    onChange={(e) => { this.selectOrdeselect('single', this.state.selectedQuestions, rowData, e.checked, 'selectedQuestions', '_id') }}
                                                ></Checkbox>
                                            }}>
                                        </Column>
                                        <Column field="questionId" header="Question Id" alignHeader={"center"} bodyClassName='text-center' sortable />
                                        <Column field="questionType" header="Question Type" alignHeader={"center"} bodyClassName='text-center' sortable />

                                        <Column field="question" header="Question" alignHeader={"center"} bodyClassName='text-center' sortable />
                                        {/* <Column field="solutionandkey" header="Solution and Key" alignHeader={"center"} bodyClassName='text-center'  sortable /> */}
                                        <Column filterField='question' headerClassName='question' alignHeader={"center"} header="Solution and Key" bodyClassName='text-center' sortable body={(rowData) => {
                                            return (<>
                                                <QuestionInTable question={rowData} />
                                            </>)
                                        }} />
                                        {/* <Column className=' ' header="" sortable body={(rowData, rowIndex) => {
                                            return (<>
                                                <div className="flex flex-column">
                                                    {this.state.autoSelection && !this.state.generateQuestions && <RefreshIcon onclick={this.handleRefresh} width={32} height={32} />}
                                                    {(this.state.selfSelection && !this.state.generateQuestions || (this.state.generateQuestions)) && <ClassetEditIcon width={32} height={32} color={'#000000'} />}
                                                    {(this.state.selfSelection && !this.state.generateQuestions || (this.state.generateQuestions)) && <QuestionMarkIcon width={32} height={32} className="mt-4" />}

                                                </div>
                                            </>
                                            );
                                        }} ></Column> */}

                                    </DataTable>
                                </div>
                            }

                        </div >

                    </div>

                    {this.state.onEdit && this.state?.selectedQuestions?.length > 0 &&
                        <div className="flex justify-center mt-4">
                            <Button className="addButton ml-4  " onClick={this.onCancel}
                            >
                                <p >Cancel</p>
                            </Button>
                            <Button className="addButton ml-4  " onClick={this.handleSubmit}
                            >
                                <p >{this.props?.location?.state?.editdata?._id ? "Update" : "Save"}</p>
                            </Button>

                        </div>
                    }
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }

}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});



export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(CreateTest)));
