import React, { Component } from 'react';
import './handStyles.css'
export default class HandLoader extends Component {
    render() {
        return (
            <div class="🤚">
                <div class="👉"></div>
                <div class="👉"></div>
                <div class="👉"></div>
                <div class="👉"></div>
                <div class="🌴"></div>
                <div class="👍"></div>
            </div>
        );
    }
}
